import styled from "styled-components";
import {useCentreConnectedData} from "../../../../services/hooks";
import {useGetAllUser} from "../../../../features/user/user.hooks";
import {useGetParamsTimed} from "../../../../hooks/useGetParamsTimed";
import React, {useEffect, useMemo, useState} from "react";
import {useGetCompteurTempsDateDate} from "../../../../features/compteurTempsDateDate/compteurTempsDateDate.hooks";
import {OneLineReso} from "./lineReso";
import TimedRoundAvatar from "../../../../components/atoms/TimedRoundAvatar/TimedRoundAvatar";
import {getNumWeek} from "../../../../components/functions/TimesFunctions";
import {IoWarningOutline} from "react-icons/io5";
import TimedSkeletonTable from "../../../../components/atoms/TimedSkeleton/TimedSkeletonTable";
import TimedTable from "../../../../components/molecules/TimedTable/TimedTable";
import {TimedModalCentral} from "../../../../components/molecules/TimedModal/TimedModalCentral";
import {FormResolutionHeuresSupp} from "../../../Forms/FormResolutionHeuresSupp";
import {TimedModalPrePage} from "../../../../components/molecules/TimedModal/TimedModalPrePage";
import TimedPlanning from "../../Plannings/PlanningIndiHebdo/TimedPlanning";
import TimedModal2 from "../../../../components/molecules/TimedModal/TimedModal2";
import {TimedProfilShort} from "../../../../components/organisms/TimedProfilShort/TimedProfilShort";
import {TimedSoldeCPShort} from "../../../../components/organisms/TimedProfilShort/TimedSoldeCPShort";
import {TimedCTShort} from "../../../../components/organisms/TimedProfilShort/TimedCTShort";
import TimedButton from "../../../../components/atoms/TimedButton/TimedButton";

interface ItemGestionHSCtrlProps{
    className?:string;
    From:Date;
    To:Date;
    supervisor?:boolean;
    type:string
}

const TitlesTab:TitleTable[]=[
    {libelle:'Pic', field:'avatar', fieldSort:'nom', date:false, textAlign:'text-center', width:"80px", StyleHead:{textAlign:"center"}},
    {libelle:'Initiales', field:'inits', fieldSort:'inits', date:false, textAlign:'text-center', StyleHead:{textAlign:"center"}},
    {libelle:'Nom', field:'fullName', fieldSort:'fullNameLib', date:false, textAlign:'', width:"160px"},
    {libelle:'Heures Ct', field:'heuresCt', fieldSort:'heuresCt_nb', date:false, textAlign:'', width:"120px"},
    {libelle:'Heures Tr.', field:'heuresDid', fieldSort:'heuresDid_nb', date:false, textAlign:'text-center', StyleHead:{textAlign:"center"}},
    {libelle:'Heures Non Tr.', field:'heuresNoWork', fieldSort:'heuresNoWork_nb', date:false, textAlign:'text-center', StyleHead:{textAlign:"center"}},
    {libelle:'Heures Regulées.', field:'heuresRegules', fieldSort:'heuresRegules_nb', date:false, textAlign:'text-center', StyleHead:{textAlign:"center"}},
    {libelle:'Heures Pauses.', field:'heuresPauses', fieldSort:'heuresPauses_nb', date:false, textAlign:'text-center', StyleHead:{textAlign:"center"}},
    {libelle:'Total heures', field:'heuresTotales', fieldSort:'heuresTotales_nb', date:false, textAlign:'text-center', StyleHead:{textAlign:"center"}},
    {libelle:'Ecart', field:'ecart', fieldSort:'ecart_nb', date:false, textAlign:'text-center', StyleHead:{textAlign:"center"}},
]

const ItemGestionHSCtrl = ({className, From, To, supervisor, type}:ItemGestionHSCtrlProps)=>{
    const centre = useCentreConnectedData();
    const {isLoading:loadUsers, data:users} = useGetAllUser(supervisor ? centre : null);
    const {TabCollab, TabFx} = useGetParamsTimed();
    const [usersConc, setUsersConc] = useState<User[]>([]);
    const [alertBadChoice, setAlertBadChoice] = useState(false);
    const CptsQuery = useGetCompteurTempsDateDate(From.toISOString().slice(0,10), To.toISOString().slice(0,10), usersConc.map(u=>u.id), supervisor ? centre : null);
    const [openReso, setOpenReso] = useState<boolean>(false);
    const [openQuickPl, setOpenQuickPl] = useState<boolean>(false);
    const [openChoice, setOpenChoice] = useState<boolean>(false);
    const [openShortProfil, setOpenShortProfil] = useState<boolean>(false);
    const [lineCurrent, setLineCurrent] = useState<OneLineReso|null>(null);
    const [UserCurrent, setUserCurrent] = useState({id:0, prenom:'', nom:'', idFx:0})
    const [endOverToday, setEndOverToday] = useState(false);
    useEffect(()=>{
        const Today = new Date();
        Today.setHours(12,0,0)
        const myTo = To;
        myTo.setHours(12,0,0)
        setEndOverToday(myTo>=Today || (myTo.toISOString().slice(0,10) === Today.toISOString().slice(0,10)));
    },[To])
    const handleClickLine = (e:any, item:OneLineReso)=>{
        const cible = e.target.className;
        if(['btn-go'].indexOf(cible) === -1) {
            setLineCurrent(item);
            setOpenReso(true);
        }

    }

    useEffect(()=>{
        setAlertBadChoice(false);
        if(users){
            if(TabCollab){
                setUsersConc(users.filter(u=>TabCollab.indexOf(u.id.toString())!==-1));
            }else if(TabFx){
                if(TabFx.length === 1 && TabFx[0] === "1"){
                    setUsersConc([]);
                    setAlertBadChoice(true);
                } else {
                    setUsersConc(users.filter(u=>TabFx.indexOf(u.Fonction.id.toString())!==-1));
                }
            } else {
                setUsersConc(users);
            }
        }
    }, [users, TabCollab, TabFx])
    const ClickName = (e:any, id:number)=>{
        e.preventDefault();
        const myUser = users?.find(u=>u.id === id);
        if(myUser){
            setUserCurrent({id:myUser.id, prenom: myUser.prenom, nom:myUser.nom, idFx:myUser.Fonction.id});
        }
        setOpenChoice(o=>!o);
    }
    useEffect(()=>{
        if(openQuickPl || openShortProfil){
            setOpenChoice(false);
        }
    }, [openQuickPl, openShortProfil])
    const lineFiltered = useMemo(()=>{
        const list:OneLineReso[] = []
        if(usersConc && CptsQuery.data){
            usersConc.map(user=>{
                const myCpts = CptsQuery.data.filter(c=>c.User.id === user.id);
                let Ecart = 0;
                let Tot = 0;
                let heuresCt = 0;
                let heuresDid = 0;
                let heuresNoWork = 0;
                let heuresRegules = 0;
                let heuresPauses = 0;
                myCpts.forEach(myCpt=>{
                    Ecart+=Math.round(((myCpt.heuresFaites+myCpt.heuresNoW+myCpt.regules-myCpt.Pause) - myCpt.heuresAFaire)*100)/100;
                    Tot += Math.round((myCpt.heuresFaites + myCpt.heuresNoW + myCpt.regules - myCpt.Pause) * 100) / 100;
                    heuresCt += Math.round(myCpt.heuresAFaire * 100) / 100;
                    heuresDid += Math.round(myCpt.heuresFaites * 100) / 100;
                    heuresNoWork += Math.round(myCpt.heuresNoW * 100) / 100;
                    heuresRegules += Math.round(myCpt.regules * 100) / 100;
                    heuresPauses -= Math.round(myCpt.Pause * 100) / 100;
                })
                if(myCpts.length>0 && heuresCt>0){
                    if(Ecart!==0) {
                        list.push({
                            id: user.id,
                            avatar: <TimedRoundAvatar src={user.avatarUrl !== undefined ? user.avatarUrl : ''} size={"sm"}/>,
                            fullName: <span className={`btn-go`} onClick={(e)=>ClickName(e, user.id)} style={{fontWeight:"bold"}}>{user.prenom + ' ' + user.nom}</span>,
                            fullNameLib: user.prenom + ' ' + user.nom,
                            inits: user.initials,
                            heuresCt: heuresCt,
                            heuresDid: <span className={`infos_nb ${heuresDid<0 ? 'warning' : 'success'}`}>{(heuresDid)>0 ? '+' : ''}{(heuresDid)!==0 ? heuresDid : '-'}</span>,
                            heuresNoWork: <span className={`infos_nb ${heuresNoWork<0 ? 'warning' : 'success'}`}>{(heuresNoWork)>0 ? '+' : ''}{(heuresNoWork)!==0 ? heuresNoWork : '-'}</span>,
                            heuresRegules: <span className={`infos_nb ${heuresRegules<0 ? 'warning' : 'success'}`}>{heuresRegules>0 ? '+' : ''}{heuresRegules!==0 ? heuresRegules : '-'}</span>,
                            heuresPauses: <span className={`infos_nb warning`}>{heuresPauses!==0 ? heuresPauses : '-'}</span>,
                            heuresTotales: <span className={`infos_nb ${Tot<0 ? 'warning' : 'success'}`}>{Tot>0 ? '+' : ''}{Tot}</span>,
                            ecart: <span className={`infos_nb ${Ecart<0 ? 'warning' : 'success'}`}>{Ecart > 0 ? "+":""}{Ecart}</span>,
                            heuresCt_nb: heuresCt,
                            heuresDid_nb: heuresDid,
                            heuresNoWork_nb: heuresNoWork,
                            heuresRegules_nb: heuresRegules,
                            heuresPauses_nb: heuresPauses,
                            heuresTotales_nb: Tot,
                            ecart_nb: Ecart,
                        })
                    }
                }
                return user;
            })
        }
        return list;
    }, [usersConc, CptsQuery.data])
    return (
        <div className={`one_w_h_s ${className}`}>
            <div className={`line_titre`}>
                <div className={`numSem`}>{type === 'Mensuelle' ? "" : `S${getNumWeek(From)}`}{From.toLocaleDateString()} au {To.toLocaleDateString()}</div>
                {endOverToday &&
                    <div className={"wrap_alert_date_over"}>
                        <div className={"alert_date_over"}>
                            <IoWarningOutline/>
                            <span>{type === 'Mensuelle' ? "Ce mois" : "Cette Semaine"} n'est pas encore écoulé{type === 'Mensuelle' ? "" : "e"}, il s'agit d'une projection planning</span>
                        </div>
                    </div>
                }
            </div>
            <div className={'body-hs'} style={{height:"100%"}}>
                {(loadUsers || CptsQuery.isLoading) ?
                    <TimedSkeletonTable nbColumns={6} nbLines={5} /> :
                    <>
                        <div className={"wrap-tab"} style={{height:"100%"}}>
                            <TimedTable
                                Titles={TitlesTab}
                                sortable={true}
                                themeColor={"Primary"}
                                Outline={true}
                                Lines={lineFiltered ? lineFiltered : []}
                                scrollable={true}
                                loading={false}
                                maxHeight={"calc(100% - 100px)"}
                                onClickLine={handleClickLine}
                                isTooAction={true}
                            />
                        </div>
                    </>
                }
            </div>
            <TimedModalCentral closeMe={()=>setOpenReso(false)} open={openReso} Pending={false} titre={"Résolution Heures"} Wd={850}>
                <FormResolutionHeuresSupp From={From} To={To} Line={lineCurrent} closeModal={()=>setOpenReso(false)}/>
            </TimedModalCentral>
            <TimedModalPrePage
                Orientation={"top"}
                Hei={68}
                UniteSize={"%"}
                open={openQuickPl}
                closeMe={()=>setOpenQuickPl(o=>!o)}
                titre={"Planning de "+UserCurrent.prenom+" "+UserCurrent.nom+" "+new Date(From).toLocaleDateString()+" au "+new Date(To).toLocaleDateString()}
                linkExpand={{libelle:"Planning Hebdo", link:`../planning_indi_hebdo?idCollab=${UserCurrent.id}&from=${new Date(From).toLocaleDateString()}&to=${new Date(To).toLocaleDateString()}`}}
            >
                <div className={`wrap_planning_modal`} style={{padding:"1rem"}}>
                    <TimedPlanning
                        dateFrom={new Date(From)}
                        dateTo={new Date(To)}
                        myHeightHour={28}
                        idUser={UserCurrent.id}
                    />
                </div>
            </TimedModalPrePage>
            <TimedModal2 closeMe={()=>setOpenShortProfil(o=>!o)} titre={"Profil"} open={openShortProfil} Wd={"500px"}>
                <TimedProfilShort idUser={UserCurrent.id}/>
                {UserCurrent.idFx !==1 &&
                    <TimedSoldeCPShort idUser={UserCurrent.id}/>
                }
                <TimedCTShort idUser={UserCurrent.id} DateCalc={From} Tempo={"Hebdomadaire"} />
            </TimedModal2>
            <TimedModalCentral closeMe={()=>setOpenChoice(o=>!o)} open={openChoice} Pending={false} titre={"Que souhaitez-vous voir ?"}>
                <div className={"wrap_btns"}>
                    <TimedButton
                        size={"sm"}
                        text={"Planning"}
                        onClick={()=>setOpenQuickPl(o=>!o)}
                        isFull={true}
                        themeColor={"Primary"}
                    />
                    <TimedButton
                        size={"sm"}
                        text={"Profil"}
                        onClick={()=>setOpenShortProfil(o=>!o)}
                        isFull={true}
                        themeColor={"Primary"}
                    />
                </div>
            </TimedModalCentral>
        </div>
    )
}

export const ItemGestionHS = styled(ItemGestionHSCtrl)`
  margin-bottom: 10px;
  height: 100%;
  .wrap_alert_date_over{
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    .alert_date_over{
      display: flex;
      justify-content: flex-start;
      gap: 6px;
    }
  }
  .wrap_planning_modal{
    padding: 1rem;
  }
  .wrap-tab{
  }
  .btn-go{

    &:hover{
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .infos_nb{
    &.warning{
      color: ${props => props.theme.Complementary};
    }
    &.success{
      color: ${props => props.theme.Secondary};
    }
  }
  .line_titre{
    display: flex;
    justify-content: flex-start;
    gap: 5px;
    align-items: center;
    width: 100%;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background: ${props => props.theme.PrimaryExtraLight};
    padding: 0.5rem;
    font-size: 14px;
    font-weight: bold;
    &:hover{
      cursor: pointer;
    }
    .SlideDown{

    }
    .wrap-chevron{
      transform: rotate(0deg);
      transition: transform 0.3s;
      display: flex;
      flex-direction: column;
      justify-content: center;
      &.close{
        transform: rotate(180deg);
        transition: transform 0.3s;
      }
    }
  }

  .body-hs{
    position: relative;
    background: white;
  }
`