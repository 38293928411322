import React from "react";
import styled from "styled-components";
import {NavLink} from "react-router-dom";
import {myBoxShadow} from "../../../assets/styles/elements";

interface TimedSideLinkCtrlProps{
    className?:string;
    isOpen:boolean;
    libelle:string;
    icon:React.ReactNode;
    link:string;
    sectionName:string|null;
    thisSection:string;
}

const TimedSideLinkCtrl = ({className, isOpen, libelle, icon, link, sectionName, thisSection}:TimedSideLinkCtrlProps)=>{
    const isActive = ()=>{
        return sectionName === thisSection;
    }
    return (
        <NavLink to={link} className={`TimedItemMenu ${className} ${isActive() ? 'isActive' : ''} ${isOpen ? 'opened' : 'closed'}`} data-info={libelle}>
            {isOpen ?
                <div className={`wrap-opened`}>
                    <div className={`icon-place`}>{icon}</div>
                    <div className={`lib-place`}>{libelle}</div>
                </div>:
                <div className={`wrap-close`}>{icon}</div>
            }
        </NavLink>
    )
}

const TimedSideLink = styled(TimedSideLinkCtrl)`
  text-decoration: none;
  width: 100%;
  padding: 0.75rem 0.85rem;
  display: block;
  font-size: 16px;
  position: relative;
  color:${props=>props.theme.colorLight}!important;
  svg{
    color: white;
    path{
      stroke: white;
    }
  }
  &.closed{
    &:after{
      content: attr(data-info);
      position: absolute;
      left: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      top: 0;
      ${myBoxShadow};
      height: 100%;
      padding: 0;
      background: ${props=>props.theme.Primary};
      color: white;
      text-wrap: none;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 0;
      transition: width 0.4s;
      border-bottom-right-radius: 0.4rem;
      border-top-right-radius: 0.4rem;
    }
    &:hover:after{
      width: 180px;
      white-space: nowrap;
      text-wrap: none;
      
      transition: width 0.4s;
    }
  }
  .wrap-close{
    position: relative;
    display: flex;
    justify-content: center;
    font-size: 22px;
    
  }
  .wrap-opened{
    display: flex;
    justify-content: start;
    .lib-place{
      margin-left: 10px;
    }
  }
  &:hover, &.isActive{
    cursor: pointer;
    background: ${props=>props.theme.Primary};
  }
`

export default TimedSideLink;