import React from "react";
import styled from "styled-components";
import {BiChevronRight, BiChevronsLeft} from "react-icons/bi";

interface TimedOpenCloseSideBarCtrlProps{
    className?:string;
    isOpen:boolean;
    setIsOpen:Function;
}

const TimedOpenCloseSideBarCtrl = ({className, isOpen, setIsOpen}:TimedOpenCloseSideBarCtrlProps)=>{
    return (
        <div className={`OpenCloseSideBar ${className}`} onClick={()=>setIsOpen((open:boolean)=>!open)}>
            {isOpen ? <BiChevronsLeft/> : <BiChevronRight/>}
        </div>
    )
}

const TimedOpenCloseSideBar = styled(TimedOpenCloseSideBarCtrl)`
  border-radius: 50%;
  background: ${props => props.theme.Primary};
  width: 30px;
  height: 30px;
  box-shadow: -1px -1px 3px rgba(0,0,0,.2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  &:hover{
    cursor: pointer;
  }
  svg{
    font-size: 22px;
    margin: auto;
    color: white;
  }
`

export default TimedOpenCloseSideBar;