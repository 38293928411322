import styled from "styled-components";
import {
    useCreateNewLieuVuePlanning,
    useGetAllLieuVuePlanningByVP, useTrashLieuVuePlanning
} from "../../../../features/vuePlanning/vuePlanning.hooks";
import {useEffect, useState} from "react";
import ItemCochable from "../components/ItemCochable";
import SquareIndic from "../components/SquareIndic";
import LibCard from "../components/LibCard";
import LoadDatas from "../components/LoadDatas";

interface FormEditVPCtrlProps{
    className?:string;
    Lieux:Lieu[];
    Vue:VuePlanning;
}
interface OneLieuVP extends Lieu{
    idLVue:number;
}

const FormEditVPCtrl = (props:FormEditVPCtrlProps)=>{
    const LieuVPQuery = useGetAllLieuVuePlanningByVP(props.Vue.id)
    const [myLieuxIn, setMyLieuxIn] = useState<OneLieuVP[]>([])
    const mutationAdd = useCreateNewLieuVuePlanning();
    const [idInTreat, setIdInTreat] = useState<number>(0)
    const mutationTrash = useTrashLieuVuePlanning()
    useEffect(() => {
        if(LieuVPQuery.data){
            setMyLieuxIn(LieuVPQuery.data.filter(lvp=>lvp.VuePlanning.id === props.Vue.id).map(lvp=> {
                return {...lvp.Lieu, idLVue:lvp.id}
            }))
        }
    }, [LieuVPQuery.data]);
    const AddLieu = (l:OneLieuVP)=>{
        setMyLieuxIn(lx=>{
            return [...lx, l]
        })
        SaveBDAdd(l)
    }
    const SaveBDAdd = (l:OneLieuVP)=>{
        const datas:PostLieuVuePlanningProps={
            VuePlanning:`/api/vue_plannings/${props.Vue.id}`,
            Lieu:`/api/lieus/${l.id}`
        }
        setIdInTreat(l.id)
        mutationAdd.mutate(datas, {
            onSuccess:()=>{
                setIdInTreat(0)
            }
        })
    }
    const SaveBDTrash = (l:OneLieuVP)=>{
        setIdInTreat(l.id)
        mutationTrash.mutate(l.idLVue, {
            onSuccess:()=>{
                setIdInTreat(0)
            }
        })
    }
    const RemoveLieu = (l:OneLieuVP)=>{
        setMyLieuxIn(lx=>{
            return [...lx.filter(ly=>ly.id !==l.id)]
        })
        SaveBDTrash(l);
    }
    const SwitchLieu = (l:Lieu)=>{
        const Exist = myLieuxIn.find(lx=>lx.id === l.id)
        if(Exist){
            RemoveLieu(Exist)
        } else {
            AddLieu({...l, idLVue: 0})
        }
    }
    return (
        <div className={`formEditVp ${props.className}`}>
            {LieuVPQuery.isLoading ?
                <LoadDatas/>:
                <>
                    {props.Lieux.map(item=>(
                        <ItemCochable isCurrent={myLieuxIn.map(i=>i.id).indexOf(item.id)!==-1} clickOn={()=>SwitchLieu(item)} key={`oneL${item.id}`} pending={idInTreat === item.id}>
                        <div className="content_lieu">
                            <SquareIndic backColor={myLieuxIn.map(i=>i.id).indexOf(item.id)===-1 ? item.backColor+"50" : item.backColor} fontColor={item.fontColor}><span>A</span></SquareIndic>
                            <LibCard text={item.libelle} isLight={myLieuxIn.map(i=>i.id).indexOf(item.id)===-1}/>
                        </div>
                    </ItemCochable>
                ))}
                </>
            }

        </div>
    )
}

const FormEditVP = styled(FormEditVPCtrl)`
    display: flex;
    flex-direction: column;
    gap: 10px;
    .content_lieu{
        display: flex;
        justify-content: flex-start;
        gap: 10px;
        align-items: center;
    }
`

export default FormEditVP