import React from "react";
import styled from "styled-components";

interface TextStdCtrlProps{
    className?:string;
    firstLine:string;
    sdLine?:string;
}

const TextStdCtrl = ({className, firstLine, sdLine}:TextStdCtrlProps)=>{
    return (
        <div className={`text-under ${className}`}>
            <div className={"first-line"} >{firstLine}</div>
            {sdLine &&
                <div className={"second-line"} >{sdLine}</div>
            }
        </div>
    )
}

export const TextStd = styled(TextStdCtrl)`
    padding: 0.5rem;
  font-size: 13px;
  .first-line{
    text-align: center;
    color:${props => props.theme.NeutreDark};
    font-weight: bold;
  }
  .second-line{
    text-align: center;
    color:${props => props.theme.NeutreDark};
  }
`