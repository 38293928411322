import React, {ReactNode, useEffect, useState} from "react";
import styled from "styled-components";
import {Shine} from "../../../assets/styles/elements";

interface TimedSkeletonListCtrlProps{
    className?:string;
    nbBar?:number;
    qty?:number;
}

const TimedSkeletonListCtrl = ({className, nbBar, qty}:TimedSkeletonListCtrlProps)=>{
    const [rows, setRows] = useState<ReactNode[]>([]);
    useEffect(()=>{
        let idx = 1;
        const myQty = !!qty ? qty : 3;
        let TabNodes:ReactNode[] = [];
        while (idx<=myQty){
            let TabBar:ReactNode[] = [];
            let idxB = 1;
            const myNBBar = !!nbBar ? nbBar : 1;
            while(idxB<=myNBBar){
                TabBar.push(<div className={`bar-ske`} key={`B${idxB}`}/>)
                idxB++;
            }
            TabNodes.push(
                <div className={`line-ske`}>
                    <div className={`round-ske`}/>
                    {TabBar}
                </div>
            )
            idx++
        }
        setRows(TabNodes);
    }, [qty, nbBar])
    return (
        <div className={`TimedSkeList ${className}`}>
            {rows.map((item:ReactNode,idx:number)=>(
                <div className={`wrap-line-ske`} key={`wls${idx}`}>
                    {item}
                </div>
            ))}
        </div>
    )
}

const $Wid = "28px"

const TimedSkeletonList = styled(TimedSkeletonListCtrl)`
    width: 100%;
    .wrap-line-ske{
      padding: 0.25rem 0;
      width: 100%;
    }
    .line-ske{
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 20px;
    }
    .round-ske{
      width: ${$Wid};
      height: ${$Wid};
      border-radius: 50%;
      background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
      background-size: 200% 100%;
      animation: 1.5s ${Shine} linear infinite;
      flex-shrink: 0;
    }
  .bar-ske{
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    background-size: 200% 100%;
    animation: 1.5s ${Shine} linear infinite;
    height: 16px;
    border-radius: 12px;
    width: 20%;
    &:nth-child(2){
      flex-grow: 1;
      
    }
  }
    
`

export default TimedSkeletonList;