import {useMutation} from "react-query";
import {addNavigation} from "./navigation.services";


export const useAddNavigation = ()=>{
    return useMutation(
        (datas:AddNavigation)=>{
            return addNavigation(datas);
        }
    )
}