import styled from "styled-components";
import {useModifyUserRole} from "../../../../features/user/user.hooks";
import {useMemo, useRef, useState} from "react";
import {GrClose} from "react-icons/gr";
import {TimedUserAvatar} from "../../../../components/molecules/_TimedUserAvatar/TimedUserAvatar";
import {IoMdSearch} from "react-icons/io";
import TimedButton from "../../../../components/atoms/TimedButton/TimedButton";

interface FormAddAdminCtrlProps{
    className?:string;
    Users:User[];
    closeMe:(a:User)=>void;
}

const FormAddAdminCtrl = (props:FormAddAdminCtrlProps)=>{
    const mutation = useModifyUserRole();
    const [search, setSearch] = useState("")
    const refS = useRef<HTMLInputElement>(null)
    const [adminSet, setAdminSet] = useState<User|null>(null)
    const handleChangeSearch = ()=>{
        if(refS.current) setSearch(refS.current.value);
    }
    const listUserFiltered = useMemo(()=>{
        if(!props.Users){
            return []
        } else {
            const regex = new RegExp(""+search+"", 'gi');
            return props.Users.filter(u=>u.roles.indexOf("ROLE_ADMIN")===-1 && u.isActif && (search === "" || (`${u.prenom} ${u.nom.toUpperCase()}`).match(regex)))
        }
    }, [props.Users, search])
    const SaveAdmin = ()=>{
        if(adminSet){
            const datas:RolePP={
                RoleNiveau:7,
                isRestreint:0,
                id:adminSet.id
            }
            mutation.mutate(datas, {
                onSuccess:(u:User)=>{
                    props.closeMe(u);
                }
            })
        }
    }
    return (
        <div className={`formAddAdmin ${props.className}`}>
            <div className="wrap_search">
                <div className="in_search">
                    <div className="wrap_icon">
                        <IoMdSearch />
                    </div>
                    <input type={"text"} ref={refS} value={search} onChange={handleChangeSearch} placeholder={"Recherche"}/>
                    {search!==""&&
                        <button className={"close_search"} onClick={()=>setSearch("")}>
                            <GrClose/>
                        </button>
                    }
                </div>
            </div>
            <div className="wrap_list">
                {listUserFiltered.map(user=>(
                    <div className={`one_choice ${user.id === adminSet?.id ? "selected" :  ""}`} key={`U${user.id}`} onClick={()=>setAdminSet(user)}>
                        <TimedUserAvatar User={user} RightAddInit/>
                    </div>
                ))}
            </div>
            {adminSet &&
                <div className="footer_add">
                    <TimedButton themeColor={"Primary"} isFull={true} text={`Ajouter ${adminSet.prenom}`} onClick={SaveAdmin} isPending={mutation.isLoading}/>
                </div>
            }
        </div>
    )
}

const FormAddAdmin = styled(FormAddAdminCtrl)`
    display: flex;
    flex-direction: column;
    max-height: 70vh;
    .footer_add{
        padding: 1rem 0.5rem;
    }
    .wrap_search{
        width: 100%;
        padding-bottom: 1rem;
        .close_search{
            position: absolute;
            border-radius: 4px;
            right: 5px;
            top: 50%;
            transform: translateY(-50%);
            width: 30px;
            height: 30px;
            background: red;
            color: white;
            display: flex;
            flex-direction: column;
            justify-content: center;
            svg{
                margin: auto;
            }
        }
        .in_search{
            width: 100%;
            padding-left: 5px;
            position: relative;
            display: flex;
            justify-content: flex-start;
            gap: 6px;
            align-items: center;
            height: 36px;
            background: ${props => props.theme.colorBackInput};
            input{
                width: 100%;
                background: transparent;
                border: none;
                padding-left: 5px;
                height: 32px;
            }
        }
    }
    .wrap_list{
        flex-grow: 1;
        overflow-y: auto;
    }
    .one_choice{
        padding: 0.25rem;
        &:hover, &.selected{
            cursor: pointer;
            background: ${props=>props.theme.PrimaryMegaLight};
        }
    }
`

export default FormAddAdmin