import styled from "styled-components";
import {useGetCptsAbsOneUser, useGetCptsAbsTabUser} from "../../../features/compteurAbs/compteurAbs.hooks";
import React, {useMemo} from "react";
import {BlockInfosShort} from "./BlockInfosShort";
import {OneInfosShort} from "./OneInfosShort";

interface TimedSoldeCPShortCtrlProps{
    className?:string;
    idUser:number;
}

interface InfosCP{
    Periode:Periode,
    Pris:number;
    Acquis:number;
    Solde:number;
    Dispo:number;
}

const TimedSoldeCPShortCtrl = (props:TimedSoldeCPShortCtrlProps)=>{
    const CptQuery = useGetCptsAbsOneUser(props.idUser)
    const MyInfoCP:InfosCP|null = useMemo(()=>{
        if(!CptQuery.data){
            return null;
        } else {
            const DateNow = new Date();
            const PeriodesCP = CptQuery.data[0].Periodes.find(p=>new Date(p.Periode.debutAt) <=DateNow && new Date(p.Periode.finAt) >=DateNow && p.Periode.Type.id === 1)
            if(!PeriodesCP){
                return null;
            } else {
                return {
                    Periode:PeriodesCP.Periode,
                    Pris:PeriodesCP.Pris,
                    Acquis:PeriodesCP.Acquis,
                    Solde:PeriodesCP.Solde,
                    Dispo:PeriodesCP.Dispo
                }
            }
        }
    }, [CptQuery.data])
    return (
        <div className={`solde_cp ${props.className}`}>
            <BlockInfosShort title={`Compteur Congés Payés ${MyInfoCP ? MyInfoCP.Periode.libelle : ''}`}>
                {!MyInfoCP ?
                    <p>Aucune période de Congés payés trouvée</p>:
                    <>
                        <OneInfosShort title={"Solde"} info={`${MyInfoCP.Solde} j`} width={"50%"}/>
                        <OneInfosShort title={"Disponibles"} info={`${MyInfoCP.Dispo} j`} width={"50%"}/>
                    </>
                }
            </BlockInfosShort>
        </div>
    )
}

export const TimedSoldeCPShort = styled(TimedSoldeCPShortCtrl)`
  padding: 1rem;
`
