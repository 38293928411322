import React from "react";
import styled from "styled-components";

interface TimedTabsCtrlProps{
    className?:string;
    themeColor:string;
    selected:string;
    choices:string[];
    handlerChoice:Function;
}

const TimedTabsCtrl = ({className, selected, choices, handlerChoice}:TimedTabsCtrlProps)=>{
    return (
        <div className={`TimedTabs ${className}`}>
            {choices.map((item, idx)=>(
                <div key={`OTab${idx}`} className={`oneTab ${item === selected ? 'selected' : ''}`} onClick={()=>handlerChoice(item)}>{item}</div>
            ))}
        </div>
    )
}

const TimedTab = styled(TimedTabsCtrl)`
  display: flex;
  justify-content: start;
  width: 100%;
  box-shadow: 0px 1px 5px rgba(0,0,0,.1);
  
  padding: 0.5rem 0.5rem 0 0.5rem;
  .oneTab{
    flex-grow: 1;
    flex-shrink: 0;
    padding: 0rem 0.25rem 0.5rem 0.25rem;
    border-bottom: solid transparent 3px;
    text-align: center;
    color:${props => props.themeColor === "Primary" ? props.theme.PrimaryLight :
            props.themeColor === "Secondary" ? props.theme.SecondaryLight :
                    props.themeColor === "Tertiary" ? props.theme.TertiaryLight : props.theme.ComplementaryLight
    };
    &:hover{
      cursor: pointer;
      color:${props => props.themeColor === "Primary" ? props.theme.Primary :
              props.themeColor === "Secondary" ? props.theme.Secondary :
                      props.themeColor === "Tertiary" ? props.theme.Tertiary : props.theme.Complementary
      };
    }
    &.selected {
      color:${props => props.themeColor === "Primary" ? props.theme.Primary :
              props.themeColor === "Secondary" ? props.theme.Secondary :
                      props.themeColor === "Tertiary" ? props.theme.Tertiary : props.theme.Complementary
      };
      border-bottom-color: ${props => props.themeColor === "Primary" ? props.theme.Primary :
              props.themeColor === "Secondary" ? props.theme.Secondary :
                      props.themeColor === "Tertiary" ? props.theme.Tertiary : props.theme.Complementary
      };
    }
  }
`

export default TimedTab;