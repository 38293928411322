import styled from "styled-components";
import InputTextSetting from "./InputTextSetting";
import {useSetLitts} from "../../../../features/centre/centre.hooks";
import {useEffect, useState} from "react";
import {useDebounce} from "../../../../services/hooks";

interface SettingsTextLittsCtrlProps{
    className?:string;
    keyLitts:string;
    currentValue:string;
    litts:any;
    idCentre:number;
    InfosProp:InfosProps;
    label:string;
    type:"text"|"number"
}

const SettingsTextLittsCtrl = (props:SettingsTextLittsCtrlProps)=>{
    const mutation = useSetLitts();
    const [myValueX, setMyValueX] = useState<string>(props.currentValue)
    const myValueSaved = useDebounce(myValueX, 300)
    const setMyValue = (value:string|null|Choice|number, id:string)=>{
        setMyValueX(value as string)
    }
    useEffect(() => {
        const actual = {...props.litts}
        const newLitts = {...actual, [props.keyLitts]:props.type === "number" ? parseInt(myValueSaved) : myValueSaved}
        mutation.mutate({idCentre:props.idCentre, litt:newLitts})
    }, [myValueSaved]);
    return (
        <div className={`settingsTextLitts ${props.className}`}>
            <InputTextSetting
                wdLib={"35%"}
                type={props.type}
                current={myValueX}
                setValue={setMyValue}
                Error={(props.type === "number" && parseFloat(myValueX+"") < 0) ? "doit être positif" : null}
                id={props.keyLitts}
                label={props.label}
                subLabel={props.InfosProp.description}
            />
        </div>
    )
}

const SettingsTextLitts = styled(SettingsTextLittsCtrl)``

export default SettingsTextLitts