import styled from "styled-components";
import {ReactNode} from "react";

interface SquareIndicCtrlProps{
    className?:string;
    backColor?:string;
    fontColor?:string;
    children:ReactNode;
}

const SquareIndicCtrl = (props:SquareIndicCtrlProps)=>{
    return (
        <div className={`squareIndic ${props.className}`}>
            {props.children}
        </div>
    )
}

const SquareIndic = styled(SquareIndicCtrl)`
    width: 32px;
    height: 32px;
    border-radius: 4px;
    background: ${props => props.backColor ? props.backColor : "transparent"};
    color:  ${props => props.fontColor ? props.fontColor : "black"};
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    span{
        text-align: center;
    }
    img{
        width: 100%;
    }
`

export default SquareIndic