import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import {isAuth, isAuthSV, isAuthWM} from "../../services/security/isAuth";



interface SupervisorRouteCtrlProps {
    from: string;
    children?:React.ReactNode;
}

function SupervisorRoute(props: SupervisorRouteCtrlProps) {
    const location = useLocation();
    return isAuthSV() ? <Outlet /> : <Navigate to="/login" state={{ from: location.pathname }} />;
}

export default SupervisorRoute;