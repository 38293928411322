import React from "react";
import styled from "styled-components";
import {TimedWMSideBar} from "../../molecules/TimedFullSideBar/TimedWMSideBar";
import {Outlet} from "react-router-dom";

interface MainLayoutWMCtrlProps{
    className?:string;
}

const MainLayoutWMCtrl = ({className}:MainLayoutWMCtrlProps)=>{
    return (
        <div className={`MainLayoutWM ${className}`}>
            <TimedWMSideBar/>
            <div className={`MainLAyWM`}>
                <Outlet/>
            </div>
        </div>
    )
}

export const MainLayoutWM = styled(MainLayoutWMCtrl)`
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: stretch;
  
  .MainLAyWM{
    overflow: hidden;
    flex-grow: 1;
    position: relative;
    height: 100vh;
    overflow-y: auto;
  }
`