import styled from "styled-components";
import {Shine} from "../../../../assets/styles/elements";

interface OneLineLivePointageSkeletonCtrlProps{
    className?:string;
    widthUser:number;
}

const OneLineLivePointageSkeletonCtrl = (props:OneLineLivePointageSkeletonCtrlProps)=>{
    const leftOne = Math.floor(Math.random() * 30);
    const widthOne=20;
    return (
        <div className={`line_pointage_ske ${props.className}`}>
            <div className={`user_ske`} style={{width:props.widthUser+"%"}}>
                <div className={`avatar_ske skely`}/>
                <div className={`name_ske skely`}/>
            </div>
            <div className={`times_ske`}style={{width:(100-props.widthUser)+"%"}}>
                <div className={`plage_ske skely`} style={{left:leftOne+"%", width:widthOne+"%"}} />
                <div className={`plage_ske skely`} style={{left:(leftOne+widthOne+5)+"%", width:widthOne+"%"}} />
            </div>
        </div>
    )
}

export const OneLineLivePointageSkeleton = styled(OneLineLivePointageSkeletonCtrl)`
    height: 46px;
    display: flex;
    justify-content: flex-start;
  .skely{
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    background-size: 200% 100%;
    animation: 1.5s ${Shine} linear infinite;
  }
  .times_ske{
    height: 100%;
    position: relative;
  }
  .plage_ske{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 15px;
    height: 32px;
  }
    .user_ske{
      height: 100%;
      flex-wrap: wrap;
      padding: 0.25rem;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 12px;
      .avatar_ske{
        width: 40px;
        height: 40px;
        border-radius: 4px;
      }
      .name_ske{
        width: 150px;
        height: 30px;
      }
    }
`