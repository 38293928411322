import React from "react";
import styled from "styled-components";
import logo from "../../assets/images/logo-timed-horizontal-black-8.png"

interface WarningNoDesktopCtrlProps{
    className?:string
}

const WarningNoDesktopCtrl = ({className}:WarningNoDesktopCtrlProps)=>{
    return (
        <div className={`WarningNoDesk ${className}`}>
            <div className={`wrap-img`}>
                <img className={`imgLogo`} src={logo} alt={"logo"}/>
            </div>
            <div className={`text`}>
                <p>Depuis un mobile, rendez-vous directement sur l'application Timed.</p>
                <a href={"https://appmobile.timed.fr"} className={`bouton`}>Ouvrir l'application mobile</a>
                <p>Pour plus d'informations veuillez vous rapprochez de votre équipe RH</p>
            </div>
        </div>
    )
}

export const WarningNoDesktop = styled(WarningNoDesktopCtrl)`
  padding:1rem 3rem;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 100px;
  p{
    text-align: center;
    color: ${props => props.theme.PrimarySuperDark};
    font-size: 22px;
  }
  .bouton{
    text-decoration: none;
    display: block;
    width: 100%;
    padding: 0.75rem 0.75rem;
    text-align: center;
    border-radius: 6px;
    background: ${props => props.theme.Secondary};
    color:white;
    font-size: 20px;
    margin: 30px auto;
  }
  .imgLogo{
    width: 50%;
    margin: auto;
  }
  .wrap-img{
    text-align: center;
    margin-bottom: 30px;
  }
`