import styled from "styled-components";
import {TitrePageNoMarg} from "../../../../assets/styles/elements";
import React, {createContext, useEffect, useMemo, useState} from "react";
import {useGetProjectionsST} from "../../../../features/semaineType/semaineType.hooks";
import {useGetCentreActuel} from "../../../../features/centre/centre.hooks";
import {useGetAllLieuBesoinByCentre} from "../../../../features/lieuBesoin/lieuBesoin.hooks";
import {TimedLoaderWrapper} from "../../../../components/atoms/TimedWaitUser/TimedLoaderWrapper";
import {useGetAllHoraires} from "../../../../features/horaires/horaires.hooks";
import {useGetParamsTimed} from "../../../../hooks/useGetParamsTimed";
import {getIsHorseBase5SameDay} from "../../../../components/functions/PlageFunctions";
import {Tab} from "@mui/material";
import {Mesures} from "../PlanningGlobalEquipe/PlanningGlobalEquipe";
import {TabJour, TabJourISO} from "../../../../services/constantes/constantes";
import {ProjSegmentation} from "./components/ProjSegmentation";
import {useModal} from "../../../../hooks/useModal";
import {TimedModalCentral2} from "../../../../components/molecules/TimedModal/TimedModalCentral2";
import {ModalPreEditST} from "../../../Modaux/ModalPreEditST";

interface ProjectionSTCtrlProps {
    className?:string;
}

export interface OneItemProj{
    user:User,
    numJ:number,
    HD:number,
    HF:number,
    Lieu:SimpleLBA,
    Acte:SimpleLBA,
    id:number;
}

export interface OneBesoinTips{fx:string, need:number, have:number}

export interface OneDayProj{
    numJ:number,
    isPbBesoin:boolean,
    BesoinTips:OneBesoinTips[]
    items:OneItemProj[]
}

export interface OneActeProj{
    Acte:SimpleLBA,
    Days:OneDayProj[]
}

export interface OneLieuProj{
    Lieu:SimpleLBA,
    Actes:OneActeProj[]
}

export interface OneSegmentationProj{
    libelle:string,
    id:number;
    Lieux:OneLieuProj[]
}

interface ContextProjection{
    EditST?:(item:OneItemProj)=>void
}
export const ContextSemaineTypeProj = createContext<ContextProjection>({})

const ProjectionSTCtrl = ({className}: ProjectionSTCtrlProps) => {
    const ProjectionsQuery = useGetProjectionsST();
    const HorairesQuery = useGetAllHoraires();
    const CentreQuery = useGetCentreActuel();
    const [idCentre, setIdCentre] = useState(0);
    const LieuxBesoinsQuery = useGetAllLieuBesoinByCentre(idCentre);
    const [choicesProjection, setChoicesProjection] = useState<Choice[]>([])
    const [rotationCurrent, setRotationCurr] = useState<Choice|null>(null)
    const {TabLieu, TabCollab, TabFx} = useGetParamsTimed();
    const [measures, setMeasures] = useState<Mesures>({segmentations:0, actes:0, lieux:0})
    const [ItemOnEdit, setItemOnEdit] = useState<OneItemProj|null>(null)
    const {open, toggle} = useModal()
    useEffect(() => {
        if(CentreQuery.data) setIdCentre(CentreQuery.data.id)
    }, [CentreQuery.data]);
    useEffect(()=>{
        if(ProjectionsQuery.data){
            const Rotations:number[] = ProjectionsQuery.data.map(p=>p.numRotation);
            setChoicesProjection(Rotations.map(r=>{
                return {id:r, libelle:r+""}
            }))
        }
    }, [ProjectionsQuery.data])
    const EditMe = (item:OneItemProj)=>{
        setItemOnEdit(item);
        toggle()
    }
    const LieuxConcerned = useMemo(()=>{
        let TabL:SimpleLBA[] = [];
        if(ProjectionsQuery.data && LieuxBesoinsQuery.data && rotationCurrent){
            const ProjectionsConcerned = ProjectionsQuery.data.filter(p=>p.numRotation === rotationCurrent.id);
            ProjectionsConcerned.forEach(p=>{
                p.semainesTypes.forEach(st=>{
                    const Lieu = st.Lieu;
                    if(TabL.map(i=>i.id).indexOf(Lieu.id)===-1){
                        TabL.push({
                            id:Lieu.id,
                            libelle:Lieu.libelle,
                            backColor:Lieu.backColor,
                            fontColor:Lieu.fontColor
                        })
                    }
                })
            })
            LieuxBesoinsQuery.data.forEach(lb=>{
                if(TabL.map(i=>i.id).indexOf(lb.Lieu.id)===-1){
                    TabL.push(lb.Lieu)
                }
            })
        }
        if(TabLieu){
            TabL = TabL.filter(l=>TabLieu.indexOf(l.id+"")!==-1)
        }
        return TabL.sort((a,b)=>a.libelle > b.libelle ? 1 :-1 );
    }, [ProjectionsQuery.data, LieuxBesoinsQuery.data, TabLieu, rotationCurrent])

    const UsersConcerned = useMemo(()=>{
        let TabU:User[] = [];
        if(ProjectionsQuery.data && rotationCurrent){
            const ProjectionsConcerned = ProjectionsQuery.data.filter(p=>p.numRotation === rotationCurrent.id);
            ProjectionsConcerned.forEach(p=>{
                p.semainesTypes.forEach(st=>{
                    const User = st.user;
                    if(TabU.map(i=>i.id).indexOf(User.id)===-1){
                        TabU.push(User)
                    }
                })
            })
        }
        if(TabFx){
            TabU = TabU.filter(u=>TabFx.indexOf(u.Fonction.id+"")!==-1)
        }
        if(TabCollab){
            TabU = TabU.filter(u=>TabCollab.indexOf(u.id+"")!==-1)
        }
        return TabU;
    }, [TabCollab, TabFx, ProjectionsQuery.data, rotationCurrent])
    const ListSegmentation = useMemo(()=>{
        if(!ProjectionsQuery.data || !LieuxBesoinsQuery.data || !HorairesQuery.data || !rotationCurrent){
            return [];
        } else if(! ProjectionsQuery.data.find(p=>p.numRotation === rotationCurrent.id)){
            return []
        } else {
            const ProjectionsRotation = ProjectionsQuery.data.find(p=>p.numRotation === rotationCurrent.id);
            const semainesTypes = !ProjectionsRotation ? [] : ProjectionsRotation.semainesTypes.filter(st=>UsersConcerned.map(u=>u.id).indexOf(st.user.id)!==-1);
            const TabSegmentation:OneSegmentationProj[] = [];
            HorairesQuery.data.forEach(h=>{
                const LieuBesoinConcerned:LieuBesoin[] = [];
                LieuxBesoinsQuery.data.forEach(lb=>{
                    const MyLieuxBesoinsActe = lb.lieuBesoinActes.filter(lba=>{
                        const end = lba.end<lba.start ? lba.end +288 : lba.end
                        return getIsHorseBase5SameDay(lba.start, end, h.startSeg, h.endSeg)
                    });
                    if(MyLieuxBesoinsActe.length>0){
                        LieuBesoinConcerned.push({...lb, lieuBesoinActes:MyLieuxBesoinsActe})
                    }
                })
                const SemainesTypesConcerned = semainesTypes.filter(st=>{
                    return getIsHorseBase5SameDay(st.HeureDebut, st.HeureFin, h.startSeg, h.endSeg)
                })
                const TabLieuxProj:OneLieuProj[] = [];
                LieuxConcerned.forEach(l=>{
                    const LieuLieuBesoinConcerned = LieuBesoinConcerned.filter(lb=>lb.Lieu.id === l.id)
                    const SemainesTypesConcernedLieu = SemainesTypesConcerned.filter(st=>st.Lieu.id === l.id)
                    const Simple_ActesLLBC = LieuLieuBesoinConcerned.reduce((acc:LieuBesoinActe[], item)=>[...acc, ...item.lieuBesoinActes], []).map(i=>i.Acte);
                    const ActesTypes:SimpleLBA[] = SemainesTypesConcernedLieu.map(st=> {
                        return {id:st.Acte.id, libelle:st.Acte.libelle, backColor:st.Acte.backColor, fontColor:st.Acte.fontColor}
                    })
                    const RotatesActes:SimpleLBA[] = [...Simple_ActesLLBC, ...ActesTypes];
                    const RotateActesSSD = RotatesActes.reduce((acc:SimpleLBA[], item:SimpleLBA)=>acc.map(i=>i.id).indexOf(item.id)===-1 ? [...acc, item] : acc, [])
                    const TabActesProj:OneActeProj[] = [];
                    RotateActesSSD.forEach(a=>{
                        const LieuxBesoinActesConcerned = LieuLieuBesoinConcerned.reduce((acc:LieuBesoinActe[], item)=>[...acc, ...item.lieuBesoinActes], []).filter(lba=>lba.Acte.id === a.id)
                        const SemainesTypeConcernedActe = SemainesTypesConcernedLieu.filter(st=>st.Acte.id === a.id);
                        const TabDaysProj:OneDayProj[] = []
                        for(let i=0; i<=6; i++){
                            const SemainesTypeConcernedDay = SemainesTypeConcernedActe.filter(st=>st.numJour === i);
                            const LieuxBesoinsActeThisDay = LieuxBesoinActesConcerned.filter(lba=>lba.jour === (i+1))
                            let FonctionsLBA = LieuxBesoinsActeThisDay.map(lba=>lba.Fonction)
                            const FonctionST = SemainesTypeConcernedDay.map(st=>st.user.Fonction)
                            FonctionsLBA = [...FonctionsLBA, ...FonctionST].reduce((acc:Fonction[], item:Fonction)=>acc.map(i=>i.id).indexOf(item.id)===-1 ? [...acc, item] : acc, []);
                            const TabBes:{fx:string, need:number, have:number}[]= []
                            let nbP=0;
                            FonctionsLBA.filter(fx=>!TabFx || TabFx.indexOf(fx.id+"")!==-1).forEach(fx => {
                                const StFonction = SemainesTypeConcernedDay.filter(p => p.user.Fonction.id === fx.id)
                                const Needs = LieuxBesoinsActeThisDay.filter(lba => lba.Fonction.id === fx.id)
                                TabBes.push({
                                    fx:fx.libelle,
                                    need:Needs.length,
                                    have:StFonction.length
                                })
                                if(Needs.length !== StFonction.length) nbP++;
                            })
                            TabDaysProj.push({
                                numJ:i,
                                isPbBesoin:nbP>0,
                                BesoinTips:TabBes,
                                items:SemainesTypeConcernedDay.map(st=>{
                                    return {
                                        user:st.user,
                                        numJ:st.numJour,
                                        HD:st.HeureDebut,
                                        HF:st.HeureFin,
                                        Lieu:st.Lieu,
                                        Acte:st.Acte,
                                        id:st.id,

                                    }
                                })
                            })
                        }
                        TabActesProj.push({
                            Acte:a,
                            Days:TabDaysProj
                        })
                    })
                    if(TabActesProj.length>0) {
                        TabLieuxProj.push({
                            Lieu: l,
                            Actes: TabActesProj
                        })
                    }
                })
                TabSegmentation.push({
                    libelle:h.libelle,
                    id:h.id,
                    Lieux:TabLieuxProj
                })
            })
            return TabSegmentation;
        }
    }, [ProjectionsQuery.data, LieuxBesoinsQuery.data, UsersConcerned, LieuxConcerned, HorairesQuery.data, rotationCurrent])
    const getWidthText = (text:string)=>{
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        if(context){
            context.font = getComputedStyle(document.body).font;
            return context.measureText(text).width;
        } else {
            return 0;
        }
    }
    useEffect(() => {
        let maxWidthSeg = 60;
        let maxWidthActe = 0;
        let maxWidthLieux = 0;
        const TabLibSeg:string[] = ListSegmentation.map(i=>i.libelle);
        const TabLibActes:string[] = [];
        const TabLibLieux:string[] = [];
        ListSegmentation.forEach(item=>{
            item.Lieux.forEach(l=>{
                TabLibLieux.push(l.Lieu.libelle)
                l.Actes.forEach(a=>{
                    TabLibActes.push(a.Acte.libelle)
                })
            })
        })

        const SegsSSD = TabLibSeg.reduce((acc:string[], item:string)=>acc.indexOf(item)===-1 ?[...acc, item] : acc, [])
        SegsSSD.map(s=>{
            if(getWidthText(s) > maxWidthSeg){
                maxWidthSeg = getWidthText(s);
            }
            return s;
        })

        const ActesSSD = TabLibActes.reduce((acc:string[], item:string)=>acc.indexOf(item)===-1 ?[...acc, item] : acc, [])
        ActesSSD.map(s=>{
            const Texts = s.split(" ");
            Texts.forEach(t=>{
                if(getWidthText(t) > maxWidthActe){
                    maxWidthActe = getWidthText(t);
                }
            })
            return s;
        })

        const lieuxSSD = TabLibLieux.reduce((acc:string[], item:string)=>acc.indexOf(item)===-1 ?[...acc, item] : acc, [])
        lieuxSSD.map(s=>{
            const Texts = s.split(" ");
            Texts.forEach(t=>{
                if(getWidthText(t) > maxWidthLieux){
                    maxWidthLieux = getWidthText(t);
                }
            })
            return s;
        })

        setMeasures({segmentations:maxWidthSeg, lieux:maxWidthLieux, actes:maxWidthActe})
    }, [ListSegmentation]);
    return (
        <div className={`projection ST ${className}`}>
            <TitrePageNoMarg>Projection Semaines Types</TitrePageNoMarg>
            <div className="content_projection">
                {ProjectionsQuery.isLoading || LieuxBesoinsQuery.isLoading || CentreQuery.isLoading || HorairesQuery.isLoading ?
                    <TimedLoaderWrapper width={"60px"}/>:
                    <>
                        <div className="introduction">
                            Cette vue vous permet de voir une compilation de toutes les semaines types de votre
                            centre.<br/>
                            Le nombre de rotations est basé sur le plus grand nombre de semaines types séquencées de vos
                            collaborateurs.<br/>
                            Le code couleur est une comparaison dynamique aux besoins, pour évaluer si votre planning
                            des besoins et vos semaines types sont cohérents entre eux.<br/>
                            Cette vue est filtrable par personne et pas lieux.
                        </div>
                        <div className="choice_rotation">
                            {choicesProjection.map(item=>(
                                <button className={`choice_proj ${item.id === rotationCurrent?.id ? "current" : ""}`} key={`R${item.id}`} onClick={()=>setRotationCurr(item)}>
                                    <span>{item.id}</span>
                                </button>
                            ))}
                        </div>
                        <div className="content_cal">
                            {!rotationCurrent ?
                                <p className={`choose_st`}>Merci de choisir une rotation ci-dessus</p>:
                                <div className="in_content_cal">
                                    <div className={`header_pl`}>
                                        <div className={`part_empty`}
                                             style={{width: (measures.segmentations + measures.lieux + measures.actes + 34) + "px"}}/>
                                        <div className={`wrap_jours`}>
                                            {TabJour.map(d => (
                                                <div style={{width: (100 / 7) + "%"}} className={"one_date_head"}
                                                     key={`head_date${d.num}`}>
                                                    <div className={`in_date`}>{d.name}</div>
                                                </div>
                                            ))}
                                        </div>
                                        <div style={{width: "4px"}}/>
                                    </div>
                                    <div className={`in_planning`}  id={"in_planning"}>
                                        <ContextSemaineTypeProj.Provider value={{EditST:EditMe}}>
                                            {ListSegmentation.map((item, idx)=>(
                                                <ProjSegmentation ProjSeg={item} measures={measures} key={`projSeg${idx}`}/>
                                            ))}
                                        </ContextSemaineTypeProj.Provider>
                                    </div>
                                </div>
                            }
                        </div>
                    </>
                }
            </div>
            <TimedModalCentral2 closeMe={()=> {
                setItemOnEdit(null)
                toggle()
            }} Wd={"350px"} open={open} Pending={false} titre={"Edition de la semaine type"}>
                {ItemOnEdit &&
                    <ModalPreEditST CloseMe={()=> {
                        setItemOnEdit(null)
                        toggle()
                    }} Item={ItemOnEdit}/>
                }
            </TimedModalCentral2>
        </div>
    )
}

export const ProjectionST = styled(ProjectionSTCtrl)`
    padding: 1% 2%;
    .introduction{
        line-height: 170%;
    }
    .choose_st{
        padding: 0.5rem;
        font-style: italic;
        font-size: 18px;
        text-align: center;
        color: ${props => props.theme.Neutre};
    }
    .choice_rotation{
        padding: 1rem 0.5rem;
        display: flex;
        justify-content: center;
        gap: 12px;
    }
    .choice_proj{
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 25px;
        height: 25px;
        text-align: center;
        border:solid ${props => props.theme.Primary} 1px;
        border-radius: 4px;
        span{
            text-align: center;
            display: block;
            width: 100%;
        }
        &:hover, &.current{
            cursor: pointer;
            background: ${props=>props.theme.Primary};
            color:white;
        }
    }
    .header_pl{
        display: flex;
        justify-content: flex-start;
        margin-bottom: 6px;
        .wrap_jours{
            display: flex;
            justify-content: flex-start;
            flex-grow: 1;
            .one_date_head{
                .in_date{
                    span{
                        display: inline-block;
                        text-align: center;
                    }
                    border: 4px;
                    //border-radius: 8px;
                    background: white;
                    padding: 0.2rem;
                    display: flex;
                    justify-content: center;
                    height: 100%;
                }
                .in_date_head{
                    display: flex;
                    justify-content: center;
                    gap: 4px;
                    font-size: 13px;
                    font-weight: bold;
                    position: relative;
                    &:hover{
                        cursor: pointer;

                    }
                    &.has-notes{
                        text-decoration: underline;
                    }
                    .mark_infos{
                        position: absolute;
                        left: -25px;
                        top: 0px;
                    }
                }
            }
        }
    }
`