import React from "react";
import styled, {useTheme} from "styled-components";
import logo from '../../../assets/images/logo-timed-horizontal-black-8.png'
import {Dna, ThreeDots} from "react-loader-spinner";


interface TimedSuspenseCtrlProps{
    className?:string;
}



const TimedSuspenseCtrl = ({className}:TimedSuspenseCtrlProps)=>{
    const theme = useTheme();
    return (
        <div className={`TimedSuspense ${className}`}>
            <div className={"message-wait"}>
                <div className={`logoWait`}>
                    <div className={`logo-place`}><img className={'my-logo'} src={logo} alt={"Timed"}/></div>
                    <div className={"spinner"}>
                        <ThreeDots
                            height="30"
                            width="40"
                            radius="9"
                            color={theme.Primary}
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}

                            visible={true}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

const TimedSuspenseStyled = styled(TimedSuspenseCtrl)`
   position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100%;
  background: white;
  .message-wait{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    .logoWait{
      display: flex;
      justify-content: start;
      align-items: baseline;
      .logo-place{
        height: 45px;
        img{height: 100%}
        margin-right: 20px;
      }
    }
    
  }
`
const TimedSuspense = React.memo(TimedSuspenseStyled);

export default TimedSuspense;