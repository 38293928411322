import styled from "styled-components";
import {useGetNewsByParams, useModifyNews, useModifyOrdreNews} from "../../../../features/news/news.hooks";
import React, {useCallback, useEffect, useState} from "react";
import update from "immutability-helper";
import {NewsMoveCard} from "./NewsMoveCard";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import {toast} from "react-toastify";

interface ModalGestionPositionCtrlProps {
    className?:string;
}

const ModalGestionPositionCtrl = ({className}: ModalGestionPositionCtrlProps) => {
    const Today = new Date();
    const NewsQuery = useGetNewsByParams({isActive:1, "startAt[before]":Today.toISOString().slice(0,10)})
    const [listNews, setListNews] = useState<News[]>([])
    const mutation = useModifyOrdreNews();
    useEffect(() => {
        if(!NewsQuery.data){
            setListNews( []);
        } else {
            const Today = new Date(new Date().setHours(0,0));

            // const myN = NewsQuery.data.filter(n=>!n.endAt || new Date(n.endAt) >= Today).sort((a:News, b:News)=>{
            //     return new Date(a.startAt) > new Date(b.startAt) ? -1 : 1;
            // })
            setListNews(NewsQuery.data.filter(n=>!n.endAt || new Date(n.endAt) >= Today).sort((a:News, b:News)=>{
                return a.ordreAffichage > b.ordreAffichage ? 1 : -1;
            }));

        }
    }, [NewsQuery.data]);
    const moveNews = useCallback((dragIndex: number, hoverIndex: number) => {
        setListNews((prevCards: News[]) =>
            update(prevCards, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, prevCards[dragIndex] as News],
                ],
            }),
        )
        //SaveMove();
    }, [])
    const SaveMove = ()=>{
        const TabTo: { id:number, ordreAffichage:number }[] = []
        listNews.forEach((n, index)=>{
            TabTo.push({
                id:n.id,
                ordreAffichage:index+1
            })
        })
        TabTo.forEach(i=>{
            mutation.mutate(i, {
                onSuccess:()=>{
                    toast.success("Ordre des news modifié")
                }, onError:()=>{
                    toast.error("Oups erreur de datas")
                }
            })
        })
    }
    const renderNews = useCallback(
        (item:News, index: number) => {
            return (
                <NewsMoveCard
                    key={`Acte${item.id}`}
                    draggable={true}
                    News={item}
                    Move={moveNews}
                    index={index}
                    SaveMove={SaveMove}
                    id={item.id}
                />
            )
        },
        [listNews],
    )
    return (
        <div className={`gestion_position_news ${className}`}>
            {NewsQuery.isLoading ? 'Chargement...':
                <div className={`wrap-news`}>
                    <DndProvider backend={HTML5Backend}>
                        <div className={'trame-seq'}>{listNews.map((card, i) => renderNews(card, i))}</div>
                    </DndProvider>
                </div>
            }
        </div>
    )
}

export const ModalGestionPosition = styled(ModalGestionPositionCtrl)`
    .trame-seq{
        display: flex;
        flex-direction: column;
        gap: 4px;    
    }
`