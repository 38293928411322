import styled from "styled-components";

interface SquareColorSettingCtrlProps{
    className?:string;
    color:string;
    ClickOn:()=>void;
}

const SquareColorSettingCtrl = (props:SquareColorSettingCtrlProps)=>{
    return (
        <div className={`squareColorSetting ${props.className}`} onClick={()=>props.ClickOn()}/>
    )
}

const SquareColorSetting = styled(SquareColorSettingCtrl)`
    width: 32px;
    height: 32px;
    border-radius: 4px;
    background: ${props => props.color};
    border: solid ${props => props.theme.Neutre} 1px;
    &:hover{
        cursor: pointer;
    }
`

export default SquareColorSetting