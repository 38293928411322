import styled from "styled-components";
import React from "react";
import {GrClose} from "react-icons/gr";

interface LibelleCurrentSmartCtrlProps{
    className?:string;
    Remove:()=>void;
    ClickOnMe:()=>void;
    text:string;
    themeColor:"Primary"|"Secondary"|"Tertiary"|"Complementary";
    Icon:React.ReactNode
    Actif:boolean;
    placeholder:string;
}

const LibelleCurrentSmartCtrl = ({className, Remove, text, Icon, Actif, placeholder, ClickOnMe}:LibelleCurrentSmartCtrlProps)=>{
    return (
        <div className={`lib_smart ${className}`}>
            <div className={`RoundIcon`}>{Icon}</div>
            <div className="wrap_libelle">
                <div className={`libelle`} onClick={()=>ClickOnMe()}><span>{Actif ? text : placeholder}</span></div>
                {Actif &&
                    <div className={"close-choice"} onClick={()=>Remove()}><GrClose/></div>
                }
            </div>
        </div>
    )
}

export const LibelleCurrentSmart = styled(LibelleCurrentSmartCtrl)`
    font-size: 14px;
    width: 100%;
    border-radius: 4px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
      background: ${props => !props.Actif ? "transparent" : props.themeColor==="Primary" ? props.theme.PrimaryExtraLight :
              props.themeColor === "Complementary" ? props.theme.ComplementaryExtraLight :
                      props.themeColor === "Secondary" ? props.theme.SecondaryExtraLight : props.theme.TertiaryExtraLight}
    ;
  
  &:hover{
    cursor: pointer;
    .libelle span{
      font-weight: bold;
    }
    
  }
  .close-choice{
    width: 30px;
    height: 26px;
    padding: 0 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    svg path {
      stroke: ${props =>!props.Actif ? props.theme.Neutre : props.themeColor === 'Primary' ? props.theme.Primary :
              props.themeColor === 'Secondary' ? props.theme.Secondary :
                      props.themeColor === 'Tertiary' ? props.theme.Tertiary : props.theme.Complementary};
    }

    &:hover {
      cursor: pointer;
    }
  }
  .wrap_libelle{
    display: flex;
    height: 28px;
    justify-content: flex-start;
    flex-grow: 1;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    overflow: hidden;
    .libelle{
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      overflow: hidden;
    }
    border-color: ${props => props.themeColor==="Primary" ? props.theme.PrimaryMegaLight :
            props.themeColor === "Complementary" ? props.theme.ComplementaryMegaLight :
                    props.themeColor === "Secondary" ? props.theme.SecondaryMegaLight : props.theme.TertiaryMegaLight};
    border-width: 1px 1px 1px 0;
    border-style: solid;
  }
    .libelle{
      flex-grow: 1;
      overflow: hidden;
      padding: 0 0.4rem;
      
      
      span {
        width: 100%;
        font-weight: 400;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      color: ${props => props.themeColor==="Primary" ? props.theme.PrimaryDark :
              props.themeColor === "Complementary" ? props.theme.ComplementaryDark :
                      props.themeColor === "Secondary" ? props.theme.SecondaryDark : props.theme.TertiaryDark}
    ;
    }
    .RoundIcon{
      width: 28px;
      height: 28px;
      text-align: center;
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-size: 18px;
      color: white;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      background: ${props => props.themeColor==="Primary" ? props.theme.PrimaryDark :
              props.themeColor === "Complementary" ? props.theme.ComplementaryDark :
                      props.themeColor === "Secondary" ? props.theme.SecondaryDark : props.theme.TertiaryDark}
      ;
      svg{
        stroke:white;
        margin: auto;
      }
    }
`