import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import TimedRoundAvatar from "../TimedRoundAvatar/TimedRoundAvatar";
import {BiChevronDown} from "react-icons/bi";

interface TimedSelectUserCtrlProps{
    className?:string;
    ChoicesUser:User[];
    setFieldValue:Function;
    Current:string;
    label:string;
    placeHolder:string;
    id:string;
    isReq:boolean;
    posLeft?:string;
    posTop?:string;
    MaxList?:string;
}

const TimedSelectUserCtrl = ({placeHolder,label,className, ChoicesUser, setFieldValue, Current, id, isReq}:TimedSelectUserCtrlProps)=>{
    const [open, setOpen] = useState(false);
    const [userCurrent, setUserCurrent] = useState<User|null>(null);
    const refObj = useRef<HTMLDivElement>(null);
    useEffect(() => {
        function handleClickOutside(event:any) {
            if (refObj.current && !refObj.current.contains(event.target)) {
                setOpen(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [refObj]);
    useEffect(()=>{
        if(Current!=='' && ChoicesUser){
            const myChoice = ChoicesUser.find(u=>u.fullName === Current);
            if(myChoice){
                setUserCurrent(myChoice);
            }
        } else {
            setUserCurrent(null);
        }
    }, [Current, ChoicesUser])
    const handleClickOnIt =()=>{
        if(!open){
            setOpen(true);
        }
    }
    const handleClickChoice = (userChoice:User)=>{
        setFieldValue(id, userChoice.fullName, { shouldDirty: true })
        setOpen(false);
    }
    return (
        <div className={`ChoiceUser ${className}`} ref={refObj}>
            <div className={`wrap-input`} onClick={handleClickOnIt}>
                <label>{label} {isReq && '*'}</label>
                <div className={`front-input`}>
                    {userCurrent ?
                        <div className={`item-choice`}>
                            <div className={`avat-place`}>
                                <TimedRoundAvatar BoxShadow={"1px 1px 3px rgba(0,0,0,.7)"} Border={true} src={userCurrent.avatarUrl!==undefined ? userCurrent.avatarUrl : '' } size={"other"} sizeP={"24px"}/>
                            </div>
                            <div className="name">{userCurrent.fullName}</div>
                        </div>:
                        <div className={`placeHolder`}>{placeHolder}{userCurrent}</div>
                    }
                    <div className={`iconPlace`}><BiChevronDown/></div>
                </div>
            </div>
            {open &&
                <div className={"listChoice"}>
                    <div className={`in-list-user`}>
                        {ChoicesUser.map((u:User, idx:number)=>(
                            <div className={`item-choice isToChoice`} key={`oneChoiceU${idx}`} onClick={()=>handleClickChoice(u)} >
                                <TimedRoundAvatar BoxShadow={"1px 1px 3px rgba(0,0,0,.7)"} Border={true} src={u.avatarUrl!==undefined ? u.avatarUrl : '' } size={"other"} sizeP={"24px"}/>
                                <div className="name">{u.fullName}</div>
                            </div>
                        ))}
                    </div>
                </div>
            }
        </div>
    )
}

const TimedSelectUser = styled(TimedSelectUserCtrl)`
  background: white;
  padding: 0.5rem;
  position: relative;
  .placeHolder{
    color: #ccc;
    padding: 0.35rem;
  }
  &:hover{
    cursor: pointer;
  }
  .listChoice{
    position: absolute;
    padding: 0.25rem 0;
    z-index: 99;
    top:${props=>props.posTop ? props.posTop : "90%"};
    left: ${props=>props.posLeft ? props.posLeft : "0"};
    background: white;
    min-width: 100%;
    width: max-content;
    height: ${props=>props.MaxList ? props.MaxList : "120px"};
    .in-list-user{
      height: 100%;
      overflow-y: auto;  
    }
    box-shadow: 1px 1px 3px rgba(0,0,0,.2);
  }
  .item-choice{
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 10px;
    padding: 0.25rem 0.5rem;
    .name{
      font-weight: bold;
      font-size: 14px;
      flex-grow: 1;
    }
    &.isToChoice{
      &:hover{
        cursor: pointer;
        background: ${props=>props.theme.PrimaryExtraLight};
      }
    }
  }
  .wrap-input{
    border: solid #ccc 1px;
    position: relative;
    border-radius: 4px;
    &.onError{
      border-color: red;
    }
    label{
      font-size: 11px;
      position: absolute;
      padding: 0 0.25rem;
      left: 5px;
      top: 0;
      transform: translateY(-50%);
      background: white;
      color:rgba(0,0,0,0.6)
    }
    .front-input{
      width: 100%;
      padding: 0.2rem;
      display: flex;
      justify-content: start;
      align-items: center;
      .item-choice, .placeHolder{
        flex-grow: 1;
      }
      .iconPlace{
        padding: 0.25rem;
      }
    }

  }
`

export default TimedSelectUser;