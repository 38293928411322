import styled from "styled-components";
import {useRef} from "react";
import {useDrag, useDrop} from "react-dnd";
import {Identifier, XYCoord} from "dnd-core";
import {ItemTypes} from "../../Plannings/SemaineType/ItemTypes";
import {MdDragIndicator} from "react-icons/md";

interface NewsMoveCardCtrlProps {
    className?:string;
    News:News;
    Move:(dragIndex: number, hoverIndex: number)=>void
    SaveMove:()=>void;
    index:number;
    id:number;
    draggable:boolean;
}
interface DragItem{
    id:number,
    index:number;
}

const NewsMoveCardCtrl = ({className, News, Move, SaveMove, index, id, draggable}: NewsMoveCardCtrlProps) => {
    const ref = useRef<HTMLDivElement>(null)
    const [{ handlerId }, drop] = useDrop<
        DragItem,
        void,
        { handlerId: Identifier | null }
    >({
        accept: ItemTypes.NEWS,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            }
        },
        drop(item:DragItem){
            SaveMove();
        },
        hover(item: DragItem, monitor) {
            if (!ref.current) {
                return
            }
            const dragIndex = item.index
            const hoverIndex = index

            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect()

            // Get vertical middle
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2


            // Determine mouse position
            const clientOffset = monitor.getClientOffset()

            // Get pixels to the top
            const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return
            }

            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%

            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return
            }

            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return
            }

            // Time to actually perform the action

            Move(item.index, index)
            item.index = hoverIndex


        },
    })
    const [{ isDragging }, drag] = useDrag({
        type: ItemTypes.NEWS,
        item: () => {
            return { id, index }
        },
        collect: (monitor: any) => ({
            isDragging: monitor.isDragging(),
        }),
        canDrag:draggable
    })
    const opacity = isDragging ? 0.5 : 1
    drag(drop(ref))
    return (
        <div className={`News_move ${draggable ? 'is-draggable' : ''} ${className}`} style={{opacity:opacity}} ref={ref}  data-handler-id={handlerId}>
            <div className="titre">{News.titre}</div>
            <div className="wrap_icon">
                <MdDragIndicator />
            </div>
        </div>
    )
}

export const NewsMoveCard = styled(NewsMoveCardCtrl)`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .titre{
        flex-grow: 1;
    }
    .wrap_icon{
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
    padding: 0.5rem 1rem;
    border-radius: 4px;
    border:solid ${props=>props.theme.NeutreLight} 1px;
`