import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {BsCheck} from "react-icons/bs";

import {RiCloseCircleFill} from "react-icons/ri";

interface TimedChipsCtrlProps{
    className?:string;
    themeColor?:string;
    setChoice:Function;
    MyChoice:Choice;
    choices:Choice[];
    Remove?:boolean
    withOutChoche?:boolean;
    BackColorPerso?:string;
    BorderColorPerso?:string;
    ColorPerso?:string;
    dbClickChoice?:Function;

}

const TimedChipsCtrl = ({className, MyChoice, setChoice, choices, Remove, withOutChoche, dbClickChoice}:TimedChipsCtrlProps)=>{
    const [isCheck, setIsCheck] = useState(false);
    useEffect(()=>{
        const idsChoices = choices.map(c=>c.id);
        setIsCheck(idsChoices.indexOf(MyChoice.id)!==-1);
    }, [choices,MyChoice])
    const handleChange = ()=>{
        setChoice(MyChoice)
    }
    const handleDDClck = ()=>{
        if(dbClickChoice){
            dbClickChoice(MyChoice, true);
        }
    }
    return (
        <div className={`TimedChips ${className} ${isCheck ? 'Checked': ''} ${Remove && 'isRemove'}`}>
            <div className={`content-chips`} onClick={handleChange} onDoubleClick={handleDDClck}>
                {(isCheck && !Remove && !withOutChoche) &&
                    <BsCheck/>
                }
                <span>{MyChoice.libelle}</span>
                {(isCheck && Remove) &&
                    <RiCloseCircleFill/>
                }
            </div>
        </div>
    )
}

const TimedChips = styled(TimedChipsCtrl)`
    padding: 0.25rem;
  span{
    font-size: 14px;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
  }
  &:hover{
    cursor: pointer;
  }
  &.isRemove{
    svg{
      margin-right: -4px;
      margin-left: 3px;
    }
    &.Checked{
      svg{
        &:last-child{
          margin-right: -4px;
          margin-left: 3px;
        }
        &:first-child{
          margin-left: -2px;
          margin-right: 3px;
        }
      }
    }
  }
  &.Checked{
    svg{
      margin-left: -2px;
      margin-right: 3px;
    }
  }
  
  &.Checked, &.isRemove{
    .content-chips{
      font-weight: bold;
      display: flex;
      justify-content: start;
      svg{
        font-size: 16px;
      }
      
    }
  }
  &.Checked, &:hover{
    .content-chips{
      padding: 0.25rem 0.5rem;
      border:solid 1px ${props=>props.BorderColorPerso ? props.BorderColorPerso : props.themeColor==='Primary' ? props.theme.PrimaryDark :
              props.themeColor==='Secondary' ? props.theme.SecondaryDark:
                      props.themeColor==='Tertiary' ?  props.theme.TertiaryDark:
                              props.themeColor==='Neutre' ? props.theme.NeutreDark :
                              props.theme.ComplementaryDark };
      background: ${props=>props.BackColorPerso ? props.BackColorPerso : props.themeColor==='Primary' ? props.theme.PrimaryExtraLight :
              props.themeColor==='Secondary' ? props.theme.SecondaryExtraLight:
                      props.themeColor==='Tertiary' ? props.theme.TertiaryExtraLight:
                              props.themeColor==='Neutre' ? props.theme.NeutreExtraLight :
                              props.theme.ComplementaryExtraLight };
      color: ${props=>props.ColorPerso ? props.ColorPerso : props.themeColor==='Primary' ? props.theme.PrimaryExtraDark :
              props.themeColor==='Secondary' ? props.theme.SecondaryExtraDark:
                      props.themeColor==='Tertiary' ? props.theme.TertiaryExtraDark:
                              props.themeColor==='Neutre' ? props.theme.NeutreExtraDark :
                              props.theme.ComplementaryExtraDark };
      
    }  
  }
  .content-chips{
    span{
    
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
    border:solid 1px ${props=>props.theme.Neutre};
    color:${props=>props.themeColor==='Primary' ? props.theme.PrimaryDark :
            props.themeColor==='Secondary' ? props.theme.SecondaryDark:
                    props.themeColor==='Tertiary' ? props.theme.TertiaryDark: props.theme.NeutreDark };
  }
`

export default TimedChips;
