import {useQuery, UseQueryResult} from "react-query";
import {DateToYMD} from "../../components/functions/TimesFunctions";
import {getPlageTimeUserDD} from "./plageTime.services";

export const useGetPlageTimeUserDD = (idUser:number, start:Date, end:Date, idCentre:number|null=null):UseQueryResult<PlageTime[], Error>=>{
    const startFormatted = DateToYMD(start);
    const endFormatted = DateToYMD(end);
    return useQuery(["plage_time", idUser, start, end, idCentre], ()=>getPlageTimeUserDD(idUser, startFormatted, endFormatted, idCentre), {
        staleTime:6000,
        enabled: idUser !== 0
    })
}