import React from "react";
import styled from "styled-components";
import TimedRoundAvatar from "../TimedRoundAvatar/TimedRoundAvatar";

interface TimedChipsUserCtrlProps{
    className?:string;
    Hei:string;
    User:User|UserLight;
    Action?:Function;
    backHover?:"primary" | "warning";
    Fx?:boolean;
    Border?:boolean;
    WAjust?:boolean;
    initPartial?:boolean;
    paddLeftRound?:boolean;
}

const TimedChipsUserCtrl = ({className, User, Action, Hei, Fx, initPartial, paddLeftRound}:TimedChipsUserCtrlProps)=>{
    const handleClick = ()=>{
        if(!!Action){
            Action(User);
        }
    }
    return (
        <div className={`TimedUserChips ${className} ${!!Action ? 'isActionable' : ''}`} onClick={handleClick}>
            <div className={"content"}>
                <div className={`avatar-place`} style={{paddingLeft:paddLeftRound ? "5px" : "0"}}>
                    <TimedRoundAvatar BoxShadow={"1px 1px 3px rgba(0,0,0,.7)"} Border={true} src={User.avatarUrl!==undefined ? User.avatarUrl : '' } size={"other"} sizeP={Hei}/>
                </div>
                <div className={"name-place"}>
                    {initPartial ? `${User.prenom.slice(0,1)}.${User.nom}` : User.fullName}
                </div>
                {!!Fx &&
                    <div className={"fx-place"}>
                        - {User.Fonction ? User.Fonction.libelle : 'nc'} -
                    </div>
                }
            </div>
        </div>
    )
}

const TimedChipsUser = styled(TimedChipsUserCtrl)`
    width: ${props=>props.WAjust===true ? "max-content" : "100%"};
    padding: 0.1rem 0;
      &.isActionable{
        &:hover{
          cursor: pointer;
          .content {
            background: ${props => (props.backHover && props.backHover === "warning") ? props.theme.ComplementaryExtraLight : props.theme.PrimaryExtraLight};
          }
        }
      }
    .content{
      padding: 0.25rem 1rem 0.25rem 0;
      display: flex;
      justify-content: start;
      gap:8px;
      align-items: center;
      border:${props=>props.Border===undefined || props.Border === true ? `solid ${props.theme.PrimaryExtraLight} 1px` : `none`};
      border-radius: 50px;
      font-size: 14px;
      .name-place{
        font-weight: bold;
      }
    }
    
`

export default TimedChipsUser;

