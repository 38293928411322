import styled from "styled-components";
import {useGetOneUser} from "../../../features/user/user.hooks";
import TimedSkeleton from "../../atoms/TimedSkeleton/TimedSkeleton";
import TimedRoundAvatarForm from "../../atoms/TimedRoundAvatar/TimedRoundAvatarForm";
import React, {useEffect, useState} from "react";
import {OneInfosShort} from "./OneInfosShort";
import {BlockInfosShort} from "./BlockInfosShort";
import {ConvertGoodDate, getDateFrByStringNoH, getDateFrByStringSimple} from "../../functions/TimesFunctions";
import noContrat from "../../../assets/images/No-Contrat.png";
import TimedSquareInfos from "../../molecules/TimedSquareInfos/TimedSquareInfos";
import TimedSquareIconInfos from "../../atoms/TimedSquareIconInfos/TimedSquareIconInfos";
import {AiOutlineFileSync} from "react-icons/ai";
import {BiTimeFive} from "react-icons/bi";
import {GiDesk} from "react-icons/gi";
import {
    SwitchDayWorkContrat
} from "../../../pages/TimedApplication/AdministrationRH/FicheSimple/components/SwitchDayWorkContrat";
import TimedLink from "../../atoms/TimedLink/TimedLink";
import {useNavigate} from "react-router-dom";
import {useGetParamsTimed} from "../../../hooks/useGetParamsTimed";

interface TimedProfilShortCtrlProps{
    className?:string;
    idUser:number;
}

const TabDays = ["L", "M", "Me", "J", "V", "S", "D"];

const TimedProfilShortCtrl = (props:TimedProfilShortCtrlProps)=>{
    const {From, To} = useGetParamsTimed()
    const {isLoading, isFetching, data:user} = useGetOneUser(props.idUser)
    const [contratCurrent, setContratCurrent] = useState<Contrat|null>(null)
    useEffect(()=>{
        if(user && user.contrats){
            const contrats = user.contrats;
            const ContratCurr:Contrat|undefined = contrats.find(ct=>ct.endAt===null || (ct.endAt && new Date(ct.endAt) > new Date((new Date()).setHours(23,59))));
            if(ContratCurr!==undefined){
                setContratCurrent(ContratCurr);
            } else {
                setContratCurrent(null);
            }
        }
    }, [user])
    const navigate = useNavigate()
    const clickLink = ()=>{
        let myparams = `idCollab=${props.idUser}`;

        if(!!From){
            const From2 = new Date(From.setHours(12,0,0));
            const myDateFrom = getDateFrByStringSimple(From2.toISOString().slice(0,10)+"T12:00:00")
            myparams+=`&from=${myDateFrom}`
        }
        if(!!To){
            const To2 = new Date(To.setHours(12,0,0));
            const myDateTo = getDateFrByStringSimple(To2.toISOString().slice(0,10)+"T12:00:00")
            myparams+=`&to=${myDateTo}`
        }

        navigate('../fiche_personnel?'+myparams);
    }
    return (
        <div className={`short_profil ${props.className}`}>
            <div className={"user_header"}>
                <div className={`avat_place`}>
                {(isLoading || isFetching) ? <TimedSkeleton width={"60px"} type={"round"}/>: user===undefined ? 'oups':
                    <TimedRoundAvatarForm isSquare size={"large"} isActif={user.isActif} idUser={user.id} src={(user.avatarUrl !== undefined) ? user.avatarUrl : ''}/>
                }
                </div>
                <div className={"name-place"}>
                    {isLoading ? <TimedSkeleton width={"200px"} type={"rect"} nbOccurence={2}/>: !user ? 'oups':
                        <>
                            <div className={"my-name"}>{`${user?.prenom} ${user?.nom.toUpperCase()}`}</div>
                            <div className={"my-fonction"}>{user?.Fonction.libelle} {user?.Fonction.id === 1 && user?.FonctionAdm.libelle}</div>
                            {user?.onboardAt ?
                                <div className={`wrap-info-boarding`}>
                                    <div className={`infos-board`}>utilisateur depuis le : {new Date(user.onboardAt).toLocaleDateString()}</div>
                                    <div className={`infos-board`}>dernière connexion : {!user.lastConnexionAt ? 'jamais connecté' : `${new Date(user.lastConnexionAt).toLocaleDateString()} à ${new Date(user.lastConnexionAt).toLocaleTimeString()}`}</div>

                                </div>:
                                user.isActif ?
                                    <p>Ne s'est jamais connecté</p>: ''
                            }
                            <TimedLink onClick={clickLink} text={"Accèder à sa fiche"} themeColor={"Primary"} size={"md"}/>
                        </>
                    }
                </div>
            </div>
            <BlockInfosShort title={"Infos Personnelles"}>
                <OneInfosShort title={"Adresse"} info={(user?.adresse||'') + " "+ (user?.codePostal||'') + " " + (user?.ville||'')} width={"50%"}/>
                <OneInfosShort title={"téléphone"} info={user?.telephone||'NC'} width={"50%"}/>
                <OneInfosShort title={"email"} info={user?.email||'NC'} width={"50%"}/>
            </BlockInfosShort>
            <BlockInfosShort title={"Infos Professionnelles"}>
                <OneInfosShort title={"Fonction"} info={(user?.Fonction.libelle||'') + " " + (user?.FonctionAdm.libelle||'')} width={"50%"}/>
                <OneInfosShort title={"Rotation"} info={user?.startRotationAt ? ConvertGoodDate(user.startRotationAt).toLocaleDateString() : 'NC'} width={"50%"}/>
            </BlockInfosShort>
            <BlockInfosShort title={"Contrat"} noFlex={true}>
                {(contratCurrent === undefined || !contratCurrent) ?
                    <div className={`no-contrat`}>
                        <div className={`img-place`}>
                            <img src={noContrat} alt={"pas de contrat"}/>
                        </div>
                        <div className={`legende`}>Aucun contrat en cours</div>
                    </div>
                    :
                    <>
                        <div className={"wrap-infos"}>
                            <div className={"oneBlockInfos"}>
                                <TimedSquareInfos title={"Type contrat"}>
                                    <TimedSquareIconInfos
                                        themeColor={"Primary"}
                                        subTitle={""}
                                        infos={contratCurrent.TypeContrat.libelle}
                                        Icon={<AiOutlineFileSync/>}
                                    />
                                </TimedSquareInfos>
                            </div>
                            <div className={"oneBlockInfos"}>
                                <TimedSquareInfos title={"Temps de travail contractuel"}>
                                    <TimedSquareIconInfos
                                        themeColor={"Primary"}
                                        subTitle={""}
                                        infos={`${contratCurrent.quantite}${contratCurrent.uniteTps}/${contratCurrent.RefTempsTravail.libelle}`}
                                        Icon={<BiTimeFive/>}
                                    />
                                </TimedSquareInfos>
                            </div>

                            <div className={"oneBlockInfos"}>
                                <TimedSquareInfos title={"Début du contrat en cours"}>
                                    <TimedSquareIconInfos
                                        themeColor={"Primary"}
                                        subTitle={""}
                                        infos={getDateFrByStringNoH(contratCurrent.startAt)}
                                        Icon={<BiTimeFive/>}
                                    />
                                </TimedSquareInfos>
                            </div>
                            <div className={"oneBlockInfos"}>
                                <TimedSquareInfos title={"Date Fin"}>
                                    <TimedSquareIconInfos
                                        themeColor={"Primary"}
                                        subTitle={""}
                                        infos={contratCurrent.endAt ? getDateFrByStringNoH(contratCurrent.endAt) : '--'}
                                        Icon={<BiTimeFive/>}
                                    />
                                </TimedSquareInfos>
                            </div>
                            {contratCurrent.poste &&
                                <div className={"oneBlockInfos"}>
                                    <TimedSquareInfos title={"Poste"}>
                                        <TimedSquareIconInfos
                                            themeColor={"Primary"}
                                            subTitle={""}
                                            infos={`${contratCurrent.poste ? contratCurrent.poste : ''}`}
                                            Icon={<GiDesk/>}
                                        />
                                    </TimedSquareInfos>
                                </div>
                            }
                        </div>
                        <div className={`block-jt`}>
                            <h5>jours de travail habituel</h5>
                            <div className={`wrap-jours`}>
                                {TabDays.map((keyDay:string, idx:number)=>(
                                    <SwitchDayWorkContrat isLectureSeule={true} contrat={contratCurrent} key={`DayW${idx}`} keyDay={keyDay}/>
                                ))}
                            </div>
                        </div>
                    </>

                }
            </BlockInfosShort>
        </div>
    )
}

export const TimedProfilShort = styled(TimedProfilShortCtrl)`
  padding: 1rem;
  .wrap-infos{
    display: flex;
    flex-wrap: wrap;
    .oneBlockInfos{
      width: 50%;
    }
  }
  .legende{
    text-align: center;
  }
  .img-place{
    text-align: center;
    height: 90px;
    margin-bottom: 10px;
    img{
      height: 100%;
    }
    
  }
  .wrap-jours{
    margin-top: 8px;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }
  .no-contrat{
     width: 100%;
  }
  .user_header {
    display: flex;
    justify-content: start;
    align-items: center;
    margin-bottom: 30px;
    .avat_place{
      width: 95px;
    }
    .name-place {
      margin-left: 15px;

      .my-name {
        font-weight: 500;
        font-size: 22px;
      }

      .my-fonction {
        color: ${props => props.theme.Neutre};
      }
    }
  }
`