import styled from "styled-components";
import {useRef} from "react";

interface SimpleSelectSettingCtrlProps{
    className?:string;
    choices:Choice[];
    Current:Choice|null;
    id:string;
    setMyValue:(c:Choice, id:string)=>void
}

const SimpleSelectSettingCtrl = (props:SimpleSelectSettingCtrlProps)=>{
    const refS = useRef<HTMLSelectElement>(null)

    const handleChange = ()=>{
        console.log("changer")
        if (refS.current){
            const my = props.choices.find(c=>c.id === parseInt(refS.current?.value||"0"))
            console.log(my);
            if(my) props.setMyValue(my, props.id);
        }
    }
    return (
        <div className={`simpleSelectSetting ${props.className}`}>
            <select className={"select_type_setting"} value={props.Current ? props.Current.id : -1}
                    onChange={handleChange} ref={refS}>
                {props.choices.map(item => (
                    <option value={item.id} key={`Choice${props.id}${item.id}`}>{item.libelle}</option>
                ))}
            </select>
        </div>
    )
}

const SimpleSelectSetting = styled(SimpleSelectSettingCtrl)``

export default SimpleSelectSetting