import styled from "styled-components";
import React, {useEffect, useMemo, useState} from "react";
import {useGetPointeuseByParams} from "../../../../features/pointeuse/pointeuse.hooks";
import {useGetPlageParams2} from "../../../../features/plage/plage.hooks";
import {useGetParamsTimed} from "../../../../hooks/useGetParamsTimed";
import {useGetCentreActuel} from "../../../../features/centre/centre.hooks";
import {LineUser} from "../LivePointage";
import {TitrePage} from "../../../../assets/styles/elements";
import ImgTime from "../../../../assets/images/Time.png";
import {HourActual} from "../components/HourActual";
import {LivePointageSkeleton} from "../components/LivePointageSkeleton";
import {OneLineLivePointage} from "../components/OneLineLivePointage";
import {FaHistory} from "react-icons/fa";
import TimedIconButton from "../../../../components/atoms/TimedIconButton/TimedIconButton";
import {useNavigate} from "react-router-dom";
import {DateToDateStrFR} from "../../../../components/functions/TimesFunctions";
import {MdLiveTv} from "react-icons/md";

interface HistoriqueLiveCtrlProps {
    className?:string;
}
interface ThisParamsPlage{
    plageAt:string;
    isPresent:number;
}
interface MyParamsPT{
    "pointAt[strictly_after]":string;
    "pointAt[strictly_before]":string;
    "User.Centre":number;
}

const hourStartGo=6;
const hourStopGo=21;
const widthHour = 102
const widthUser=20

export interface OnePlageChrono{
    plage:Plage,
    isPointageStart:Date|null;
    isPointageEnd:Date|null;
}

const HistoriqueLiveCtrl = ({className}: HistoriqueLiveCtrlProps) => {
    const [paramsPlage, setParamsPlage] = useState<ThisParamsPlage|null>(null)
    const CentreQuery = useGetCentreActuel();
    const PlagesQuery = useGetPlageParams2(paramsPlage)
    const [paramsPt, setParamsPt] = useState<MyParamsPT|null>(null)
    const PointeusesQuery = useGetPointeuseByParams(paramsPt)
    const {From, TabFx, TabCollab} = useGetParamsTimed()
    const TabHour:React.ReactNode[] = [];
    let hourStart = hourStartGo;
    while(hourStart<=hourStopGo){
        TabHour.push(<div className={`one_hour`} style={{width:(100/((hourStopGo+1) - hourStartGo))+"%"}} key={`oH${hourStart}`}><div className={`lib_hour`}>{hourStart}</div></div> )
        hourStart++;
    }
    useEffect(() => {
        if(From && CentreQuery.data){
            setParamsPlage({plageAt:From.toISOString().slice(0,10), isPresent:1})
            setParamsPt({"pointAt[strictly_after]":From.toISOString().slice(0,10)+" 00:00:00", "pointAt[strictly_before]":From.toISOString().slice(0,10)+" 23:59:59", "User.Centre":CentreQuery.data.id})
        }
    }, [From, CentreQuery.data]);
    const listUser = useMemo(()=>{
        const list : LineUser[] = []
        if(PlagesQuery.data && PointeusesQuery.data){
            const UsersPlages = PlagesQuery.data.map(p=>p.User).filter(u=>u.Fonction.id!==1);
            let UsersSSD = UsersPlages.reduce((acc:User[], item:User)=>acc.map(a=>a.id).indexOf(item.id)===-1 ? [...acc, item] : acc, [] )
            if(TabFx){
                UsersSSD = UsersSSD.filter(u=>TabFx.indexOf(u.Fonction.id.toString())!==-1);
            } else if(TabCollab){
                UsersSSD = UsersSSD.filter(u=>TabCollab.indexOf(u.id.toString())!==-1);
            }
            UsersSSD.sort((a:User, b:User)=>a.nom > b.nom ? 1 : -1).forEach(u=>{
                const PointeusesConcerned =  PointeusesQuery.data.filter(pt=>pt.User.id === u.id).sort((a:Pointeuse, b:Pointeuse)=>new Date(a.pointAt) > new Date(b.pointAt) ? 1 : -1);
                list.push({
                    User:u,
                    Plages:PlagesQuery.data.filter(p=>p.User.id === u.id),
                    Pointeuses:PointeusesConcerned
                })
            })
        }
        return list;
    }, [PlagesQuery.data, PointeusesQuery.data, TabFx, TabCollab])
    const navigate = useNavigate()
    const ClickHisto = ()=>{
        navigate(`/timed_application/live_pointage`)
    }
    return (
        <div className={`live_histo ${className}`}>
            <div className="head_page">
                <TitrePage>Historique de pointages {From ? `pour la journée du ${From.toLocaleDateString()}` : ""}</TitrePage>
                <TimedIconButton
                    size={"large"}
                    themeColor={"Primary"}
                    Icon={<MdLiveTv />}
                    toolTip={"Live pointage !"}
                    onClick={ClickHisto}
                    BackAlw={true}
                    isActive={true}
                    marginTop={"-0.1rem"}
                    isSquare={true}
                />
            </div>
            {!From ?
                <p className={`empty_date`}>Merci de sélectionner une date</p>:
                <>
                    {!PlagesQuery.isLoading && !PointeusesQuery.isLoading && !CentreQuery.isLoading && listUser.length === 0 ?
                        <div className={"empty_result"}>
                            <div className="wrap_img">
                                <img src={ImgTime} alt={"personne ne travaille"}/>
                            </div>
                            <p className={"legende_no_result"}>Personne ne travaille aujourd'hui</p>
                        </div>:
                        <div className={"wrapper"} style={{width:"100%"}}>
                            <div className={"w_wrap_hour"}>
                                <div className={"cell-personne"} style={{width:widthUser+"%"}}/>
                                <div className={"wrap_hour"} style={{width:(100-widthUser)+"%"}}>
                                    {TabHour}
                                </div>
                            </div>
                            <div className={"wrapper_plage"}>
                                {((PlagesQuery.isLoading || PointeusesQuery.isLoading || CentreQuery.isLoading) && listUser.length === 0) ?
                                    <LivePointageSkeleton widthUser={widthUser} nbLine={5}/>:
                                    listUser.map(item=>(
                                        <OneLineLivePointage isPast widthUser={widthUser} widthHour={widthHour} key={`one_line${item.User.id}`} LineUser={item} HourEnd={hourStopGo} HourStart={hourStartGo}/>
                                    ))
                                }
                            </div>
                        </div>
                    }
                </>
            }
        </div>
    )
}

export const HistoriqueLive = styled(HistoriqueLiveCtrl)`
    padding: 1% 2%;
    .head_page{
        display: flex;
        justify-content: space-between;
    }
    .empty_result{
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 2rem;
        p{
            text-align: center;
            font-size: 18px;
        }
        .wrap_img{
            width: 30%;
            margin: auto;
            img{
                width: 100%;
            }

        }
    }
    .wrapper{
        background: white;
        position: relative;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
    }
    .w_wrap_hour{
        padding-top: 28px;
        display: flex;
        justify-content: flex-start;
        border-bottom: solid ${props => props.theme.PrimaryDark} 1px;
        position: relative;
    }
    .wrap_hour{
        display: flex;
        justify-content: flex-start;
        .one_hour{
            border-left: solid ${props => props.theme.PrimaryDark} 1px;
            flex-shrink: 0;
            height: 12px;
            position: relative;
            .lib_hour{
                position: absolute;
                left: 0;
                top: 0;
                transform: translate(-5px,-100%);
                font-size: 14px;
            }
        }
    }
`