import React, {useState} from "react";
import styled from "styled-components";
import TimedChips from "../../../../../../components/atoms/TimedChips/TimedChips";
import DetailsDailyReleve from "./DetailsDailyReleve";
import DetailsDailyNonTravaille from "./DetailsDailyNonTravaille";
import DetailsDailyRegules from "./DetailsDailyRegules";

interface DetailsDailyCtrlProps{
    className?:string;
    dateUseEnd:string;
    idUser:number;
    setPointeurTreat:Function;
    setFerie:Function;
    supervisor?:boolean;
}
const Choices:Choice[] = [
    {id:1, libelle:"Relevées"},
    {id:2, libelle:"Non travaillées"},
    {id:3, libelle:"Régules"},
]

const DetailsDailyCtrl = ({className, dateUseEnd, idUser, setPointeurTreat, setFerie, supervisor}:DetailsDailyCtrlProps)=>{
    const [choicesType, setChoicesType] = useState<Choice[]>([{id:1, libelle:"Relevées"}])
    const setNewChoice = (thisChoice:Choice)=>{
        setChoicesType([thisChoice])
    }
    return (
        <div className={`DetailsDaily ${className}`}>
            <div className={`title`}>
                <div className={`lib-title`}>details heures par jour</div>
                <div className={`wrap-change-type`}>
                    {Choices.map((item:Choice, idx:number)=>(
                        <TimedChips
                            key={`oneTypeChoice${idx}`}
                            themeColor={"Primary"}
                            setChoice={setNewChoice}
                            choices={choicesType}
                            MyChoice={item}
                            withOutChoche={true}
                        />
                    ))}
                </div>
            </div>
            <div className={`content-daily`}>
                {choicesType[0].id === 1 || choicesType[0].id === 2 ?
                    <DetailsDailyReleve
                        idUser={idUser}
                        dateUseEnd={dateUseEnd}
                        setPointeurTreat={setPointeurTreat}
                        isAbsent={choicesType[0].id === 2}
                        setFerie={setFerie}
                        supervisor={supervisor}
                    /> : /*choicesType[0].id === 2 ?
                        <DetailsDailyNonTravaille
                            idUser={idUser}
                            dateUseEnd={dateUseEnd}
                        />:*/
                        <DetailsDailyRegules
                            idUser={idUser}
                            dateUseEnd={dateUseEnd}
                            supervisor={supervisor}
                        />
                }
            </div>
        </div>
    )
}

const DetailsDaily = styled(DetailsDailyCtrl)`
  padding: 20px;
  .title{
    display: flex;
    .wrap-change-type{
      display: flex;
      gap:5px
    }
    .lib-title {
      flex-grow: 1;
      font-size: 14px;
      color: ${props => props.theme.Dark};
      font-weight: bold;
    }
    
    //margin-bottom: 10px;
  }
`

export default DetailsDaily;