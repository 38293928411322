import styled from "styled-components";
import ReactDOM from "react-dom";
import {GrClose} from "react-icons/gr";
import React, {useEffect, useState} from "react";
import {useGetUser} from "../../features/user/user.hooks";
import AvatAno from "../../assets/images/Unisex-Person-with-Glasses.png";
import {useNavigate} from "react-router-dom";

interface TimedNotificationChatCtrlProps {
    className?:string;
}
const VISIBLE = 1;
const HIDDEN = 2;
const ENTERING = 3;
const LEAVING = 4;
interface OneMajConv{
    content:string;
    who:string;
    members:{idUser:number, member:number}[];
    urlSticker:string;
    date_time:string;
    MemberSender:number;
    UserId:number;
    conversationName:string;
    roomId:number;
}
const DefaultConv:OneMajConv={
    content:'',
    who:'',
    members:[],
    urlSticker:'',
    date_time:'',
    MemberSender:0,
    UserId:0,
    conversationName:'',
    roomId:0
}

const TimedNotificationChatCtrl = ({className}: TimedNotificationChatCtrlProps) => {
    const [state, setState] = useState(HIDDEN);
    const [open, setOpen] = useState(false);
    const UserQuery = useGetUser();
    const [myConv, setMyConv] = useState<OneMajConv>(DefaultConv);
    const [pause, setPause] = useState(false)
    const [count, setCount] = useState(0);
    const handleError = (e:any)=>{
        e.target.src = AvatAno;
    }
    useEffect(() => {
        let intervalId:any = null;
        if(!pause && count>0){
            intervalId = setInterval(()=>{
                setCount(ct=>ct-1)
            }, 1000)
        } else if(count === 0){
            setOpen(false);
            setMyConv(DefaultConv);
        }
        // Nettoyage de l'intervalle
        return () => clearInterval(intervalId);
    }, [count, pause]);
    useEffect(()=>{
        let myEventSource:EventSource|null;
        if(UserQuery.data) {
            const url = `${process.env.REACT_APP_MERCURE_URL}?topic=chats/${UserQuery.data.id}`;
            myEventSource = new EventSource(url);
            myEventSource.onmessage = event => {
                const myConv:OneMajConv = JSON.parse(event.data)

                setMyConv(myConv)

            }
        }
        return ()=>{
            if(myEventSource instanceof EventSource){
                myEventSource.close();
            }
        }
    }, [UserQuery.data])
    useEffect(() => {
        if(myConv.UserId !==0){
            setOpen(true);
            setCount(10);
        }
    }, [myConv]);
    useEffect(()=>{
        if(!open){
            setState(LEAVING)
        } else{
            setState((s)=>s===HIDDEN ? ENTERING : LEAVING)
        }
    }, [open])

    useEffect(()=>{
        if(state === LEAVING){
            const timer = setTimeout(()=>{
                setState(HIDDEN)
            }, 300)
            return ()=>{
                clearTimeout(timer);
            }
        } else if (state === ENTERING){
            const xx = document.body.offsetHeight
            setState(VISIBLE);
        }
    }, [state])
    const navigate = useNavigate();
    const ClickOn = ()=>{
        if(myConv.roomId!==0){
            navigate('/timed_application/messagerie2/'+myConv.roomId)
        }
    }
    const styleModal = {transitionDuration: `300ms`, transitionProperty:"opacity, right", opacity:1, right:"5px"}
    if(state!==VISIBLE){
        styleModal.opacity = 0;
        styleModal.right = "-305px";
    }
    if(state === HIDDEN){
        return null;
    } else {
        return ReactDOM.createPortal(
            <div className={`timed_notif_message ${className}`} style={styleModal} onMouseOver={()=>setPause(true)} onMouseOut={()=>setPause(false)} onClick={ClickOn}>
                <div className="when">{myConv.date_time}</div>
                <div className="who_said">
                    <div className="avat">
                        <img src={`${process.env.REACT_APP_API_URL_ROOT}/${myConv.urlSticker}`}
                             alt={`pic ${myConv.who}`} onError={handleError}/>
                    </div>
                    <div className="name">{myConv.who} {myConv.conversationName}</div>
                </div>
                <div className="what">
                    {myConv.content}
                </div>
            </div>, document.body
        )
    }
}

export const TimedNotificationChat = styled(TimedNotificationChatCtrl)`
    position: fixed;
    bottom: 10px;
    width: 300px;
    left: auto;
    background: white;
    border-radius: 4px;
    padding: 0.5rem;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    .when{
        color:${props => props.theme.Primary};
        display: flex;
        justify-content: flex-end;
        font-size: 14px;
    }
    .who_said{
        margin: 10px 0;
        display: flex;
        justify-content: flex-start;
        border-radius: 4px;
        overflow: hidden;
        align-items: center;
        height: 36px;
        .avat{
            height: 100%;
            width: 36px;
            img{
                width: 100%;
                height: auto;
            }
        }
        .name{
            padding-left: 5px;
        }
    }
    .what{
        margin-bottom: 10px;
        font-style: italic;
    }
`