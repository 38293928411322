import React from "react";
import styled from "styled-components";
import {Shine} from "../../../assets/styles/elements";

interface TimedSkeletonCtrlProps{
    className?:string;
    type:"round" | "rect" | "square" | "perso";
    width:string;
    nbOccurence?:number;
    isFlex?:boolean;
    heightPerso?:string;
}

const TimedSkeletonCtrl = ({className, type, nbOccurence, isFlex}:TimedSkeletonCtrlProps)=>{
    const nbOcc = nbOccurence ? nbOccurence : 1;
    let items = [];
    for(let i=1; i<=nbOcc; i++){
        items.push(<div className={type} key={`occSke${i}`}/>)
    }
    return (
        <div className={`wrapSkeleton ${className} ${isFlex ? 'Flexi' : ''}`}>
            {items}
        </div>
    )
}

const TimedSkeleton = styled(TimedSkeletonCtrl)`
  width: ${props => props.width};
  
  border-radius: 12px;
  &.Flexi {
    display: flex;
    gap: 20px;

    div {
      flex-grow: 1;
    }
  }

  .round {
    width: ${props => props.isFlex && props.nbOccurence ? `${100 / props.nbOccurence}%` : '100%'};
    padding-bottom: 100%;
    border-radius: 50%;
    // background: #eee;
    // background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    // background-size: 200% 100%;
      // animation: 1.5s ${Shine} linear infinite;
  }

  .rect {
    width: ${props => props.isFlex && props.nbOccurence ? `${100 / props.nbOccurence}%` : '100%'};
    height: 30px;
    padding: 0 0.25rem;
    position: relative;
    margin-bottom: ${props=>!props.isFlex ? "10px" : "0"};
    background: ${props=>!props.isFlex ?  "linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%)" : "transparent"};
    background-size: 200% 100%;
    animation: 1.5s ${Shine} linear infinite;
    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 80%;
      // background: #eee;
      // background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
      // background-size: 200% 100%;
        // animation: 1.5s ${Shine} linear infinite;
    }
  }

  .square {
    width: ${props => props.isFlex && props.nbOccurence ? `${100 / props.nbOccurence}%` : '100%'};
    padding-bottom: ${props => props.isFlex && props.nbOccurence ? `${100 / props.nbOccurence}%` : '100%'};
    // background: #eee;
    // background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    // background-size: 200% 100%;
      // animation: 1.5s ${Shine} linear infinite;
  }

  .perso {
    width: ${props => props.width};
    height: ${props => props.heightPerso ? props.heightPerso : "200px"};
    background: #eee;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    background-size: 200% 100%;
    animation: 1.5s ${Shine} linear infinite;
    border-radius: 12px;
    margin-bottom: ${props=>!props.isFlex ? "10px" : "0"};
  }
`

export default TimedSkeleton;