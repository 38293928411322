import styled from "styled-components";
import {TitrePage} from "../../../../assets/styles/elements";
import React, {useEffect, useState} from "react";
import {useGetPeriodesByType} from "../../../../features/periode/periode.hooks";
import {ListChoiceWeeks} from "./ListChoiceWeeks";
import {ListChoiceMonths} from "./ListChoiceMonths";
import {ItemGestionHS} from "./ItemGestionHS";

interface GestionHeuresSuppFullCtrlProps{
    className?:string;
    supervisor?:boolean;
}

export interface OneSemaineChoice{
    numSem:number;
    From:Date;
    To:Date;
    nbResult:number;
    id:number;
}

export interface OneMonthChoice{
    libelle:string;
    From:Date;
    To:Date;
    nbResult:number;
    id:string;
}

const GestionHeuresSuppFullCtrl = (props:GestionHeuresSuppFullCtrlProps)=>{
    const PeriodesQuery = useGetPeriodesByType(2);
    const [TypeFrequence, setTypeFrequence] = useState<string|null>("Hebdo");
    const [PeriodeCurrent, setPeriodeCurrent] = useState<Periode|null>(null);
    const [semaineCurrent, setSemaineCurrent] = useState<OneSemaineChoice|null>(null)
    const [MoisCurrent, setMoisCurrent] = useState<OneMonthChoice|null>(null)
    useEffect(()=>{
        if(PeriodesQuery.data){
            const Today = new Date();
            const PeriodesLimits = PeriodesQuery.data.filter(p=>new Date(p.debutAt)<=Today && new Date(p.finAt) >= Today);
            if(PeriodesLimits.length>0) {
                setPeriodeCurrent(PeriodesLimits[0])
                setTypeFrequence(!!PeriodesLimits[0].TypeFrequenceHeuresSupp ? PeriodesLimits[0].TypeFrequenceHeuresSupp.libelle : 'Hebdomadaire')
            }
        }
    }, [PeriodesQuery])
    return (
        <div className={`gestion_heures_supp_fully ${props.className}`}>
            <TitrePage>Gestion Heures Supplémentaires</TitrePage>
            <div className={`content_gestion_hs`}>
                {PeriodesQuery.isLoading ? 'Chargement...' : PeriodesQuery.isError ? 'Error BD':
                    <>
                        <div className={`wrapper_choice_periode is_month`}>
                            <ListChoiceMonths Periode={PeriodeCurrent} setMonthCurrent={setMoisCurrent} MonthCurrent={MoisCurrent} wR={TypeFrequence === "Mensuelle"}/>
                        </div>
                        {TypeFrequence === 'Hebdomadaire' && MoisCurrent &&
                            <div className={`wrapper_choice_periode`}>
                                <ListChoiceWeeks Periode={MoisCurrent} SemaineCurrent={semaineCurrent} setSemaineCurrent={setSemaineCurrent}/>
                            </div>
                        }
                        <div className={`central_treat`}>
                            {TypeFrequence === 'Hebdomadaire' && MoisCurrent && semaineCurrent &&
                                <ItemGestionHS From={semaineCurrent.From} To={semaineCurrent.To} type={TypeFrequence}/>
                            }
                            {TypeFrequence === 'Mensuelle' && MoisCurrent &&
                                <ItemGestionHS From={MoisCurrent.From} To={MoisCurrent.To} type={TypeFrequence}/>
                            }
                        </div>
                    </>
                }
            </div>
        </div>
    )
}

const GestionHeuresSuppFull = styled(GestionHeuresSuppFullCtrl)`
    padding: 1% 2%;
    display: flex;
  height: 100%;
  flex-direction: column;
    .content_gestion_hs{
      width: 100%;
      display: flex;
      justify-content: flex-start;
      gap: 20px;
      flex-grow: 1;
      overflow: hidden;
    }
  .wrapper_choice_periode{
    background: white;
    border-radius: 6px;
    width: 170px;
    flex-shrink: 0;
    height: 100%;
    overflow-y: auto;
    &.is_month{
      width: 120px;
    }
  }
  .central_treat{
    flex-grow: 1;
    background: white;
    border-radius: 6px;
    height: 100%;
  }
`

export default GestionHeuresSuppFull;
