import {useMutation, useQuery, useQueryClient, UseQueryResult} from "react-query";
import {getAllLBByCentre, PostLieuBesoin, PutLieuBesoin} from "./lieuBesoin.services";
import {toast} from "react-toastify";


export const useGetAllLieuBesoinByCentre = (id:number):UseQueryResult<LieuBesoin[], Error>=>{
    return useQuery(["lieux_besoins", id], ()=>getAllLBByCentre(id), {
        enabled:id!==0
    })
}

export const useChangeLieuBesoin = (id:number)=>{
    const queryClient = useQueryClient();
    return useMutation(
        (datas:PutLB)=>{
            return PutLieuBesoin(id, datas);
        }, {
            onSuccess:()=>{
                queryClient.invalidateQueries(["lieux_besoins"])
            }, onError:()=>{
                toast.error("impossible de modifier le calque")
            }
        }
    )
}

export const useAddLieuBesoin = ()=>{
    const queryClient = useQueryClient()
    return useMutation(
        (datas:PostLieuBesoin)=>{
            return PostLieuBesoin(datas);
        }, {
            onSuccess:()=>{
                queryClient.invalidateQueries(["lieux_besoins"])
            }, onError:()=>{
                toast.error("impossible de créer le calque")
            }
        }
    )
}