import React from "react";
import styled from "styled-components";
import {BiMessageDetail} from "react-icons/bi";
import {BsInfo} from "react-icons/bs";

interface TimedNotificationCtrlProps{
    className?:string;
    Notification:NotificationTimed
}

const TimedNotificationCtrl = ({className, Notification}:TimedNotificationCtrlProps)=>{
    return (
        <div className={`ToastNotif ${className}`}>

            <div className="head">
                <div className={"icon-place"}>
                    {Notification.type==='NewMessage' ? <BiMessageDetail/> : <BsInfo/>}
                </div>
                <div className="type">{Notification.type==='NewMessage' ? 'Nouveau Message' : Notification.type}</div>
            </div>
            <div className="body">
                <div className="content">{Notification.content}</div>
            </div>
        </div>
    )
}

const TimedNotification = styled(TimedNotificationCtrl)`
  font-size: 14px;
  .head{
    display: flex;
    justify-content: start;
    align-items: center;
    margin-bottom: 10px;
    .icon-place{
      width: 28px;
      height: 28px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      background: ${props=>props.theme.Primary};
      svg{
        color: white;
        margin: auto;
        font-size: 18px;
      }
    }
    .type{
      font-weight: bold;
      margin-left: 5px;
      color: ${props=>props.theme.Primary}
    }
  }
  .body{
    .who{
      font-weight: bold;
      margin-top: 7px;
    }
    .content{
      font-style: italic;
    }
  }
`

export default TimedNotification;