import React from "react";
import styled from "styled-components";
import {Shine} from "../../../assets/styles/elements";

interface TimedSkeletonGraphBarCtrlProps{
    className?:string;
}

const TimedSkeletonGraphBarCtrl = ({className}:TimedSkeletonGraphBarCtrlProps)=>{
    return (
        <div className={`TimedSkeletonGraphBar ${className}`}>
            <div className={`wrap-bar-ske`}>
                <div className={`oneBar isHight`}><div className={`in-bar`}/></div>
                <div className={`oneBar isMedium`}><div className={`in-bar`}/></div>
                <div className={`oneBar isLow`}><div className={`in-bar`}/></div>
                <div className={`oneBar isMedium`}><div className={`in-bar`}/></div>
                <div className={`oneBar isHight`}><div className={`in-bar`}/></div>
                <div className={`oneBar isLow`}><div className={`in-bar`}/></div>
                <div className={`oneBar isMedium`}><div className={`in-bar`}/></div>
                <div className={`oneBar isHight`}><div className={`in-bar`}/></div>
            </div>
        </div>
    )
}

const TimedSkeletonGraphBar = styled(TimedSkeletonGraphBarCtrl)`
    width: 100%;
    padding: 0.5rem 0.75rem;
    border-left:solid #ececec 1px;
    border-bottom:solid #ececec 1px;
    height: 150px;
    max-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  .oneBar{
    flex-grow: 1;
    .in-bar{
      background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
      background-size: 200% 100%;
      animation: 1.5s ${Shine} linear infinite;
      width: 100%;
    }
    
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    &.isHight{
      .in-bar{
        height: 100%;
      }
    }
    &.isLow{
      .in-bar{
        height: 30%; 
      }
    }
    &.isMedium{
      .in-bar{
        height: 55%;
      }
    }
  }
  .wrap-bar-ske{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    gap: 20px;
    
  }
    
`

export default TimedSkeletonGraphBar;