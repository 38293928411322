import {dataFetcher} from "../../services/dataGuetter/dataFetcher";

export function addSupport(datas:SupportFD):Promise<Support>{
    return dataFetcher(`supports`, {
        method:'post',
        body:datas
    })
}

export function getOneSupport(id:number):Promise<Support>{
    return dataFetcher(`supports/${id}`, {method:'get'})
}

export async function getAllSupport():Promise<Support[]>{
    const res:any = await dataFetcher(`supports`, {method:'get'})
    return res["hydra:member"]
}

export function modifySupport(datas:ModifySupport):Promise<Support>{
    return dataFetcher(`supports/${datas.id}`, {
        method:'put',
        body:datas
    })
}