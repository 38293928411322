import styled from "styled-components";
import React from "react";
import {OneLineLivePointageSkeleton} from "./OneLineLivePointageSkeleton";

interface LivePointageSkeletonCtrlProps{
    className?:string;
    nbLine:number;
    widthUser:number;
}

const LivePointageSkeletonCtrl = (props:LivePointageSkeletonCtrlProps)=>{
    const lines:React.ReactNode[] = []
    for(let i=0; i<props.nbLine; i++){
        lines.push(<OneLineLivePointageSkeleton widthUser={props.widthUser} key={`lS${i}`}/>)
    }
    return (
        <div className={`live_pointage_ske ${props.className}`}>
            {lines}
        </div>
    )
}

export const LivePointageSkeleton = styled(LivePointageSkeletonCtrl)`
    
`