import React, {useEffect, useState} from "react";
import styled, {keyframes} from "styled-components";
import {UseQueryResult} from "react-query";
import TimedSkeleton from "../../../../../../components/atoms/TimedSkeleton/TimedSkeleton";
import TimedRoundAvatar from "../../../../../../components/atoms/TimedRoundAvatar/TimedRoundAvatar";
import TimedChipsInfos from "../../../../../../components/atoms/TimedChips/TimedChipsInfos";
import {BsCalendar2Date} from "react-icons/bs";
import TimedIconButton from "../../../../../../components/atoms/TimedIconButton/TimedIconButton";
import {AiOutlineUser} from "react-icons/ai";
import {useNavigate} from "react-router-dom";
import {SwitchDayWorkContrat} from "../../../../AdministrationRH/FicheSimple/components/SwitchDayWorkContrat";

interface UserBlockCtrlProps{
    className?:string;
    UserQuery:UseQueryResult<User, Error>,
    CptQuery:UseQueryResult<CompteurTempsDateDate[], Error>
    dateStart:string;
    dateEnd:string
}
interface OneInfosJtrav{
    contrat:Contrat,
    jtrail:JoursTravail
}
const TabDays = ["L", "M", "Me", "J", "V", "S", "D"];
const UserBlockCtrl = ({className, UserQuery, CptQuery, dateStart, dateEnd}:UserBlockCtrlProps)=>{
    const navigate = useNavigate();
    const [user, setUser] = useState<User|null>(null);
    const [contratLib, setContratLib] = useState<string>('');
    const [infosJtrav, setInfosJtrav] = useState<OneInfosJtrav[]>([]);
    const [cpt, setCpt] = useState<CompteurTempsDateDate|null>(null);
    useEffect(()=>{
    }, [infosJtrav])
    useEffect(()=>{
        if(CptQuery.data){
            setCpt(CptQuery.data[0]);
        }
    }, [CptQuery])
    useEffect(()=>{
        if(UserQuery.data){
            setUser(UserQuery.data);
            const Contrats = UserQuery.data.contrats;
            const ContratsConc = Contrats.filter(c=>new Date(c.startAt)<=new Date(dateEnd) && (!c.endAt || new Date(c.endAt.slice(0,10))>=new Date(dateStart)))
            setInfosJtrav(ContratsConc.map(c=>{
                return {contrat:c, jtrail:c.joursTravail}
            }))

            const TabContratLib = ContratsConc.map(c => {
                const ContratLib = c.TypeContrat.libelle
                const ContratVolume = c.quantite
                const ContratUnite = c.uniteTps
                const ContratRef = c.RefTempsTravail.libelle
                return `${ContratLib} ${ContratVolume}${ContratUnite}/${ContratRef}`
            })
            const ContratLib = ContratsConc.length === 0 ? '--' : TabContratLib.join(', ');
            setContratLib(ContratLib);
        }
    }, [UserQuery,dateEnd, dateStart])
    const GoPlanning = ()=>{
        if(UserQuery.data) {
            const start = new Date(dateStart).toLocaleDateString();
            const end = new Date(dateEnd).toLocaleDateString();
            navigate(`/timed_application/planning_indi_mensuel?idCollab=${UserQuery.data.id}&from=${start}&to=${end}`)
        }
    }
    const GoFiche = ()=>{
        if(UserQuery.data) {
            const start = new Date(dateStart).toLocaleDateString();
            const end = new Date(dateEnd).toLocaleDateString();
            navigate(`/timed_application/fiche_personnel?idCollab=${UserQuery.data.id}&from=${start}&to=${end}`)
        }
    }
    return (
        <div className={`blockUser ${className}`}>
            {UserQuery.isLoading || CptQuery.isLoading ?
                <TimedSkeleton width={"100%"} nbOccurence={1} type={"perso"} heightPerso={"154px"}/>:
                <div className={`in-block-user`}>
                    {user === null ? <p>Error user</p> :
                        <>
                            <div className={`line-head`}>
                                <div className={`avat`}>
                                    <TimedRoundAvatar src={user.avatarUrl ? user.avatarUrl : ''} sizeP={"50px"} size={"other"}/>
                                </div>
                                <div className={`infos`}>
                                    <div className={`name`}>{user.fullName} ({user.initials})</div>
                                    <div className={`fonction`}>{user.Fonction.libelle}</div>
                                    {infosJtrav.length > 1 ?
                                        <div className={`infos-jtrav-multi`}>
                                            <div className={"infos-multi"}>Jours travaillés différents dans ce mois</div>
                                            <div className={`list-multi`}>
                                                {infosJtrav.map((item: OneInfosJtrav, index: number) => (
                                                    <div className={`one-info-jt`} key={`OInfJT${index}`}>
                                                        <div className={`lib-contrat`}>
                                                            {!item.contrat.endAt ? `à partir du ${new Date(item.contrat.startAt).toLocaleDateString().slice(0,5)}` : `du ${new Date(item.contrat.startAt).toLocaleDateString().slice(0,5)} au ${new Date(item.contrat.endAt.slice(0,10)).toLocaleDateString().slice(0,5)}`}
                                                        </div>
                                                        <div className={`info-jtrav-one-multi wrap-jours`}>
                                                            {TabDays.map((keyDay:string, idx:number)=>(
                                                                <SwitchDayWorkContrat wid={16} isLectureSeule={true} contrat={item.contrat} key={`DayW${idx}${index}`} keyDay={keyDay}/>
                                                            ))}
                                                        </div>
                                                    </div>
                                                ))
                                                }
                                            </div>
                                        </div> : infosJtrav.length>0 ?
                                        <div className={`infos-jtrav-single`}>
                                            <div className={`wrap-jours`}>
                                                {TabDays.map((keyDay:string, idx:number)=>(
                                                    <SwitchDayWorkContrat wid={16} isLectureSeule={true} contrat={infosJtrav[0].contrat} key={`DayW${idx}`} keyDay={keyDay}/>
                                                ))}
                                            </div>
                                        </div>:''
                                    }
                                </div>
                            </div>
                            <div className={`line-scd`}>
                                <div className={`contrat-infos bl1`}>
                                    <TimedChipsInfos
                                        title={"Contrat"}
                                        info={contratLib}
                                        themeColor={"primary"}
                                        Pending={false}
                                        maxWidth={"120px"}
                                    />
                                    <TimedChipsInfos
                                        title={"Heures a Faire"}
                                        info={cpt ? `${Math.round(cpt.heuresAFaire*100)/100}h` : '--'}
                                        themeColor={"primary"}
                                        Pending={false}
                                    />
                                </div>

                                {!!cpt &&
                                    <div className={`cptr-infos bl1`}>
                                        <TimedChipsInfos
                                            title={"Cpt début mois"}
                                            info={cpt.soldeInRelevees>=0 ? `+${Math.round(cpt.soldeInRelevees*100)/100}` : `${Math.round(cpt.soldeInRelevees*100)/100}`}
                                            themeColor={cpt.soldeInRelevees >= 0 ? "success" : "warning"}
                                            Pending={false}
                                        />
                                    </div>
                                }
                                <div className={`actions`}>
                                    <div className={`wrap-action go-planning-wrap`}>
                                        <TimedIconButton
                                            size={"medium"}
                                            themeColor={"Primary"}
                                            Icon={<BsCalendar2Date/>}
                                            toolTip={"planning"}
                                            onClick={GoPlanning}
                                            BackAlw={true}
                                            isActive={false}
                                        />
                                    </div>
                                    <div className={`wrap-action go-fiche`}>
                                        <TimedIconButton
                                            size={"medium"}
                                            themeColor={"Primary"}
                                            Icon={<AiOutlineUser/>}
                                            toolTip={"Fiche"}
                                            onClick={GoFiche}
                                            BackAlw={true}
                                            isActive={false}
                                        />
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                </div>
            }
        </div>
    )
}
const appearMulti = keyframes`
    from{
      transform: scale(0);
      opacity: 0;
    }
    to{
      transform: scale(1);
      opacity: 1;
    }
`
const UserBlock = styled(UserBlockCtrl)`
    width: 100%;
    padding: 20px;
  .line-scd{
    display: flex;
    align-items: baseline;
    .actions{
      width: 32px;
      .wrap-action{
        padding: 0.25rem 0;
      }
    }
    .bl1{
      padding: 0 0.25rem;
      flex-grow: 1;
    }
  }
  .line-head{
    width: 100%;
    display: flex;
    .avat{
      padding: 0.25rem;
    }
    .infos{
      flex-grow: 1;
      padding: 0.25rem 0.5rem;
      font-size : clamp(0.9rem, 1.2vw, 1rem);
      .name{
        font-weight: bold;
        color: ${props=>props.theme.PrimaryExtraDark};
      }
      .fonction{
        color: ${props=>props.theme.PrimaryLight};
      }
    }
  }
  .wrap-jours{
    display: flex;
    gap: 4px;
  }
  .infos-jtrav-multi{
    position: relative;
    .infos-multi{
      width: max-content;
      padding: 0.25rem 0.5rem;
      border-radius: 4px;
      background: ${props=>props.theme.PrimaryDark};
      font-size: 13px;
      color: white;
    }
    &:hover{
      cursor: pointer;
      .list-multi{
        display: block;
      }
    }
  }
  .list-multi{
    display: none;
    animation: ${appearMulti} 200ms ease-in-out forwards;
    position: absolute;
    top: 102%;
    z-index: 4;
    left: 0;
    padding: 0.5rem;
    background: white;
    width: max-content;
    border-radius: 4px;
    box-shadow: 1px 1px 3px rgba(0,0,0,.2);
  }
  .one-info-jt{
    margin-bottom: 8px;
    .lib-contrat{
      font-size: 14px;
    }
  }
`

export default UserBlock;