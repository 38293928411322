import React from "react";
import styled from "styled-components";

interface TimedEmptyFlexCtrlProps{
    className?:string;
    children:React.ReactNode;
    Ill:any;
    HeiImg:string;
}

const TimedEmptyFlexCtrl = ({className, children, Ill}:TimedEmptyFlexCtrlProps)=>{
    return (
        <div className={`TimeEmptyFlex ${className}`}>
            <div className={`image-place`}><img src={Ill} alt={"Aucun souci"}/></div>
            <div className={`text-place`}>
                {children}
            </div>
        </div>
    )
}

export const TimedEmptyFlex = styled(TimedEmptyFlexCtrl)`
    width: 100%;
  .image-place{
    width: 100%;
    text-align: center;
    img{
      width: auto;
      height: ${props=>props.HeiImg};
    }
    .text-place{
      padding: 0.2rem;
    }
  }
`