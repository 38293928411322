import styled from "styled-components";

interface OneInfosShortCtrlProps{
    className?:string;
    title:string;
    info:string;
    width:string;
}

const OneInfosShortCtrl = (props:OneInfosShortCtrlProps)=>{
    return (
        <div className={`one_infos_short ${props.className}`}>
            <span className={`title`}>{props.title}</span>
            <span className={`info`}>{props.info}</span>
        </div>
    )
}

export const OneInfosShort = styled(OneInfosShortCtrl)`
  padding: 0.25rem;
  display: flex;
  justify-content: flex-start;
  width: ${props => props.width};
  gap: 8px;
  font-size: 14px;
  .title{
    font-weight: bold;
    color: ${props => props.theme.Primary};
  }
`