import React, {useState} from "react";
import styled from "styled-components";
import AvatAno from '../../../assets/images/Unisex-Person-with-Glasses.png';

interface TimedRoundAvatarCtrlProps{
    className?:string;
    size:"xs"|"sm" | "medium" | "large" | "xl" |"other";
    sizeP?:string;
    isActif?:boolean;
    src:string;
    Border?:boolean;
    BoxShadow?:string;
    isOtherImg?:boolean;
    isSquare?:boolean;
}

const TimedRoundAvatarCtrl = ({className, isActif, src, isOtherImg}:TimedRoundAvatarCtrlProps)=>{

    const handleError = (e:any)=>{
        e.target.src = AvatAno;
    }
    return (
        <div className={`TimedRAV ${className} ${(isActif!==undefined && isActif) ? 'isActif' : (isActif!==undefined && !isActif) ? 'notActif' : ''}`}>
            <img
                src={isOtherImg ? src : `${process.env.REACT_APP_API_URL_ROOT}${src}`}
                // onLoad={handleLoaded}
                onError={handleError}
                alt={'avatar user'}
            />
        </div>
    )
}

const TimedRoundAvatar = styled(TimedRoundAvatarCtrl)`
  border:${props=>props.Border ? 'solid white 1px' : 'none'};
  box-shadow: ${props=>props.BoxShadow ? props.BoxShadow : 'none'};
    width: ${props=>(props.size === "other" && props.sizeP) ? props.sizeP : props.size=== "xs" ? "24px" :props.size === "sm" ? "36px" : props.size==="medium" ? "60px" : props.size==="large" ? "80px" : "100px"};
    border-radius: ${props=>props.isSquare ? "4px" : "50%"};
    height: ${props=>(props.size === "other" && props.sizeP) ? props.sizeP : props.size=== "xs" ? "24px" :props.size === "sm" ? "36px" : props.size==="medium" ? "60px" : props.size==="large" ? "80px" : "100px"};
    overflow: hidden;
  position: relative;
  margin: auto;
  &.isActif{
    border: solid ${props=>props.theme.Success} 6px; 
  }
    img{
      width: 100%;
      height: auto;
      position: absolute;
      left: 50%;
      top:50%;
      transform: translate(-50%, -50%);
    }
`

export default TimedRoundAvatar;