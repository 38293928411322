import {useMutation} from "react-query";
import {newSendEmail} from "./creneauEmailSender.services";
import {toast} from "react-toastify";


export const useSendEmailRplt = ()=>{
    return useMutation(
        (datas:CreneauEmailSenderFD)=>{
            return newSendEmail(datas);
        }, {
            onSuccess:(ces:CreneauEmailSender)=>{
                toast.success(ces.idsMed.length +' Emails envoyés')
            }, onError:()=>{
                toast.error("Un problème est survenu, merci de retenter plus tard");
            }
        }
    )
}