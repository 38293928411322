import styled from "styled-components";
import {OneItemProj} from "../TimedApplication/Plannings/ProjectionST/ProjectionST";
import {useGetSemaineTypesUser} from "../../features/semaineType/semaineType.hooks";
import React, {useEffect, useState} from "react";
import {useGetAllCentreSemaineType} from "../../features/centreSemaineType/centreSemainType.hooks";
import {FormEditST2} from "../Forms/FormEditST2";

interface ModalPreEditSTCtrlProps {
    className?:string;
    Item:OneItemProj;
    CloseMe:()=>void;
}

const ModalPreEditSTCtrl = ({className, CloseMe, Item}: ModalPreEditSTCtrlProps) => {
    const SemaineTypeQuery = useGetSemaineTypesUser(Item.user.id);
    const CentreSTQuery = useGetAllCentreSemaineType();
    const [myST, setMyST] = useState<SemaineType|null>(null)
    const [myCST, setMyCST] = useState<CentreSemaineType|null>(null)
    const [allSemainesType, setAllSemainesType] = useState<SemaineType[]>([])
    const [loadingDatas, setLoadingData] = useState(false);
    useEffect(() => {
        if(SemaineTypeQuery.isLoading ||CentreSTQuery.isLoading){
            setLoadingData(true);
        }
    }, [SemaineTypeQuery.isLoading, CentreSTQuery.isLoading]);
    useEffect(() => {
        if(SemaineTypeQuery.data && CentreSTQuery.data){
            const my = SemaineTypeQuery.data.find(st=>st.id === Item.id);
            if(my) {
                const myCST = CentreSTQuery.data.find(cst => cst.semaineTypes.map(i => i.id).indexOf(my.id) !== -1);
                if(myCST){
                    const All = myCST.semaineTypes;
                    setMyST(my)
                    setMyCST(myCST)
                    setAllSemainesType(All)
                    setLoadingData(false)
                } else {
                    console.log("no myCST")
                }
            } else {
                console.log("no my "+Item.id)
            }
        }
    }, [SemaineTypeQuery.data, Item, CentreSTQuery.data]);
    const setSemainesTypes = (sts:SemaineType[])=>{
        console.log(sts);
    }
    return (
        <div className={`pre_edit_st ${className}`}>
            {loadingDatas ?
                <div className={"loading_data"}>
                    Chargement des informations
                </div>:
                (myST && allSemainesType.length >0 && myCST) ?
                <FormEditST2 nbCST={CentreSTQuery.data?.length} setSemainesTypes={setSemainesTypes} closeMe={CloseMe} SemaineType={(myST as SemaineType)} allSemType={allSemainesType} User={Item.user} CentreSemType={myCST} />:
                    <p>No data</p>
            }
        </div>
    )
}

export const ModalPreEditST = styled(ModalPreEditSTCtrl)``