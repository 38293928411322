import styled from "styled-components";
import {useEffect, useState} from "react";

interface TimedChoiceNiveauCrenCtrlProps{
    className?:string;
    setFieldValue:Function;
    Current:number;
    Choice:number[];
    id:string;
}

const TimedChoiceNiveauCrenCtrl = (props:TimedChoiceNiveauCrenCtrlProps)=>{
    const [myCurrent, setMyCurrent] = useState(props.Current);
    useEffect(() => {
        setMyCurrent(props.Current)
    }, [props.Current]);
    return (
        <div className={`choice_niv ${props.className}`}>
            <label>Niveau</label>
            <div className={`wrap_choice`}>
                {props.Choice.map(item=>(
                    <div key={`oneChoiceNiv${item}`} className={`choice_niv_item ${item === props.Current ? 'selected' : ''}`} onClick={()=>props.setFieldValue(props.id, item, {shouldDirty: true})}>
                        {item}
                    </div>
                ))}
            </div>
        </div>
    )
}

export const TimedChoiceNiveauCren = styled(TimedChoiceNiveauCrenCtrl)`
  border-radius: 4px;
  position: relative;
  border:solid 1px ${props => props.theme.PrimaryExtraLight};
  padding: 0.75rem 0.5rem 0.25rem;
  margin-top: 3px;
  .wrap_choice{
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 5px;
    .choice_niv_item{
      padding: 0.15rem 0.25rem;
      border-radius: 4px;
      border:solid 1px ${props => props.theme.Primary};
      color:${props => props.theme.Primary};
      font-size: 13px;
      &.selected{
        background: ${props => props.theme.Primary};
        color:white;
      }
      &:hover{
        cursor: pointer;
        &:not(.selected) {
          background: ${props => props.theme.PrimaryMegaLight};
        }
      }
    }
  }
  label{
    display: block;
    padding: 0.2rem 0.5rem;
    position: absolute;
    left: 4px;
    top: 0px;
    transform:translateY(-50%);
    background: white;
    font-size: 12px;
  }
`