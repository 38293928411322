import React from "react";
import styled from "styled-components";
import LeftLogin from "../../assets/images/Left_login.png";
import {myBoxShadow} from "../../assets/styles/elements";
import LoginForm from "./LoginForm/LoginForm";
import {useLocation} from "react-router-dom";

interface LoginCtrlProps{
    className?:string;
}

const LoginCtrl = ({className}:LoginCtrlProps)=>{
    const location = useLocation();
    return (
        <div className={`LoginPage ${className}`}>
            <div className={`wrapConnexion`}>
                <div className="contentWrapConnexion">
                    <div className="ill-side">
                        <img src={LeftLogin} alt={"Welcome to Timed"}/>
                    </div>
                    <div className="connexion-side">
                        <div className="connexion">
                            <h1>Bienvenue !</h1>
                            <LoginForm location={location}/>
                        </div>
                        <p className={`redirect`}>Utilisateur de {process.env['REACT_APP_NAME']} (non administrateur) rendez vous sur {
                            process.env['REACT_APP_NAME']=== 'Timed' ?  <a href="https://appmobile.timed.fr">https://appmobile.timed.fr</a> : <a href="https://appmobile.restimed.fr">https://appmobile.restimed.fr</a>}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

const Login = styled(LoginCtrl)`
    height: 100vh;
  position: relative;
  .redirect{
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 0.5rem;
    font-size: 13px;
  }
  .wrapConnexion{
    position: absolute;
    width: 75%;
    max-width: 1200px;
    &:after{
      content: "";
      display: block;
      padding-bottom: 50%;
    }
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    ${myBoxShadow};
    .contentWrapConnexion{
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: start;
      background: white;
      .ill-side{
        width: 50%;
        img{
          width: 100%;
          height: 100%;
        }
      }
      .connexion-side{
        flex-grow: 1;
        position: relative;
        .connexion{
          position: absolute;
          width: 70%;
          left: 50%;
          top:50%;
          transform: translate(-50%,-50%);
          h1{
            margin-bottom: 15px;
          }
        }
      }
    }
  }
`

export default Login;