import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {useGetOneUser} from "../../../../../features/user/user.hooks";
import TimedSkeleton from "../../../../../components/atoms/TimedSkeleton/TimedSkeleton";
import {useGetParamsTimed} from "../../../../../hooks/useGetParamsTimed";
import {TabMois} from "../../../../../services/constantes/constantes";
import {useSearchParams} from "react-router-dom";
import {FiChevronLeft} from "react-icons/fi";
import {FiChevronRight} from "react-icons/fi";

import UserBlock from "./components/UserBlock";
import ChartHistoHeures from "./components/ChartHistoHeures";
import DetailsHeures from "./components/DetailsHeures";
import WeeklyHeures from "./components/WeeklyHeures";
import ChartHistoHeureDay from "./components/ChartHistoHeureDay";
import DetailsDaily from "./components/DetailsDaily";
import {useModal} from "../../../../../hooks/useModal";
import {useCentreConnectedData, useUserConnected} from "../../../../../services/hooks";
import TimedAccordion from "../../../../../components/molecules/TimedAccordion/TimedAccordion";
import {ModalGereDayPointage} from "../../../../Modaux/ModalGereDayPointage";
import {useGetCompteurTempsDateDate} from "../../../../../features/compteurTempsDateDate/compteurTempsDateDate.hooks";
import {ModalTreatJourFerie} from "../../../../Modaux/ModalTreatJourFerie";
import {TabDaily} from "./components/TabDaily";
import {useGetOneCentre} from "../../../../../features/centre/centre.hooks";


interface TempsTravailleSingleCtrlProps{
    className?:string;
    idUser:string|undefined;
    supervisor?:boolean;
}

const TempsTravailleSingleCtrl = ({className, idUser, supervisor}:TempsTravailleSingleCtrlProps)=>{
    const centre = useCentreConnectedData();
    const CentreQuery = useGetOneCentre(centre||-1);
    const idCentreSuper = supervisor ? centre : null;
    const [searchParams, setSearchParams] = useSearchParams();
    const {userConnected} = useUserConnected();
    const [isAnnualisation, setIsAnnualisation] = useState<boolean>(false);
    const UserQuery = useGetOneUser(idUser=== undefined ? 0 : parseInt(idUser))
    const {From} = useGetParamsTimed();

    const [dateStart, setDateStart] = useState<string>('')
    const [dateEnd, setDateEnd] = useState<string>('')
    const CptsQuery = useGetCompteurTempsDateDate(dateStart, dateEnd, idUser=== undefined ? [] : [parseInt(idUser)], idCentreSuper);
    const {open, toggle} = useModal();
    const {open:openFerie, toggle:toggleFerie} = useModal();
    const [pointeuseTreatCurrent, setPointeuseTreatCurrent] = useState<PointeuseTreat|null>(null);
    const [pointeuseFerieCurrent, setPointeuseFerieCurrent] = useState<PointeuseTreat|null>(null);
    useEffect(()=>{
        if(userConnected && !supervisor){
            const CentreParameters = userConnected.Centre.parameters;
            const isAnnualisation = CentreParameters.find(c=>c.Parameter.id === 6)
            if(isAnnualisation){
                setIsAnnualisation(isAnnualisation.statut)
            }
        } else if(CentreQuery.data){
            const CentreParameters = CentreQuery.data.parameters;
            const isAnnualisation = CentreParameters.find(c=>c.Parameter.id === 6)
            if(isAnnualisation){
                setIsAnnualisation(isAnnualisation.statut)
            }
        }
    }, [userConnected, supervisor, CentreQuery.data])
    useEffect(()=>{
        let DateRef = new Date(new Date().setHours(18,0));
        if(From){
            DateRef = new Date(From.setHours(18,0));
        }
        const First = new Date(DateRef.getFullYear(), DateRef.getMonth(), 1);
        First.setHours(18,0);
        const NextMonth = new Date(First.getTime()+32*86400000);
        const FirstNext = new Date(NextMonth.getFullYear(),NextMonth.getMonth(), 1);
        const Last = new Date(FirstNext.getTime() - 86400000);
        Last.setHours(18,0,0);
        const Today = new Date();
        Today.setHours(18,0);
        let Hier = new Date();
        Hier = new Date(Hier.getTime() - 86400000)
        if(Last>Today && First<Today){
            if(Hier < First){
                setDateEnd(First.toISOString().slice(0,10));
            } else {
                setDateEnd(Hier.toISOString().slice(0,10));
            }
        } else {
            setDateEnd(Last.toISOString().slice(0,10));
        }
        setDateStart(First.toISOString().slice(0,10));

    }, [From])
    const getLibDate = ()=>{
        if(dateStart === ''){
            return ''
        } else {
            const dateUseDate = new Date(dateStart);
            const month = dateUseDate.getMonth()+1;
            const year = dateUseDate.getFullYear();
            const LibMonth = TabMois.find(m=>m.num === month);
            if(!LibMonth){
                return 'error'
            } else {
                return LibMonth.name+' '+year;
            }
        }
    }
    const MoveMonth = (coef:number)=>{
        const params:any = {};
        searchParams.forEach((key, value) => {
            params[value] = key;
        });
        const DateUseDate = new Date(dateStart);
        const month = DateUseDate.getMonth();
        let year = DateUseDate.getFullYear();
        let newMonth = month+coef;
        if(newMonth < 0){
            newMonth = 11;
            year--;
        }
        if(newMonth > 11 ){
            newMonth = 0;
            year++;
        }
        const myDate = `01/${newMonth+1}/${year}`
        setSearchParams({...params, from:myDate, to:myDate})
    }
    const setMyPointeurTreatCurrent = (PointeuseT:PointeuseTreat)=>{
        setPointeuseTreatCurrent(PointeuseT);
        toggle()
    }
    const setMytPointeurFerieCurrent = (PointeuseT:PointeuseTreat)=>{
        setPointeuseFerieCurrent(PointeuseT);
        toggleFerie();
    }
    const CloseModalTreat = ()=>{
        toggle();
        setPointeuseTreatCurrent(null);
    }
    const CloseModalB = ()=>{
        toggleFerie();
    }
    return (
        <div className={`TempsTravailleSingle ${className}`}>
            <div className={`choice-date`}>
                {dateStart === '' ?
                    <div className={`wrap-wait-line1`}>
                        <TimedSkeleton width={"100%"} nbOccurence={1} type={"rect"}/>
                    </div>:
                    <div className={`wrap-choice-date`}>
                        <div className={`moveDate isRwd`} onClick={()=>MoveMonth(-1)}>
                            <FiChevronLeft/>
                        </div>
                        <div className={`libDate`}>
                            {getLibDate()}
                        </div>
                        <div className={`moveDate isFwd`} onClick={()=>MoveMonth(1)}><FiChevronRight/></div>
                    </div>
                }
            </div>
            <div className={`wrap-content`}>
                <div className={`line-user line-tps-travaille`}>
                    <div className={`wrap-block-user isBlockWhite`}>
                        {dateStart !== '' && dateEnd !== '' &&
                            <UserBlock UserQuery={UserQuery} CptQuery={CptsQuery} dateStart={dateStart}
                                       dateEnd={dateEnd}/>
                        }
                    </div>
                    <div className={"wrap-graph-histo isBlockWhite"}>
                        {(!!idUser && dateStart !== '') &&
                            <ChartHistoHeures idUser={parseInt(idUser)} dateFrom={dateStart} supervisor={supervisor}/>
                        }
                    </div>
                </div>
                <div className={`line-details-cpt line-tps-travaille`}>
                    <div className={`wrap-details isBlockWhite`}>
                        {(dateStart !== '' && dateEnd!=='') &&
                            <DetailsHeures CptQuery={CptsQuery} libDate={getLibDate()} UserQuery={UserQuery} dateUseStart={dateStart}/>
                        }
                    </div>
                </div>

                <div className={`line-user line-tps-travaille`}>
                    <div className={"wrap-details isBlockWhite"}>
                        {(!!idUser && dateStart !== '') &&
                            <ChartHistoHeureDay idUser={parseInt(idUser)} dateStart={dateStart} dateEnd={dateEnd} />
                        }
                    </div>
                </div>
                {(!!idUser && dateEnd !== '') &&
                    <div className={"wrapper-tab-daily line-tps-travaille"}>
                        <div className={"wrap-details"}>
                            <TimedAccordion
                                title={"tableau des heures"}
                                fontSize={"14px"}
                            >
                                <TabDaily idUser={parseInt(idUser)}
                                          dateUseEnd={dateEnd}
                                          setPointeurTreat={setMyPointeurTreatCurrent}
                                          setFerie={setMytPointeurFerieCurrent}
                                          supervisor={supervisor}
                                />

                            </TimedAccordion>
                        </div>
                    </div>
                }
                <div className={`line-details-cpt line-tps-travaille`}>
                    <div className={`wrap-details isBlockWhite`}>
                        {(!!idUser && dateEnd !== '') &&
                            <DetailsDaily idUser={parseInt(idUser)} dateUseEnd={dateEnd} setPointeurTreat={setMyPointeurTreatCurrent} setFerie={setMytPointeurFerieCurrent} supervisor={supervisor} />
                        }
                    </div>
                </div>
                {/*{!isAnnualisation && */!!idUser && dateStart !== '' &&
                    <div className={`line-details-sem line-tps-travaille`}>
                        <div className={"wrap-details"}>
                            <TimedAccordion
                                title={"details des semaines"}
                                fontSize={"14px"}
                            >
                                <WeeklyHeures
                                    idUser={parseInt(idUser)}
                                    dateUse={dateStart}
                                    supervisor={supervisor}
                                />
                            </TimedAccordion>
                        </div>
                    </div>
                }
            </div>
            {open && pointeuseTreatCurrent &&
                <ModalGereDayPointage id={pointeuseTreatCurrent.id} CloseMe={CloseModalTreat} supervisor={supervisor}/>
            }
            {pointeuseFerieCurrent &&
                <ModalTreatJourFerie
                    titre={"Gestion férié"}
                    CloseMe={CloseModalB}
                    open={openFerie}
                    type={"right"}
                    PointeuseTreat={pointeuseFerieCurrent}
                />
            }
        </div>
    )
}

const TempsTravailleSingle = styled(TempsTravailleSingleCtrl)`
  padding: 1rem;
  .Switch-Weekly{
    width: 100%;
    margin-bottom: 15px;
    &:hover{
      cursor: pointer;
    }
  }
  .content-weekly{
    width: 100%;
    overflow: hidden;
    max-height: 1000px;
    transition: max-height 1s ease-in-out;
  }
  .content-weekly[aria-expanded="true"] {
    max-height: 0;
    transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
  }
  .wrap-details{
    width: 100%;
  }
  .isBlockWhite{
    background: white;
    border-radius: 12px;
  }
  .wrap-content{
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .line-tps-travaille{
    width: 100%;
    display: flex;
    gap: 20px;
    .wrap-block-user{
      width: 350px;
    }
    .wrap-graph-histo{
      flex-grow: 1;
    }
  }
    .choice-date{
      width: 100%;
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
      .wrap-wait-line1{
        width: 60%;
      }
      .wrap-choice-date{
        display: flex;
        justify-content: center;
        align-items: center;
        .libDate{
          padding: 0 1rem;
        }
        .moveDate{
          text-align: center;
          border-radius: 50%;
          width: 25px;
          height: 25px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          svg{
            margin: auto;
          }
          &:hover{
            cursor: pointer;
            background: ${props=>props.theme.NeutreExtraLight};
          }
        }
        
      }
    }
`

export default TempsTravailleSingle;