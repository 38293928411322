import styled from "styled-components";

interface NoRightToAccessCtrlProps {
    className?:string;
}

const NoRightToAccessCtrl = ({className}: NoRightToAccessCtrlProps) => {
    return (
        <div className={`no_access_right ${className}`}>
            <p>En raison de vos droits d'accès, nous ne pouvez pas consulter cette page.</p>
        </div>
    )
}

export const NoRightToAccess = styled(NoRightToAccessCtrl)`
    padding: 1% 2%;
    p{
        text-align: center;
        font-size: 18px;
    }
`