import styled, {useTheme} from "styled-components";
import {Oval} from "react-loader-spinner";

interface LoadDatasCtrlProps{
    className?:string;
    legend?:string;
}

const LoadDatasCtrl = (props:LoadDatasCtrlProps)=>{
    const theme = useTheme();
    return (
        <div className={`loadDatas ${props.className}`}>
            <div className="content">
                <div className="wrap_spinner">
                    <Oval
                        visible={true}
                        height="80"
                        width="80"
                        color={theme.Primary}
                        secondaryColor={theme.PrimaryExtraLight}
                        ariaLabel="oval-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                    />
                </div>
                <div className="legend">
                    {props.legend ? props.legend : "Chargement des données en cours"}
                </div>
            </div>
        </div>
    )
}

const LoadDatas = styled(LoadDatasCtrl)`
    padding: 1rem;
    .wrap_spinner{
        width: 80px;
        margin: auto;
    }
    .legend{
        padding: 1rem;
        text-align: center;
    }
`

export default LoadDatas