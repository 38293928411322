import styled from "styled-components";

interface OneHoraireDrag_dragCtrlProps{
    className?:string;
    Horaire:string;
}

const OneHoraireDrag_dragCtrl = (props:OneHoraireDrag_dragCtrlProps)=>{
    return (
        <div className={`one_horaire_drag ${props.className}`}>
            {props.Horaire}
        </div>
    )
}

export const OneHoraireDrag_drag = styled(OneHoraireDrag_dragCtrl)`
  border-radius: 12px;
  text-align: center;
  width: max-content;
  border:solid ${props=>props.theme.Primary} 1px;
  background: white;
  margin: 5px;
  font-size: 13px;
  padding: 0.25rem 1rem;
  transform: rotate(-7deg)
`