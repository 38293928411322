import React from "react";
import styled from "styled-components";
import {BodyDash, ContentDash, TitleDash, Widy, WrapBodyDash} from "../../../assets/styles/elements";
import TimedSkeletonGraphBar from "../../../components/atoms/TimedSkeleton/TimedSkeletonGraphBar";

interface WidgetGraphCtrlProps{
    className?:string;
    children:React.ReactNode;
    width?:string;
    paddBott?:string;
    title?:string;
    subTitle?:string;
    isPending?:boolean;
}

const WidgetGraphCtrl = ({className, children, title, subTitle, isPending}:WidgetGraphCtrlProps)=>{
    return (
        <Widy className={`widget_graph ${className}`}>
            <ContentDash>
                <TitleDash>
                    <div className={`main-title`}>{title}</div>
                    {subTitle &&
                        <div className={`sub-title`}>{subTitle}</div>
                    }
                </TitleDash>
                <BodyDash className={`${subTitle ? 'suby' : ''}`}>
                    <WrapBodyDash>
                        {isPending ? <TimedSkeletonGraphBar/> :
                            children
                        }
                    </WrapBodyDash>
                </BodyDash>
            </ContentDash>
        </Widy>
    )
}

const WidgetGraph = styled(WidgetGraphCtrl)`
  width: ${props => props.width ? props.width : "100%"};
  height: 100%;
`

export default WidgetGraph;