import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {useChangeLogo} from "../../../features/user/user.hooks";
import AvatAno from '../../../assets/images/Unisex-Person-with-Glasses.png';

interface TimedRoundAvatarFormCtrlProps{
    className?:string;
    size:"sm" | "medium" | "large" | "xl";
    isActif?:boolean;
    src:string;
    idUser:number;
    isSquare?:boolean;
}

const TimedRoundAvatarFormCtrl = ({className, isActif, src, idUser}:TimedRoundAvatarFormCtrlProps)=>{
    const mutationLogo = useChangeLogo();
    const [image, setImage] = useState<string>(`${process.env.REACT_APP_API_URL_ROOT}${src}`)
    useEffect(()=>{
        setImage(`${process.env.REACT_APP_API_URL_ROOT}${src}`)
    }, [src])
    const handleError = (e:any)=>{
        e.target.src = AvatAno;
    }
    const onChange =(e:any)=>{
        if (e) {
            const datas:UserAvatarFormData = {
                File:e.target.files[0],
                id:idUser
            }
            mutationLogo.mutate(datas, {
                onSuccess:(data)=>{
                    if(data.avatarUrl!==undefined) {
                        setImage(`${process.env.REACT_APP_API_URL_ROOT}${data.avatarUrl}`)
                    }
                }
            })
        }

    }
    return (
        <label className={`TimedRAV ${className} ${(isActif!==undefined && isActif) ? 'isActif' : (isActif!==undefined && !isActif) ? 'notActif' : ''}`}>
            <img src={image} alt={'avatar user'} onError={handleError}/>
            <input id="photo-upload" type="file" onChange={onChange}/>
        </label>
    )
}

const TimedRoundAvatarForm = styled(TimedRoundAvatarFormCtrl)`
    width: ${props=>props.size === "sm" ? "40px" : props.size==="medium" ? "60px" : props.size==="large" ? "80px" : "100px"};
    border-radius: ${props=>props.isSquare ? "4px" :  "50%"};
    height: ${props=>props.size === "sm" ? "40px" : props.size==="medium" ? "60px" : props.size==="large" ? "80px" : "100px"};
    overflow: hidden;
  position: relative;
  margin: auto;
  display: block;
  &:hover{
    cursor: pointer;
  }
  input{
    position: absolute;
    left: -9999px;
    opacity: 0;
  }
  &.notActif{
    border: solid ${props=>props.theme.NeutreExtraLight} 6px;
  }
  &.isActif{
    border: solid ${props=>props.theme.Success} 6px; 
  }
    img{
      width: 100%;
      height: auto;
      position: absolute;
      left: 50%;
      top:50%;
      transform: translate(-50%, -50%);
    }
`

export default TimedRoundAvatarForm;