import {dataFetcher} from "../../services/dataGuetter/dataFetcher";

/**
 * Permet de récupérer les notifications de la personne connectée
 */
export async function getAllNotifications():Promise<NotificationTimed[]>{
    const res:any = await dataFetcher(`notifications`, {method:"get"})
    return res["hydra:member"];
}

export function allIsRead():Promise<any>{
    return dataFetcher(`notifications/all_is_read`, {method:"post"})
}


/**
 * Permet la modification d'une notification
 * @param data (NotificationFormData)
 */
export async function updateNotification(data:NotificationFormData):Promise<NotificationTimed>{
    let myDatas = {...data};
    delete myDatas.id;
    return await dataFetcher(`notifications/${data?.id}`, {
        method: "put",
        body: myDatas,
    });
}