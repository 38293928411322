import styled from "styled-components";
import PoigneeDrag from "../../components/PoigneeDrag";
import RightCard from "../../components/RightCard";
import SquareIndic from "../../components/SquareIndic";
import LibCard from "../../components/LibCard";

interface OneCardTypeCompteurCtrlProps{
    className?:string;
    Type:TypePeriode;
    ClickOnEdit:()=>void;
    isCurrent?:boolean;
}

const OneCardTypeCompteurCtrl = (props:OneCardTypeCompteurCtrlProps)=>{
    return (
        <div className={`OneCardTypeCompteur card_draggable ${props.className}`}>
            <PoigneeDrag isNoDrag/>
            <RightCard isCurrent={props.isCurrent} ClickOn={()=>props.ClickOnEdit()}>
                <LibCard text={props.Type.libelle.toUpperCase()} isLight={!props.isCurrent}/>
            </RightCard>
        </div>
    )
}

const OneCardTypeCompteur = styled(OneCardTypeCompteurCtrl)`
    
`

export default OneCardTypeCompteur