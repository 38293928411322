import styled from "styled-components";
import {usePutNewPeriode} from "../../../../features/periode/periode.hooks";
import {useEffect, useState} from "react";
import {useDebounce} from "../../../../services/hooks";
import {Contraint, DatasSetter, ValideNoError} from "../../../../components/functions/ValideDataFunctions";
import InputTextSetting from "../components/InputTextSetting";
import InputDateSetting from "../components/InputDateSetting";
import TimedButton from "../../../../components/atoms/TimedButton/TimedButton";
import ModifyLoader from "../components/ModifyLoader";

interface FormEditPeriodeCtrlProps{
    className?:string;
    Periode:Periode;
    maxDateSameType:Date|null;
}

interface PeriodeProps{
    libelle:string;
    dateDebut:string;
    dateFin:string;
}

interface ErrorsProps{
    libelle:string|null;
    dateDebut:string|null;
    dateFin:string|null;
}
const DefaultErrors:ErrorsProps={
    libelle:null,
    dateDebut:null,
    dateFin:null,
}

const FormEditPeriodeCtrl = (props:FormEditPeriodeCtrlProps)=>{
    const mutation = usePutNewPeriode();
    const [isValideDateDebut, setIsValideDateDebut] = useState<boolean>(false)
    const [minDateFin, setMinDateFin] = useState<Date>(new Date(new Date(props.Periode.finAt.slice(0,10)).setHours(23,59,59)))
    const [errors, setErrors] = useState<ErrorsProps>(DefaultErrors)
    const [isValideDateFin, setIsValideDateFin] = useState<boolean>(false)
    const [isDisabledPast, setIsDisabledPast] = useState(true);
    const [myPeriode, setMyPeriode] = useState<PeriodeProps>({
        libelle:props.Periode.libelle,
        dateDebut:props.Periode.debutAt.slice(0,10),
        dateFin:props.Periode.finAt.slice(0,10)
    })
    const libelleDebounce = useDebounce(myPeriode.libelle, 900)
    const TabNeeds:Contraint[] = [
        {id:"libelle", required:true},
        {id:"dateDebut", isDate:true, minDate:new Date(new Date((new Date()).getTime()-86400000).setHours(23,59,59))},
        {id:"dateFin", minDate:new Date(new Date((new Date()).getTime()-86400000).setHours(23,59,59))},
    ]
    const setMyValue = (value:string|null|Choice|number, id:string)=>{
        DatasSetter<PeriodeProps, ErrorsProps>(value, id as keyof PeriodeProps, ["libelle", "dateDebut", "dateFin"], [], [], TabNeeds, myPeriode, setMyPeriode,errors,setErrors)
    }
    useEffect(() => {
        setMyPeriode({
            libelle:props.Periode.libelle,
            dateDebut:props.Periode.debutAt.slice(0,10),
            dateFin:props.Periode.finAt.slice(0,10)
        })
        setIsDisabledPast(new Date(props.Periode.finAt.slice(0,10)) < new Date())
    }, [props.Periode]);
    useEffect(() => {
        if(myPeriode.dateDebut === ""){
            setIsValideDateDebut(false)
        } else {
            const limitDateDebut = props.maxDateSameType ? props.maxDateSameType : new Date(new Date(new Date().getTime()-86400000).setHours(23,59,59))
            const dateDebutDate = new Date((new Date(myPeriode.dateDebut)).setHours(12,0,0))
            setIsValideDateDebut(dateDebutDate>limitDateDebut)
            if(dateDebutDate>limitDateDebut){
                setMinDateFin(dateDebutDate)
            }
        }
        if(myPeriode.dateFin === ""){
            setIsValideDateFin(false)
        } else if(myPeriode.dateDebut !==""){
            const dateDebutDate = new Date((new Date(myPeriode.dateDebut)).setHours(12,0,0))
            const dateFinDate = new Date((new Date(myPeriode.dateFin)).setHours(12,0,0))
            setIsValideDateFin(dateFinDate>dateDebutDate)
        } else {
            const limitDateDebut = props.maxDateSameType ? props.maxDateSameType : new Date(new Date(new Date().getTime()-86400000).setHours(23,59,59))
            const dateFinDate = new Date((new Date(myPeriode.dateFin)).setHours(12,0,0))
            setIsValideDateFin(dateFinDate > limitDateDebut)
        }

    }, [myPeriode.dateDebut, myPeriode.dateFin]);
    useEffect(() => {
        if(libelleDebounce!==props.Periode.libelle && libelleDebounce!==""){
            SaveProperty("libelle", libelleDebounce)
        }
    }, [libelleDebounce, props.Periode.libelle]);

    useEffect(() => {
        if(isValideDateDebut && myPeriode.dateDebut !== props.Periode.debutAt){
            SaveProperty("dateDebut", myPeriode.dateDebut)
        }
    }, [myPeriode.dateDebut]);
    useEffect(() => {
        if(isValideDateFin && myPeriode.dateFin !== props.Periode.finAt){
            SaveProperty("dateFin", myPeriode.dateFin)
        }
    }, [myPeriode.dateDebut]);

    const SaveProperty=(key:keyof PeriodeProps, value:string)=>{
        const datas:PutPeriode = {id:props.Periode.id}
        if(key === "libelle"){
            datas.libelle = value;
        } else if(key === "dateDebut"){
            datas.debutAt = value;
        } else {
            datas.finAt = value;
        }
        mutation.mutate(datas)
    }
    return (
        <div className={`formEditPeriode ${props.className}`}>
            {(mutation.isLoading) &&
                <ModifyLoader/>
            }
            <div className="wrap_inputs_setting">

                {isDisabledPast &&
                    <p className="dis_infos">Le compteur est clos il ne peut être modifié</p>
                }
                <InputTextSetting current={myPeriode.libelle} setValue={setMyValue} Error={errors.libelle}
                                  id={"libelle"}
                                  label={"Libellé"} disabled={isDisabledPast}/>
                <InputDateSetting current={myPeriode.dateDebut} setValue={setMyValue} Error={errors.dateDebut}
                                  id={"dateDebut"} label={"Date début"}
                                  minDate={props.maxDateSameType ? props.maxDateSameType.toISOString() : new Date(new Date().getTime() - 86400000).toISOString()} disabled={isDisabledPast}/>
                <InputDateSetting current={myPeriode.dateFin} setValue={setMyValue} Error={errors.dateFin}
                                  id={"dateFin"}
                                  label={"Date fin"}
                                  minDate={minDateFin.toISOString().slice(0, 10)} disabled={isDisabledPast}/>
            </div>
        </div>
    )
}

const FormEditPeriode = styled(FormEditPeriodeCtrl)`
    position: relative;
    .dis_infos{
        font-style: italic;
        color: red;
        margin-bottom: 15px;
    }
`

export default FormEditPeriode