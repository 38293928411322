import styled from "styled-components";
import {OneLieuProj, OneSegmentationProj} from "../ProjectionST";
import {PlEntry} from "../../PlanningGlobalEquipe/components/Objects/PlEntry";
import {Mesures} from "../../PlanningGlobalEquipe/PlanningGlobalEquipe";
import {ProjActe} from "./ProjActe";

interface ProjLieuCtrlProps {
    className?:string;
    ProjLieu:OneLieuProj;
    ProjSeg:OneSegmentationProj;
    measures:Mesures
}

const ProjLieuCtrl = ({className, ProjLieu, measures, ProjSeg}: ProjLieuCtrlProps) => {
    return (
        <div className={`one_proj_lieu ${className} proj_lieu_${ProjSeg.id}`}>
            <PlEntry libelle={ProjLieu.Lieu.libelle} wD={(measures.lieux+10)+"px"} isRestFlex>
                {ProjLieu.Actes.map((item)=>(
                    <ProjActe ProjSeg={ProjSeg} ProjLieu={ProjLieu} measures={measures} ProjActe={item} key={`OneProjActe${ProjSeg.id}${ProjLieu.Lieu.id}${item.Acte.id}`}/>
                ))}
            </PlEntry>
        </div>
    )
}

export const ProjLieu = styled(ProjLieuCtrl)`
    border: solid ${props => props.theme.NeutreExtraLight} 1px;
`