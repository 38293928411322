import React from "react";
import styled from "styled-components";
import {BiMessageDetail} from "react-icons/bi";
import {BsInfo} from "react-icons/bs";
import {myBoxShadow} from "../../../assets/styles/elements";
import {getDateFrByString} from "../../functions/TimesFunctions";

interface TimedNotificationInCurtainCtrlProps{
    className?:string;
    Notification:NotificationTimed;
    setIsRead:Function;
}

const TimedNotificationInCurtainCtrl = ({className, Notification, setIsRead}:TimedNotificationInCurtainCtrlProps)=>{
    return (
        <div className={`oneNotifInCurtain ${className} ${Notification.reatAt === undefined ? 'new' : 'readed'}`}>
            <div className={`wrap-read-unread`}>
                <div className={`round-read-unread`} onClick={()=>{
                    if(Notification.reatAt===undefined){
                        setIsRead(Notification.id, true)
                    } else {
                        setIsRead(Notification.id, false)
                    }
                }}/>
            </div>
            <div className={`wrap-notification`}>
                <div className={`one-notif`}>
                    <div className="headNotif">
                        <div className="type">
                            <div className={"icon-place"}>
                                {Notification.type==='newMessage' ? <BiMessageDetail/> : <BsInfo/>}
                            </div>
                            <div className={"type-place"}>{Notification.type==='newMessage' ? 'Nouveau Message' : Notification.type}</div>
                        </div>
                        <div className="time">{getDateFrByString(Notification.creationAt)}</div>
                    </div>
                    <div className="bodyNotif">
                        {Notification.content}
                    </div>
                </div>
            </div>
        </div>
    )
}

const TimedNotificationInCurtain = styled(TimedNotificationInCurtainCtrl)`
    width: 100%;
  padding: 0.3rem 0.25rem;
  display: flex;
  justify-content: start;
  align-items: stretch;
  &.new{
    background: ${props=>props.theme.PrimaryExtraLight};
    .wrap-read-unread .round-read-unread{
      background: ${props=>props.theme.Primary};
    }
    .wrap-notification{
      .one-notif{
        .headNotif{
          .type, .time{
            font-weight: 500;
          }
        }
      }
    }
  }
  .wrap-read-unread{
    width: 30px;
    padding-top: 0.25rem;
    .round-read-unread{
      width: 16px;
      height: 16px;
      border-radius: 50%;
      margin: auto;
      background: ${props=>props.theme.NeutreExtraLight};
      &:hover{
        background: ${props=>props.theme.Primary};
        cursor: pointer;
      }
    }
  }
  .wrap-notification{
    padding: 0.25rem;
    flex-grow: 1;
    .one-notif{
      background: white;
      border-radius: 0.25rem;
      display: flex;
      flex-direction: column;
      justify-content: start;
      ${myBoxShadow};
      .bodyNotif{
        padding: 0.5rem;
        font-size: 13px;
        font-style: italic;
      }
      .headNotif{
        font-size: 14px;
        padding: 0.25rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: solid 1px ${props=>props.theme.Primary};
        .time{
          font-size: 12px;
        }
        .type{
          display: flex;
          justify-content: start;
          align-items: center;
          .type-place{
            color:${props=>props.theme.Primary}
          }
          .icon-place{
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background: ${props=>props.theme.PrimaryLight};
            color: white;
            margin-right: 5px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            svg{
              margin: auto;
            }
          }
          
        }
        
      }
    }
  }
`

export default TimedNotificationInCurtain;