import styled from "styled-components";
import {useEffect, useRef, useState} from "react";
import {useDebounce} from "../../services/hooks";
import {DeciToHeures, HeuresToDeci} from "../functions/TimesFunctions";

interface ConvertisseurCtrlProps {
    className?:string;
}

const ConvertisseurCtrl = ({className}: ConvertisseurCtrlProps) => {
    const refDeci = useRef<HTMLInputElement>(null);
    const refHoraire = useRef<HTMLInputElement>(null)
    const [deci, setDeci] = useState<string>('');
    const [hor, setHor] = useState<string>('');

    const changeDeci = ()=>{
        if(refDeci.current){
            const rgx = /^[0-9]*\.?[0-9]*$/;
            if(refDeci.current.value.match(rgx)) {
                setDeci(refDeci.current.value)
            }
        }
    }
    const changeHor = ()=>{
        if(refHoraire.current){
            setHor(refHoraire.current.value)
        }
    }
    useEffect(() => {
        if(refDeci && refDeci.current === document.activeElement){
            setHor(DeciToHeures(+deci))
        }
    }, [deci, refDeci]);
    useEffect(() => {
        if(refHoraire && refHoraire.current === document.activeElement){
            setDeci(Math.round(HeuresToDeci(hor)*100)/100+"")
        }
    }, [hor, refHoraire]);
    return (
        <div className={`Convertisseur ${className}`}>
            <div className="wrap_sider">
                <div className="wrap_one_side">
                    <label>Le temps en décimal</label>
                    <input lang={"en"} type={"text"} value={deci} onChange={changeDeci} ref={refDeci} onFocus={event=>event.target.select()}/>
                </div>
                <div className="wrap_one_side">
                    <label>Le temps en HH:MM</label>
                    <input type={"string"} value={hor} onChange={changeHor} ref={refHoraire} onFocus={event=>event.target.select()}/>
                </div>
            </div>
        </div>
    )
}

export const Convertisseur = styled(ConvertisseurCtrl)`
    padding: 2rem 1rem;
    .wrap_sider{
        
    }
    .wrap_one_side{
        flex-grow: 1;
        min-width: 30%;
        margin-bottom: 20px;
        label{
            display: block;
            width: 100%;
            font-size: 14px;
            text-align: left;
            margin-bottom: 4px;
            font-weight: bold;
            color: ${props=>props.theme.Dark};
        }
        input{
            width: 100%;
            text-align: center;
            height: 36px;
            border-radius: 4px;
            background: #F5F4F8;
            border: none;
            font-size: 20px;
            &::selection{
                background: ${props=>props.theme.PrimaryExtraLight};
                color: ${props=>props.theme.PrimarySuperDark};
            }
            &:focus{
                border:solid 2px ${props=>props.theme.Primary};
            }
        }
    }
`