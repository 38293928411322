import styled from "styled-components";
import {useGetFonctions} from "../../features/fonction/fonction.hooks";
import {useGetVuePl} from "../../features/vuePlanning/vuePlanning.hooks";
import {useGetUser, useUpdateInfosPerso} from "../../features/user/user.hooks";
import {useEffect, useState} from "react";
import {TimedChoiceMulti} from "../../components/atoms/TimedChoiceMulti/TimedChoiceMulti";
import TimedButton from "../../components/atoms/TimedButton/TimedButton";

interface FormGereFiltreHeadCtrlProps{
    className?:string;
    closeMe:()=>void;
}
const FormGereFiltreHeadCtrl = (props:FormGereFiltreHeadCtrlProps)=>{
    const mutation = useUpdateInfosPerso();
    const UserQuery = useGetUser();
    const FonctionsQuery = useGetFonctions();
    const VuesQuery = useGetVuePl();
    const [FiltersFx, setFiltersFx] = useState<Fonction[]>([])
    const [FiltersVues, setFiltersVues] = useState<VuePlanning[]>([])
    useEffect(()=>{
        if(UserQuery.data && FonctionsQuery.data && VuesQuery.data){
            const params = UserQuery.data.particularParams;
            if(params && params.hasOwnProperty("FxFilter")){
                const FxFilter = params.FxFilter;
                const prefiltreFx:Fonction[] = [];
                FxFilter.map((idFx:number)=>{
                    const FonctionFind = FonctionsQuery.data.find(fx=>fx.id === idFx)
                    if(FonctionFind){
                        prefiltreFx.push(FonctionFind);
                    }
                    return idFx
                })
                setFiltersFx(prefiltreFx);
            }
            if(params && params.hasOwnProperty("VuesFilter")){
                const VuesFilter = params.VuesFilter;
                const preFiltreVue:VuePlanning[] = [];
                VuesFilter.map((idVue:number)=>{
                    const  VueFind = VuesQuery.data.find(v=>v.id === idVue)
                    if(VueFind){
                        preFiltreVue.push(VueFind)
                    }
                    return idVue
                })
                setFiltersVues(preFiltreVue);
            }
        }
    }, [UserQuery.data, FonctionsQuery.data, VuesQuery.data])
    const setFonctionFilterChoice = (cx:Choice[])=>{
        if(cx.length === 0){
            setFiltersFx([])
        } else {
            const newFxFilter:Fonction[] = [];
            cx.map(i=>{
                const myFonction = FonctionsQuery.data?.find(fx=>fx.id === i.id);
                if(myFonction){
                    newFxFilter.push(myFonction)
                }
            })
            setFiltersFx(newFxFilter);
        }
    }
    const setVueFilterChoice = (cx:Choice[])=>{
        if(cx.length === 0){
            setFiltersVues([])
        } else {
            const newVueFilter:VuePlanning[] = [];
            cx.map(i=>{
                const myVue = VuesQuery.data?.find(v=>v.id === i.id);
                if(myVue){
                    newVueFilter.push(myVue)
                }
            })
            setFiltersVues(newVueFilter);
        }
    }
    const ClickSave = ()=>{
        const data:UserInfoPersoFormData={
            id:UserQuery.data?.id||0,
            particularParams:{FxFilter:FiltersFx.map(fx=>fx.id), VuesFilter:FiltersVues.map(v=>v.id)}
        }
        mutation.mutate(data, {
            onSuccess:()=>{
                props.closeMe();
            }
        });
    }
    return (
        <div className={`form_gere_filtre ${props.className}`}>
            <div className={`wrap_column`}>
                <div className={`one_column`}>
                    <TimedChoiceMulti
                        libelle={"Fonction"}
                        setChoice={setFonctionFilterChoice}
                        ChoicesCurrent={FiltersFx.map(fx=>{ return {id:fx.id, libelle:fx.libelle} })}
                        TabChoice={FonctionsQuery.data ? FonctionsQuery.data.map(fx=>{ return {id:fx.id, libelle:fx.libelle} }) : []}
                    />
                </div>
                <div className={`one_column`}>
                    <TimedChoiceMulti
                        libelle={"Vue Planning"}
                        setChoice={setVueFilterChoice}
                        ChoicesCurrent={FiltersVues.map(v=>{ return {id:v.id, libelle:v.libelle} })}
                        TabChoice={VuesQuery.data ? VuesQuery.data.map(v=>{ return {id:v.id, libelle:v.libelle} }) : []}
                    />
                </div>
            </div>
            <div className={"wrap_finish"}>
                <TimedButton
                    size={"sm"}
                    themeColor={"Primary"}
                    text={"Enregistrer"}
                    onClick={ClickSave}
                    type={"button"}
                />
            </div>
        </div>
    )
}

export const FormGereFiltreHead = styled(FormGereFiltreHeadCtrl)`
    .wrap_finish{
      padding: 1.5rem 0 0;
      display: flex;
      justify-content: center;
    }
    .wrap_column{
      width: 100%;
      display: flex;
      justify-content: flex-start;
      gap: 30px;
      .one_column{
        flex-grow: 1;
      }
    }
`