import styled from "styled-components";
import {useEffect, useState} from "react";
import {Contraint, DatasSetter, ValideNoError} from "../../../../components/functions/ValideDataFunctions";
import {useModifyRubrique, useTrashRubrique} from "../../../../features/rubriqueSpe/rubriqueSpe.hooks";
import ModifyLoader from "../components/ModifyLoader";
import InputTextSetting from "../components/InputTextSetting";
import InputSelectSetting from "../components/InputSelectSetting";
import {useGetUserRubriqueByRubrique} from "../../../../features/userRubriqueSpe/userRubriqueSpe.hooks";
import {useGetCentreActuel} from "../../../../features/centre/centre.hooks";
import InputListChoix from "../components/InputListChoix";
import {useModal} from "../../../../hooks/useModal";

interface FormEditRubriqueCtrlProps{
    className?:string;
    Rubrique:RubriqueSpe;
    setRubActif:(r:RubriqueSpe|null)=>void;
}
interface PropsRubriques{
    libelle:string;
    Type:Choice|null;
    Journal:Choice|null;
    Statut:Choice|null;
    List:string[]|null;
}

interface ErrorsProps{
    libelle:string|null;
    Type:string|null;
    Journal:string|null;
    Statut:Choice|null;
}

const DefaultErrors:ErrorsProps={
    libelle:null,
    Type:null,
    Journal:null,
    Statut:null
}

interface DetailsProps{
    Type:InfosProps;
    Journal:InfosProps;
    Statut:InfosProps;

}
const TabInfos:DetailsProps = {
    Type:{keyProps:"Type", libelle:"Rubrique - Type de donnée", description:"Vous choisissez le type de données que devra contenir la rubrique"},
    Journal:{keyProps:"Journal", libelle:"Rubrique - Journal de paie", description:"Si oui, la rubrique sera ajouté au journal de paie des personnes concernées"},
    Statut:{keyProps:"Statut", libelle:"Rubrique - Statut de la rubrique", description:"Si l'acte est inactif il ne pourra plus être choisi dans le futur, il restera cependant présent dans le passé"},

}

const FormEditRubriqueCtrl = (props:FormEditRubriqueCtrlProps)=>{
    const CentreQuery = useGetCentreActuel();
    const mutationModify = useModifyRubrique();
    const mutationTrash = useTrashRubrique()
    const [idRub, setIdRub] = useState<number>(props.Rubrique.id)
    const UserRubQuery = useGetUserRubriqueByRubrique(idRub);
    const ChoiceJournal:Choice[] = [{id:0, libelle:"Non", description:"Les éléments de ce type seront ignorés du journal de paie"}, {id:1, libelle:"Oui", description:"Les éléments de ce type seront repris dans le journal de paie"}]
    const ChoiceType:Choice[] = [
        {id:0, libelle:"Entier", type:"integer", description:"La rubrique sera rempli par un nombre entier"},
        {id:1, type:'float', libelle:'Decimal', description:"La rubrique sera remplie d'un nombre décimal"},
        {id:2, libelle:"Date", type:"date", description:"La rubrique sera remplie d'une date"},
        {id:3, type:'string', libelle:'Text', description:"La rubrique sera remplie par du texte"},
        {id:4, type:'list', libelle:'Liste', description:"La rubrique sera une liste de choix"}
    ]
    const choicesStatut:Choice[] = [{id:0, libelle:"Inactif", description:"Rendre la rubrique inactive pour le futur"},{id:1, libelle:"Actif", description:"La rubrique est sélectionnable"}]
    const [myRub, setMyRub] = useState<PropsRubriques>({
        libelle:props.Rubrique.libelle,
        Type:ChoiceType.find(c=>c.type === props.Rubrique.type) as Choice,
        Journal:props.Rubrique.isJournalPaie ? ChoiceJournal[1] : ChoiceJournal[0],
        Statut:props.Rubrique.isProduction ? choicesStatut[1] : choicesStatut[0],
        List:props.Rubrique.choices ? props.Rubrique.choices : null
    })
    const [myRubInitial, setMyRubInitial] = useState<PropsRubriques>({
        libelle:props.Rubrique.libelle,
        Type:ChoiceType.find(c=>c.type === props.Rubrique.type) as Choice,
        Journal:props.Rubrique.isJournalPaie ? ChoiceJournal[1] : ChoiceJournal[0],
        Statut:props.Rubrique.isProduction ? choicesStatut[1] : choicesStatut[0],
        List:props.Rubrique.choices ? props.Rubrique.choices : null
    })
    const TabString = ["libelle"];
    const TabChoice = ["Type", "Journal"]
    const TabNeed:Contraint[] = [
        {id:"libelle", required:true},
        {id:"Type", required:true},
        {id:"Journal", required:true}
    ]
    const [errors, setErrors] = useState<ErrorsProps>(DefaultErrors)
    const [isDirty, setIsDirty] = useState(false)

    const setMyValue = (value:string|null|Choice|number, id:string, confirm=false)=>{
        DatasSetter<PropsRubriques, ErrorsProps>(value, id as keyof PropsRubriques, TabString, [], TabChoice, TabNeed, myRub, setMyRub,errors,setErrors)
        if(id === "libelle"){
            setTimeout(()=>{
                setIsDirty(true);
            }, 900)
        } else {
            setIsDirty(true);
        }
    }
    useEffect(() => {
        setMyRub({
            libelle:props.Rubrique.libelle,
            Type:ChoiceType.find(c=>c.type === props.Rubrique.type) as Choice,
            Journal:props.Rubrique.isJournalPaie ? ChoiceJournal[1] : ChoiceJournal[0],
            Statut:props.Rubrique.isProduction ? choicesStatut[1] : choicesStatut[0],
            List:props.Rubrique.choices ? props.Rubrique.choices : null
        })
        setMyRubInitial({
            libelle:props.Rubrique.libelle,
            Type:ChoiceType.find(c=>c.type === props.Rubrique.type) as Choice,
            Journal:props.Rubrique.isJournalPaie ? ChoiceJournal[1] : ChoiceJournal[0],
            Statut:props.Rubrique.isProduction ? choicesStatut[1] : choicesStatut[0],
            List:props.Rubrique.choices ? props.Rubrique.choices : null
        })
        setIdRub(props.Rubrique.id)
    }, [props.Rubrique]);
    useEffect(() => {
        if(isDirty){
            SaveModify();
        }
    }, [isDirty]);
    const RealDirty = ()=>{
        const TabKey:(keyof PropsRubriques)[] = ["libelle", "Type", "Journal"]
        let nbDiff = 0;
        TabKey.forEach(k=>{
            if(myRub[k]!==myRubInitial[k]) nbDiff++;
        })
        console.log(nbDiff);
        return nbDiff>0;
    }
    const SaveModify = ()=>{
        if(ValideNoError<ErrorsProps>(errors)  &&  RealDirty()){
            const datas:RubriqueSpeModifyFD={
                libelle:myRub.libelle,
                type:myRub.Type?.type||"",
                isJournalPaie:myRub.Journal?.id ===1,
                Centre:`/api/centres/${CentreQuery.data?.id||0}`
            }
            mutationModify.mutate({id:idRub, data:datas})
        }
    }
    const TrashRubrique = ()=>{
        mutationTrash.mutate(idRub, {
            onSuccess:()=>{
                props.setRubActif(null);
            }
        })
    }
    const saveList = (list:string[]|null)=>{
        const datas:RubriqueSpeModifyFD={
            choices:list
        }
        mutationModify.mutate({id:idRub, data:datas})
    }
    const AddItemChoice = (s:string)=>{
        const actual = [...(myRub.List||[])];
        const newL = [...actual, s]
        setMyRub(rb=>{
            return {...rb, List:newL}
        })
        saveList(newL);
    }
    const RemoveItemChoice = (s:string)=>{
        const actual = [...(myRub.List||[])];
        const newL = [...actual.filter(r=>r!==s)]
        const def = newL.length === 0 ? null: newL
        setMyRub(rb=>{
            return {...rb, List:def}
        })
        saveList(def);
    }

    return (
        <div className={`formEditRubrique ${props.className}`}>
            {(mutationModify.isLoading || mutationTrash.isLoading || UserRubQuery.isLoading) &&
                <ModifyLoader/>
            }
            <div className="wrap_inputs_setting">
                <InputTextSetting current={myRub.libelle} setValue={setMyValue} Error={errors.libelle} id={"libelle"} label={"Libellé"}/>
                <InputSelectSetting setValue={setMyValue} Error={errors.Type} label={"Type"} id={"Type"} choices={ChoiceType} Current={myRub.Type} ItemInfos={TabInfos.Type} disabled={UserRubQuery.data && UserRubQuery.data.length>0} disInfo={"Cette rubrique est déjà utilisée et ne peux donc pas changer de type"}/>
                {myRub.Type?.id === 4 &&
                    <InputListChoix libelle={"Choix"} myList={myRub.List ? myRub.List : []} AddItem={AddItemChoice} RemoveChoice={RemoveItemChoice} id={"List"}/>
                }
                <InputSelectSetting setValue={setMyValue} Error={errors.Journal} label={"Journal de paie"} id={"Journal"} choices={ChoiceJournal} Current={myRub.Journal} ItemInfos={TabInfos.Journal}/>
                <InputSelectSetting setValue={setMyValue} Error={null} label={"Statut de la rubrique"} id={"Statut"} choices={choicesStatut} Current={myRub.Statut} ItemInfos={TabInfos.Statut}/>
            </div>
            {UserRubQuery.data &&
                <div className="wrap_delete_poss">
                    {UserRubQuery.data.length>0 ?
                        <div className="no_delete_poss">Cette rubrique est utilisée <strong>{UserRubQuery.data.length}</strong> fois, il n'est pas possible de la supprimer</div>:
                        <div className="delete_possible">
                            <span className={`link_delete`} onClick={TrashRubrique}>Supprimer cette rubrique</span>
                        </div>
                    }
                </div>
            }
        </div>
    )
}

const FormEditRubrique = styled(FormEditRubriqueCtrl)`
    position: relative;
    .wrap_delete_poss{
        margin-top: 30px;
    }
    .no_delete_poss{
        line-height: 170%;
    }
`

export default FormEditRubrique