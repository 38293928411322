import {dataFetcher} from "../../services/dataGuetter/dataFetcher";


export async function getUserHeureDD(idUser: number, dateStart:string, dateEnd:string, idCentre:number|null):Promise<UserHeureDD>{
    let params = `datFrom=${dateStart}&datTo=${dateEnd}`
    if(idCentre){
        params+=`&Centre=${idCentre}`
    }
    const res:any = await dataFetcher(`user_heure_d_ds/${idUser}?${params}`, {method:'get'})
    return res
}