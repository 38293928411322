import React from "react";
import styled from "styled-components";
import {useGetParamsTimed} from "../../../../hooks/useGetParamsTimed";
import TempsTravailleSingle from "./Single/TempsTravailleSingle";
import TempsTravailleMulti from "./Multi/TempsTravailleMulti";
import {PreVerifSingle} from "./Single/components/PreVerifSingle";

interface TempsTravailleCtrlProps{
    className?:string;
    supervisor?:boolean;
}

const TempsTravailleCtrl = ({className, supervisor}:TempsTravailleCtrlProps)=>{
    const {TabCollab} = useGetParamsTimed();
    return (
        <div className={`TpsTrav ${className}`}>
            {
                (TabCollab && TabCollab.length === 1) ? <PreVerifSingle idUser={parseInt(TabCollab[0])} supervisor={supervisor}/> : <TempsTravailleMulti supervisor={supervisor}/>
            }
        </div>
    )
}

const TempsTravaille = styled(TempsTravailleCtrl)`
    height: 100%;
`

export default TempsTravaille;