import styled from "styled-components";
import {useEffect, useState} from "react";
import TimedLink from "../atoms/TimedLink/TimedLink";
import {DeciToHeures} from "../functions/TimesFunctions";
import {GrClose} from "react-icons/gr";

interface CalculJourneeCtrlProps {
    className?:string;
}
interface onHor{
    heureS:string, minutesS:string, heureE:string, minutesE:string, ref:HTMLInputElement|null
}
const CalculJourneeCtrl = ({className}: CalculJourneeCtrlProps) => {
    const [listhor, setListhor] = useState<onHor[]>([{heureS:'', minutesS:'',heureE:'', minutesE:'', ref:null}])
    const [totalDeci, setTotalDeci] = useState<number>(0)
    const [totalHor, setTotalHor] = useState<string>('')
    const handleChangeForm = (index:number, prop:string, e:any)=>{
        let data = [...listhor];
        data[index][prop as keyof onHor] = e.target.value;
        setListhor(data);
        if(e.target.value.length === 2 && prop==='heureS'){
            const elt = document.getElementById("minutesS_"+index)
            elt?.focus();
        }
        if(e.target.value.length === 2 && prop==='heureE'){
            const elt = document.getElementById("minutesE_"+index)
            elt?.focus();
        }
        if(e.target.value.length === 2 && prop==='minutesS'){
            const elt = document.getElementById("heureE_"+index)
            elt?.focus();
        }
    }
    useEffect(() => {
        let nbHeure = 0;
        listhor.forEach(item=>{
            if(item.heureS!=='' && item.minutesS!=='' && item.heureE !== '' && item.minutesE!==''){
                const Start = parseInt(item.heureS)+(parseInt(item.minutesS)/60)
                const End = parseInt(item.heureE)+(parseInt(item.minutesE)/60)
                nbHeure+=End-Start;
            }
        })
        setTotalDeci(Math.round(nbHeure*100)/100)
        setTotalHor(DeciToHeures(nbHeure));
    }, [listhor]);
    const AddOption = ()=>{
        setListhor(hors=>[...hors, {heureS:'', minutesS:'',heureE:'', minutesE:'', ref:null}])
    }
    const RemoveOption = (idx:number)=>{
        const data = [...listhor];
        let newData = data.filter((i, index)=>idx!==index)
        newData = newData.filter(()=>{return true})
        setListhor(newData)
    }
    return (
        <div className={`CalculDay ${className}`}>
            {listhor.map((item, idx) => (
                <div className="one_line" key={`LH${idx}`}>
                    <div className="libLine">Periode {idx + 1}</div>
                    <div className="wrap_debut wrap_seg">
                        <div className="wrap_inputs">
                            <input id={"heureS_" + idx} placeholder={"hh"} type={"text"}
                                   onChange={(e) => handleChangeForm(idx, "heureS", e)}
                                   onFocus={event=>event.target.select()}
                            />
                            <div className="trans">:</div>
                            <input id={"minutesS_" + idx} placeholder={"mm"} type={"text"}
                                   onChange={(e) => handleChangeForm(idx, "minutesS", e)}
                                   onFocus={event=>event.target.select()}
                            />
                        </div>
                    </div>
                    <div className="wrap_fin wrap_seg">
                        <div className="wrap_inputs">
                            <input id={"heureE_" + idx} placeholder={"hh"} type={"text"}
                                   onChange={(e) => handleChangeForm(idx, "heureE", e)}
                                   onFocus={event=>event.target.select()}
                            />
                            <div className="trans">:</div>
                            <input id={"minutesE_" + idx} placeholder={"mm"} type={"text"}
                                   onChange={(e) => handleChangeForm(idx, "minutesE", e)}
                                   onFocus={event=>event.target.select()}
                            />
                        </div>
                    </div>
                    <div className="wrap_remove">
                        {idx > 0 &&
                            <button className="remove_per" onClick={()=>RemoveOption(idx)}><GrClose/></button>
                        }
                    </div>
                </div>
            ))}
            <div style={{marginTop: "12px"}}>
                <TimedLink size={"sm"} onClick={AddOption} themeColor={"Primary"} text={"+ Ajouter une periode"}/>
            </div>
            <div className="line_total">
                <div className="libLine">Total</div>
                <div className="wrap_totaux">
                    <div className="one_total"><span>{totalDeci} h</span></div>
                    <div className="one_total"><span>{totalHor}</span></div>
                </div>
            </div>
        </div>
    )
}

export const CalculJournee = styled(CalculJourneeCtrl)`
    padding: 2rem 0;
    .wrap_totaux{
        flex-grow: 1;
    }
    .wrap_remove{
        width: 36px;
        display: flex;
        justify-content: center;
        .remove_per{
            width: 24px;
            height: 24px;
            border:none;
            background: ${props=>props.theme.Warning};
            color:white;
            border-radius: 4px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            svg{
                margin: auto;
            }
        }
    }
    .line_total{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
        margin-top: 30px;
        .one_total{
            width: 50%;
            margin: auto;
            margin-bottom: 8px;
            height: 36px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            span{
                text-align: center;
            }
            border-radius: 4px;
            background: #F5F4F8;
            border: none;
            font-size: 20px;
        }
    }
    .one_line {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap:4px;
        margin-bottom: 8px;
    }
    .libLine{
        font-size: 14px;
        font-weight: bold;
    }
    .wrap_seg{
        flex-grow: 1;
        .lib{
            text-align: center;
            margin-bottom: 6px;
            font-size: 14px;
            font-weight: bold;
        
        }
        .wrap_inputs{
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 2px;
            input{
                width: 40px;
                height: 36px;
                text-align: center;
                border-radius: 4px;
                background: #F5F4F8;
                border: none;
                font-size: 20px;
                &::selection{
                    background: ${props=>props.theme.PrimaryExtraLight};
                    color: ${props=>props.theme.PrimarySuperDark};
                }
                &:focus{
                    border:solid 2px ${props=>props.theme.Primary};
                }
            }
        }
    }
`