import React, {useCallback, useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {ListChoice} from "./ListChoice";
import {WrapperInput} from "./WrapperInput";

interface TimedAutoCompletionHoraireCtrlProps{
    className?:string;
    setFieldValue:Function;
    Current:string;
    label:string;
    placeHolder:string;
    id:string;
    isReq:boolean;
    posLeft?:string;
    posTop?:string;
    MaxList?:string;
    isError?:boolean;
    clearError:Function;
    noSetEnd?:boolean;
}

interface HorMe{
    lib:string;
    lib2:string;
    lib3:string;
    lib4:string;
    numb:number;
    base5:number;
}

const start = 0;
const delta = 1/12;
const end = 23.75;
let variant = start;
const Horaires:HorMe[]=[];
while(variant <= end){
    let ent = Math.floor(variant);
    let dec = variant - ent;
    let min = Math.round(dec*6000)/100;
    if(min === 60){
        ent++;
        min=0;
    }
    Horaires.push({
        lib:`${("0"+ent).slice(-2)}:${("0"+min).slice(-2)}`,
        lib2:`${("0"+ent).slice(-2)}${("0"+min).slice(-2)}`,
        lib3:`${ent}:${("0"+min).slice(-2)}`,
        lib4:`${ent}${("0"+min).slice(-2)}`,
        numb:variant,
        base5:variant*12,
    })
    variant+=delta;
}

const TimedAutoCompletionHoraireCtrl = ({placeHolder,label,className, setFieldValue, Current, id, isReq, ...rest}:TimedAutoCompletionHoraireCtrlProps)=>{
    const [open, setOpen] = useState(false);
    const refObj = useRef<HTMLDivElement>(null);
    const [search, setSearch] = useState<string>(Current ? Current : '')
    const refSearch = useRef<HTMLInputElement>(null)
    const [libCurr, setLibCurrent] = useState<string>(Current ? Current : '')
    const refGood = useRef<HTMLDivElement>(null)
    const [onError, setOnError] = useState<boolean>(false);
    useEffect(()=>{
        if(Current!==search){
            setSearch(Current);
        }
    }, [Current])
    const handleSearchChange = ()=>{
        if(refSearch.current){
            setSearch(refSearch.current.value);
        }
    }
    useEffect(()=>{
        let searchAjust = search.length === 1 ? `0${search}00` : search.length === 2 ? `${search}00` : search;
        const valueInt = parseInt(searchAjust.slice(-2));
        if(valueInt%5!==0) {
            const RoundValue = Math.floor(valueInt / 5) * 5;
            const StrValue = RoundValue < 10 ? `0${RoundValue}` : RoundValue;
            searchAjust = searchAjust.slice(0,2)+StrValue;
        }
        const HoraireFind = Horaires.find(l=>l.lib === searchAjust || l.lib2 === searchAjust || l.lib3 === searchAjust || l.lib4 === searchAjust);
        if(HoraireFind){
            if(HoraireFind.lib !== search){
                //setSearch(HoraireFind.lib);
            }
            setLibCurrent(HoraireFind.lib)
        }
    }, [search])
    useEffect(()=>{
        if(open && refGood.current){
            refGood.current.scrollIntoView({ behavior: 'auto', block: 'start' });
        }
    }, [open, libCurr, refGood])
    const handleChanging = useCallback(()=>{
        if(search){
            let searchAjust = search.length === 1 ? `0${search}00` : search.length === 2 ? `${search}00` : search;
            const valueInt = parseInt(searchAjust.slice(-2));
            if(valueInt%5!==0) {
                const RoundValue = Math.floor(valueInt / 5) * 5;
                const StrValue = RoundValue < 10 ? `0${RoundValue}` : RoundValue;
                searchAjust = searchAjust.slice(0,2)+StrValue;
            }
            const HoraireFind = Horaires.find(l=>l.lib === searchAjust || l.lib2 === searchAjust || l.lib3 === searchAjust || l.lib4 === searchAjust);
            if(HoraireFind){
                setOnError(false)
                if(HoraireFind.lib !== search){
                    setSearch(HoraireFind.lib);
                }
                setFieldValue(id, HoraireFind.lib, {shouldDirty: true})
                // if(id==='heureDebut' && !rest.noSetEnd){
                //
                //     const HoraireEnd = Horaires.find(h=>Math.round(h.numb*100)/100 === Math.round((HoraireFind.numb + 2)*100)/100);
                //     const myHoraireEnd = HoraireEnd ? HoraireEnd.lib : "23:55";
                //     setFieldValue("heureFin", myHoraireEnd);
                // }
                setOpen(false);
            } else{
                if(search!=='') {
                    setOnError(true);
                }
            }
        }
    }, [id, rest.noSetEnd, search, setFieldValue])
    useEffect(() => {
        function handleClickOutside(event:any) {
            if (refObj.current && !refObj.current.contains(event.target)) {
                setOpen(false);
                handleChanging();

            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [refObj, handleChanging]);
    const handleClickOnIt =()=>{
        if(!open){
            refSearch.current?.focus();
            setOpen(true);
        }
    }
    const handleClickChoice = (horaireChoice:HorMe|null)=>{
        if(!!horaireChoice) {
            rest.clearError(id);
            setSearch(horaireChoice.lib);
            setFieldValue(id, horaireChoice.lib, {shouldDirty: true})
        } else {
            setFieldValue(id, '', {shouldDirty: true})
        }
        setOpen(false);
    }



    const HandleKeyDown = (e:any)=>{
        if(e.key === 'Enter' || e.keyCode === 9){
            if(search){
                let searchAjust = search.length === 1 ? `0${search}00` : search.length === 2 ? `${search}00` : search;
                const valueInt = parseInt(searchAjust.slice(-2));
                if(valueInt%5!==0) {
                    const RoundValue = Math.floor(valueInt / 5) * 5;
                    const StrValue = RoundValue < 10 ? `0${RoundValue}` : RoundValue;
                    searchAjust = searchAjust.slice(0,2)+StrValue;
                }
                const HoraireFind = Horaires.find(l=>l.lib === searchAjust || l.lib2 === searchAjust || l.lib3 === searchAjust || l.lib4 === searchAjust);
                if(HoraireFind){
                    setOnError(false)
                    if(HoraireFind.lib !== search){
                        setSearch(HoraireFind.lib);
                    }
                    setFieldValue(id, HoraireFind.lib, {shouldDirty: true})
                    if(id==='heureDebut' && !rest.noSetEnd){
                        const HoraireEnd = Horaires.find(h=>Math.round(h.numb*100)/100 === Math.round((HoraireFind.numb + 2)*100)/100);
                        const myHoraireEnd = HoraireEnd ? HoraireEnd.lib : "23:55";
                        //setFieldValue("heureFin", myHoraireEnd);
                    }
                    setOpen(false);
                } else{
                    if(search!=='') {
                        setOnError(true);
                    }
                }
            }
        }
        if (e.key !== 'ArrowUp' && e.key !== 'ArrowDown') return;
        setOnError(false)
        e.preventDefault();
        if(!open){
            setOpen(true);
        }
        const MyElt = document.getElementById("myDiv");
        if(MyElt){
            MyElt.focus();
        }
        let index = 71;
        if(search){
            const libs = Horaires.map(l=>l.lib);
            index = libs.indexOf(search) === -1 ? 71 : libs.indexOf(search);

        }
        if (e.key === 'ArrowUp'){index--}
        if (e.key === 'ArrowDown'){index++}
        if(index<0){index = 0}
        if(index>Horaires.length - 1){index = Horaires.length - 1}
        rest.clearError(id);

        setSearch(Horaires[index].lib)
        setFieldValue(id, Horaires[index].lib, {shouldDirty: true})
    }
    return (
        <div className={`ChoiceLieu ${className}`} ref={refObj}>
            {onError &&
                <div className={`tips_error`}>Horaires incorrect</div>
            }
            <WrapperInput onError={!!rest.isError && rest.isError} ClickOnIt={handleClickOnIt}>
                <input value={search} onChange={handleSearchChange} ref={refSearch} placeholder={placeHolder} onKeyDown={HandleKeyDown}/>
            </WrapperInput>

            {open &&
                <ListChoice MaxList={rest.MaxList} posLeft={rest.posLeft} posTop={rest.posLeft}>
                    <div className={`in-list-user`}>
                        {Horaires.map((h:HorMe)=> {
                            if(h.lib === libCurr){
                                return (
                                    <div ref={refGood} className={`item-choice-list isToChoice  ${h.lib === search ? 'current' : '' }`} key={`oneChoiceL${h.numb}`} onClick={()=>handleClickChoice(h)} >
                                        <div className={`name`}>{h.lib}</div>
                                    </div>
                                )
                            } else {
                                return (
                                    <div className={`item-choice-list isToChoice  ${h.lib === search ? 'current' : '' }`} key={`oneChoiceL${h.numb}`} onClick={()=>handleClickChoice(h)} >
                                        <div className={`name`}>{h.lib}</div>
                                    </div>
                                )
                            }
                        })}
                    </div>
                </ListChoice>
            }
        </div>
    )
}

const TimedAutoCompletionHoraire = styled(TimedAutoCompletionHoraireCtrl)`
  background: white;
  padding: 0.5rem;
  position: relative;
  .tips_error{
    position: absolute;
    top: -5px;
    left: 50%;
    transform: translate(-50%, -100%);
    width: max-content;
    padding: 0.25rem 0.5rem;
    background: black;
    color: white;
    z-index: 9;
    font-size: 14px;
    border-radius: 4px;
  }
  .placeHolder{
    color: #ccc;
    padding: 0.35rem;
  }
  &:hover{
    cursor: pointer;
  }
  .input-search{
    width: 100%;
    height: 100%;
    border: none;
    padding: 0 0.25rem;
  }
  .item-choice-list{
    padding: 0.5rem 0.75rem;
    font-size: 16px;
    &:hover, &.current{
      cursor: pointer;
      background: ${props=>props.theme.PrimaryExtraLight};
    }
  }
  .item-choice{
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 10px;
    padding: 0.25rem 0.5rem;
    .trash{
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background: ${props=>props.theme.NeutreSuperLight};
      color: white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      &:hover{
        cursor: pointer;
      }
      svg{
        path{
          stroke:white;
          fill:white;
        }
        margin: auto;
      }
    }
    .name{
      font-weight: bold;
      font-size: 14px;
      flex-grow: 1;
    }
   
  }
  .wrap-input{
    border: solid #ccc 1px;
    position: relative;
    border-radius: 4px;
    &.on-error{
      border: solid ${props=>props.theme.Complementary} 1px;
    }
    &.is-hight{
      border: solid ${props=>props.theme.Primary} 1px;
    }
    &:hover{
      border: solid ${props=>props.theme.PrimaryExtraDark} 1px;
    }
    &.onError{
      border-color: red;
    }
    label{
      font-size: 11px;
      position: absolute;
      padding: 0 0.25rem;
      left: 5px;
      top: 0;
      transform: translateY(-50%);
      background: white;
      color:rgba(0,0,0,0.6)
    }
    .front-input{
      width: 100%;
      padding: 0.2rem;
      display: flex;
      justify-content: start;
      align-items: center;
      .item-choice, .placeHolder{
        flex-grow: 1;
      }
      .iconPlace{
        padding: 0.25rem;
      }
    }

  }
`

export default TimedAutoCompletionHoraire;