import styled from "styled-components";
import LibelleSetting from "./LibelleSetting";
import {useRef} from "react";
import ErrorInputSetting from "./ErrorInputSetting";

interface InputTextAreaSettingCtrlProps{
    className?:string;
    id:string;
    setValue:(Choice:Choice|string|number|null, id:string)=>void;
    Error:string|null;
    label:string;
    subLabel?:string;
    current:string|number;
    wdLib?:string;
}

const InputTextAreaSettingCtrl = (props:InputTextAreaSettingCtrlProps)=>{
    const refI = useRef<HTMLTextAreaElement>(null)
    const handleChangeInput = ()=>{
        if(refI.current) props.setValue(refI.current.value, props.id)
    }
    return (
        <div className={`InputTextAreaSetting wrap_input_setting ${props.className}`}>
            <div className="in_wrap_input_setting">
                <LibelleSetting libelle={props.label} wdLib={props.wdLib} subLabel={props.subLabel}/>
                <textarea className={"textarea_type_setting"} ref={refI} onChange={handleChangeInput} value={props.current}/>
            </div>
            <ErrorInputSetting Error={props.Error}/>
        </div>
    )
}

const InputTextAreaSetting = styled(InputTextAreaSettingCtrl)``

export default InputTextAreaSetting