import styled from "styled-components";

interface LibCardCtrlProps{
    className?:string;
    text:string;
    isLight?:boolean;
}

const LibCardCtrl = (props:LibCardCtrlProps)=>{
    return (
        <div className={`libCard ${props.className}`}>{props.text}</div>
    )
}

const LibCard = styled(LibCardCtrl)`
    font-weight: ${props=>props.isLight ? "normal" :  "bold"};
    flex-grow: 1;
    
`

export default LibCard