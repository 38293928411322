import styled from "styled-components";
import TimedButton from "../../../../components/atoms/TimedButton/TimedButton";
import {TiPlus} from "react-icons/ti";
import TitrePageSetting from "../components/TitrePageSetting";
import {useGetAllActes} from "../../../../features/acte/acte.hooks";
import {useEffect, useState} from "react";
import RightPartDoublePage from "../components/RightPartDoublePage";
import LeftPartDoublePage from "../components/LeftPartDoublePage";
import PageDoubleSetting from "../components/PageDoubleSetting";
import WrapListActe from "./WrapListActe";
import OneBlockLeftPart from "../components/OneBlockLeftPart";
import FormEditActe from "./FormEditActe";
import {useGetCentreActuel} from "../../../../features/centre/centre.hooks";
import {TimedModalCentral2} from "../../../../components/molecules/TimedModal/TimedModalCentral2";
import FormNewActe from "./FormNewActe";
import {useModal} from "../../../../hooks/useModal";

interface ParamsActesCtrlProps{
    className?:string;
}

const ParamsActesCtrl = (props:ParamsActesCtrlProps)=>{
    const CentreQuery = useGetCentreActuel();
    const ActesQuery = useGetAllActes();
    const [mesActes, setMesActes] = useState<Acte[]>([])
    const [acteSet, setActeSet] = useState<Acte|null>(null)
    const {open:openNew, toggle:toggleNew} = useModal()
    useEffect(() => {
        console.log("change ici");
        if(ActesQuery.data && CentreQuery.data){
            const ActesCentre = ActesQuery.data.filter(a=>a.Centre?.id === CentreQuery.data.id)
            setMesActes(ActesCentre)
        }
    }, [ActesQuery.data, CentreQuery.data]);
    useEffect(() => {
        if(!acteSet){
            setActeSet(mesActes.filter(a=>a.isProduction).sort((a,b)=>(a.ordreEdit||0) > (b.ordreEdit||0) ? 1 : -1)[0])
        }
    }, [mesActes, acteSet]);
    const ClickNewActe = ()=>{
        toggleNew()
    }
    const EndActeNew = (a:Acte)=>{
        setActeSet(a);
        toggleNew();
    }
    return (
        <div className={`paramsActes page_params ${props.className}`}>
            <TitrePageSetting titre={"Gestion des lieux"}>
                <TimedButton onClick={ClickNewActe} themeColor={"Primary"} size={"sm"}>
                    <div className={`wrap_in_btn`}><TiPlus /><span>Créer un acte</span></div>
                </TimedButton>
            </TitrePageSetting>
            <PageDoubleSetting>
                <LeftPartDoublePage titre={"Vos Lieux"}>
                    <div className="multi_block">
                        <OneBlockLeftPart titre={"Actifs actifs"}>
                            <WrapListActe Actes={mesActes} setActeActif={setActeSet} ActeActif={acteSet}
                                          isActifView={true}/>
                        </OneBlockLeftPart>
                        <OneBlockLeftPart titre={"Actifs Inactifs"}>
                            <WrapListActe Actes={mesActes} setActeActif={setActeSet} ActeActif={acteSet}
                                          isActifView={false}/>
                        </OneBlockLeftPart>
                    </div>
                </LeftPartDoublePage>
                <RightPartDoublePage titre={acteSet ? "Configuration de l'acte "+acteSet.libelle : "Configuration acte"} subtitle={"Modifier les paramètres de l'acte en cours"}>
                    {acteSet &&
                        <FormEditActe Acte={acteSet} setActeActif={setActeSet}/>
                    }
                </RightPartDoublePage>
            </PageDoubleSetting>
            <TimedModalCentral2 closeMe={toggleNew} Wd={"500px"} open={openNew} Pending={false} titre={"Nouvel acte"}>
                <FormNewActe Actes={mesActes} closeMe={EndActeNew}/>
            </TimedModalCentral2>
        </div>
    )
}

const ParamsActes = styled(ParamsActesCtrl)``

export default ParamsActes