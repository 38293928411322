import {dataFetcher} from "../../services/dataGuetter/dataFetcher";

/**
 * Récupère tous les compteurs pour un user
 * @param id
 */
export async function getCptAbsByUser(id:number, idCentre:number|null=null):Promise<CompteurAbs>{
    let parms = `User=${id}`
    if(idCentre){
        parms +=`&Centre=${idCentre}`
    }
    const res:any = await dataFetcher(`compteur_abs?${parms}`, {method:'get'})
    return res["hydra:member"][0];
}

export async function getCptAbsHeuresByUserDate(idUser:number, date:string):Promise<CompteurAbs[]>{
    const parms = `User=${idUser}&isHeure=1&Date=${date}`
    const res:any = await dataFetcher(`compteur_abs?${parms}`, {method:'get'})
    return res["hydra:member"];
}



export async function getCptAbsByParams(params:ParamsGetCompteurAbs|null):Promise<CompteurAbs[]>{
    const TabSearch:string[] = [];
    if(params) {
        for (const [key, value] of Object.entries(params)){
            TabSearch.push(`${key}=${value}`)
        }
    }
    const search = TabSearch.length>0 ? `&${TabSearch.join('&')}` : ``
    const res:any = await dataFetcher(`compteur_abs?pagination=false${search}`, {method:'get'})
    return res["hydra:member"];
}

export async function getAllCptAbs(idCentre:number|null):Promise<CompteurAbs[]>{
    let url = `compteur_abs`
    if(idCentre){
        url+=`?Centre=${idCentre}`
    }
    const res:any = await dataFetcher(url, {method:'get'})
    return res["hydra:member"];
}

export async function getAllCptAbsDate(Date:string, idCentre:number|null):Promise<CompteurAbs[]>{
    let parms = `Date=${Date}`
    if(idCentre){
        parms+=`&Centre=${idCentre}`
    }
    const res:any = await dataFetcher(`compteur_abs?${parms}`, {method:'get'})
    return res["hydra:member"];
}

export async function getCptsAbsUsers(ids:string):Promise<CompteurAbs[]>{
    const res:any = await dataFetcher(`compteur_abs?User=${ids}`, {method:'get'})
    return res["hydra:member"];
}

export async function getCptsAbsUser(id:number):Promise<CompteurAbs[]>{
    const res:any = await dataFetcher(`compteur_abs?User=${id}`, {method:'get'})
    return res["hydra:member"];
}
