import styled from "styled-components";
import {OneActeProj, OneLieuProj, OneSegmentationProj} from "../ProjectionST";
import {Mesures} from "../../PlanningGlobalEquipe/PlanningGlobalEquipe";
import {PlEntry} from "../../PlanningGlobalEquipe/components/Objects/PlEntry";
import {ProjDay} from "./ProjDay";

interface ProjActeCtrlProps {
    className?:string;
    ProjActe:OneActeProj;
    ProjSeg:OneSegmentationProj;
    ProjLieu:OneLieuProj;
    measures:Mesures
}

const ProjActeCtrl = ({className, ProjActe, ProjSeg, ProjLieu, measures}: ProjActeCtrlProps) => {
    return (
        <div className={`one_pl_eq_acte ${className}`}>
            <PlEntry libelle={ProjActe.Acte.libelle} wD={(measures.actes + 10) + "px"} colorEntry={"PrimaryDark"}>
                <div className={`wrap_jours`}>
                    {ProjActe.Days.map((item, idx) => (
                        <ProjDay ProjD={item} key={`ProjD${ProjSeg.id}${ProjLieu.Lieu.id}${ProjActe.Acte.id}${idx}`}/>
                    ))}
                </div>
            </PlEntry>
        </div>
)
}

export const ProjActe = styled(ProjActeCtrl)`
    flex-grow: 1;
    &:not(:last-child){
        border-bottom: dashed ${props => props.theme.TertiaryExtraLight} 1px;
    }
    .wrap_jours{
        display: flex;
        justify-content: flex-start;
        align-items: stretch;
        height: 100%;
    }
`