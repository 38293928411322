import {dataFetcher} from "../../services/dataGuetter/dataFetcher";


export async function getAllTypePeriodeByCentre(idCentre:number):Promise<TypePeriode[]>{
    const res:any = await dataFetcher(`type_periodes?Acte.Centre[]=${idCentre}&Acte.Centre[]=0`)
    return res["hydra:member"]
}

export async function getAllTypeFrequence():Promise<TypeFrequence[]>{
    const res:any = await dataFetcher(`type_frequence_periodes`)
    return res["hydra:member"]
}

