import {dataFetcher} from "../../services/dataGuetter/dataFetcher";

export async function getAllHoraires(idCentre:number|null):Promise<Horaires[]>{
    let url = `horaires`;
    if(idCentre){
        url = `horaires?Centre=${idCentre}`
    }
    const res:any = await dataFetcher(url, {method:'get'});
    return res["hydra:member"];
}

export function modifyHoraires(id:number, datas:HorairesNewFD):Promise<Horaires>{
    return dataFetcher(`horaires/${id}`, {
        method:'put',
        body:datas
    })
}

