import styled, {css, keyframes} from "styled-components";
import TimedTheme from "../../../assets/styles/TimedTheme";

const Theme = TimedTheme;

export const ZoneSearchSmart = styled.div`
  width: 100%;
  border-bottom: solid 1px ${Theme.NeutreExtraLight};
  position: relative;
  input{
    width: 100%;
    height: 38px;
    border:none;
    padding: 0 20px 0 10px;
    font-size: 14px;
  }
  .close-search{
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    &:hover{
      cursor: pointer;
    }
  }
`

export const ContentSmart = styled.div`
  flex-grow: 1;
  height: 0;
  overflow-y: auto;
  padding: 0.5rem;
`