import {useQuery, UseQueryResult} from "react-query";
import {getForUserDateFrom} from "./userHeure.services";

export const useGetUserHeure = (id:number, datFrom:string, idCentre:number|null=null):UseQueryResult<UserHeure, Error>=>{
    return useQuery<UserHeure, Error>(["user_heures", id, datFrom, idCentre], ()=>getForUserDateFrom(id, datFrom, idCentre), {
        staleTime:60000,
        enabled:id!==0 && datFrom!==''
    })
}


