import React from "react";
import {useGetCreneauxNonPris} from "../../../features/creneau/creneau.hooks";
import WidgetMetrix from "../Components/WidgetMetrix";
import {useCentreConnectedData} from "../../../services/hooks";

interface WidgetCreneauxNonPrisCtrlProps{
    supervisor?:boolean;
}

export const WidgetCreneauxNonPris = ({supervisor}:WidgetCreneauxNonPrisCtrlProps)=>{
    const centre = useCentreConnectedData();
    const CreneauxNonPrisQuery = useGetCreneauxNonPris(supervisor ? centre : null);
    return (
        <WidgetMetrix
            title={"Créneaux à prendre"}
            Metrix={(CreneauxNonPrisQuery.isLoading || !CreneauxNonPrisQuery.data?.filter(c=>new Date(c.creneauAt)>new Date())) ? 0 : CreneauxNonPrisQuery.data.filter(c=>new Date(c.creneauAt)>new Date()).length}
            width={"100%"}
            isPending={CreneauxNonPrisQuery.isLoading}
        />
    )
}

