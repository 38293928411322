import React, {useEffect, useState} from "react";
import styled from "styled-components";
import TimedIconButton from "../../../../components/atoms/TimedIconButton/TimedIconButton";
import {BsPencil, BsTrash} from "react-icons/bs";
import {useTrashNews} from "../../../../features/news/news.hooks";


interface CardNewsCtrlProps{
    className?:string;
    News:News;
    setNewsEdit:(news:News)=>void;
}

const CardNewsCtrl = ({className, News, setNewsEdit}:CardNewsCtrlProps)=>{
    const [themeColor, setThemeColor] = useState("Primary");
    const mutationTrash = useTrashNews();
    useEffect(()=>{
        const isProgramme = new Date(News.startAt) > new Date()
        const isTermine = News.endAt!==null && new Date(News.endAt) < new Date();
        const ThemeColor = isProgramme ? "Tertiary" : isTermine ? "Complementary" : "Secondary"
        setThemeColor(ThemeColor);
    }, [News])
    const handleClickEdit = ()=>{
        setNewsEdit(News);
    }
    const handleClickTrash = ()=>{
        mutationTrash.mutate((News.id))
    }
    return (
        <div className={`CardNews ${className}`}>
            <div className={`Image-place`}>
                <img src={`${process.env.REACT_APP_API_URL_ROOT}${News.illustrationUrl}`} alt={`${News.titre}`}/>
            </div>
            <div className={`informations`}>
                <div className={`titre-date`}>
                    <div className={`wrap-titre`}>
                        <div className={`Active ${News.isActive ? 'is-active' : ''}`}/>
                        <div className={`titre`}>{News.titre}</div>
                    </div>
                    <div className={`date ${themeColor}`}>
                        publication à partir du  {new Date(News.startAt).toLocaleDateString()} - {News.endAt === null ? 'sans date de fin' : `jusqu'au ${new Date(News.endAt).toLocaleDateString()}`}
                    </div>
                </div>
                <div className={'sujet-edit'}>
                    <div className={`sujet`}>{News.sujet}</div>
                    <div className={`wrap-actions`}>
                        <TimedIconButton
                            size={"medium"}
                            themeColor={"Primary"}
                            Icon={<BsPencil/>}
                            toolTip={"Edit"}
                            onClick={handleClickEdit}
                            BackAlw={false}
                            isActive={false}
                        />
                        <TimedIconButton
                            size={"medium"}
                            themeColor={"Warning"}
                            Icon={<BsTrash/>}
                            toolTip={"Supprimer"}
                            onClick={handleClickTrash}
                            BackAlw={false}
                            isActive={false}
                            isPending={mutationTrash.isLoading}
                        />
                    </div>
                </div>
                <div className={'content'}>{News.content}</div>
            </div>
        </div>
    )
}

export const CardNews = styled(CardNewsCtrl)`
    width: 100%;
  margin-bottom: 20px;
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  background: white;
  box-shadow: 1px 1px 3px rgba(0,0,0,.2);
  .sujet-edit{
    display: flex;
    justify-content: space-between;
    .sujet{
      font-size: 16px;
      font-weight: bold;
    }
    .wrap-actions{
      flex-grow: 1;
      display: flex;
      justify-content: flex-end;
      gap:10px;
    }
  }
  .titre-date{
    display: flex;
    justify-content: space-between;
    .wrap-titre{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap:10px;
      .Active{
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background: ${props => props.theme.Neutre};
        &.is-active{
          background: ${props => props.theme.Success};
        }
      }
    }
    .titre{
      font-size: 18px;
      font-weight: bold;
    }
    .date{
      flex-grow: 1;
      text-align: right;
      font-size: 14px;
      font-weight: bold;
      &.Complementary{
        color: ${props=>props.theme.Complementary};
      }
      &.Tertiary{
        color: ${props=>props.theme.Tertiary};
      }
      &.Secondary{
        color: ${props=>props.theme.Secondary};
      }
    }
  }
  .informations{
    flex-grow: 1;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap:10px
    
  }
  .Image-place{
    width: 180px;
    height: 180px;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    overflow: hidden;
    img{
      width: 100%;
      height: auto;
    }
  }
`
