import React, {useEffect, useState} from "react";
import styled from "styled-components";
import TimedButton from "../../components/atoms/TimedButton/TimedButton";
import TimedModal from "../../components/molecules/TimedModal/TimedModal";
import {useGetPlageDate, useGetPlageDateWait} from "../../features/plage/plage.hooks";
import {useValideCreneau} from "../../features/creneau/creneau.hooks";
import {getIsHorseV2} from "../../components/functions/PlageFunctions";

interface ModalValideCreneauxCtrlProps{
    className?:string,
    Close:Function;
    isPending?:boolean;
    Creneau:Creneau;
}

const ModalValideCreneauxCtrl = ({className, Creneau, ...props}:ModalValideCreneauxCtrlProps)=>{
    const PlageQuery = useGetPlageDate(new Date(Creneau.creneauAt.slice(0,10)).toISOString().slice(0,10), Creneau.UserAffected ? Creneau.UserAffected.id : undefined);
    const mutationValideCreneau = useValideCreneau();
    const [plagesConflits, setPlagesConflits] = useState<Plage[]>([]);
    const [busy, setBusy] = useState<boolean>(false);
    useEffect(()=>{
        if(PlageQuery.data){
           setPlagesConflits(getIsHorseV2(Creneau.heureDebut, Creneau.heureFin, PlageQuery.data));
        }
    }, [PlageQuery.data])
    // const CloseMe = ()=>{
    //     props.Close();
    // }
    const ValideCreneau = ()=>{
        setBusy(true);
        mutationValideCreneau.mutate((Creneau.id), {
            onSuccess:()=>{
                setBusy(false);
                setPlagesConflits([]);
                props.Close();
            }, onError:()=>{
                setBusy(false);
            }
        })
    }
    return (
        <TimedModal
            size={"regular"}
            Close={props.Close}
            title={"Validation Creneaux"}
            position={"center"}
            topPosition={"Middle"}
        >
            <div className={"valide-creneau" + className}>
                {PlageQuery.isLoading ?
                    <div className={`wait-load`}>
                        <p>Validation de la disponibilité du docteur <strong>{Creneau.UserAffected?.nom}</strong> en cours...</p>
                    </div>:
                    busy ?
                        <div className={`wait-load`}>
                            <p>Validation en cours...</p>
                        </div>:
                    plagesConflits.length > 0 ?
                        <div className={"wrap-conflits"}>
                            <p className={"already_take"}>Le créneau ne peut être validé car le docteur <strong>{Creneau.UserAffected?.nom}</strong> est déjà affecté.</p><p>Veuillez libérer le planning du docteur <strong>{Creneau.UserAffected?.nom}</strong>, ou libérer ce créneau pour qu'il puisse être pris par quelqu'un d'autre</p>
                        </div>:
                        <div className={`wrap-valide`}>
                            <p style={{textAlign:'center'}}>le docteur <strong>{Creneau.UserAffected?.nom}</strong> est disponible pour ce créneaux vous pouvez valider le créneau</p>
                            <div className={`wrap-confirm`}>
                                <TimedButton
                                    size={"sm"}
                                    themeColor={"Secondary"}
                                    text={"Valider le créneau"}
                                    onClick={ValideCreneau}
                                />
                            </div>
                        </div>
                }
            </div>
        </TimedModal>
    )
}

const  ModalValideCreneaux = styled(ModalValideCreneauxCtrl)`
    padding: 1rem;
    .TextConfirm{
      text-align: center;
      font-size: 18px;
    }
  .wrap-confirm{
    margin-top: 10px;
    display: flex;
    justify-content: center;
    padding: 0.5rem;
  }
`
export default ModalValideCreneaux;