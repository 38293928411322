import styled from "styled-components";

import {useEffect, useRef, useState} from "react";
import {ConvertBase5, ConvertToBase5} from "../../../../components/functions/TimesFunctions";


interface SetterHorairesSettingCtrlProps {
    className?:string;
    setHor:(b:number, id:string)=>void;
    CurrentStart:number;
    CurrentEnd:number;
    idStart:string;
    idEnd:string;
    ErrorStart:string|null;
    ErrorEnd:string|null;
    flexer?:boolean;
    isDisabled?:boolean;
    gap?:string;
}
const ConvertToHeurex = (base5:number, type:"HH"|"MM")=>{
    const My:string = ConvertBase5(base5);
    if(type === "HH") {
        return My.slice(0, 2);
    } else {
        return My.slice(-2);
    }
}
interface HorThis{
    HH:string;
    MM:string;
    full:string;
}

const SetterHorairesSettingCtrl = ({className, setHor, CurrentStart, idStart, CurrentEnd, idEnd, ErrorStart, ErrorEnd, isDisabled}: SetterHorairesSettingCtrlProps) => {
    const [horairesS, setHorairesS] = useState<HorThis>({HH:ConvertToHeurex(CurrentStart, "HH"), MM:ConvertToHeurex(CurrentStart, "MM"), full:ConvertBase5(CurrentStart)})
    const [horairesE, setHorairesE] = useState<HorThis>({HH:ConvertToHeurex(CurrentEnd, "HH"), MM:ConvertToHeurex(CurrentEnd, "MM"), full:ConvertBase5(CurrentEnd)})
    const refHS = useRef<HTMLInputElement>(null)
    const refMS = useRef<HTMLInputElement>(null)
    const refHE = useRef<HTMLInputElement>(null)
    const refME = useRef<HTMLInputElement>(null)
    const ChangeHor = (Hor:HorThis, type:"Start"|"End")=>{
        if(type === "Start"){
            setHorairesS(Hor)
        } else {
            setHorairesE(Hor)
        }
        if(Hor.HH.length === 2 && Hor.MM.length === 2) {
            const myH = ConvertToBase5(`${Hor.HH}:${Hor.MM}`)
            if(type === "Start") {
                setHor(myH, idStart)
            } else {
                setHor(myH, idEnd)
            }
        }

    }
    // useEffect(() => {
    //     console.log('ici1')
    //
    //     if(horairesS.HH.length === 2 && horairesS.MM.length === 2){
    //         const myH = ConvertToBase5(`${horairesS.HH}:${horairesS.MM}`)
    //         console.log(myH)
    //         console.log(CurrentStart)
    //         if(myH !== CurrentStart) setHor(myH, idStart)
    //     }
    // }, [horairesS, idStart, setHor, CurrentStart]);
    // useEffect(() => {
    //     if(horairesE.HH.length === 2 && horairesE.MM.length === 2){
    //         const myH = ConvertToBase5(`${horairesE.HH}:${horairesE.MM}`)
    //         if(myH !== CurrentEnd) setHor(myH, idEnd)
    //     }
    // }, [horairesE, idEnd, setHor, CurrentEnd]);
    useEffect(() => {
        if(CurrentStart!==0) {
            setHorairesS({
                HH: ConvertToHeurex(CurrentStart, "HH"),
                MM: ConvertToHeurex(CurrentStart, "MM"),
                full: ConvertBase5(CurrentStart)
            })
        }
    }, [CurrentStart]);
    useEffect(() => {
        if(CurrentEnd!==0) {
            setHorairesE({
                HH: ConvertToHeurex(CurrentEnd, "HH"),
                MM: ConvertToHeurex(CurrentEnd, "MM"),
                full: ConvertBase5(CurrentEnd)
            })
        }
    }, [CurrentEnd]);
    const ConvertB5 = (val:string)=>{
        const valueInt = parseInt(val);
        let valNumber = valueInt;
        if(valueInt%5!==0) {
            valNumber = Math.floor(valueInt / 5) * 5;
        }
        return valNumber < 10 ? `0${valNumber}` : valNumber+"";
    }
    const ChangeForm = (id:string)=>{
        switch (id){
            case "HS":
                if(refHS.current) {
                    const val = refHS.current.value;
                    const rgx = /^[0-9]*\.?[0-9]*$/;
                    if(val.match(rgx)) {
                        ChangeHor({...horairesS, HH:val}, "Start")
                        // setHorairesS(h=>{
                        //     return {...h, HH:val}
                        // })
                    }
                    if(val.length === 2 && refMS.current){
                        refMS.current.focus()
                    }
                }
                break;
            case "MS":
                if(refMS.current) {
                    const val = refMS.current.value;
                    const rgx = /^[0-9]*\.?[0-9]*$/;
                    if(val.match(rgx)) {
                        ChangeHor({...horairesS, MM:val}, "Start")
                        // setHorairesS(h=>{
                        //     return {...h, MM:val}
                        // })
                        if(val.length === 2){
                            ChangeHor({...horairesS, MM:ConvertB5(val)}, "Start")
                            // setHorairesS(h=>{
                            //     return {...h, MM:ConvertB5(val)}
                            // })
                            if(refHE.current) refHE.current.focus();
                        }
                    }

                }
                break;
            case "HE":
                if(refHE.current) {
                    const val = refHE.current.value;
                    const rgx = /^[0-9]*\.?[0-9]*$/;
                    if(val.match(rgx)) {
                        ChangeHor({...horairesE, HH:val}, "End")
                        // setHorairesE(h=>{
                        //     return {...h, HH:val}
                        // })
                    }
                    if(val.length === 2 && refME.current){
                        refME.current.focus()
                    }

                }
                break;
            default:
                if(refME.current) {
                    const val = refME.current.value;
                    const rgx = /^[0-9]*\.?[0-9]*$/;
                    if(val.match(rgx)) {
                        ChangeHor({...horairesE, MM:val}, "End")
                        // setHorairesE(h=>{
                        //     return {...h, MM:val}
                        // })
                    }

                }
                break;
        }
    }
    const Convert2DigitH = (valNB:number)=>{
        return `0${valNB}`.slice(-2);
    }
    const Convert2DigitHeureS = ()=>{
        if(refHS.current) {
            const val = refHS.current.value;
            const valNB = parseInt(val);
            setHorairesS(h=>{
                return {...h, HH:Convert2DigitH(valNB)}
            })

        }
    }

    const Convert2DigitHeureE = ()=>{
        if(refHE.current) {
            const val = refHE.current.value;
            const valNB = parseInt(val);
            setHorairesE(h=>{
                return {...h, HH:Convert2DigitH(valNB)}
            })
        }
    }
    return (
        <div className={`set_horaires ${className}`}>
            <div className="wrap_on_series">

                <div className="wrap_inputs">
                    <input
                        ref={refHS}
                        disabled={isDisabled}
                        className={`set_h ${ErrorStart ? "on_error" : ""}`}
                        value={horairesS.HH}
                        onFocus={event => event.target.select()}
                        onChange={() => ChangeForm("HS")}
                        onBlur={Convert2DigitHeureS}
                    />
                    <span>:</span>
                    <input
                        ref={refMS}
                        disabled={isDisabled}
                        className={`set_h ${ErrorStart ? "on_error" : ""}`}
                        value={horairesS.MM}
                        onFocus={event => event.target.select()}
                        onChange={() => ChangeForm("MS")}
                    />
                </div>
                {/*{ErrorStart &&*/}
                {/*    <ErrorInput>{ErrorStart}</ErrorInput>*/}
                {/*}*/}
            </div>
            <span>-</span>
            <div className="wrap_on_series">

                <div className="wrap_inputs">
                    <input
                        ref={refHE}
                        disabled={isDisabled}
                        className={`set_h ${ErrorEnd ? "on_error" : ""}`}
                        value={horairesE.HH}
                        onFocus={event => event.target.select()}
                        onChange={() => ChangeForm("HE")}
                        onBlur={Convert2DigitHeureE}
                    />
                    <span>:</span>
                    <input
                        ref={refME}
                        disabled={isDisabled}
                        className={`set_h ${ErrorEnd ? "on_error" : ""}`}
                        value={horairesE.MM}
                        onFocus={event => event.target.select()}
                        onChange={() => ChangeForm("ME")}
                    />
                    {/*{ErrorEnd &&*/}
                    {/*    <ErrorInput>{ErrorEnd}</ErrorInput>*/}
                    {/*}*/}
                </div>
            </div>
        </div>
    )
}

export const SetterHorairesSetting = styled(SetterHorairesSettingCtrl)`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 25px;
    label{
        font-weight: bold;
        font-size: 14px;
        margin-bottom: 2px;
    }
    .set_h{
        width: 50px;
        background: ${props => props.theme.colorBackInput};
        height: 30px;
        border-radius: 4px;
        text-align: center;
        border:solid 1px transparent;
        &.on_error{
            border: solid ${props => props.theme.Warning} 1px;
            background: ${props => props.theme.Warning};
            color:white;
        }
        &:focus{
            border: solid ${props => props.theme.PrimaryLight} 1px;
        }
        &:hover{
            cursor: ${props=>props.isDisabled ? "not-allowed" :  "pointer"};
            filter:brightness(97%);
        }
    }
    .wrap_on_series{
        display: flex;
        justify-content: flex-start;
        .wrap_inputs{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 2px;
            span{
                display: block;
            }
        }
    }
`