import React from "react";
import styled from "styled-components";

interface TimedRoundDayCtrlProps{
    className?:string;
    isSelected:boolean;
    isAnomalie:boolean;
    isAnomalieTreat:boolean;
    hasObject:boolean;
    date:Date;
    setDate:Function;
}

const TimedRoundDayCtrl = ({className, isSelected, isAnomalie, hasObject, setDate, date, isAnomalieTreat}:TimedRoundDayCtrlProps)=>{
    return (
        <div className={`choiceD ${className} ${isSelected ? 'current' : ''} ${hasObject ? 'hasObject' : 'empty'} ${(isAnomalie && !isAnomalieTreat) ? 'ano' : (isAnomalie && isAnomalieTreat) ? 'anoT' : ''}`} onClick={()=>setDate(date)}>
            {("0"+date.getDate()).slice(-2)}
        </div>
    )

}

export const TimedRoundDay = styled(TimedRoundDayCtrl)`
  font-size: 12px;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  &.empty{
    color: ${props => props.theme.NeutreLight};
  }
  &.hasObject{
    &:after{
      content: '';
      position: absolute;
      top: 80%;
      left: 50%;
      transform: translateX(-50%);
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background: ${props=>props.theme.PrimaryDark};
    }
    &.ano{
      &:after{
        background: red;
      }
    }
    &.anoT{
      &:after{
        background: ${props=>props.theme.SecondaryDark};
      }
    }
  }

  &:hover:not(.empty), &.current{
    cursor: pointer;
    background: ${props => props.theme.NeutreExtraLight};
  }
`