
export const TabMois = [
    {numEn:0, num:1, numMois:'01', name:'Janvier', shortName:'Janv.'},
    {numEn:1, num:2, numMois:'02', name:'Février', shortName:'Fév.'},
    {numEn:2, num:3, numMois:'03', name:'Mars', shortName:'Mars.'},
    {numEn:3, num:4, numMois:'04', name:'Avril', shortName:'Avr.'},
    {numEn:4, num:5, numMois:'05', name:'Mai', shortName:'Mai.'},
    {numEn:5, num:6, numMois:'06', name:'Juin', shortName:'Juin.'},
    {numEn:6, num:7, numMois:'07', name:'Juillet', shortName:'Juil.'},
    {numEn:7, num:8, numMois:'08', name:'Août', shortName:'Août'},
    {numEn:8, num:9, numMois:'09', name:'Septembre', shortName:'Sept.'},
    {numEn:9, num:10, numMois:'10', name:'Octobre', shortName:'Oct.'},
    {numEn:10, num:11, numMois:'11', name:'Novembre', shortName:'Nov.'},
    {numEn:11, num:12, numMois:'12', name:'Décembre', shortName:'Dec.'},
];

export const TabJour = [
    {num:0, name:'Lundi'},
    {num:1, name:'Mardi'},
    {num:2, name:'Mercredi'},
    {num:3, name:'Jeudi'},
    {num:4, name:'Vendredi'},
    {num:5, name:'Samedi'},
    {num:6, name:'Dimanche'},
];

export const TabJourISO = [
    {num:0, name:'Dimanche', short:'D', orderStd:7},
    {num:1, name:'Lundi', short:'L', orderStd:1},
    {num:2, name:'Mardi', short:'M', orderStd:2},
    {num:3, name:'Mercredi', short:'Me', orderStd:3},
    {num:4, name:'Jeudi', short:'J', orderStd:4},
    {num:5, name:'Vendredi', short:'V', orderStd:5},
    {num:6, name:'Samedi', short:'S', orderStd:6},
];

export const NbJourMois = [
    {id:1, nb:31, lib:'Janv'},
    {id:2, nb:28.25, lib:'Fev'},
    {id:3, nb:31, lib:'Mars'},
    {id:4, nb:30, lib:'Avril'},
    {id:5, nb:31, lib:'Mai'},
    {id:6, nb:30, lib:'Juin'},
    {id:7, nb:31, lib:'Juillet'},
    {id:8, nb:31, lib:'Aout'},
    {id:9, nb:30, lib:'Sept'},
    {id:10, nb:31, lib:'Oct'},
    {id:11, nb:30, lib:'Nov'},
    {id:12, nb:31, lib:'Dec'},
]

export const TabJourArray = ['Dimanche','Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];



export const TabFixeNavBar = [
    {
        road:'gestion_pointages',
        Navigation:true,
        Fonction:false,
        Collab:false,
        Moment:false,
        Lieu:false,
    },
    {
        road: 'rh_dashboard',
        Navigation:true,
        Fonction:false,
        Collab:false,
        Moment:false,
        Lieu:false,
    },
    {
        road: 'messagerie',
        Navigation:true,
        Fonction:false,
        Collab:false,
        Moment:false,
        Lieu:false,
    },
    {
        road: 'fiche_personnel',
        Navigation:true,
        Fonction:true,
        Collab:true,
        Moment:false,
        Lieu:false,
    }
    ,
    {
        road: 'temps_travaille',
        Navigation:true,
        Fonction:true,
        Collab:true,
        Moment:false,
        Lieu:false,
    }, {
        road: "equivalent_temps_plein",
        Navigation:true,
        Fonction:true,
        Collab:true,
        Moment:false,
        Lieu:false,
    }
]

export const TabColorActeLieu = [
    {back:"#A60095", font:"#FFFFFF"},
    {back:"#C741AE", font:"#FFFFFF"},
    {back:"#EC5EB3", font:"#FFFFFF"},
    {back:"#FFC1EE", font:"#000000"},
    {back:"#F8E5FB", font:"#000000"},

    {back:"#AA101B", font:"#FFFFFF"},
    {back:"#E23040", font:"#FFFFFF"},
    {back:"#F8959B", font:"#000000"},
    {back:"#E8A1A6", font:"#000000"},
    {back:"#FFF0F2", font:"#000000"},

    {back:"#9B6A1F", font:"#FFFFFF"},
    {back:"#FDA625", font:"#FFFFFF"},
    {back:"#FDD750", font:"#000000"},
    {back:"#FCF31E", font:"#000000"},
    {back:"#F8EFAA", font:"#000000"},

    {back:"#055B30", font:"#FFFFFF"},
    {back:"#02844A", font:"#FFFFFF"},
    {back:"#1AD276", font:"#000000"},
    {back:"#95F8B9", font:"#000000"},
    {back:"#DAFCDF", font:"#000000"},

    {back:"#06746F", font:"#FFFFFF"},
    {back:"#3CB0AC", font:"#FFFFFF"},
    {back:"#28D4CC", font:"#000000"},
    {back:"#8FF2EC", font:"#000000"},
    {back:"#D8FAFA", font:"#000000"},

    {back:"#0F3B7E", font:"#FFFFFF"},
    {back:"#0066B7", font:"#FFFFFF"},
    {back:"#0E90D8", font:"#FFFFFF"},
    {back:"#54BBEB", font:"#000000"},
    {back:"#C6E3EC", font:"#000000"},

    {back:"#0B3152", font:"#FFFFFF"},
    {back:"#325578", font:"#FFFFFF"},
    {back:"#6383A8", font:"#FFFFFF"},
    {back:"#9CB4CE", font:"#000000"},
    {back:"#D6E3FF", font:"#000000"},
]

