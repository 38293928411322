import styled from "styled-components";
import LibelleSetting from "./LibelleSetting";
import {useEffect, useRef} from "react";
import ErrorInputSetting from "./ErrorInputSetting";

interface InputDateSettingCtrlProps{
    className?:string;
    id:string;
    setValue:(Choice:Choice|string|number|null, id:string)=>void;
    Error:string|null;
    label:string;
    subLabel?:string;
    current:string|number;
    wdLib?:string;
    disabled?:boolean;
    minDate?:string;
    maxDate?:string;
}

const InputDateSettingCtrl = (props:InputDateSettingCtrlProps)=>{
    const refI = useRef<HTMLInputElement>(null)
    useEffect(() => {
        console.log(props.current)
    }, [props.current]);
    const handleChangeInput = ()=>{
        if(refI.current) props.setValue(refI.current.value, props.id)
    }
    return (
        <div className={`InputDateSetting wrap_input_setting ${props.className}`}>
            <div className="in_wrap_input_setting">
                <LibelleSetting libelle={props.label} wdLib={props.wdLib} subLabel={props.subLabel}/>
                <input type={"date"} min={props.minDate} max={props.maxDate} className={"input_type_setting"} ref={refI} onChange={handleChangeInput} value={props.current} disabled={props.disabled}/>
            </div>
            <ErrorInputSetting Error={props.Error}/>
        </div>
    )
}

const InputDateSetting = styled(InputDateSettingCtrl)``

export default InputDateSetting