import styled from "styled-components";
import LibelleSetting from "./LibelleSetting";
import {SetterHorairesSetting} from "./SetterHorairesSetting";

interface ChangeHoraireSettingCtrlProps{
    className?:string;
    currentStart:number;
    currentEnd:number;
    setHor:(b:number, id:string)=>void;
    label:string;
    errorEnd:string|null;
    errorStart:string|null;
    idStart:string;
    idEnd:string;
}

const ChangeHoraireSettingCtrl = (props:ChangeHoraireSettingCtrlProps)=>{
    return (
        <div className={`changeHoraireSetting wrap_input_setting ${props.className}`}>
            <div className="in_wrap_input_setting">
                <LibelleSetting libelle={props.label}/>
                <div className="wrap_horaires">
                    <SetterHorairesSetting ErrorEnd={props.errorEnd} CurrentStart={props.currentStart} setHor={props.setHor} CurrentEnd={props.currentEnd} ErrorStart={props.errorStart} idEnd={props.idEnd} idStart={props.idStart}/>
                </div>
            </div>
        </div>
    )
}

const ChangeHoraireSetting = styled(ChangeHoraireSettingCtrl)``

export default ChangeHoraireSetting