import styled from "styled-components";
import { OnePlageChrono } from "../LivePointage";
import TimedRoundAvatar from "../../../../components/atoms/TimedRoundAvatar/TimedRoundAvatar";
import {ConvertBase5} from "../../../../components/functions/TimesFunctions";

interface OnePlageLivePointageCtrlProps{
    plage:Plage;
    HourStart:number;
    HourEnd:number;
    className?:string;
}

const OnePlageLivePointageCtrl = (props:OnePlageLivePointageCtrlProps)=>{
    const widthRef = 1/((props.HourEnd+1) - props.HourStart)
    const width = (props.plage.heureFin - props.plage.heureDebut)/12 * widthRef *100
    const left = (props.plage.heureDebut/12 - props.HourStart) * widthRef *100
    return (
        <div className={`one_plage_live_pointage ${props.className}`} style={{width:width+"%",left:left+"%"}}>
            <div className={`content-plage`}>
                <div className={"horaires"}>{ConvertBase5(props.plage.heureDebut)} {ConvertBase5(props.plage.heureFin)}</div>
                <div className={"lieu_acte"}>
                    <div className={"in_la"}>{props.plage.Lieu.libelle} - {props.plage.Acte.libelle}</div>
                </div>
            </div>
        </div>
    )
}

export const OnePlageLivePointage = styled(OnePlageLivePointageCtrl)`
    background: ${props => props.theme.NeutreExtraLight};
   border-radius: 15px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  .content-plage{
    display: flex;
    height: 28px;
    justify-content: flex-start;
    font-size: 13px;
    gap: 6px;
    align-content: center;
    position: relative;
    .horaires{
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 8px;
      width: 85px;
      flex-shrink: 0;
    }
    .lieu_acte{
      flex-grow: 1;
      overflow: hidden;
      width: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      .in_la{
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 100%;
        display: block;
        overflow: hidden;
      }
    }
  }
`