import React, {useEffect, useMemo, useState} from "react";
import styled, {useTheme} from "styled-components";
import {TabJour, TabJourArray} from "../../../../../../services/constantes/constantes";
import {useGetPointeuseTreatOneDayOneUser} from "../../../../../../features/pointeuseTreat/pointeuseTreat.hooks";
import {DecToHHMM} from "../../../../../../components/functions/TimesFunctions";
import TimedSkeleton from "../../../../../../components/atoms/TimedSkeleton/TimedSkeleton";
import {MdOutlineWarning} from "react-icons/md";
import {TimedWrapperDay} from "../../../../../../components/molecules/TimedWrapperDay/TimedWrapperDay";
import {TimedChipsPlageSideBar} from "../../../../../../components/atoms/TimedChips/TimedChipsPlageSideBar";
import {useCentreConnectedData} from "../../../../../../services/hooks";

interface OneDayDetailsCtrlProps{
    className?:string;
    idUser:number;
    dateUse:Date;
    setPointeurTreat:Function;
    setFerie:Function;
    isAbsent:boolean;
    supervisor?:boolean;
}


const OneDayDetailsCtrl = ({className, idUser, dateUse, setPointeurTreat, isAbsent, setFerie, supervisor}:OneDayDetailsCtrlProps)=>{
    const centre = useCentreConnectedData();
    const theme = useTheme();
    const [nameDay, setNameDay] = useState('')
    const [timeDay, setTimeDay] = useState('');
    const [timeDayDec, setTimeDayDec] = useState(0);
    const PointeusesTreatQuery = useGetPointeuseTreatOneDayOneUser(idUser, dateUse, supervisor?centre:null)
    const [FerieConcerned, setFerieConcerned] = useState<PointeuseTreat[]>([]);
    useEffect(()=>{
        const libDay = TabJourArray[dateUse.getDay()];
        let lib = dateUse.toLocaleDateString();
        if(!!libDay){
            lib = libDay + " " + lib
        }
        setNameDay(lib);
    }, [dateUse])
    const ActionClickPeriode = (id:number)=>{
        const MyPT = PointeusesTreatQuery.data?.find(pt=>pt.id === id)
        if(MyPT) setPointeurTreat(MyPT);
    }
    useEffect(()=>{
        if(PointeusesTreatQuery.data) {

            if(isAbsent){
                const listSorted = PointeusesTreatQuery.data.filter(pt=>pt.isAbsent && !pt.MotifReguleTemps).sort((a:PointeuseTreat, b:PointeuseTreat)=>{
                    return (a.planningDebut) > (b.planningDebut) ? 1 : -1
                })
                const TimesTot = listSorted.filter(pt=>pt.timeFinal!==null).map(pt=>pt.timeFinal).reduce((acc:number, obj:number|null)=>acc+(!!obj ? obj : 0), 0);
                setTimeDay(DecToHHMM(TimesTot))
                setTimeDayDec(TimesTot)
                const FerieConc = PointeusesTreatQuery.data.filter(pt=>pt.isAbsent && pt.MotifReguleTemps && pt.MotifReguleTemps.id === 7)

                setFerieConcerned(FerieConc);
            } else {
                const listSorted = PointeusesTreatQuery.data.filter(pt=>!pt.MotifReguleTemps).sort((a:PointeuseTreat, b:PointeuseTreat)=>{
                    return (a.planningDebut) > (b.planningDebut) ? 1 : -1
                })
                const TimesTot = listSorted.filter(pt=>pt.timeFinal!==null).map(pt=>pt.timeFinal).reduce((acc:number, obj:number|null)=>acc+(!!obj ? obj : 0), 0);
                setTimeDay(DecToHHMM(TimesTot))
                setTimeDayDec(TimesTot)
            }


        }
    }, [PointeusesTreatQuery.data])
    const PlagesInWrapper = useMemo(()=>{
        if(!PointeusesTreatQuery.data){
            return []
        } else {
            if(!isAbsent) {
                const listSorted = PointeusesTreatQuery.data.filter(pt=>!pt.MotifReguleTemps).sort((a:PointeuseTreat, b:PointeuseTreat)=>{
                    return (a.planningDebut) > (b.planningDebut) ? 1 : -1
                })
                return listSorted.map(pt => {
                    const isAno = pt.AnomaliePointages.E === 1 || pt.AnomaliePointages.S === 1
                    const infosO =
                        <div className={`wrap-infos`}>
                            {(isAno && !pt.isAnomalieTreat) &&
                                <div className={`wrap-ano`}>
                                    <MdOutlineWarning/>
                                    <span>Anomalie de pointage</span>
                                </div>
                            }
                            {pt.plages.map((itemP: Plage, idxP: number) => (
                                <TimedChipsPlageSideBar Plage={itemP} key={`OneP${idxP}`}/>
                            ))}
                            <div className={`total-heures`}>total
                                : {pt.timeFinal ? `${Math.round(pt.timeFinal * 100) / 100} heures` : '--'}</div>
                        </div>
                    return {
                        id: pt.id,
                        debPlage: (isAno && !pt.isAnomalieTreat) ? pt.planningDebut : pt.pointageEntreeRetenu ? pt.pointageEntreeRetenu : 0, // minutes
                        finPlage: (isAno && !pt.isAnomalieTreat) ? pt.planningFin : pt.pointageSortieRetenu ? pt.pointageSortieRetenu : 0, // minutes
                        backGround: (isAno && !pt.isAnomalieTreat) ? theme.Error : isAno ? theme.SecondaryDark : theme.PrimaryDark,
                        infos: infosO,

                    }
                })
            } else {

                const listSorted = PointeusesTreatQuery.data.filter(pt=>pt.isAbsent && !pt.MotifReguleTemps).sort((a:PointeuseTreat, b:PointeuseTreat)=>{
                    return (a.planningDebut) > (b.planningDebut) ? 1 : -1
                })

                return listSorted.map(pt => {
                    const Plages = pt.plages;
                    const TabActes = Plages.map(p=>p.Acte.libelle);
                    return {
                        id:pt.id,
                        debPlage:pt.planningDebut, // minutes
                        finPlage:pt.planningFin, // minutes
                        backGround:theme.ComplementaryExtraLight,
                        infos:<div className={`wrap-nw`}>
                            <div className={`type`} style={{marginBottom:"5px"}}>type : {TabActes.join(', ')}</div>
                            <div className={`time-place`}>temps : {pt.timeFinal ? Math.round(pt.timeFinal*100)/100 : 'nc'} heures</div>
                        </div>,
                    }
                })
            }
        }
    }, [PointeusesTreatQuery.data, theme])
    return (
        <div className={`OneDayDetail ${className}`}>
            <div className={`libday`}>{nameDay} ({Math.round(timeDayDec*100)/100} heures // {timeDay})</div>
            {PointeusesTreatQuery.isLoading ? <TimedSkeleton type={"rect"} nbOccurence={1} width={"100%"}/>:
                <TimedWrapperDay
                    heureDep={6}
                    heureFin={22}
                    PlagesIn={PlagesInWrapper}
                    ActionOnClick={ActionClickPeriode}
                />
            }
            {FerieConcerned.length>0 &&
                <div className={`listFerie`}>
                    {FerieConcerned.map((pt:PointeuseTreat)=>(
                        <div className={`oneFerie`} key={`pt${pt.id}`} onClick={()=>setFerie(pt)}>
                            Ajout au titre du jour férié {pt.timeFinal ? Math.round(pt.timeFinal*100)/100 : 0}heures {pt.AcquisitionDroitsAbsence ? 'avec acquisition de cp' : 'sans acquisition de cp'}
                        </div>
                    ))}
                </div>
            }
        </div>
    )
}


const OneDayDetails = styled(OneDayDetailsCtrl)`
  position: relative;
  .wrap-ano{
    display: flex;
    align-items: center;
    gap: 5px;
    color: ${props => props.theme.Error};
  }
  .wrap-infos{
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .libday{
    font-weight: bold;
    font-size: 13px;
    margin-bottom: 5px;
  }
  .listFerie{
    margin: 50px auto;
    .oneFerie{
      width: max-content;
      padding: 0.25rem 0.75rem;
      background: ${props=>props.theme.SecondaryLight};
      border-radius: 12px;
      &:hover{
        cursor: pointer;
        filter:brightness(95%);
      }
    }
  }

`

export default OneDayDetails;