import {dataFetcher} from "../../services/dataGuetter/dataFetcher";


export async function getAllMotifReguleTps():Promise<MotifReguleTemps[]>{
    const res:any = await dataFetcher(`motif_regule_temps`, {method:'get'})
    return res["hydra:member"];
}

export async function getAllMotifReguleTpsByCentre(idCentre:number):Promise<MotifReguleTemps[]>{
    const res:any = await dataFetcher(`motif_regule_temps?Centre[]=${idCentre}&Centre[]=0`, {method:'get'})
    return res["hydra:member"];
}