import React from "react";
import styled from "styled-components";
import TimedModal, {TimedModalCtrlProps} from "../../components/molecules/TimedModal/TimedModal";
import {FormNewNews} from "../Forms/FormNewNews";

interface ModalNewNewsCtrlProps extends TimedModalCtrlProps{
    className?:string;
    News?:News;
    supervisor?:boolean;
}

const ModalNewNewsCtrl= ({className, supervisor, News, ...props}:ModalNewNewsCtrlProps)=>{
    return (
        <TimedModal {...props}>
            <FormNewNews News={News} CloseMe={props.Close} supervisor={supervisor}/>
        </TimedModal>
    )
}

export const ModalNewNews = styled(ModalNewNewsCtrl)``