import styled from "styled-components";
import SquareIndic from "../../components/SquareIndic";
import LibCard from "../../components/LibCard";
import RightCard from "../../components/RightCard";
import PoigneeDrag from "../../components/PoigneeDrag";

interface OneCardLieuInactifCtrlProps{
    className?:string;
    Lieu:Lieu;
    isCurrent:boolean;
    ClickOnEdit:()=>void;
}

const OneCardLieuInactifCtrl = (props:OneCardLieuInactifCtrlProps)=>{
    return (
        <div className={`oneCardLieuInactif  card_draggable ${props.className}`}>
            <PoigneeDrag isNoDrag/>
            <RightCard isCurrent={props.isCurrent} ClickOn={()=>props.ClickOnEdit()}>
                <SquareIndic backColor={props.Lieu.backColor} fontColor={props.Lieu.fontColor}><span>A</span></SquareIndic>
                <LibCard text={props.Lieu.libelle}/>
            </RightCard>
        </div>
    )
}

const OneCardLieuInactif = styled(OneCardLieuInactifCtrl)``

export default OneCardLieuInactif