import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {Shine} from "../../../assets/styles/elements";

interface TimedSkeletonRoundDayCtrlProps{
    className?:string;
    nbRound:number;
}

const TimedSkeletonRoundDayCtrl = ({className, nbRound}:TimedSkeletonRoundDayCtrlProps)=>{
    const [rounds, setRounds] = useState<React.ReactNode[]>([])
    useEffect(()=>{
        const tabRounds:React.ReactNode[] = [];
        let idx = 0;
        while(idx<=nbRound){
            tabRounds.push(<div className={`oneRound`} key={`oRTS${idx}`}/> )
            idx++;
        }
        setRounds(tabRounds);
    }, [nbRound])
    return (
        <div className={`TS ${className}`}>
            {rounds.map(r=>r)}
        </div>
    )
}

const $Wid = "26px"

export const TimedSkeletonRoundDay = styled(TimedSkeletonRoundDayCtrl)`
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  padding: 0.5rem 0;
  .oneRound{
    width: ${$Wid};
    height: ${$Wid};
    border-radius: 50%;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    background-size: 200% 100%;
    animation: 1.5s ${Shine} linear infinite;
    flex-shrink: 0;
  }
    
`