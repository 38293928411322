import React from "react";
import styled from "styled-components";
import TimedButton from "../../components/atoms/TimedButton/TimedButton";
import TimedModal from "../../components/molecules/TimedModal/TimedModal";

interface ModalWarningCtrlProps{
    className?:string,
    text:React.ReactNode;
    Close:Function;
    isPending?:boolean;
}

const ModalWarningCtrl = ({className, text, ...props}:ModalWarningCtrlProps)=>{
    const handleCancel = ()=>{
        props.Close();
    }
    return (
        <TimedModal
            size={"regular"}
            Close={props.Close}
            title={"Attention"}
            position={"center"}
            topPosition={"Middle"}
        >
            <div className={"wrap-confirmation" + className}>
                <div className={"TextConfirm"}>{text}</div>
                <div className={"wrap-actions"}>
                    <div className={"wrap-btn"}>
                        <TimedButton
                            size={"sm"}
                            text={"Fermer"}
                            onClick={handleCancel}
                        />
                    </div>
                </div>
            </div>
        </TimedModal>
    )
}

const ModalWarning = styled(ModalWarningCtrl)`
    padding: 1rem;
    .TextConfirm{
      text-align: center;
      font-size: 18px;
    }
  .wrap-actions{
    margin-top: 10px;
    display: flex;
    justify-content: center;
    .wrap-btn{
      padding: 0.5rem;
    }
  }
`
export default ModalWarning;