import {dataFetcher} from "../../services/dataGuetter/dataFetcher";
import {UserDocument, UserDocumentEditFD, UserDocumentNewFD, UserDocumentPage} from "./userDocument";

export async function getDocumentsByUser(id:number):Promise<UserDocument[]>{
    const res:any = await dataFetcher(`user_documents?User=${id}`, {method:'get'});
    return res["hydra:member"];
}

export function getOneDocument(id:number):Promise<UserDocument>{
    return dataFetcher(`user_documents/${id}`, {method:'get'});
}

export async function getDocumentsByUserPage(Users:number[], libType:string, namePerso:string, page:number, idCentre:number):Promise<UserDocumentPage>{
    let params = `page=${page}&User.Centre=${idCentre}&pagination=true`;
    if(Users.length>0){
        Users.map(u=>{
            params+=`&User[]=${u}`
        })
    }
    if(libType !== ''){
        params+=`&Type.libelle=${libType}`
    }
    if(namePerso !== ''){
        params+=`&namePerso=${libType}`
    }
    const res:any = await dataFetcher(`user_documents?${params}`, {method:'get'})
    const myRes:UserDocumentPage = {
        list: res["hydra:member"],
        views:res["hydra:view"],
        total:res["hydra:totalItems"]
    }
    return myRes;
}

export async function getDocumentsByUserPageDate(Users:number[], From:string, To:string, page:number, idCentre:number, namePerso:string):Promise<UserDocumentPage>{
    let params = `page=${page}&User.Centre=${idCentre}&pagination=true`;
    if(From!==''){
        if(To!==''){
            if(To!==From) {
                params += `&creationAt[after]=${From}&creationAt[before]=${To}`
            } else {
                const myNewTo = new Date((new Date(`${To} 12:00:00`)).getTime()+86400000).toISOString().slice(0,10);
                params += `&creationAt[after]=${From}&creationAt[strictly_before]=${myNewTo}`
            }
        } else {
            params+=`&creationAt=${From}`
        }
    }
    if(Users.length>0){
        Users.map(u=>{
            params+=`&User[]=${u}`
        })
    }
    if(namePerso !== ''){
        params+=`&namePerso=${namePerso}`
    }
    const res:any = await dataFetcher(`user_documents?${params}`, {method:'get'})
    const myRes:UserDocumentPage = {
        list: res["hydra:member"],
        views:res["hydra:view"],
        total:res["hydra:totalItems"]
    }
    return myRes;
}

export function trashDocument(id:number):Promise<any>{
    return dataFetcher(`user_documents/${id}`, {method:'DELETE'});
}

export async function switchDiffusable(id:number, isDiffusable:boolean):Promise<UserDocument>{
    const res:any = await dataFetcher(`user_documents/${id}`, {
        method:'put',
        body:{isDiffusable:isDiffusable}
    });
    return res["hydra:member"];
}

export function addUserDocument(datas:UserDocumentNewFD):Promise<UserDocument>{
    const formData = new FormData();
    for (const [key, value] of Object.entries(datas)){
        formData.append(`${key}`, value);
    }
    return dataFetcher<UserDocument>(
        `user_documents/postOne`,
        {
            method: "POST",
            body: formData,
        },
        true
    );
}

export function ModifyUserDocument(datas:UserDocumentEditFD):Promise<UserDocument>{
    const formData = new FormData();
    for (const [key, value] of Object.entries(datas)){
        if(key!=="id") {
            formData.append(`${key}`, value);
        }
    }
    return dataFetcher<UserDocument>(
        `user_documents/${datas.id}/changeOne`,
        {
            method: "POST",
            body: formData,
        },
        true
    );
}

export function ModifyUserDocument2(datas:UserDocumentEditFD):Promise<UserDocument>{

    return dataFetcher<UserDocument>(
        `user_documents/${datas.id}`,
        {
            method: "put",
            body: datas,
        }
    );
}