import React from "react";
import styled from "styled-components";

interface TimedBlockGreyHeadCtrlProps{
    className?:string;
    title:string;
    icon:React.ReactNode;
    children:React.ReactNode;
}

const TimedBlockGreyHeadCtrl = ({className, title, icon, children}:TimedBlockGreyHeadCtrlProps)=>{
    return (
        <div className={`TBGH ${className}`}>
            <div className={`head-tbgh`}>
                <div className={`icon-place`}>{icon}</div>
                <div className={`title-place`}>{title}</div>
            </div>
            <div className={`body-tbgh`}>
                {children}
            </div>
        </div>
    )
}

const myBordRad = "8px";

const TimedBlockGreyHead = styled(TimedBlockGreyHeadCtrl)`
  width: 100%;
  height: 100%;
  border-radius: ${myBordRad};
  background: ${props=>props.theme.PrimaryMegaLight};
  display: flex;
  flex-direction: column;
  justify-content: start;
  .head-tbgh{
    padding: 0.25rem 0.5rem;
    border-top-left-radius: ${myBordRad};
    border-top-right-radius: ${myBordRad};
    display: flex;
    background: ${props=>props.theme.PrimaryExtraLight};
    color: ${props=>props.theme.PrimaryDark};
    justify-content: center;
    gap: 10px;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
  }
  .body-tbgh{
    padding: 0.5rem;
    flex-grow: 1;
  }
`

export default TimedBlockGreyHead;