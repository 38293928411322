import styled from "styled-components";
import SquareIndic from "../../components/SquareIndic";
import LibCard from "../../components/LibCard";
import RightCard from "../../components/RightCard";
import PoigneeDrag from "../../components/PoigneeDrag";

interface OneCardActeInactifCtrlProps{
    className?:string;
    Acte:Acte;
    isCurrent:boolean;
    ClickOnEdit:()=>void;
}

const OneCardActeInactifCtrl = (props:OneCardActeInactifCtrlProps)=>{
    return (
        <div className={`OneCardActeInactif  card_draggable ${props.className}`}>
            <PoigneeDrag isNoDrag/>
            <RightCard isCurrent={props.isCurrent} ClickOn={()=>props.ClickOnEdit()}>
                <SquareIndic backColor={props.Acte.backColor} fontColor={props.Acte.fontColor}><span>A</span></SquareIndic>
                <LibCard text={props.Acte.libelle}/>
            </RightCard>
        </div>
    )
}

const OneCardActeInactif = styled(OneCardActeInactifCtrl)``

export default OneCardActeInactif