import styled, {css, useTheme} from "styled-components";
import TimedTheme from "../../../assets/styles/TimedTheme";


export const WrapperMulti = styled.div`
    display: flex;
    justify-content: flex-start;
    gap:6px;
`

export const LabelInter = styled.label`
    font-weight: bold;
    color:${props=>props.theme.Primary};
    display: block;
    font-size: 14px;
`

export const SelectorInter = styled.div`
    border:none;
    padding: 0.25rem 0.5rem 0.25rem 0;
    border-radius: 4px;
    width: max-content;
    transition: padding-left 0.3s;
    font-size: 16px;
    &.isCenter{
        text-align: center;
        width: 100%;
    }
    &:hover, &.opened{
        padding: 0.25rem 0.5rem;
        transition: padding-left 0.3s;
        background: ${props=>props.theme.PrimaryMegaLight};
        cursor: pointer;
    }
`

export const TextAreaInter = styled.textarea`
    border:none;
    border-radius: 4px;
    padding: 0.5rem;
    resize: none;
    width: 100%;
    font-size: 16px;
    background: ${props=>props.theme.PrimaryMegaLight};
`
export const InputInter = styled.input`
    border:none;
    border-radius: 4px;
    padding: 0.25rem 0.5rem 0.25rem 0;
    width: 100%;
    transition: padding-left 0.3s;
    font-size: 16px;
    &:hover, &:focus{
        padding: 0.25rem 0.5rem;
        transition: padding-left 0.3s;
        background: ${props=>props.theme.PrimaryMegaLight};
        cursor: pointer;
    }
    &.color_force{
        &:hover, &:focus{
            background: ${props=>props.theme.colorBackInput};
        }
        &.empty{
            background: ${props=>props.theme.colorBackInput};
        }
    }
    &.empty{
        background: ${props=>props.theme.PrimaryMegaLight};
    }
    &.is-error{
        background: ${props=>props.theme.WarningMegaLight};
    }
`

export const ListChoice = styled.div`
    display: block;
    width: max-content;
    position: fixed;
    background: white;
    padding: 0.25rem;
    border-radius: 4px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    transform: translateY(-50%);
    max-height: 30vh;
    overflow-y: auto;
    .one_choice_inter{
        padding: 0.30rem 0.75rem 0.30rem 0.5rem;
        font-size: 15px;
        &:hover{
            background: ${props => props.theme.PrimaryMegaLight};
            cursor: pointer;
        }
    }
`
export const ListChoiceAbs = styled.div`
    display: block;
    width: max-content;
    position: absolute;
    background: white;
    left: 102%;
    top: 100%;
    padding: 0.25rem;
    border-radius: 4px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    transform: translateY(-50%);
    .one_choice_inter{
        padding: 0.30rem 0.75rem 0.30rem 0.5rem;
        font-size: 15px;
        &:hover{
            background: ${props => props.theme.PrimaryMegaLight};
            cursor: pointer;
        }
    }
`

export const ErrorInput = styled.div`
    display: block;
    width: max-content;
    font-size: 12px;
    position: absolute;
    left: 50%;
    top: calc(100% + 2px);
    background: ${props => props.theme.WarningLight};
    color: white;
    transform: translateX(-50%);
    padding: 0.25rem;
    z-index: 4;
    border-radius: 4px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
`