import styled from "styled-components";
import {useGetAllUserCentre} from "../../../features/userCentre/userCentre.hooks";
import {useGetUser} from "../../../features/user/user.hooks";
import React, {useEffect, useMemo, useRef, useState} from "react";
import {useLocation, useSearchParams} from "react-router-dom";

interface TimedSwitchCentreCtrlProps{
    className?:string;
}

const TimedSwitchCentreCtrl = ({className}:TimedSwitchCentreCtrlProps)=>{
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const UserCentresQuery = useGetAllUserCentre();
    const [open, setOpen] = useState<boolean>(false);
    const UserQuery = useGetUser();
    const [idCentreCurr, setIdCentreCurr] = useState<number|null>(localStorage.getItem("__centre_connected__") ? parseInt(localStorage.getItem("__centre_connected__")||"") : null);
    const refObj = useRef<HTMLDivElement>(null);
    const listCentre = useMemo(()=>{
        const list:{id:number, libelle:string, logo:string}[] = [];
        if(UserCentresQuery.data){
            UserCentresQuery.data.map(uc=>{
                list.push({id:uc.Centre.id, libelle:uc.Centre.name, logo:uc.Centre.logo})
                return uc;
            })
        }
        return list;
    }, [UserCentresQuery.data])
    const GetCentre = (id:number|null)=>{
        if(id){
            const Centre = listCentre.find(l=>l.id === id);
            if(Centre){
                return Centre;
            } else {
                return {id:0, libelle:'nc', logo:'nc'}
            }
        } else {
            return {id:0, libelle:'nc', logo:'nc'}
        }
    }
    useEffect(()=>{
        if(localStorage.getItem("__centre_connected__") === null && UserQuery.data){
            setIdCentreCurr(UserQuery.data.Centre.id)
        }
    }, [UserQuery.data])
    useEffect(()=>{
        window.dispatchEvent( new Event('storage') )
        if(idCentreCurr) {
            setSearchParams({});
            localStorage.setItem("__centre_connected__", idCentreCurr.toString())
            window.dispatchEvent( new Event('storage') )
        }
    }, [idCentreCurr])
    useEffect(() => {
        function handleClickOutside(event:any) {
            if (refObj.current && !refObj.current.contains(event.target)) {
                setOpen(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [refObj]);
    const SetMyCentre = (id:number)=>{
        setIdCentreCurr(id);
        setOpen(false);
        //window.location.reload();
    }
    return (
        <div className={`filter_centre ${className}`} ref={refObj}>
            {UserCentresQuery.isLoading || UserQuery.isLoading ? 'charg...' : UserQuery.isError || UserCentresQuery.isError ? 'error':
                <>
                    <div className={"centre_curr"} onClick={()=>setOpen(o=>!o)}>
                        <div className={"logo-place"}>
                            {GetCentre(idCentreCurr).logo!=='nc' ? <img src={`${process.env.REACT_APP_API_URL_ROOT}/CentreLogos/${GetCentre(idCentreCurr).logo}`} alt={"centre_logo"}/> : ''}
                        </div>
                        <div className={`lib-centre`}>{GetCentre(idCentreCurr).libelle!=='nc' ? GetCentre(idCentreCurr).libelle : ''}</div>
                    </div>
                    <div className={`list_choice ${open ? "opened" : "closed"}`}>
                        <ul>
                        {listCentre.map((centre:{id:number, libelle:string, logo:string})=>(
                            <li key={`one_centre${centre.id}`} onClick={()=>SetMyCentre(centre.id)}>
                                <div className={`logo-place`}><img src={`${process.env.REACT_APP_API_URL_ROOT}/CentreLogos/${centre.logo}`} alt={"centre_logo"}/></div>
                                <div className={`name-list`}>{centre.libelle}</div>
                            </li>
                        ))}
                        </ul>
                    </div>
                </>
            }
        </div>
    )
}

export const TimedSwitchCentre = styled(TimedSwitchCentreCtrl)`
  padding: 0.25rem 0.5rem;
  background: white;
  border-radius: 12px;
  position: relative;
  .logo-place{
    width: 28px;
    height: 28px;
    border-radius: 50%;
    img{
      width: 100%;
      height: auto;
    }
  }
  .list_choice{
    display: none;
    background: white;
    padding: 0.5rem;
    border-radius: 6px;
    box-shadow: 1px 1px 2px rgba(0,0,0,.2);
    max-height: 380px;
    overflow-y: auto;
    position: absolute;
    left: 0;
    top:102%;
    z-index: 9;
    &.opened{
      display: block;
    }
    ul{
      margin: 0;
      padding: 0;
      li{
        list-style: none;
        padding: 0.5rem;
        display: flex;
        gap: 5px;
        align-items: center;
        justify-content: flex-start;
        &:hover{
          cursor: pointer;
          background: ${props => props.theme.NeutreExtraLight};
        }
      }
    }
  }
  .centre_curr{
    width: max-content;
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: flex-start;
    &:hover{
      cursor: pointer;
    }
    .lib-centre{
      font-weight: bold;
    }
  }
`
