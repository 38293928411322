import styled from "styled-components";
import React, {useEffect, useRef, useState} from "react";
import {useCentreConnectedData,useGetParams} from "../../../services/hooks";
import {LibelleCurrentSmart} from "./LibelleCurrentSmart";
import {useSearchParams} from "react-router-dom";
import {ListSmart} from "./ListSmart";
import TimedTabs from "../../molecules/TimedTabs/TimedTabs";
import TimedDatePicker from "../../molecules/TimedDatePicker/TimedDatePicker";
import {LibTitleSmartNav} from "../../../assets/styles/elements";
import {
    getElevenMonths,
    getElevenWeeks,
    getLastMonthByDate,
    getLastWeekByDate,
    getMonthByDate, getNextMonthByDate,
    getNextWeekByDate, getThisYearByDate,
    getTomorrowByDate,
    getWeekByDate,
    getYesterdayByDate,
    OneMonthEleven,
    OneWeekEleven
} from "../../functions/TimesFunctions";
import {useGetAllPeriodes} from "../../../features/periode/periode.hooks";
import {ContentSmart} from "./styledElt";
import {MdOutlineAvTimer} from "react-icons/md";

interface TimedSelectDatesCtrlProps{
    className?:string;
    supervisor?:boolean;
}
interface my_params{
    index:string,
    from?:string,
    to?:string,
    lieu?:string,
    fx?:string,
    idCollab?:string,
    vues?:string
}
const myPredef = [
    [
        {id:0, libelle:'Hier'},
        {id:1, libelle:"Aujourd'hui"},
        {id:2, libelle:"Demain"}
    ],
    [
        {id:3, libelle:"La semaine dernière"},
        {id:4, libelle:"Cette Semaine"},
        {id:5, libelle:"La semaine prochaine"}
    ],
    [
        {id:6, libelle:"Le mois dernier"},
        {id:7, libelle:"Ce mois-ci"},
        {id:8, libelle:"Le mois prochain"}
    ],
    [
        {id:9, libelle:"L'année dernière"},
        {id:10, libelle:"Cette année"},
        {id:11, libelle:"L'année prochaine"}
    ]
]
const TimedSelectDatesCtrl = ({className, supervisor}:TimedSelectDatesCtrlProps)=>{
    const [, setSearchParams] = useSearchParams();
    const {TabFrom, TabTo, TabFx, TabLieu, TabVues, TabCollab} = useGetParams()
    const refObj = useRef<HTMLDivElement>(null)
    const [open, setOpen] = useState<boolean>(false);
    const [libelleCurrent, setLibelleCurrent] = useState('');
    const TabChoicesTime = ['pré-défini', 'date', 'plage', 'période']
    const [selected, setSelected] = useState("pré-défini");
    const idCentre:number|null = useCentreConnectedData()
    const {isLoading, isError, data:periodes} = useGetAllPeriodes(supervisor ? idCentre : null)
    useEffect(()=>{
        let stLib ='';
        if(TabFrom){
            stLib+=TabFrom
        }
        if(TabTo && TabTo!==TabFrom){
            stLib+=" - "+TabTo
        }
        setLibelleCurrent(stLib);
    }, [TabTo, TabFrom])
    const handleSelectItem = (item:string)=>{
        setSelected(item)
    }
    const setMyChoice = (fromChoice:Date|undefined, toChoice:Date|undefined)=>{
        let myFrom = undefined;
        let myTo = undefined;
        if(fromChoice){
            const day = `0${fromChoice.getDate()}`.slice(-2)
            const month = `0${fromChoice.getMonth()+1}`.slice(-2)
            const year = fromChoice.getFullYear();
            myFrom = `${day}/${month}/${year}`
        }
        if(toChoice){
            const dayx = `0${toChoice.getDate()}`.slice(-2)
            const monthx = `0${toChoice.getMonth()+1}`.slice(-2)
            const yearx = toChoice.getFullYear();
            myTo = `${dayx}/${monthx}/${yearx}`
        }
        let myParams: my_params = {
            from: myFrom,
            to: myTo,
            lieu: TabLieu,
            fx: TabFx,
            idCollab: TabCollab,
            vues:TabVues,
            index: '0'
        };
        if(fromChoice===undefined) delete myParams.from;
        if(toChoice===undefined) delete myParams.to;
        if(TabLieu===undefined) delete myParams.lieu;
        if(TabFx===undefined) delete myParams.fx;
        if(TabCollab===undefined) delete myParams.idCollab;
        if(TabVues===undefined || TabVues==="undefined") delete myParams.vues;

        const meP = {...myParams}
        setSearchParams(meP);
    }
    useEffect(() => {
        function handleClickOutside(event:any) {
            if (refObj.current && !refObj.current.contains(event.target)) {
                setOpen(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [refObj]);
    const Remove = ()=>{
        let myParams: my_params = {
            from: TabFrom!==undefined ? TabFrom : '',
            to: TabTo!==undefined ? TabTo : '',
            lieu: TabLieu!==undefined ? TabLieu : '',
            fx: TabFx!==undefined ? TabFx : '',
            idCollab: TabCollab!==undefined ? TabCollab : '',
            vues:TabVues!==undefined ? TabVues : '',
            index: '0'
        };
        if(TabFrom===undefined){
            delete myParams.from;
        }
        if(TabCollab===undefined){
            delete myParams.idCollab;
        }
        if(TabFx===undefined){
            delete myParams.fx;
        }
        if(TabTo===undefined){
            delete myParams.to;
        }
        if(TabLieu===undefined){
            delete myParams.lieu;
        }
        if(TabVues===undefined){
            delete myParams.vues;
        }
        delete myParams.from
        delete myParams.to
        const meP = {...myParams}
        setSearchParams(meP);
    }
    const handleChoicePreDef = (id:number)=>{
        const myDate = new Date(new Date().setHours(12,0,0));
        switch (id) {
            case 0: //hier
                const yest = getYesterdayByDate(myDate);
                setMyChoice(yest, yest);
                break;
            case 1: //aujourd'hui
                setMyChoice(myDate, myDate)
                break;
            case 2: //demain
                setMyChoice(getTomorrowByDate(myDate), getTomorrowByDate(myDate))
                break;
            case 3: // semaine dernière
                const {prevMonday, prevSunday} = getLastWeekByDate(myDate);
                setMyChoice(prevMonday,prevSunday)
                break;
            case 4: //cette semaine
                const {Monday, Sunday} = getWeekByDate(myDate);
                setMyChoice(Monday, Sunday)
                break;
            case 5: //semaine prochaine
                const {NextMonday, NextSunday} = getNextWeekByDate(myDate);
                setMyChoice(NextMonday, NextSunday)
                break;
            case 6: //mois dernier
                const {FirstPrevMonth, LastPrevMonth} = getLastMonthByDate(myDate);
                setMyChoice(FirstPrevMonth,LastPrevMonth)
                break;
            case 7: //ce mois
                const {FirstMonth, LastMonth} = getMonthByDate(myDate);

                setMyChoice(FirstMonth, LastMonth)
                break;
            case 8: //le mois prochain
                const {FirstNextMonth, LastNextMonth} = getNextMonthByDate(myDate)
                setMyChoice(FirstNextMonth,LastNextMonth)
                break;
            case 9: //l'année dernière
                const yearDate = myDate.getFullYear();
                const DateLastYear =new Date(yearDate-1, myDate.getMonth(), myDate.getDate())
                const {FirstDay:FDLY, LastDay:LDLY} = getThisYearByDate(DateLastYear)
                setMyChoice(FDLY,LDLY)
                break;
            case 10: //cette annee
                const {FirstDay, LastDay} = getThisYearByDate(myDate)
                setMyChoice(FirstDay, LastDay)
                break;
            default:
                const yearD = myDate.getFullYear();
                const DateNextYear =new Date(yearD+1, myDate.getMonth(), myDate.getDate())
                const {FirstDay:FDNY, LastDay:LDNY} = getThisYearByDate(DateNextYear)
                setMyChoice(FDNY,LDNY)
                break;

        }
    }
    const handlePickOneDate = (date:Date)=>{
        setMyChoice(date,date)
        setOpen(false);
    }
    const DateFrToDateEng = (dt:string)=>{
        const Tab = dt.split('/');
        const year = Tab[2];
        const month = Tab[1];
        const day = Tab[0];
        return new Date(`${year}-${("0"+month).slice(-2)}-${("0"+day).slice(-2)}`)
    }
    const handlePickMultiDate = (date:Date)=>{
        if(TabFrom && TabTo){
            if(DateFrToDateEng(TabFrom).toLocaleDateString() === DateFrToDateEng(TabTo).toLocaleDateString()){
                setMyChoice(DateFrToDateEng(TabFrom), date)
            } else {
                setMyChoice(date, date)

            }
        } else {
            setMyChoice(date, date)
        }
    }
    const handlePickPeriode = (periode:Periode)=>{
        setMyChoice(new Date(periode.debutAt),new Date(periode.finAt))
        setOpen(false);
    }
    const handlePickWeek = (week:OneWeekEleven)=>{
        setMyChoice(week.monday, week.sunday)
        setOpen(false);
    }
    const handlePickMonth = (item:OneMonthEleven)=>{
        setMyChoice(item.firstDay, item.lastDay)
        setOpen(false);
    }
    return (
        <div className={`element_smart_nav timed_select_dates ${className}`} ref={refObj}>
            <LibelleCurrentSmart
                text={libelleCurrent}
                Icon={<MdOutlineAvTimer/>}
                themeColor={"Tertiary"}
                Actif={libelleCurrent!==''}
                placeholder={"Moment ou période"}
                Remove={Remove}
                ClickOnMe={()=>setOpen(o=>!o)}
            />
            <ListSmart open={open} wD={"300px"}>
                <TimedTabs themeColor={"Tertiary"} choices={TabChoicesTime} selected={selected} handlerChoice={handleSelectItem}/>
                <ContentSmart>
                    {selected==="pré-défini" ?
                        <div className={"select-predef"}>
                            {myPredef.map((onPredef, idx)=>(
                                <ul className={"choice-predef is-predef"} key={`block-predef${idx}`}>
                                    {onPredef.map((item, idx: number) => (
                                        <li className={"item-choice"} key={`onPD${idx}`} onClick={()=>handleChoicePreDef(item.id)}>{item.libelle}</li>
                                    ))
                                    }
                                </ul>
                            ))}
                        </div> :
                        selected === "date" ?
                            <div className={"select-date"}>
                                <TimedDatePicker
                                    dateRef={new Date()}
                                    dateChoice={TabFrom ? new Date(TabFrom) : null}
                                    size={"small"}
                                    onPick={handlePickOneDate}
                                    themeColor={"Primary"}
                                    handlePickWeek={handlePickWeek}
                                />

                            </div> :
                            selected === "plage" ?
                                <div className="select-plage">
                                    <TimedDatePicker
                                        dateRef={new Date()}
                                        dateChoice={TabTo ? DateFrToDateEng(TabTo) : null}
                                        dateRefDep={TabFrom ? DateFrToDateEng(TabFrom) : null}
                                        size={"small"}
                                        onPick={handlePickMultiDate}
                                        themeColor={"Primary"}
                                        handlePickWeek={handlePickWeek}
                                    />
                                </div>:
                                <div className="select-periode">
                                    {isLoading ? 'chargement des periodes' : isError ? 'impossible de charger les périodes' :
                                        <>
                                            <LibTitleSmartNav>Périodes</LibTitleSmartNav>
                                            <ul className={"choice-predef"}>
                                                {periodes?.sort((a:Periode, b:Periode)=>a.libelle > b.libelle ? 1 : -1).map((periode: Periode, idx: number) => (
                                                    <li className={"item-choice"} key={`one-periode${idx}`} onClick={()=>handlePickPeriode(periode)}>
                                                        {periode.libelle} - {new Date(periode.debutAt.slice(0,10)).toLocaleDateString()} - {new Date(periode.finAt.slice(0,10)).toLocaleDateString()}
                                                    </li>
                                                ))
                                                }
                                            </ul>
                                            <LibTitleSmartNav>Semaines</LibTitleSmartNav>
                                            <ul className={"choice-predef"}>
                                                {getElevenWeeks(new Date()).map((semaine:OneWeekEleven, idx)=>(
                                                    <li className={"item-choice"} key={`one-sem${idx}`} onClick={()=>handlePickWeek(semaine)}>S{semaine.numSem} ({`${semaine.monday.toLocaleDateString()} - ${semaine.sunday.toLocaleDateString()}`})</li>
                                                ))}
                                            </ul>
                                            <LibTitleSmartNav>Mois</LibTitleSmartNav>
                                            <ul className={"choice-predef"}>
                                                {getElevenMonths(new Date(new Date().getTime() - 32*82400000)).map((item:OneMonthEleven, idx:number)=>(
                                                    <li className={"item-choice"} key={`one-mois${idx}`} onClick={()=>handlePickMonth(item)}>{!!item && item.mois ? item.mois.name : 'nc'} {item.firstDay.getFullYear()}</li>
                                                ))}
                                            </ul>
                                        </>
                                    }
                                </div>
                    }
                </ContentSmart>
            </ListSmart>
        </div>
    )
}

export const TimedSelectDates = styled(TimedSelectDatesCtrl)`
    position: relative;
  .choice-predef{
    margin: 0;
    padding:0;
    &.is-predef:not(:last-child){
      padding: 0.25rem 0;
      border-bottom: solid ${props=>props.theme.NeutreExtraLight} 1px;
    }
    li.item-choice{
      font-size: 14px;
      list-style: none;
      padding: 0.25rem 0.5rem 0.25rem  0.75rem;
      border-radius: 4px;
      &:hover{
        font-weight: bold;
        cursor: pointer;
        color:${props=>props.theme.TertiaryExtraDark};
        background: ${props=>props.theme.TertiaryMegaLight};
      }
    }
  }
`