import styled from "styled-components";
import OneCardRubrique from "./components/OneCardRubrique";

interface WrapListRubriqueCtrlProps{
    className?:string;
    Rubriques:RubriqueSpe[];
    RubriqueActive:RubriqueSpe|null;
    setRubrique:(r:RubriqueSpe)=>void;
}

const WrapListRubriqueCtrl = (props:WrapListRubriqueCtrlProps)=>{

    return (
        <div className={`wrapListRubrique wrap_list ${props.className}`}>
            {props.Rubriques.length === 0 ?
                <div className="no_items">Aucune Rubriques pour le moment</div>:
                <>
                {props.Rubriques.map(item=>(
                    <OneCardRubrique key={`R${item.id}`} isCurrent={props.RubriqueActive?.id === item.id} ClickOnEdit={()=>props.setRubrique(item)} Rubrique={item}/>
                ))}
                </>
            }
        </div>
    )
}

const WrapListRubrique = styled(WrapListRubriqueCtrl)``

export default WrapListRubrique