import {dataFetcher} from "../../services/dataGuetter/dataFetcher";


export async function getUserRubriqueByUser(id:number):Promise<UserRubriqueSpe[]>{
    const res:any = await dataFetcher(`user_rubrique_spes?User=${id}`, {
        method:'get'
    })
    return res["hydra:member"];
}
export async function getUserRubriqueByCentre(id:number):Promise<UserRubriqueSpe[]>{
    const res:any = await dataFetcher(`user_rubrique_spes?User.Centre=${id}`, {
        method:'get'
    })
    return res["hydra:member"];
}
export async function getUserRubriqueByRubrique(id:number):Promise<UserRubriqueSpe[]>{
    const res:any = await dataFetcher(`user_rubrique_spes?RubriqueSpe=${id}`, {
        method:'get'
    })
    return res["hydra:member"];
}

export function addUserRubrique(data:UserRubriqueSpeNewFD):Promise<UserRubriqueSpe>{
    return dataFetcher(`user_rubrique_spes`, {
        method:'post',
        body:data
    })
}

export function trashUserRubrique(id:number):Promise<UserRubriqueSpe>{
    return dataFetcher(`user_rubrique_spes/${id}`, {method:'delete'})
}

export function modifyUserRubrique(id:number, data:UserRubriqueSpeModifyFD):Promise<UserRubriqueSpe>{
    return dataFetcher(`user_rubrique_spes/${id}`, {
        method:'put',
        body:data
    })
}