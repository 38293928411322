import styled from "styled-components";
import {useEffect, useState} from "react";
import {useGetCentreActuel} from "../../../../features/centre/centre.hooks";
import TitrePageSetting from "../components/TitrePageSetting";
import PageSimpleSetting from "../components/PageSimpleSetting";
import FormSetApps from "./FormSetApps";

interface ParamsAppsCtrlProps{
    className?:string;
}

export interface OneMobParams{
    lib:string;
    collabs:{settable:number, value:number};
    rempla:{settable:number, value:number};
    key:string;
}

const ParamsAppsCtrl = (props:ParamsAppsCtrlProps)=>{
    const centreQuery = useGetCentreActuel();
    const [mobParameters, setMobParameters] = useState<OneMobParams[]>([])
    useEffect(()=>{
        if(centreQuery.data){
            const mobP = centreQuery.data.mobParameters;
            const tabMobP:OneMobParams[] = [];
            for (const [key, value] of Object.entries(mobP)) {
                tabMobP.push({...value, key:key});
            }
            setMobParameters(tabMobP.sort((a:OneMobParams, b:OneMobParams)=>{
                return a.lib > b.lib ? 1 : -1
            }));
        }
    }, [centreQuery.data])
    return (
        <div className={`paramsApps page_params ${props.className}`}>
            <TitrePageSetting titre={"Gestion des accès aux fonctionnalités - Accès utilisateurs"}/>
            <PageSimpleSetting>
                <p className={"intro_simple"}>Activez ou désactivez les fonctionnalités par type d'utilisateur et définissez leurs accès dans les applications mobiles et PC</p>
                {(mobParameters.length>0 && centreQuery.data) &&
                    <FormSetApps setMobParameters={setMobParameters} Centre={centreQuery.data} MobParameters={mobParameters}/>
                }
            </PageSimpleSetting>
        </div>
    )
}

const ParamsApps = styled(ParamsAppsCtrl)``

export default ParamsApps