import styled from "styled-components";
import {ReactNode} from "react";

interface PageTripleSettingCtrlProps{
    className?:string;
    children:ReactNode;
}

const PageTripleSettingCtrl = (props:PageTripleSettingCtrlProps)=>{
    return (
        <div className={`pageTripleSetting ${props.className}`}>
            {props.children}
        </div>
    )
}

const PageTripleSetting = styled(PageTripleSettingCtrl)`
    display: flex;
    justify-content: stretch;
    align-items: stretch;
    background: white;
    padding: 1rem;
`

export default PageTripleSetting