import {dataFetcher} from "../../services/dataGuetter/dataFetcher";

/**
 * Permet de récupérer toutes les fonctions
 */
export async function getAllFonction(idCentre:number|null):Promise<Fonction[]>{
    let url = `fonctions`;
    if(idCentre){
        url = `fonctions?Centre=${idCentre}`
    }
    const res:any = await dataFetcher(url, {
        method:'get',
    })
    return res["hydra:member"];
}

export async function getAllFonctionByCentre(idCentre:number):Promise<Fonction[]>{
    const res:any = await dataFetcher(`fonctions?Centre=${idCentre}`, {
        method:'get',
    })
    return res["hydra:member"];
}