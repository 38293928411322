import React, {useEffect, useMemo, useState} from "react";
import styled from "styled-components";
import {
    useAllIsRead,
    useChangeNotification,
    useGetNotifications
} from "../../../features/notification/notification.hooks";
import TimedNotificationInCurtain from "../TimedNotificationInCurtain/TimedNotificationInCurtain";
import {GrFormClose} from "react-icons/gr";
import {BtnClose} from "../../../assets/styles/elements";
import TimedNoResult from "../TimedNoResult/TimedNoResult";
import {formatDate} from "../../functions/TimesFunctions";

interface TimedNotificationCurtainCtrlProps{
    className?:string;
    Open:boolean;
    SwitchOpen:Function;
}

const TimedNotificationCurtainCtrl = ({className, Open, SwitchOpen}:TimedNotificationCurtainCtrlProps)=>{
    const {isLoading, isFetching, isError, data:notifications} = useGetNotifications();
    const [isAll, setIsAll] = useState(false);
    const [haveNew, setHaveNew] = useState(false);
    const {mutate} = useChangeNotification();
    const mutationRead = useAllIsRead();
    useEffect(()=>{
        if(!isLoading && !isError && !isFetching) {
            const TabNew = notifications?.filter(n => n.reatAt === undefined);
            if(TabNew!==undefined) {
                setHaveNew(TabNew.length > 0);
            }
        }
    }, [isLoading, isFetching, isError, notifications])
    const NotificationsFiltered = useMemo(()=>{
        if(isLoading || isError || isFetching){
            return [];
        } else {
            if(isAll){
                return notifications;
            } else {
                return notifications?.filter(notif=>notif.reatAt === undefined);
            }
        }
    }, [isLoading, isError, isFetching, isAll, notifications])
    const HandleAllisReaded = ()=>{
        mutationRead.mutate();
    }
    const setIsRead = (id:number, isRead:boolean)=>{
        const reatAt = isRead ? formatDate(new Date()) : null;
        const notFD:NotificationFormData = {
            id:id,
            reatAt:reatAt
        }
        mutate(notFD);
    }
    return (
        <div className={`NotifCurt ${className} ${Open ? "opened" : "closed"}`}>
            <div className={"content-notification-center"}>
                <div className="head-center">
                    <div className="marger"></div>
                    <div className="title">Notifications</div>
                    <div className="marger"><BtnClose onClick={()=>SwitchOpen()}><GrFormClose/></BtnClose></div>
                </div>
                <div className="actions">
                    <>
                        {isAll ?
                            <div className="action" onClick={()=>setIsAll(false)}>Filter par non lues</div>:
                            <div className="action" onClick={()=>setIsAll(true)}>Afficher tout</div>
                        }
                        {haveNew &&
                        <div className="action" onClick={HandleAllisReaded}>Tout marquer comme lu</div>
                        }
                    </>
                </div>
                <div className="body">
                    {isLoading ? 'chargement...' : isError ? 'oups...':
                        NotificationsFiltered?.map((notification:NotificationTimed, idx:number)=>(
                            <TimedNotificationInCurtain Notification={notification} setIsRead={setIsRead} key={`onNIC${idx}`}/>
                        ))
                    }
                    {(NotificationsFiltered!==undefined && NotificationsFiltered.length === 0 && !isFetching) &&
                        <TimedNoResult className={"NoResThis"} text="Aucune notification non lue" width={"75%"}/>
                    }
                </div>
            </div>
        </div>
    )
}

const TimedNotificationCurtain = styled(TimedNotificationCurtainCtrl)`
  position: absolute;
  z-index: 9;
  background: white;
  top: 75px;
  right: -350px;
  width: 350px;
  box-shadow: -1px 0 3px rgba(0,0,0,.2);
  height: calc(100vh - 75px);
  transition: right 0.3s linear;
  .NoResThis{
    margin-top: 50px;
  }
  &.opened{
    right: 0;
    transition: right 0.3s linear;
  }
  .content-notification-center{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    .head-center{
      width: 100%;
      padding: 0.25rem;
      border-bottom: solid #ccc 1px;
      display: flex;
      justify-content: start;
      align-items: center;
      .marger{
        width: 30px;
      }
      .title{
        text-align: center;
        flex-grow: 1;
      }
    }
    .body{
      flex-grow: 1;
      height: 0;
      overflow-y: auto;
      .oneNotifInCurtain{
        &:first-child{
          padding-top: 0.75rem;  
        }
        &:last-child{
          padding-bottom: 0.75rem;
        }
      }
    }
    .actions{
      width: 100%;
      padding: 0.5rem 0.25rem;
      border-bottom: solid #ccc 1px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .action{
        text-decoration: underline;
        &:hover{
          cursor: pointer;
          font-weight: bold;
        }
      }
    }
  }
`

export default TimedNotificationCurtain;