import React, {useState} from "react";
import styled from "styled-components";
import TimedInputText from "../../components/atoms/TimedInputText/TimedInputText";
import PulseLoader from "../../components/atoms/TimedSpinners/PulseLoader";

interface MainTestCtrlProps{
    className?:string;
}

const MainTestCtrl = ({className}:MainTestCtrlProps)=>{
    const [valInit, setValInit] = useState({prenom:"", nom:"", password:""})
    return (
        <div className={`pageTest ${className}`}>
            <h1>Page de test</h1>
            <div style={{margin:"20px auto", padding:"10px", border:"solid black 1px"}}>
                <PulseLoader size={"sm"} themeColor={"Secondary"}/>
            </div>
            <div style={{margin:"20px 0"}}>
                <TimedInputText
                    label={"Prénom"}
                    id={"prenom"}
                    type={"text"}
                    isRequired={true}
                    isError={true}
                    helperText={"le champ en erreur"}
                    value={valInit.prenom}
                    size={"small"}
                    setFieldValue={(id:string, value:string)=>{setValInit({...valInit, prenom:value})}}
                />
                <TimedInputText
                    label={"Nom"}
                    id={"nom"}
                    type={"text"}
                    isRequired={true}
                    isError={false}
                    helperText={""}
                    size={"small"}
                    value={valInit.nom}
                    setFieldValue={(id:string, value:string)=>{setValInit({...valInit, nom:value})}}
                />
            </div>
        </div>
    )
}

const MainTest = styled(MainTestCtrl)``

export default MainTest;