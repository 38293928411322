import styled from "styled-components";
import TitrePageSetting from "../components/TitrePageSetting";
import PageSimpleSetting from "../components/PageSimpleSetting";
import {useGetCentreActuel} from "../../../../features/centre/centre.hooks";
import {useEffect, useState} from "react";
import {useGetAllModuleCarte} from "../../../../features/moduleCarte/moduleCarte.hooks";
import {
    useAddCentreParameter,
    useModifyCentreParameter
} from "../../../../features/centreParameter/centreParameter.hooks";
import LoadDatas from "../components/LoadDatas";
import FormSetParamsRempla from "./FormSetParamsRempla";

interface ParamsRemplaCtrlProps{
    className?:string;
}

const ParamsRemplaCtrl = (props:ParamsRemplaCtrlProps)=>{
    const mutation = useModifyCentreParameter();
    const mutationAdd = useAddCentreParameter();
    const CentreQuery = useGetCentreActuel();
    const [paramsRempla, setParamsRempla] = useState<CentreParameter|undefined>(undefined)
    const [paramsRemplaInterne, setParamsRemplaInterne] = useState<CentreParameter|undefined>(undefined)
    const [isSubscribeRemplace, setIsSubscribeRempla] = useState(false)
    const [litterals, setLitterals] = useState<any|null>(null)
    const [idCentre, setIdCentre] = useState(0)
    const ModuleCarteQuery = useGetAllModuleCarte(idCentre)
    useEffect(() => {
        if(ModuleCarteQuery.data){
            const myModule = ModuleCarteQuery.data.find(m=>m.ModuleFact.id === 6)
            const today = new Date((new Date().setHours(12,0,0)))
            if(myModule && new Date(myModule.startAt.slice(0,10)+"T01:00:00")<today && (!myModule.endAt || new Date(myModule.endAt.slice(0,10)+"T23:59:59")>today)){
                setIsSubscribeRempla(true);
            } else {
                setIsSubscribeRempla(false);
            }
        }
    }, [ModuleCarteQuery.data]);
    useEffect(() => {
        if(CentreQuery.data) {
            setIdCentre(CentreQuery.data.id)
            const CentreParams = CentreQuery.data.parameters;
            const myParams = CentreParams.find(cp=>cp.Parameter.id === 8)
            const myParamsI = CentreParams.find(cp=>cp.Parameter.id === 23)
            setParamsRempla(myParams);
            setParamsRemplaInterne(myParamsI);
            setLitterals(CentreQuery.data.literalParameters)
        }
    }, [CentreQuery.data]);
    const SwitchRempla = ()=>{
        if(!paramsRempla){
            mutationAdd.mutate(({idCentre:idCentre, idParams:8}))
        } else{
            const newValue = !paramsRempla.statut;
            mutation.mutate(({id:paramsRempla.id, newValue:newValue}));
        }
    }
    const SwitchRemplaI = ()=>{
        if(!paramsRemplaInterne){
            mutationAdd.mutate(({idCentre:idCentre, idParams:23}))
        } else{
            const newValue = !paramsRemplaInterne.statut;
            mutation.mutate(({id:paramsRemplaInterne.id, newValue:newValue}));
        }
    }
    return (
        <div className={`paramsRempla page_params ${props.className}`}>
            <TitrePageSetting titre={"Les remplacements"}/>
            <PageSimpleSetting>
                <p className={"intro_simple"}>Définissez les options de vos remplacements médicaux, y compris les honoraires, les rétrocessions et le modalités de remplacement</p>
                {CentreQuery.isLoading || ModuleCarteQuery.isLoading || !CentreQuery.data || !litterals ?
                    <LoadDatas/>:
                    <FormSetParamsRempla
                        isRemplaceI={(!!paramsRemplaInterne && paramsRemplaInterne.statut)}
                        isRemplace={(!!paramsRempla && paramsRempla.statut)}
                        SwitchRempla={SwitchRempla}
                        SwitchRemplaI={SwitchRemplaI}
                        Centre={CentreQuery.data}
                        isSubscribe={isSubscribeRemplace}
                        litterals={litterals}
                    />
                }
            </PageSimpleSetting>
        </div>
    )
}

const ParamsRempla = styled(ParamsRemplaCtrl)``

export default ParamsRempla