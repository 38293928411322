import styled from "styled-components";
import {ReactNode} from "react";

interface BtnOutlineCtrlProps{
    className?:string;
    OnClick:()=>void;
    libelle:string;
    Icon:ReactNode
}

const BtnOutlineCtrl = (props:BtnOutlineCtrlProps)=>{
    return (
        <button className={`btnOutline ${props.className}`} onClick={()=>props.OnClick()}>
            {props.Icon}<span>{props.libelle}</span>
        </button>
    )
}

const BtnOutline = styled(BtnOutlineCtrl)`
    border:solid ${props=>props.theme.Primary} 2px;
    background: white;
    padding: 0.5rem 1rem;
    display: flex;
    justify-content: flex-start;
    gap: 6px;
    align-items: center;
    color: ${props=>props.theme.Primary};
    border-radius: 4px;
    font-weight: bold;
    &:hover{
        cursor: pointer;
        background: ${props=>props.theme.PrimaryMegaLight};
    }
`

export default BtnOutline