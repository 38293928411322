export const VerifErrorString = (id:string, value:string, TabNeed:MyConstraint[])=>{
    const myContraint = TabNeed.find(n=>n.id === id);
    if(!myContraint){
        return '';
    } else {
        if(value === '' && myContraint.required){
            return 'Champs obligatoire';
        } else if((myContraint.minLen && value.length < myContraint.minLen) || (myContraint.maxLen && value.length > myContraint.maxLen) ){
            if(myContraint.minLen && myContraint.maxLen){
                return `doit être compris entre ${myContraint.minLen} et ${myContraint.maxLen} caractères ${value} ${value.length}`
            } else if(myContraint.minLen){
                return `doit être supérieure à ${myContraint.minLen} caractères`
            } else {
                return `doit être inférieure à ${myContraint.minLen} caractères`
            }

        } else if(myContraint.min && new Date(value) < new Date(myContraint.min)){
            return `doit être supérieure à ${myContraint.min}`
        } else if(myContraint.max && new Date(value) > new Date(myContraint.max)){
            return `doit être inférieure à ${myContraint.max}`
        } else if(myContraint.regex){

            if(!value.match(myContraint.regex)){
                return 'Format invalide';
            } else {
                return '';
            }
        } else {
            return '';
        }
    }
}

export const VerifErrorNumber = (id:string, value:string, TabNeed:MyConstraint[])=>{
    const myContraint = TabNeed.find(n=>n.id === id);
    const valueContraint = parseInt(value);
    if(!myContraint){
        return '';
    } else {
        if(value === '' && myContraint.required){
            return 'Champs obligatoire';
        } else if(myContraint.min && valueContraint < myContraint.min){
            return `doit être supérieure à ${myContraint.min}`
        } else if(myContraint.max && valueContraint > myContraint.max){
            return `doit être inférieure à ${myContraint.max}`
        } else if(myContraint.regex){

            if(!value.match(myContraint.regex)){
                return 'Format invalide';
            } else {
                return '';
            }
        } else {
            return '';
        }
    }
}

export function ValideNoError<TypeError>(Error:TypeError){
    let nbError = 0
    for (const [key, value] of Object.entries(Error)){
        if(value!==null) nbError++;
        console.log(key, value)
    }
    return nbError ===0
}

export function DatasSetter<TypeObject, TypeError>(value:string|null|number|Choice, id:keyof TypeObject, TabString:string[], TabNumber:string[], TabChoice:string[], TabContraint:MyConstraint[], object:TypeObject, setObject:(o:TypeObject)=>void,Errors:TypeError, setError:(err:TypeError)=>void){
    let myError:string|null = null;
    if(TabString.indexOf(id as string)!==-1){
        const myValue = value as string;
        const returnError = VerifErrorString(id as string, myValue, TabContraint);
        myError = returnError!== "" ? returnError : null;
        setObject({...object, [id]:myValue})
    } else if(TabNumber.indexOf(id as string)!==-1){
        const myValue = value as number;
        const returnError = VerifErrorNumber(id as string, value as string, TabContraint)
        console.log(returnError);
        myError = returnError!== "" ? returnError : null;
        setObject({...object, [id]:myValue})
    } else if (TabChoice.indexOf(id as string)!==-1){
        const myValue = value as Choice;
        setObject({...object, [id]:myValue})
        myError = null;
    }
    setError({...Errors, [id]:myError})
}