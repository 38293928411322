import React from "react";
import styled, {keyframes} from "styled-components";

interface PulseLoaderCtrlProps{
    className?:string;
    size:"xs" |"sm" | "md" | "l" | "xl";
    themeColor:"Primary" | "Secondary" | "Complementary" | "Tertiary" | "Neutre";
}

const PulseLoaderCtrl = ({className, size, themeColor}:PulseLoaderCtrlProps)=>{
    return (
        <span className={`wrap-spinner ${className}`}>
            <span className={`one-buble first`}/>
            <span className={`one-buble second`}/>
            <span className={`one-buble third`}/>
        </span>
    )
}

const pulse = keyframes`
    from {
      transform: scale(0);
    }
  to{
    transform: scale(1);
  }
`

const PulseLoader = styled(PulseLoaderCtrl)`
  height: ${props=>props.size === "xs" ? "8px" : props.size === "sm" ? "11px" : props.size === "md" ? "13px" : props.size === "l" ? "15px" : "17px"};
    .one-buble{
      margin: 2px;
      display: inline-block;
      width: ${props=>props.size === "xs" ? "8px" : props.size === "sm" ? "11px" : props.size === "md" ? "13px" : props.size === "l" ? "15px" : "17px"};
      height: ${props=>props.size === "xs" ? "8px" : props.size === "sm" ? "11px" : props.size === "md" ? "13px" : props.size === "l" ? "15px" : "17px"};
      border-radius: 50%;
      background: ${props=>
              props.themeColor==="Primary" ? props.theme.Primary :
                      props.themeColor==="Secondary" ? props.theme.Secondary :
                              props.themeColor==="Complementary" ? props.theme.Complementary :
                                      props.themeColor === "Tertiary" ? props.theme.Tertiary : "white"
      };
      animation-name: ${pulse};
      animation-duration: 0.75s;
      animation-timing-function: cubic-bezier(0.2,0.68,0.18,1.08);
      animation-iteration-count: infinite;
      animation-direction: normal;
      animation-fill-mode: both;
      animation-play-state: running;
      &.first{
        animation-delay: 0.12s;
      }
      &.second{
        animation-delay: 0.24s;
      }
      &.third{
        animation-delay: 0.36s;
      }
    }
`

PulseLoader.defaultProps={
    size:"sm",
    themeColor:"Primary"
}

export default PulseLoader;