import React, {useCallback, useEffect, useState} from "react";
import styled from "styled-components";
import {BsFillBellFill} from "react-icons/bs";
import {myBoxShadow, Ring} from "../../../assets/styles/elements";
import {toast} from "react-toastify";
import TimedNotification from "../TimedNotification/TimedNotification";
import {useGetNotifications} from "../../../features/notification/notification.hooks";
import {useQueryClient} from "react-query";

const url = `${process.env.REACT_APP_MERCURE_URL}?topic=${process.env.REACT_APP_API_URL}/notifications`


interface TimedNotificationCenterCtrlProps{
    className?:string;
    User:UserSimple;
    OpenNotif?:boolean;
    SwitchOpen?:Function;
}

const TimedNotificationCenterCtrl = ({className, User, SwitchOpen}:TimedNotificationCenterCtrlProps)=>{
    const [newNotification, setNewNotification] = useState(false);
    const {isLoading, isFetching, isError, data:notifications} = useGetNotifications();
    const [haveNew, setHaveNew] = useState(false);
    const queryClient = useQueryClient();
    useEffect(()=>{
        if(!isLoading && !isError && !isFetching) {
            const TabNew = notifications?.filter(n => n.reatAt === undefined);
            if(TabNew!==undefined) {
                setHaveNew(TabNew.length > 0);
            }
        }
    }, [isLoading, isFetching, isError, notifications])
    const HandleNotification = useCallback((myNotification:NotificationTimed)=>{
        if(myNotification.idUserTo === User.id){
            const Exist = notifications?.find(n=>n.id===myNotification.id)

            if(myNotification.type === 'Success_Export' || myNotification.type === 'Erreur_Export'){
                queryClient.invalidateQueries(["exports"]).then();
            }
            if(myNotification.type === 'backNotif'){
                return;
            }
            if(Exist === undefined) {
                queryClient.invalidateQueries(["notifications"])
                setNewNotification(true)
                toast.info(<TimedNotification Notification={myNotification}/>, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    icon: false,
                });
            }
        }
    }, [notifications, User.id, queryClient])
    useEffect(()=>{
        const eventSource = new EventSource(url);
        eventSource.onmessage = event =>{
            const myNotification:NotificationTimed = JSON.parse(event.data)
            HandleNotification(myNotification);
        }
        return ()=>{
            eventSource.close();
        }
    }, [HandleNotification])


    const HandleClick = ()=>{
        setNewNotification(false)
        if(SwitchOpen!==undefined){
            SwitchOpen();
        }
    }
    return (
        <div className={`NotifCenter ${className} ${haveNew ? "alerte" : ''} ${newNotification ? "ring" : ''}`} onClick={HandleClick}>
            <div className={`wrap-bell`}><BsFillBellFill/></div>
        </div>
    )
}

const TimedNotificationCenter = styled(TimedNotificationCenterCtrl)`
  width: 36px;
  height: 36px;
  background: white;
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  &:hover{
    cursor: pointer;
  }
  &.ring{
    .wrap-bell{
      animation: ${Ring} 0.3s infinite  ;
    }
  }
  &.alerte{
    .wrap-bell{
      svg{
        color:${props=>props.theme.Complementary}
      }
    }
  }
  .wrap-bell{
    margin: auto;
    transform-origin: 50% 0;
    width: 16px;
    svg{
      font-size: 16px;
    }
  }
`

export default TimedNotificationCenter;