import {dataFetcher} from "../../services/dataGuetter/dataFetcher";

export async function getAllRubrique(idCentre:number|null):Promise<RubriqueSpe[]>{
    let url = `rubrique_spes`
    if(idCentre){
        url = `rubrique_spes?Centre=${idCentre}`
    }
    const res:any = await dataFetcher(url, {method:'get'});
    return res["hydra:member"];
}

export function AddRubrique(datas:RubriqueSpeNewFD):Promise<RubriqueSpe>{
    return dataFetcher(`rubrique_spes`, {
        method:'post',
        body:datas
    })
}

export function TrashRubrique(id:number):Promise<RubriqueSpe>{
    return dataFetcher(`rubrique_spes/${id}`, {
        method:'delete'
    })
}

export function ModifyRubrique(id:number, datas:RubriqueSpeModifyFD):Promise<RubriqueSpe>{
    return dataFetcher(`rubrique_spes/${id}`, {
        method:'put',
        body:datas
    })
}