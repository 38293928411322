import React from "react";
import {SomethingWrong} from "../components/organisms/SomethingWrong";

export class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }
    componentDidCatch(error, info) {
        this.setState({ hasError: true });
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <SomethingWrong/>;
        }
        return this.props.children;
    }
}