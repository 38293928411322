import React from "react";
import styled from "styled-components";

interface TimedFullErrorCtrlProps{
    className?:string;
    text:string;
    textAlign?:"center" | "right";
}

const TimedFullErrorCtrl = ({className, text, textAlign}:TimedFullErrorCtrlProps)=>{
    return (
        <div className={`TimedFE ${className} ${textAlign ? textAlign : ''}`}>
            {text}
        </div>
    )
}

const TimedFullError = styled(TimedFullErrorCtrl)`
  width: 100%;
  padding: 0.75rem;
  margin: 15px auto;
  background: ${props=>props.theme.Warning};
  color: white;
  &.center{
    text-align: center;
  }
  &.right{
    text-align: right;
  }
`

export default TimedFullError;