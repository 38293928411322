import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {ConvertMinToHeure} from "../../../../components/functions/TimesFunctions";
import {useDrop} from "react-dnd";
import {ItemTypes} from "../../../Modaux/ModalGereDayPointage";
import {MdLogin, MdLogout} from "react-icons/md";
import { BsFlagFill } from "react-icons/bs";
import {useDebounce} from "../../../../services/hooks";

interface OneDropHourCtrlProps{
    className?:string;
    type:"heureDebut"|"heureFin"|"time";
    value:number;
    setValue:(heureStr:string, type:"heureDebut"|"heureFin"|"time", jUn:boolean)=>void
    isDoubt:boolean;
    leverDoute:(type:'E'|'S')=>void;
    jUn:boolean;
    setJUn:(is:boolean)=>void;
}

const OneDropHourCtrl = ({className, type, value, setValue, isDoubt, leverDoute, jUn, setJUn}:OneDropHourCtrlProps)=>{
    const refH = useRef<HTMLInputElement>(null)
    const [Heure, setHeure] = useState(value)
    const HeureDebounce = useDebounce(Heure, 900);
    useEffect(() => {
        setHeure(value);
    }, [value]);
    const HandleChange = ()=>{
        if(refH.current){
            const Tab = refH.current.value.split(":")
            setHeure(parseInt(Tab[0]) * 60 + parseInt(Tab[1]))
        }
    }
    useEffect(()=>{
        setValue(ConvertMinToHeure(HeureDebounce), type, jUn);
    }, [HeureDebounce])
    const [{ isOver }, drop] = useDrop(
        () => ({
            accept: ItemTypes.Horaire,
            drop: (item) => moveHoraire(item),
            collect: (monitor) => ({
                isOver: !!monitor.isOver()
            })
        }),
        []
    )
    const moveHoraire = (item:any)=>{
        const Tab = item.horaire.split(':');
        setHeure(parseInt(Tab[0]) * 60 + parseInt(Tab[1]));
        setValue(item.horaire, type, item.jUn)
    }
    return (
        <div className={`heureDeb is-hour ${className} ${isOver ? 'overing' : ''}`} ref={drop}>
            {isDoubt &&
                <div className={`doubt ${type==='heureDebut' ? 'is-entree' : 'is-sortie'}`} onClick={()=>leverDoute(type==='heureDebut' ? 'E' : 'S')} data-infos={"Timed a detecté ce pointage par calcul, si vous êtes d'accord avec celui-ci, cliquez sur le drapeau pour lever le doute"}><BsFlagFill/></div>
            }
            <div className={`triangle ${type === 'heureFin' ? 'end' : ''}`}/>
            {type === 'heureDebut' ? <MdLogin/> : <MdLogout/>}
            <input className={`input-time`} type={"time"} ref={refH} value={Heure === 0 ? '' : ConvertMinToHeure(Heure)} onChange={HandleChange}/>
            {type === 'heureFin' &&
                <div className={"wrap_jun"}>
                    <input className={`input-jun`} type={"checkbox"} disabled={value===0} checked={jUn} onChange={()=>setJUn(!jUn)}/>
                    <label>j+1</label>
                </div>
            }
        </div>
    )
}

export const OneDropHour = styled(OneDropHourCtrl)`
    border-radius: 12px;
  border:solid white 3px;
    width: max-content;
    position: relative;
    background: ${props => props.type === 'heureDebut' ? props.theme.SuccessExtraLight : props.theme.ComplementaryExtraLight};
    color: ${props => props.type === 'heureDebut' ? props.theme.SuccessDark : props.theme.ComplementaryDark};
    padding: 0.25rem 1rem;
    display: flex;
    justify-content: flex-start;
  align-items: center;
  gap: 5px;
  position: relative;
  .wrap_jun{
    position: absolute;
    display: flex;
    justify-content: flex-start;
    gap: 3px;
    top: 50%;
    left: 105%;
    transform: translateY(-50%);
  }
  .doubt{
    position: absolute;
    top: 50%;
    width: 25px;
    height: 25px;
    background: ${props=>props.theme.TertiaryExtraLight};
    
    transform: translateY(-50%);
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    &:hover{
      cursor: pointer;
    }
    &:hover:after{
      content: attr(data-infos);
      position: absolute;
      color: ${props=>props.theme.PrimaryDark};
      width: 120px;
      left: calc(100% + 20px);
      right: auto;
      z-index: 6;
      font-size: 14px;
      padding: 0.25rem;
      border-radius: 6px;
      background: white;
      box-shadow: 1px 1px 3px rgba(0,0,0,0.2);
    }
    svg{
      font-size: 14px;
      fill:${props=>props.theme.TertiaryDark};
      margin: auto;
    }
    &.is-entree{
      left:105%;
      right: auto;
    }
    &.is-sortie{
      right:105%;
      left: auto;
      &:hover:after{
        right: calc(100% + 20px);
        left: auto;
      }
    }
  }
  transition: transform .3s;
  &.overing{
    filter: brightness(105%);
    transform-origin: bottom;
    transform: scale(1.05);
    transition: transform .3s;
    border:dashed ${props=>props.theme.Dark} 3px;
    .triangle{
      display: none;
    }
  }
  input{
    background: transparent;
    border:solid ${props => props.type === 'heureDebut' ? props.theme.SuccessLight : props.theme.ComplementaryLight} 1px;
    &.input-time{
      height: 30px;
      min-width: 50px;
      border-radius: 4px;
      padding: 0 0.2rem;
    }
    color: ${props => props.type === 'heureDebut' ? props.theme.SuccessDark : props.theme.ComplementaryDark};
  }
`
