import styled from "styled-components";
import {ReactNode} from "react";

interface PageSimpleSettingCtrlProps{
    className?:string;
    children:ReactNode;
}

const PageSimpleSettingCtrl = (props:PageSimpleSettingCtrlProps)=>{
    return (
        <div className={`pageSimpleSetting ${props.className}`}>
            {props.children}
        </div>
    )
}

const PageSimpleSetting = styled(PageSimpleSettingCtrl)`
    background: white;
    padding: 1rem;
`

export default PageSimpleSetting