import styled from "styled-components";
import {useGetAllTypeFrequence} from "../../../../features/typePeriode/typePeriode.hooks";
import {useGetAllActes} from "../../../../features/acte/acte.hooks";
import {useEffect, useState} from "react";
import {Contraint, DatasSetter} from "../../../../components/functions/ValideDataFunctions";
import InputTextSetting from "../components/InputTextSetting";
import InputSelectSetting from "../components/InputSelectSetting";

interface FormEditCompteurCtrlProps{
    className?:string;
    Type:TypePeriode;
    idCentre:number;
}
interface CompteurProps{
    libelle:string;
    unite:Choice|null;
    Acte:Choice|null;
    TypeFrequence:Choice|null;
    nbOccurence:number;

}
const DefautCptr:CompteurProps={
    libelle:"",
    unite:null,
    Acte:null,
    TypeFrequence:null,
    nbOccurence:1
}

interface ErrorProps{
    libelle:string|null;
    unite:string|null;
    Acte:string|null;
    TypeFrequence:string|null;
    nbOccurence:string|null;
}
const ErrorDefault:ErrorProps={
    libelle:null,
    unite:null,
    Acte:null,
    TypeFrequence:null,
    nbOccurence:null,
}

interface DetailsProps{
    unite:InfosProps;
    Acte:InfosProps;
    TypeFrequence:InfosProps;
    nbOccurence:InfosProps;
}
const TabInfos:DetailsProps = {
    unite:{keyProps:"unite", libelle:"Compteur - Unité", description:"Cela détermine la façon de comptabiliser le nombre dans le compteur, en jours ou en heures"},
    Acte:{keyProps:"Acte", libelle:"Compteur - Acte", description:"Cela détermine sur quel acte le compteur est positionné"},
    TypeFrequence:{keyProps:"Compteur", libelle:"Compteur - Fréquence", description:"Pour le renouvellement des périodes."},
    nbOccurence:{keyProps:"Compteur", libelle:"Compteur -Amplitude", description:"Lors du renouvellement des périodes, détermine l'amplitude de la période, ex: Fréquence Hebdomadaire, Amplitude 3 donnera une période de 3 semaines"},
}

const FormEditCompteurCtrl = (props:FormEditCompteurCtrlProps)=>{
    const TFreqQuery = useGetAllTypeFrequence();
    const ActesQuery = useGetAllActes();
    const choicesUnite:Choice[] = [{id:"J", libelle:"Jours", description:"Permet de comptabiliser le compteur en jours"}, {id:"H", libelle:"Heures", description:"Permet de comptabiliser le compteur en heures"}]
    const [choicesActe, setChoicesActe] = useState<Choice[]>([])
    const [choicesTypeFreq, setChoicesTypeFreq] = useState<Choice[]>([])
    const [isDisabled, setIsDisabled] = useState(true)
    const [myCptr, setMyCptr] = useState<CompteurProps>(DefautCptr)
    const [errors, setErrors] = useState<ErrorProps>(ErrorDefault)
    const TabChoice=["unite", "Acte", "TypeFrequence"]
    const TabNumber = ["nbOccurence"]
    const TabString = ["libelle"]
    const TabNeeds:Contraint[] = [
        {id:"libelle", required:true},
        {id:"TypeFrequence", required:true},
        {id:"nbOccurence", required:true},
    ]
    useEffect(() => {
        setIsDisabled(props.Type.Acte.Centre?.id !== props.idCentre)
    }, [props.Type, props.idCentre]);
    useEffect(() => {
        if(ActesQuery.data){
            setChoicesActe(ActesQuery.data.map(a=>{
                return {id:a.id, libelle:a.libelle}
            }))
        }
    }, [ActesQuery.data]);
    useEffect(() => {
        if(TFreqQuery.data){
            setChoicesTypeFreq(TFreqQuery.data.map(tf=>{
                return {id:tf.id, libelle:tf.libelle}
            }))
        }
    }, [TFreqQuery.data]);
    useEffect(()=>{
        if(choicesActe.length>0 && choicesTypeFreq.length>0 && props.Type){
            setMyCptr({
                libelle:props.Type.libelle,
                unite:choicesUnite.find(c=>c.id === props.Type.unite) as Choice,
                Acte:choicesActe.find(a=>a.id === props.Type.Acte.id) as Choice,
                TypeFrequence:choicesTypeFreq.find(tf=>tf.id === props.Type.TypeFrequence?.id)||null,
                nbOccurence:props.Type.nbOccurence||1
            })
        }
    }, [choicesActe, choicesTypeFreq, props.Type])
    const setMyValue = (value:string|null|Choice|number, id:string)=>{
        DatasSetter<CompteurProps, ErrorProps>(value, id as keyof CompteurProps, TabString, TabNumber, TabChoice, TabNeeds, myCptr, setMyCptr,errors,setErrors)
    }
    return (
        <div className={`fromEditCompteur ${props.className}`}>
            {isDisabled &&
                <p className="dis_infos">Le compteur est partagé par tous les centres, il n'est pas modifiable</p>
            }
            <div className={"wrap_inputs_setting"}>
                <InputTextSetting current={myCptr.libelle} setValue={setMyValue} Error={errors.libelle} id={"libelle"} label={"Libellé"} disabled={isDisabled}/>
                <InputSelectSetting setValue={setMyValue} Error={errors.unite} label={"Unité"} id={"unite"} choices={choicesUnite} Current={myCptr.unite} ItemInfos={TabInfos.unite} placeNoChoice={"-- Choix de l'unité' --"} disabled={isDisabled}/>
                <InputSelectSetting setValue={setMyValue} Error={errors.Acte} label={"Acte associé"} id={"Acte"} choices={choicesActe} Current={myCptr.Acte} ItemInfos={TabInfos.Acte} placeNoChoice={"-- Choix de l'acte --"} disabled={isDisabled}/>
                <InputSelectSetting setValue={setMyValue} Error={errors.TypeFrequence} label={"Type de fréquence"} id={"TypeFrequence"} choices={choicesTypeFreq} Current={myCptr.TypeFrequence} ItemInfos={TabInfos.TypeFrequence} placeNoChoice={"-- Choix de la fréquence --"} disabled={isDisabled}/>
                <InputTextSetting type={"number"} current={myCptr.nbOccurence} setValue={setMyValue} Error={errors.nbOccurence} id={"nbOccurence"} label={"Amplitude"}  disabled={isDisabled}/>
            </div>
        </div>
    )
}

const FormEditCompteur = styled(FormEditCompteurCtrl)`
    .dis_infos{
        font-style: italic;
        color: red;
        margin-bottom: 15px;
    }
`

export default FormEditCompteur