import styled from "styled-components";
import {ReactNode} from "react";

interface LeftPartTriplePageCtrlProps{
    className?:string;
    titre:string;
    children:ReactNode;
}

const LeftPartTriplePageCtrl = (props:LeftPartTriplePageCtrlProps)=>{
    return (
        <div className={`LeftPartTriplePage ${props.className}`}>
            <h4>{props.titre}</h4>
            <div className="content_leftpdp">
                {props.children}
            </div>
        </div>
    )
}

const LeftPartTriplePage = styled(LeftPartTriplePageCtrl)`
    max-height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    border-right: solid ${props => props.theme.Primary} 1px;
    width: 25%;
    h4{
        font-weight: bold;
        margin-bottom: 20px;
        font-size: 18px;
    }
    .content_leftpdp{
        flex-grow: 1;
        overflow-y: auto;
    }
`

export default LeftPartTriplePage