import styled from "styled-components";
import OneCardAdmin from "./components/OneCardAdmin";

interface WrapListAdminCtrlProps{
    className?:string;
    Admins:User[];
    AdminActif:User|null;
    setAdmin:(u:User)=>void;
}

const WrapListAdminCtrl = (props:WrapListAdminCtrlProps)=>{

    return (
        <div className={`wrapListAdmin wrap_list ${props.className}`}>
            {props.Admins.map(item=>(
                <OneCardAdmin
                    isCurrent={item.id === props.AdminActif?.id}
                    ClickOnEdit={()=>props.setAdmin(item)}
                    Admin={item}
                    key={`A${item.id}`}
                />
            ))}
        </div>
    )
}

const WrapListAdmin = styled(WrapListAdminCtrl)``

export default WrapListAdmin