import React from "react";
import styled from "styled-components";
import {WidgetCreneauxNonPris} from "../../../Widgets/Remplacements/WidgetCreneauxNonPris";
import {WidgetCreneauxNonPrisJ7} from "../../../Widgets/Remplacements/WidgetCreneauxNonPrisJ7";
import {WidgetCreneauxToValide} from "../../../Widgets/Remplacements/WidgetCreneauxToValide";
import {WidgetNbReplace} from "../../../Widgets/Remplacements/WidgetNbReplace";


import {WidgetRationPris} from "../../../Widgets/Remplacements/WidgetRationPris";
import {WidgetTopRempla} from "../../../Widgets/Remplacements/WidgetTopRempla";
import {WidgetNbHeureV} from "../../../Widgets/Remplacements/WidgetNbHeureV";
import {WidgetNbCreneauxPris} from "../../../Widgets/Remplacements/WidgetNbCreneauxPris";

interface DashboardCreneauxCtrlProps{
    className?:string;
    supervisor?:boolean;
}

const DashboardCreneauxCtrl=({className, supervisor}:DashboardCreneauxCtrlProps)=>{
    return (
        <div className={`FP ${className}`}>
            <div className={`content-dash`}>
                <div className="wrap-line-dash" style={{height:"150px"}}>
                    <div className={`wrap-column-dash`} style={{flexGrow:1}}>
                        <WidgetCreneauxNonPris supervisor={supervisor}/>
                    </div>
                    <div className={`wrap-column-dash`} style={{flexGrow:1}}>
                        <WidgetCreneauxNonPrisJ7 supervisor={supervisor}/>
                    </div>
                    <div className={`wrap-column-dash`} style={{flexGrow:1}}>
                        <WidgetCreneauxToValide supervisor={supervisor}/>
                    </div>
                    <div className={`wrap-column-dash`} style={{flexGrow:1}}>
                        <WidgetNbReplace supervisor={supervisor}/>
                    </div>
                </div>
                <div className="wrap-line-dash" style={{height:"200px"}}>
                    <div className={`wrap-column-dash`} style={{flexGrow:1}}>
                        <WidgetRationPris supervisor={supervisor}/>
                    </div>
                    <div className={`wrap-column-dash`} style={{flexGrow:1}}>
                        <WidgetTopRempla TopFlop={"Top"} supervisor={supervisor}/>
                    </div>
                    <div className={`wrap-column-dash`} style={{flexGrow:1}}>
                        <WidgetTopRempla TopFlop={"Flop"} supervisor={supervisor}/>
                    </div>
                </div>
                <div className={"wrap-line-dash"}>
                    <div className={`wrap-column-dash w-100`}>
                        <WidgetNbHeureV supervisor={supervisor}/>
                    </div>
                </div>
                {/*<div className={"wrap-line-dash"}>*/}
                {/*    <div className={`wrap-column-dash w-100`}>*/}
                {/*        <WidgetNbCreneauxPris supervisor={supervisor}/>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
        </div>
    )
}


const DashboardCreneaux = styled(DashboardCreneauxCtrl)`
    height: 100%;
    
`

export default DashboardCreneaux;