import styled from "styled-components";
import OneCardCompteur from "./components/OneCardCompteur";
import OneCardTypeCompteur from "./components/OneCardTypeCompteur";

interface WrapListTypeCompteurCtrlProps{
    className?:string;
    Types:TypePeriode[];
    TypeActif:TypePeriode|null;
    setType:(t:TypePeriode)=>void;
}

const WrapListTypeCompteurCtrl = (props:WrapListTypeCompteurCtrlProps)=>{
    return (
        <div className={`wrapListTypeCompteur wrap_list ${props.className}`}>
            {props.Types.map(item=>(
                <OneCardTypeCompteur ClickOnEdit={()=>props.setType(item)} isCurrent={props.TypeActif?.id === item.id} Type={item}  key={`TP${item.id}`}/>
            ))}
        </div>
    )
}

const WrapListTypeCompteur = styled(WrapListTypeCompteurCtrl)``

export default WrapListTypeCompteur