import styled from "styled-components";
import React, {useEffect, useState} from "react";

interface ListSmartCtrlProps{
    className?:string;
    children:React.ReactNode;
    open:boolean;
    wD?:string;
    maxH?:string;
}

const VISIBLE =1;
const HIDDEN =2;
const ENTERING =3;
const LEAVING =4;

const ListSmartCtrl = ({className, children, open, ...rest}:ListSmartCtrlProps)=>{
    const [state, setState] = useState(open ? VISIBLE : HIDDEN);
    useEffect(()=>{
        if(!open){
            setState(LEAVING)
        } else{
            setState((s)=>s===HIDDEN ? ENTERING : LEAVING)
        }
    }, [open])
    useEffect(()=>{
        if(state === LEAVING){
            const timer = setTimeout(()=>{
                setState(HIDDEN)
            }, 300)
            return ()=>{
                clearTimeout(timer);
            }
        } else if (state === ENTERING){
            const xx = document.body.offsetHeight
            setState(VISIBLE);
        }
    }, [state])

    const style = {transitionDuration: `300ms`, transitionProperty:"height", height:rest.maxH ? rest.maxH : "450px"}
    if(state!==VISIBLE){
        style.height = "0";
    }


    if(state === HIDDEN){
        return null;
    } else {
        return (
            <div className={`list_smart ${className}`} style={style}>
                {children}
            </div>
        )
    }
}

export const ListSmart = styled(ListSmartCtrl)`
  transform-origin: 50% 0;
  position: absolute;
  left: 0;
  top:calc(100% + 1px);
  width: ${props => props.wD ? props.wD : "100%"};
  background: white;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  box-shadow: 1px 1px 3px rgba(0,0,0,.2);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 450px;
  z-index: 8;
`