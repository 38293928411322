import React from "react";
import styled from "styled-components";
import { MdOutlineClose } from "react-icons/md";
import ReactDOM from "react-dom";

export interface TimedModalCtrlProps{
    className?:string;
    size:"regular"|"large"|"xl"|"full";
    Close?:Function;
    title:string;
    position:"center"|"right"|"left";
    topPosition?:"Top"|"Middle";
    children?:React.ReactNode;
    footer?:React.ReactNode;
}

const TimedModalCtrl = ({className, Close, title, children, footer}:TimedModalCtrlProps)=>{
    return ReactDOM.createPortal(
        <div className={`wrapper-modal ${className}`}>
            <div className={`backModal`}/>
            <div className={"BlockModal"}>
                <div className={"Head-Modal"}>
                    <div className={"content-head-modal"}>
                        <div className={"title-modal"}>{title}</div>
                        <div className={"close-modal"}>
                            {!!Close &&
                                <div className={"content-close"} onClick={()=>Close()}><MdOutlineClose/></div>
                            }
                        </div>
                    </div>
                </div>
                <div className={"Body-Modal"}>
                    {children}
                </div>
                {footer &&
                <div className={`Footer-modal`}>{footer}</div>
                }
            </div>
        </div>, document.body
    )
}

const TimedModal = styled(TimedModalCtrl)`
    position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1001;
  background: rgba(0,0,0,.7);
  .BlockModal{
    width: ${props=>props.size==='regular' ? '30%' : props.size === 'large' ? '50%' : props.size==='xl' ? '75%' : '100%'};
    background: white;
    border-radius: ${props=>props.position === 'center' ? '0.2rem' : '0'};
    position: absolute;
    z-index: 999;
    top:${props=>(props.position === 'center' && props.topPosition === 'Top') ? '60px' : (props.position==='center' && props.topPosition === 'Middle') ? '50%' : '0'};
    left:${props=>props.position === 'center' ? '50%' : 'auto'};
    right:${props=>props.position !== 'center' ? '0' : 'auto'};
    transform: ${props=>(props.position === 'center' && props.topPosition === 'Top') ? 'translateY(-50%)' : (props.position==='center' && props.topPosition === 'Middle') ? 'translate(-50%,-50%)' : '0'};
    max-height: ${props=>props.position === 'center' ? '95vh' : '100vh'};
    height: ${props=>props.position==='center' ? 'auto' : '100%'};
    display: flex;
    flex-direction: column;
    .Body-Modal{
      padding: 0.25rem;
      flex-grow: 1;
      flex-shrink: 0;
      height: ${props=>props.position==='right' ? "0" : "auto"};
      min-height: ${props=>props.position==='right' ? "0" : "auto"};
      overflow-y: auto;
    }
    .Footer-modal{
      padding: 0.25rem;
      border-top: solid ${props=>props.theme.NeutreLight} 1px;
      //position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 62px;
    }
    .Head-Modal{
      padding: 0.25rem;
      border-bottom: solid ${props=>props.theme.NeutreLight} 1px;
      .content-head-modal{
        width: 100%;
        display: flex;
        justify-content: start;
        align-items: center;
        .title-modal{
          flex-grow: 1;
          padding-left: 0.5rem;
          font-weight: bold;
        }
        .close-modal{
          padding: 0.2rem;
          
          .content-close{
            width: 30px;
            height: 30px;
            display: flex;
            justify-content: center;
            flex-direction: column;
            &:hover{
              cursor: pointer;
            }
          }
        }
      }
    }
  }
`

export default TimedModal;