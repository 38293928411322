import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {useDrag} from "react-dnd";
import {ItemTypes} from "../../../Modaux/ModalGereDayPointage";
import {getEmptyImage} from "react-dnd-html5-backend";

interface OneHoraireDragCtrlProps{
    className?:string;
    Horaire:string;
    jUn:boolean;
    typeP?:string;
}

const OneHoraireDragCtrl = ({className, Horaire, jUn, typeP}:OneHoraireDragCtrlProps)=>{
    useEffect(() => {
        preview(getEmptyImage(), { captureDraggingState: true })
    }, [])
    const [{isDragging}, drag, preview] = useDrag(() => ({
        type: ItemTypes.Horaire,
        item:{horaire:Horaire, jUn:jUn},
        collect: monitor => ({
            isDragging: !!monitor.isDragging(),
        }),
    }))
    return (
        <div className={`oneHoraireDrag ${className} ${isDragging ? 'onDrag' : ''}`} ref={drag}>{`${Horaire}${jUn ? '(+1)' : ''}${typeP ? ' '+typeP : ''}`}</div>
    )
}

export const OneHoraireDrag = styled(OneHoraireDragCtrl)`
  padding: 0.25rem 1rem;
  margin: auto;
  border-radius: 12px;
  text-align: center;
  width: max-content;
  border:solid ${props=>props.theme.Primary} 1px;
  margin: 5px;
  font-size: 13px;
  &:hover{
    cursor: grab;
    background: ${props=>props.theme.PrimaryMegaLight};
  }
  &.onDrag{
    background: ${props=>props.theme.PrimaryMegaLight};
    //background: red;
  }
`