import React from "react";
import styled from "styled-components";
import {useDragLayer, XYCoord} from "react-dnd";
import {OneHoraireDrag_drag} from "./OneHoraireDrag_drag";
import {ItemTypes} from "../../../../pages/Modaux/ModalGereDayPointage";

interface CustomDragLayerOHDCtrlProps{
    className?:string;
}

function getItemStyles(
    initialOffset: XYCoord | null,
    currentOffset: XYCoord | null,
) {
    if (!initialOffset || !currentOffset) {
        return {
            display: 'none',
        }
    }
    let { x, y } = currentOffset
    const transform = `translate(${x}px, ${y}px)`
    return {
        transform,
        WebkitTransform: transform,
    }
}

const CustomDragLayerOHDCtrl = ({className}:CustomDragLayerOHDCtrlProps)=>{
    const { itemType, isDragging, item, initialOffset, currentOffset } =
        useDragLayer((monitor) => ({
            item: monitor.getItem(),
            itemType: monitor.getItemType(),
            initialOffset: monitor.getInitialSourceClientOffset(),
            currentOffset: monitor.getSourceClientOffset(),
            isDragging: monitor.isDragging(),
        }))
    // if (!isDragging) {
    //     return null
    // }
    function renderItem() {
        switch (itemType) {
            case ItemTypes.Horaire:
                return <OneHoraireDrag_drag Horaire={item.horaire}/>
            default:
                return null
        }
    }
    return (
        <div className={`CustomLayer ${className}`}>
            <div
                style={getItemStyles(initialOffset, currentOffset)}
            >
                {renderItem()}
            </div>
        </div>
    )
}

const CustomDragLayerOHD = styled(CustomDragLayerOHDCtrl)`
    position: fixed;
  pointer-events: none;
  z-index: 100;
  left:0;
  top: 0;
  width: 100%;
  height: 100%;
  transform-origin: center;
  
`

export default CustomDragLayerOHD;
