import {useMutation, useQuery, useQueryClient, UseQueryResult} from "react-query";
import {EndContrat, getAllContratCentre, ModifyContrat, NewContrat, updateJoursTravContrat} from "./contrat.services";
import {toast} from "react-toastify";

export const useGetAllContratCentre = (CentreId:number|null):UseQueryResult<Contrat[], Error>=>{
    let centre_id = CentreId;
    const queryClient = useQueryClient();
    if(!CentreId){
        const userConnected:User|undefined = queryClient.getQueryData(["user_connected"]);
        centre_id = userConnected ? userConnected.Centre.id : 0;
    }
    return useQuery<Contrat[], Error>(["contrats_all", centre_id], ()=>getAllContratCentre(centre_id||0), {
        enabled:!!centre_id
    })
}

export const useNewContrat = ()=>{
    const queryClient = useQueryClient();
    return useMutation((datas:ContratFormData)=>{
        return NewContrat(datas)
    },
        {
            onSuccess:()=>{
                queryClient.invalidateQueries(["contrats"]);
                queryClient.invalidateQueries(["week"]);
                queryClient.invalidateQueries(["user"]);
                queryClient.invalidateQueries(["users_ids"]);
                toast.success("contrat ajouté !", {});
            },
            onError: (error: any) => {
                toast.error("Erreur : " + error.message, {});
            },
        }
    )
}

export const useEndContrat = ()=>{
    const queryClient = useQueryClient();
    return useMutation((datas:ContratEndFormData)=>{
            return EndContrat(datas)
        },
        {
            onSuccess:()=>{
                queryClient.invalidateQueries(["contrats"]);
                queryClient.invalidateQueries(["user"]);
                toast.success("contrat terminé !", {});
            },
            onError: (error: any) => {
                toast.error("Erreur : " + error.message, {});
            },
        }
    )
}

export const useModifyContrat = ()=>{
    const queryClient = useQueryClient();
    return useMutation((datas:{id:number, data:ContratEditFD})=>{
        return ModifyContrat(datas.id, datas.data);
    }, {
        onSuccess:()=>{
            queryClient.invalidateQueries(["contrats"]);
            queryClient.invalidateQueries(["user"]);
            toast.success("contrat modifié !", {});
        },
        onError:()=>{
            toast.error("Une erreur est survenue.")
        }
    }
    )
}

export const useUpdateJoursTravContrat = ()=>{
    const queryClient = useQueryClient();
    return useMutation((datas: {idContrat:number, jt:JoursTravail })=>{
            return updateJoursTravContrat(datas.idContrat, datas.jt)
        },
        {
            onSuccess:()=>{
                queryClient.invalidateQueries(["contrats"]);
                queryClient.invalidateQueries(["user"]);
                toast.success("contrat modifié !", {});
            },
            onError: (error: any) => {
                toast.error("Erreur : " + error.message, {});
            },
        }

    )
}