import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import {isAuth} from "../../services/security/isAuth";


interface PrivateRouteCtrlProps {
    from: string;
    children?:React.ReactNode;
}

function PrivateRoute(props: PrivateRouteCtrlProps) {
    const location = useLocation();
    return isAuth() ? <Outlet /> : <Navigate to="/login" state={{ from: location.pathname }} />;
}

export default PrivateRoute;