import {useMutation, useQuery, useQueryClient, UseQueryResult} from "react-query";
import {AddRubrique, getAllRubrique, ModifyRubrique, TrashRubrique} from "./rubriqueSpe.services";
import {toast} from "react-toastify";


export const useGetAllRubrique = (idCentre:number|null=null):UseQueryResult<RubriqueSpe[], Error>=>{
    return useQuery<RubriqueSpe[], Error>(["rubriques", idCentre], ()=>getAllRubrique(idCentre))
}

export const useTrashRubrique = ()=>{
    const queryClient = useQueryClient();
    return useMutation(
        (id:number)=>{
            return TrashRubrique(id);
        }, {
            onSuccess:()=>{
                queryClient.invalidateQueries(["rubriques"]);
                toast.success("Rubrique supprimée");
            }, onError:()=>{
                toast.error("Impossible supprimer rubrique")
            }
        }
    )
}

export const useAddRubrique = ()=>{
    const queryClient = useQueryClient();
    return useMutation(
        (datas:RubriqueSpeNewFD)=>{
            return AddRubrique(datas);
        }, {
            onSuccess:()=>{
                queryClient.invalidateQueries(["rubriques"]);
                toast.success("Rubrique ajoutée");
            }, onError:()=>{
                toast.error("Impossible ajouter rubrique")
            }
        }
    )
}

export const useModifyRubrique = ()=>{
    const queryClient = useQueryClient();
    return useMutation(
        (datas: { id:number, data:RubriqueSpeModifyFD })=>{
            return ModifyRubrique(datas.id, datas.data);
        }, {
            onSuccess:()=>{
                queryClient.invalidateQueries(["rubriques"]);
                toast.success("Rubrique modifiée");
            }, onError:()=>{
                toast.error("Impossible modifier rubrique")
            }
        }
    )
}