import styled from "styled-components";
import {useEffect, useRef, useState} from "react";
import {useDebounce} from "../../../../services/hooks";
import {useModifyPointeuseTreat} from "../../../../features/pointeuseTreat/pointeuseTreat.hooks";

interface GereComentPTreatCtrlProps {
    className?:string;
    coment:string|undefined;
    idPT:number;
}

const GereComentPTreatCtrl = ({className, coment, idPT}: GereComentPTreatCtrlProps) => {
    const [myComent, setMyComent] = useState<string>(coment||'')
    const comentDebounce = useDebounce(myComent, 500)
    const refComent = useRef<HTMLInputElement>(null)
    const {mutate, isLoading:mutationLoading} = useModifyPointeuseTreat();
    const handleChangeComent = ()=>{
        if(refComent.current) setMyComent(refComent.current.value)
    }
    useEffect(() => {
        const datas:PointeuseTreatModifyFormData={
            id:idPT,
            comentFinalHour:myComent
        }
        mutate(datas);
    }, [comentDebounce]);
    return (
        <div className={`gere_coment ${className}`}>
            <input type={"text"} value={myComent} ref={refComent} placeholder={"Commentaire"} onChange={handleChangeComent}/>
        </div>
    )
}

export const GereComentPTreat = styled(GereComentPTreatCtrl)`
    background: white;
    input{
        width: 100%;
        height: 36px;
        border-radius: 4px;
        border solid #ccc 1px;
        padding-left: 10px;
    }
`