import styled from "styled-components";
import {ReactNode, useContext} from "react";
import {NavLink, useLocation} from "react-router-dom";
import {SectionContext} from "./OneSection";
import CSS from "csstype";
import {IoIosWarning} from "react-icons/io";

interface  DirectLinkAlerteCtrlProps{
    className?:string;
    isHight:boolean;
}

const styleTitle:CSS.Properties = {
    display: "flex",
    justifyContent: "flex-start",
    gap: "8px",
    padding: "0.5rem",
    alignItems: "center",
}

const  DirectLinkAlerteCtrl = (props: DirectLinkAlerteCtrlProps)=>{

    const { search } = useLocation();
    return (
        <NavLink to={`timed_alertes${search}`} className={`direct_link ${props.className} ${props.isHight ? "hight" : ""}`} style={styleTitle}>
            <div className="icon_place"><IoIosWarning /></div>
            <div className="lib_place">Alertes</div>
        </NavLink>
    )
}

export const  DirectLinkAlerte = styled( DirectLinkAlerteCtrl)`
    text-decoration: none;
    color: white;
    font-size: 15px;
    &.hight{
        color: black;
        background: ${props => props.theme.PrimaryExtraLight};
    }
    width: 80%;
    margin: 0 auto 10px 0;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    &:visited {
        color: white;
        &.hight{
            color: black;
        }
    }

    &:hover {
        cursor: pointer;
        filter: brightness(95%);

    }
`