import styled from "styled-components";
import React, {useEffect, useRef, useState} from "react";
import {WrapperInput} from "./WrapperInput";
import {ListChoice} from "./ListChoice";
import {GrFormClose} from "react-icons/gr";


interface TimedAutoCompletionMultiCtrlProps{
    className?:string;
    Choices:OneChoicePoss[]
    Current:string;
    setFieldValue:(c:string)=>void;
    placeHolder:string;
    posLeft?:string;
    posTop?:string;
    MaxList?:string;
    onError:boolean
    textEmptyChoice?:string;
    disabled?:boolean;
    isClearable?:boolean;
    contentLoading?:boolean;
    clearError?:()=>void;
}

export interface OneChoicePoss{
    id:number;
    libelle:string;
}

const TimedAutoCompletionMultiCtrl = (props:TimedAutoCompletionMultiCtrlProps)=>{
    const [open, setOpen] = useState(false);
    const refObj = useRef<HTMLDivElement>(null);
    const [search, setSearch] = useState<string>(props.Current)
    const refSearch = useRef<HTMLInputElement>(null)
    const [libCurr, setLibCurrent] = useState<string>(props.Current)
    const refGood = useRef<HTMLDivElement>(null)
    const [listFiltered,setListFiltered] = useState<OneChoicePoss[]>(props.Choices)
    useEffect(() => {
        if((props.Current !== search && props.Current!==libCurr) || search === ''){
            setSearch(props.Current);
            setLibCurrent(props.Current);
        }
    }, [props.Current]);
    const handleSearchChange = ()=>{
        if(props.clearError){
            props.clearError();
        }
        if(refSearch.current){
            setSearch(refSearch.current.value);
        }
    }
    useEffect(() => {
        const regex = new RegExp(""+search+"", 'gi');
        setListFiltered(props.Choices.filter(c=>search === '' || c.libelle.match(regex)))
    }, [search, props.Choices]);
    useEffect(() => {
        function handleClickOutside(event:any) {
            if (refObj.current && !refObj.current.contains(event.target)) {
                setOpen(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [refObj]);
    const handleClickOnIt =()=>{
        if(!open){
            refSearch.current?.focus();
            setOpen(true);
        }
    }
    const handleClickChoice = (Choice:OneChoicePoss|null)=>{
        if(props.clearError){
            props.clearError();
        }
        if(!!Choice) {
            setSearch(Choice.libelle);
            props.setFieldValue(Choice.libelle)
        } else {
            props.setFieldValue('')
        }
        setOpen(false);
    }
    
    useEffect(()=>{
        if(listFiltered.length>0) {
            const elt = listFiltered[0];
            setLibCurrent(elt.libelle);
        }
    }, [search, listFiltered])
    useEffect(()=>{
        if(open && refGood.current){
            refGood.current.scrollIntoView({ behavior: 'auto', block: 'start' });
        }
    }, [open, libCurr, refGood])
    const HandleKeyDown = (e:any)=>{
        if(!open){
            setOpen(true);
        }
        if(e.key === 'Enter' || e.keyCode === 9){
            if(libCurr!==''){
                const eltFind = props.Choices.find(l=>l.libelle.toLowerCase() === libCurr.toLowerCase());
                if(eltFind){
                    setSearch(eltFind.libelle)
                    props.setFieldValue(eltFind.libelle)
                    setOpen(false);
                } else {
                    setSearch('');
                    props.setFieldValue('')
                    setOpen(false);
                }
            }
        }
        // if(e.key === 'Backspace' && !open){
        //     setOpen(true);
        // }
        if (e.key !== 'ArrowUp' && e.key !== 'ArrowDown') return;
        e.preventDefault();
        if(!open){
            setOpen(true);
        }
        const MyElt = document.getElementById("myDiv");
        if(MyElt){
            MyElt.focus();
        }
        let index = 0;
        if(libCurr){
            const libs = listFiltered.map(l=>l.libelle);
            index = libs.indexOf(libCurr);
        }
        if (e.key === 'ArrowUp'){index--}
        if (e.key === 'ArrowDown'){index++}
        if(index<0){index = 0}
        if(index>listFiltered.length - 1){index = listFiltered.length - 1}
        setLibCurrent(listFiltered[index].libelle)
        //props.setFieldValue(listFiltered[index].libelle)
    }
    const ClearSearch = ()=>{
        setSearch('');
        setLibCurrent('');
        props.setFieldValue('');
    }
    return (
        <div className={`ChoiceMulti ${props.className}`} ref={refObj}>
            <WrapperInput onError={props.onError} ClickOnIt={handleClickOnIt}>
                <input disabled={!!props.disabled} value={search} onChange={handleSearchChange} ref={refSearch} placeholder={props.placeHolder} onKeyDown={HandleKeyDown}/>
            </WrapperInput>
            {props.isClearable && search!=='' &&
                <div className={"clear_choice"} onClick={ClearSearch}><GrFormClose/></div>
            }
            {open &&
                <ListChoice MaxList={props.MaxList} posLeft={props.posLeft} posTop={props.posLeft}>
                    <div className={`in-list-user`}>
                        {props.contentLoading && <div className={"item-choice-list no_select"}>Chargement en cours...</div> }
                        {listFiltered.length === 0 && <div className={"item-choice-list no_select"}>{props.textEmptyChoice ? props.textEmptyChoice : 'Aucun résultat'}</div>}
                        {listFiltered.map((l:OneChoicePoss)=> {
                            if(l.libelle.toLowerCase() === libCurr.toLowerCase()){
                                return (
                                    <div ref={refGood} className={`item-choice-list isToChoice  ${l.libelle.toLowerCase() === libCurr.toLowerCase() ? 'current' : '' }`} key={`oneChoiceL${l.id}`} onClick={()=>handleClickChoice(l)} >
                                        <div className={`name`}>{l.libelle}</div>
                                    </div>
                                )
                            } else {
                                return (
                                    <div className={`item-choice-list isToChoice  ${l.libelle.toLowerCase() === search.toLowerCase() ? 'current' : '' }`} key={`oneChoiceL${l.id}`} onClick={()=>handleClickChoice(l)} >
                                        <div className={`name`}>{l.libelle}</div>
                                    </div>
                                )
                            }
                        })}
                    </div>
                </ListChoice>
            }
        </div>
    )
}

export const TimedAutoCompletionMulti = styled(TimedAutoCompletionMultiCtrl)`
  background: white;
  padding: 0.5rem 0;
  position: relative;
  flex-grow: 1;
  .clear_choice{
    position: absolute;
    right: 10px;
    top: 55%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    svg{
      margin: auto;
    }
    &:hover{
      background: ${props=>props.theme.PrimaryMegaLight};
    }
  }
  .placeHolder{
    color: #ccc;
    padding: 0.35rem;
  }
  &:hover{
    cursor: pointer;
  }
  .input-search{
    width: 100%;
    height: 100%;
    border: none;
    padding: 0 0.25rem;
  }
  .item-choice-list{
    padding: 0.5rem 0.75rem;
    font-size: 16px;
    &:not(.no_select):hover, &.current{
      cursor: pointer;
      background: ${props=>props.theme.PrimaryExtraLight};
    }
  }
  .item-choice{
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 10px;
    padding: 0.25rem 0.5rem;
    .trash{
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background: ${props=>props.theme.NeutreSuperLight};
      color: white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      &:hover{
        cursor: pointer;
      }
      svg{
        path{
          stroke:white;
          fill:white;
        }
        margin: auto;
      }
    }
    .name{
      font-weight: bold;
      font-size: 14px;
      flex-grow: 1;
    }
   
  }
  .wrap-input{
    border: solid #ccc 1px;
    position: relative;
    border-radius: 4px;
    &.on-error{
      border: solid ${props=>props.theme.Complementary} 1px;
    }
    &.is-hight{
      border: solid ${props=>props.theme.Primary} 1px;
    }
    &:hover{
      border: solid ${props=>props.theme.PrimaryExtraDark} 1px;
    }
    &.onError{
      border-color: red;
    }
    label{
      font-size: 11px;
      position: absolute;
      padding: 0 0.25rem;
      left: 5px;
      top: 0;
      transform: translateY(-50%);
      background: white;
      color:rgba(0,0,0,0.6)
    }
    .front-input{
      width: 100%;
      padding: 0.2rem;
      display: flex;
      justify-content: start;
      align-items: center;
      .item-choice, .placeHolder{
        flex-grow: 1;
      }
      .iconPlace{
        padding: 0.25rem;
      }
    }

  }
`