import styled from "styled-components";
import {ReactNode} from "react";
import { FaCheck } from "react-icons/fa6";

interface ItemCochableCtrlProps{
    className?:string;
    children:ReactNode;
    isCurrent:boolean;
    clickOn:()=>void;
    pending:boolean;
}

const ItemCochableCtrl = (props:ItemCochableCtrlProps)=>{
    return (
        <div className={`itemCochable ${props.className} ${props.isCurrent ? "current" : ""}`} onClick={()=>props.clickOn()}>
            {props.pending &&
                <div className="pending_lieu"></div>
            }
            <div className="wrap_check_mark">
                <div className={`checkmark`}>
                    <FaCheck />
                </div>
            </div>
            <div className="wrap_content">
                <div className="in_content">
                    {props.children}
                </div>
            </div>
        </div>
    )
}

const ItemCochable = styled(ItemCochableCtrl)`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    .pending_lieu{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(255,255,255,.6);
    }
    &:hover{
        cursor: pointer;
        .wrap_check_mark .checkmark{
            background: ${props => props.theme.PrimaryExtraLight};
            color: white;
        }
        .wrap_content{
            background: ${props=>props.theme.PrimaryMegaLight};
            border:solid ${props => props.theme.PrimaryExtraLight} 1px;
            .in_content{
                border:solid 2px ${props => props.theme.PrimaryExtraLight};
            }
        }
    }
    &.current{
        .wrap_check_mark .checkmark{
            background: ${props => props.theme.Primary};
            color: white;
            svg{
                transform: scale(1);
                transition: transform 0.3s;
            }
        }
        .wrap_content{
            background: ${props=>props.theme.PrimaryMegaLight};
            border:solid ${props => props.theme.Primary} 1px;
            .in_content{
                border:solid 2px ${props => props.theme.Primary};
            }
        }
    }
    .wrap_check_mark{
        width: 60px;
        display: flex;
        justify-content: center;
        .checkmark{
            width: 26px;
            height: 26px;
            border: solid ${props => props.theme.Primary} 1px;
            border-radius: 4px;
            display: flex;
            flex-direction: column;
            align-items: center;
            svg{
                margin: auto;
                transform: scale(0);
                transition: transform 0.3s;
            }
        }
    }
    .wrap_content{
        width: 350px;
        border:solid ${props => props.theme.Neutre} 1px;
        border-radius: 4px;
        .in_content{
            border-radius: 4px;
            border:solid 2px transparent;
            padding: 0.5rem;
            width: 100%;
        }
    }
`

export default ItemCochable