import styled, {StyledComponent} from "styled-components";
import {useState} from "react";
import {Convertisseur} from "./Convertisseur";
import {CalculJournee} from "./CalculJournee";

interface TimeCalculatorCtrlProps {
    className?:string;
}
interface Menu{
    id:number;
    libelle:string;
    Component:StyledComponent<any, any>;
}
const TabMenus:Menu[]=[
    {id:1, libelle:"Convertisseur", Component:Convertisseur},
    {id:2, libelle:"Calcul journée", Component:CalculJournee},
]
const TimeCalculatorCtrl = ({className}: TimeCalculatorCtrlProps) => {
    const [monMenu, setMonMenu] = useState<Menu>(TabMenus[0])
    return (
        <div className={`time_calculator ${className}`}>
            <div className="head">
                {TabMenus.map(item=>(
                    <div className={`one_item_menu ${monMenu.id === item.id ? "selected" : ""}`} key={`M${item.id}`} onClick={()=>setMonMenu(item)}>
                        <span>{item.libelle}</span>
                    </div>
                ))}
            </div>
            <div className="wrap_content">
                <monMenu.Component/>
            </div>
        </div>
    )
}

export const TimeCalculator = styled(TimeCalculatorCtrl)`
    .head{
        display: flex;
        justify-content: flex-start;
    }
    .one_item_menu{
        padding: 0.5rem;
        &:hover{
            cursor: pointer;
            font-weight: bold;
        }
        &.selected{
            border-bottom: solid 4px ${props => props.theme.Primary};
            font-weight: bold;
        }
    }
`