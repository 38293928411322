import React, {useMemo} from "react";
import styled from "styled-components";
import {useGetCreneauxFullParams} from "../../../features/creneau/creneau.hooks";
import {useGetUsersByFonctionFAdm} from "../../../features/user/user.hooks";
import TimedChipsUser from "../../../components/atoms/TimedChips/TimedChipsUser";
import WidgetList from "../Components/WidgetList";
import {useCentreConnectedData} from "../../../services/hooks";

interface WidgetTopRemplaCtrlProps{
    TopFlop:"Top"|"Flop";
    className?:string;
    supervisor?:boolean;
}
interface OneLinRempla{
    user:User,
    nb:number,
}

const WidgetTopRemplaCtrl = ({TopFlop, className, supervisor}:WidgetTopRemplaCtrlProps)=>{
    const centre = useCentreConnectedData();
    const mToday = new Date().getMonth();
    const yToday = new Date().getFullYear();
    const dToday = new Date().getDate();
    const m6 = mToday-6 < 0 ? (mToday-6)+11 : mToday-6;
    const y6 = mToday-6 < 0 ? yToday-1 : yToday;
    const DateAfter = new Date(y6, m6, dToday);
    const Params = !supervisor ? {"creneauAt[after]":DateAfter.toISOString().slice(0,10), isAffect:1} : {"creneauAt[after]":DateAfter.toISOString().slice(0,10), isAffect:1, Centre:centre}
    const CreneauxQuery = useGetCreneauxFullParams(Params);
    const UsersQuery = useGetUsersByFonctionFAdm(1,3, supervisor ?centre :null);
    const ListRempla = useMemo(()=>{
        let list:OneLinRempla[] = []
        if(UsersQuery.data && CreneauxQuery.data){
            UsersQuery.data.map(u=>{
                const CreneauxConc = CreneauxQuery.data.filter(c=>c.UserAffected && c.UserAffected.id === u.id);
                list.push({
                    user:u,
                    nb:CreneauxConc.length
                })
            })
            if(TopFlop === 'Top'){
                list.sort((a:OneLinRempla, b:OneLinRempla)=>{
                    return a.nb > b.nb ? -1 : 1;
                })
            } else {
                list.sort((a:OneLinRempla, b:OneLinRempla)=>{
                    return a.nb > b.nb ? 1 : -1;
                })
            }
            list = list.filter((item, idx)=>idx<=5);
        }
        return list;
    }, [UsersQuery.data, CreneauxQuery.data])

    return (
        <WidgetList
            title={TopFlop+" remplaçant"}
            width={"100%"}
            height={"100%"}
            isPending={UsersQuery.isLoading || CreneauxQuery.isLoading || UsersQuery.isFetching || CreneauxQuery.isFetching}
        >
            <div className={`topflopRempla-content ${className}`}>
                {(UsersQuery.isLoading || CreneauxQuery.isLoading) ? 'chargement...':
                    <table className={`table-users-concerned`}>
                        <tbody>
                        {ListRempla.map((item:OneLinRempla, idx:number)=>(
                            <tr key={`oneUS${idx}`}>
                                <td>
                                    <TimedChipsUser
                                        Hei={"24px"}
                                        User={item.user}
                                        Action={()=>{}}
                                        backHover={"primary"}
                                        Fx={false}
                                        Border={false}
                                    />
                                </td>
                                <td>
                                    <div className={"numberOcc"}>
                                        {item.nb}
                                    </div>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                }
            </div>
        </WidgetList>
    )
}

export const WidgetTopRempla = styled(WidgetTopRemplaCtrl)`
    
`

