import {dataFetcher} from "../../services/dataGuetter/dataFetcher";


export async function getAllExports(idCentre:number|null=null):Promise<Export[]>{
    let url = `exports`
    if(idCentre){
        url+=`?Centre=${idCentre}`
    }
    const res:any = await dataFetcher(url, {method:'get'})
    return res["hydra:member"]
}

export function getOneExport(id:number):Promise<Export>{
    return dataFetcher(`exports/${id}`, {method:'get'})
}

export function addExport(datas:ExportNewFD):Promise<Export>{
    return dataFetcher(`exports`, {
        method:'post',
        body:datas
    })
}

export function trashExport(id:number):Promise<Export>{
    return dataFetcher(`exports/${id}`, {method:'delete'})
}