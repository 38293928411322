import React from "react";
import styled from "styled-components";
import {useViewport} from "../../services/providers/ViewportProvider";
import {WarningNoDesktop} from "./WarningNoDesktop";
import Login from "./Login";



export const LoginPre = ()=>{
    const { width } = useViewport();
    const breakpoint = 620;
    if(width < breakpoint){
        return <WarningNoDesktop/>
    } else {
        return <Login/>
    }
}