import styled, {useTheme} from "styled-components";
import {Oval} from "react-loader-spinner";

interface ModifyLoaderCtrlProps{
    className?:string;
}

const ModifyLoaderCtrl = (props:ModifyLoaderCtrlProps)=>{
    const theme = useTheme()
    return (
        <div className={`modifyLoader ${props.className}`}>
            <div className="wrap_spinner">
                <Oval
                    visible={true}
                    height="80"
                    width="80"
                    color={theme.Primary}
                    secondaryColor={theme.PrimaryExtraLight}
                    ariaLabel="oval-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                />
            </div>
            <div className="legend">Modification en cours</div>
        </div>
    )
}

const ModifyLoader = styled(ModifyLoaderCtrl)`
    position: absolute;
    left: 0;
    top:0;
    width: 100%;
    height: 100%;
    z-index: 8;
    background: rgba(255,255,255,0.9);
    display: flex;
    flex-direction: column;
    justify-content: center;
    .legend{
        text-align: center;
        margin-top: 12px;
        font-weight: bold;
    }
    .wrap_spinner{
        z-index: 9;
        display: flex;
        justify-content: center;
    }
`

export default ModifyLoader