import styled from "styled-components";
import OneChoiceHelp from "./OneChoiceHelp";

interface ModalHelpCtrlProps{
    className?:string;
    Infos:InfosProps;
    setValue:(Choice:Choice|string|number|null, id:string)=>void;
    choices:Choice[];
    Current:Choice|null;
    placeNoChoice?:string;
}

const ModalHelpCtrl = (props:ModalHelpCtrlProps)=>{

    return (
        <div className={`modalHelp ${props.className}`}>
            <p className="description">{props.Infos.description}</p>
            <div className="wrap_choices">
                {props.placeNoChoice &&
                    <OneChoiceHelp
                        isCurrent={!props.Current}
                        Choice={{id:0, libelle:"Aucun"}}
                        setValue={(Choice, id:string)=>props.setValue(null, id)}
                        id={props.Infos.keyProps}
                    />
                }
                {props.choices.map(item=>(
                    <OneChoiceHelp
                        key={`C${props.Infos.keyProps}${item.id}`}
                        Choice={item}
                        setValue={props.setValue}
                        isCurrent={props.Current?.id === item.id}
                        id={props.Infos.keyProps}
                    />
                ))}
            </div>
        </div>
    )
}

const ModalHelp = styled(ModalHelpCtrl)`
    .description{
        margin: 20px 0;
        text-align: left !important;
        font-weight: bold;
    }
    .wrap_choices{
        display: flex;
        flex-direction: column;
        gap: 8px;
        max-height: 60vh;
        overflow-y: auto;
        padding: 1rem;
    }
`

export default ModalHelp