import React from "react";
import styled from "styled-components";
import {TitrePage} from "../../../../assets/styles/elements";

import TimedStatsActes from "../../../../components/organisms/TimedStatsActes/TimedStatsActes";
import {useGetParamsTimed} from "../../../../hooks/useGetParamsTimed";

interface TableauCroiseDemiJoursCtrlProps{
    className?:string;
    supervisor?:boolean;
}

const TableauCroiseDemiJoursCtrl=({className, supervisor}:TableauCroiseDemiJoursCtrlProps)=>{
    const {From, To} = useGetParamsTimed()
    return (
        <div className={`statsActi ${className}`}>
            <TitrePage>Statistiques activités</TitrePage>
            <p className={'Periode'}>Nombre d'heures par acte {!From || !To ? 'Pour le mois en cours' : `du ${From.toLocaleDateString()} au ${To.toLocaleDateString()}`}</p>
            <div className={`wrap-tab`}>
                <TimedStatsActes isPresent={true} supervisor={supervisor}/>
            </div>
        </div>
    )
}

const TableauCroiseDemiJours = styled(TableauCroiseDemiJoursCtrl)`
    padding: 1rem;
    .wrap-tab{
      width: 100%;
      overflow-x: auto;
      background: white;
      border-radius: 20px;
    }
  .Periode{
    padding-left: 0.5rem;
    font-weight: bold;
    color: ${props => props.theme.Primary};
    margin-bottom: 10px;
  }
`

export default TableauCroiseDemiJours;