import styled, {DefaultTheme} from "styled-components";
import React, {useEffect, useState} from "react";

interface PlEntryCtrlProps{
    className?:string;
    children:React.ReactNode
    libelle:string;
    wD:string;
    wDNumber?:number;
    fSize?:string;
    isRestFlex?:boolean;
    colorEntry?:keyof DefaultTheme;
    isHight?:boolean;
    isRotate?:boolean;
    rotateSize?:string;
    height?:number;
    padIn?:boolean;
    isGap?:boolean;
}

const PlEntryCtrl = (props:PlEntryCtrlProps)=>{
    const [myHeight, setMyHeight] = useState(props.height ? props.height : 0);
    const [myWidth, setMyWidth]= useState( props.wDNumber ? props.wDNumber : 0);
    useEffect(() => {
        if(props.isRotate) {
            if (props.height) setMyHeight(props.height)
            if (props.wDNumber) setMyWidth(props.wDNumber)
            console.log(props.height, props.wDNumber)
        }
    }, [props.wDNumber, props.height, props.isRotate]);
    return (
        <div className={`line_entry ${props.className} ${props.isHight ? 'hight' : ''}`}>
            <div className={"lib_entry"} style={{width:(props.wD)}}>
                {(props.isRotate && myHeight>myWidth) ?
                    <div className="rotate_lib">{props.libelle}</div> :
                    <div className={`in_entry`}>
                        <span>{props.libelle}</span>
                    </div>
                }
            </div>
            <div className={`wrap_reste ${props.isRestFlex ? 'flexy' : ''} `} style={{padding:props.padIn ? "4px 4px 4px 0" : "0"}} >
                {props.children}
            </div>
        </div>
    )
}

export const PlEntry = styled(PlEntryCtrl)`
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  height: 100%;
  //border-top: solid ${props => props.theme.Primary} 1px;
  //background: yellow;
    gap:${props=>props.isGap ? "4px" : "0"};
  .wrap_reste{
    flex-grow: 1;
    
    .flexy{
      display: flex;
      flex-direction: column;
    }
    //background: red;
  }
    .rotate_lib{
        position: absolute;
        transform-origin: 50% 50%;
        width: ${props=>props.height ? (props.height - 30)+"px" : "auto"};
        transform: translate(-50%,-50%) rotate(-90deg);
        font-size: ${props=>props.rotateSize ? props.rotateSize : "24px"};
        left: 50%;
        top: 50%;
        text-align: center;
        
    }
  .lib_entry{
    //padding: 3px;
    flex-shrink: 0;
    border-right: solid ${props => props.theme.NeutreExtraLight} 1px;
      position: relative;
      background: white;
    .in_entry {
      padding: 3px;
      
      height: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      font-weight: bold;
      
      //border-radius: 8px;
      flex-shrink: 0;
      color:${props=>props.colorEntry ? props.theme[props.colorEntry] : "black"};
      font-size: ${props => props.fSize ? props.fSize : "12px"};
      span {

      }
    }
  }
`