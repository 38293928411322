import React, {createContext, useContext, useState} from "react";
import styled from "styled-components";
import {Outlet} from "react-router-dom";
import TimedHeader from "../../molecules/TimedHeader/TimedHeader";
import TimedFullSideBar from "../../molecules/TimedFullSideBar/TimedFullSideBar";
import {useModal} from "../../../hooks/useModal";
import TimedNotificationCurtain from "../../molecules/TimedNotificationsCenter/TimedNotificationCurtain";
import {UseQueryResult} from "react-query";
import TimedSideBar from "../../molecules/_TimedSideBar/TimedSideBar";
import {TimedNotificationChat} from "../TimedNotificationChat";
import {TimedContext} from "../../../main/App";

interface MainLayoutCtrlProps{
    className?:string;
    UserQuery:UseQueryResult<UserSimple, Error>
    FonctionsQuery:UseQueryResult<Fonction[], Error>,
    UsersQuery:UseQueryResult<User[], Error>,
    LieuxQuery:UseQueryResult<Lieu[], Error>
    ModulesQuery:UseQueryResult<Module[], Error>
    VuesQuery:UseQueryResult<VuePlanning[], Error>
}
export interface SideBarContext{
    setSectionOpened?:(s:Section|null)=>void;
    SectionOpened?:Section|null;
}

export const ContextSideBar = createContext<SideBarContext>({})

const MainLayoutCtrl = ({className, UserQuery, UsersQuery, LieuxQuery, VuesQuery, ModulesQuery, FonctionsQuery}:MainLayoutCtrlProps)=>{
    const {open, toggle} = useModal();
    const CTimedContext = useContext(TimedContext);
    const [sectionOpened, setSectionOpened] = useState<Section|null>(null);
    const settingMyOpened = (s:Section|null)=>{
        setSectionOpened(s);
    }
    return (
        <div className={`MainLayout ${className}`}>
            <ContextSideBar.Provider value={{setSectionOpened:settingMyOpened, SectionOpened:sectionOpened}}>
                {!CTimedContext.isFullScreen &&
                    <TimedSideBar/>
                }
                <div className={`MainLay`}>
                    {!CTimedContext.isFullScreen &&
                        <TimedHeader
                            UserQuery={UserQuery}
                            OpenNotif={open}
                            SwitchNotif={toggle}
                            FonctionsQuery={FonctionsQuery}
                            UsersQuery ={UsersQuery}
                            LieuxQuery ={LieuxQuery}
                            ModulesQuery={ModulesQuery}
                            VuesQuery={VuesQuery}
                        />
                    }
                    <div className={`MainContent`} style={{height:!CTimedContext.isFullScreen ? "calc(100% - 90px)" : "100%"}}>
                        <Outlet/>
                    </div>
                    <TimedNotificationCurtain Open={open} SwitchOpen={toggle}/>
                </div>
            </ContextSideBar.Provider>
            <TimedNotificationChat/>
        </div>
    )
}

const MainLayout = styled(MainLayoutCtrl)`
  height: 100svh;
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: stretch;
  
  .MainLay{
    overflow: hidden;
    position: relative;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    .MainContent{
      overflow-y: auto;
      //height: calc(100% - 90px);
    }
  }
`

export default MainLayout;