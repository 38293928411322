import {dataFetcher} from "../../services/dataGuetter/dataFetcher";
import {UserDocumentPage} from "../userDocument/userDocument";


export async function getPointeuseByParams(params:any|null):Promise<Pointeuse[]>{
    let TabSearch:string[] = [];
    if(params) {
        for (const [key, value] of Object.entries(params)){
            TabSearch.push(`${key}=${value}`)
        }
    }
    const search = TabSearch.length>0 ? `${TabSearch.join('&')}&pagination=false` : 'pagination=false'
    const res:any = await dataFetcher(`pointeuses?${search}`, {method:'get'})
    return res["hydra:member"];
}

export async function getPointeuseFree(page:string, Params:ParamsPointage):Promise<PointeusePage>{
    let TabSearch:string[] = [];
    for (const [key, value] of Object.entries(Params)){
        TabSearch.push(`${key}=${value}`)
    }
    let search = page;
    if(TabSearch.length>0){
        search = page+"&"+TabSearch.join('&')
    }
    const res:any = await dataFetcher(`pointeuses/free?${search}`, {method:'get'})
    const myRes:PointeusePage = {
        list: res["hydra:member"],
        views:res["hydra:view"],
        total:res["hydra:totalItems"]
    }
    return myRes;
}



