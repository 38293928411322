import {useEffect, useState} from "react";

export const useModal = ()=>{
    const [open, setOpen] = useState(false);
    useEffect(()=>{
    }, [open])
    const toggle = ()=>{
        setOpen(open=>!open);
    }

    return {open, toggle};
}


