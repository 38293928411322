import React, {lazy, Suspense} from "react";

import {Routes, Route, Navigate} from "react-router-dom";
import TimedSuspense from "../../components/atoms/TimedWaitUser/TimedSuspense";
import {MainLayoutWM} from "../../components/organisms/layouts/MainLayoutWM";
const DashboardWM = lazy(()=>import("./pages/DashboardWM/DashboardWM"));
const ConnectShadow = lazy(()=>import("./pages/ConnectShadow/ConnectShadow"));
const OnBoarding = lazy(()=>import("./pages/Onboarding/OnBoarding"));
const Fiche = lazy(()=>import('./pages/Fiche/Fiche'));
const SupportsWM = lazy(()=>import("./pages/Supports/SupportsWM"));


interface WebMasterApplicationCtrlProps{
    className?:string;
}

export const WebMasterApplication = ({className}:WebMasterApplicationCtrlProps)=>{
    return (
        <div className={`WebMasterApplication ${className}`}>
            <Suspense fallback={<TimedSuspense/>}>
                <Routes>
                    <Route element={<MainLayoutWM/>}>
                        <Route path="" element={<Navigate to="dashboard" />} />
                        <Route path="/dashboard" element={<DashboardWM/>}/>
                        <Route path="/supports/*" element={<SupportsWM/>}/>
                        <Route path="/connect_shadow" element={<ConnectShadow/>}/>
                        <Route path="/onboarding" element={<OnBoarding/>}/>
                        <Route path="/fiche" element={<Fiche/>}/>
                    </Route>
                </Routes>
            </Suspense>
        </div>
    )
}