import React from "react";
import styled from "styled-components";
import notF from "../../assets/images/404.png";
import Chat from "../../assets/images/Course.png";
import TimedButton from "../../components/atoms/TimedButton/TimedButton";
import {useNavigate} from "react-router-dom";

interface NotFoundCtrlProps{
    className?:string;
}

const NotFoundCtrl = ({className}:NotFoundCtrlProps)=>{
    const navigate = useNavigate();
    const onClickBtn = ()=>{
        navigate('/timed_application/dashboard')
    }
    return (
        <div className={`NotFoundPage ${className}`}>
            <div className={"wrap-content-not-found"}>
                <div className={`imag-n-f`}>
                    <img src={notF} alt={"page non trouvée"}/>
                </div>
                <h1>Désolé la page n'a pas été trouvée</h1>
                <div className={"wrap-btn-navigate"}>
                    <TimedButton
                        size={"sm"}
                        text={"Accueil"}
                        themeColor={"Primary"}
                        onClick={onClickBtn}
                    />
                </div>
                <div className={`imag-course`}>
                    <img src={Chat} alt={"course"}/>
                </div>
            </div>
        </div>
    )
}

const NotFound = styled(NotFoundCtrl)`
  height: 100%;
  padding: 5rem 0;
  .wrap-content-not-found{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  h1{
    margin-top: 15px;
    text-align: center;
    font-size: 24px;
  }
  .wrap-btn-navigate{
    display: flex;
    justify-content: center;
    margin: 50px auto;
  }
    .imag-n-f, .imag-course{
      padding: 1rem;
      text-align: center;
    }
`

export default NotFound;