import React, {useEffect, useState} from "react";
import WidgetMetrix from "../Components/WidgetMetrix";
import {useGetCreneauxToValide} from "../../../features/creneau/creneau.hooks";
import {useCentreConnectedData} from "../../../services/hooks";

interface WidgetCreneauxToValideCtrlProps{
    supervisor?:boolean;
}

export const WidgetCreneauxToValide = ({supervisor}:WidgetCreneauxToValideCtrlProps)=>{
    const centre = useCentreConnectedData();
    const CreneauxQuery = useGetCreneauxToValide(supervisor ? centre : null);
    const [total, setTotal] = useState(0)
    useEffect(()=>{
        if(CreneauxQuery.data){

            setTotal(CreneauxQuery.data.length);
        }
    }, [CreneauxQuery.data])
    return (
        <WidgetMetrix
            title={"Créneaux à valider"}
            redirect={"../creneaux"}
            Metrix={total}
            width={"100%"}
            isPending={CreneauxQuery.isLoading}
            isNoAlert={!CreneauxQuery.isLoading && total === 0}
            isAlert={!CreneauxQuery.isLoading && total > 0}
        />
    )
}

