import styled from "styled-components";
import {OneMonthChoice} from "../GestionHeuresSuppFull";
import {useState} from "react";

interface OneChoiceMonthCardCtrlProps{
    className?:string;
    Mois:OneMonthChoice;
    setMois:(m:OneMonthChoice)=>void;
    MoisCurrent:OneMonthChoice|null;
    wR:boolean // with result (est-ce qu'on met les résultats par mois)
}

const OneChoiceMonthCardCtrl = (props:OneChoiceMonthCardCtrlProps)=>{
    const [From, setFrom] = useState('')

    return (
        <div className={`one_month_choice ${props.className} ${props.MoisCurrent && props.MoisCurrent.id === props.Mois.id ? 'current' : ''}`} onClick={()=>props.setMois(props.Mois)}>
            <div className={`content_card`}>
                {props.Mois.libelle}
            </div>
        </div>
    )
}

export const OneChoiceMonthCard = styled(OneChoiceMonthCardCtrl)`
  font-size: 14px;
  &.current{
    .content_card {
      border-left: solid 5px ${props => props.theme.Primary};
    }
  }
  .content_card{
    padding: 1rem 0.5rem;
    border-bottom: ${props => props.theme.NeutreExtraLight} solid 1px;
    transition: transform 0.3s;
    
    &.disabled{
      color: ${props=>props.theme.PrimaryExtraLight};
    }
    &:hover{
      cursor: pointer;
      background: ${props=>props.theme.PrimaryMegaLight};
      font-weight: bold;
    }
  }
`
