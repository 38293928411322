import {useMutation, useQuery, useQueryClient, UseQueryResult} from "react-query";
import {addSupport, getAllSupport, getOneSupport, modifySupport} from "./support.services";
import {toast} from "react-toastify";


export const useAddSupport = ()=>{
    return useMutation(
        (datas:SupportFD)=>{
            return addSupport(datas);
        }, {
            onSuccess:()=>{
                toast.success("Votre demande à été prise en compte")
            }, onError:()=>{
                toast.error("un problème est survenu");
            }
        }
    )
}

export const useGetOneSupport = (id:number):UseQueryResult<Support, Error>=>{
    return useQuery(["support", id], ()=>getOneSupport(id))
}

export const useGetAllSupports = ():UseQueryResult<Support[], Error>=>{
    return useQuery(["supports"], ()=>getAllSupport())
}

export const useModifySupport = ()=>{
    const queryClient = useQueryClient();
    return useMutation((datas:ModifySupport)=>{
        return modifySupport(datas)
    }, {
        onSuccess:(newVersion:Support)=>{
            queryClient.invalidateQueries("supports");
            queryClient.setQueryData(["support", newVersion.id], newVersion)
            toast.success("Support modifié")
        }, onError:()=>{
            toast.error("Une erreur est survenue")
        }
    })
}