import styled from "styled-components";
import {IoMdHelpCircleOutline} from "react-icons/io";

interface BtnOpenHelpCtrlProps{
    className?:string;
    clickOn:()=>void;
}

const BtnOpenHelpCtrl = (props:BtnOpenHelpCtrlProps)=>{
    return (
        <button className={`btnOpenHelp ${props.className}`} onClick={()=>props.clickOn()}>
            <IoMdHelpCircleOutline />
        </button>
    )
}

const BtnOpenHelp = styled(BtnOpenHelpCtrl)`
    width: 32px;
    height: 32px;
    background: transparent;
    border: none;
    display: flex;
    flex-direction: column;
    font-size: 22px;
    color:${props => props.theme.Dark};
    svg{
        margin: auto;
    }
    &:hover{
        cursor: pointer;
    }
`

export default BtnOpenHelp