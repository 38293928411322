import React from "react";
import styled, {useTheme} from "styled-components";
import {Oval} from "react-loader-spinner";
import logoShort from "../../../assets/images/Logo-Small-Timed-Light.png";

interface TimedSkeletonLogoCtrlProps{
    className?:string;
    Text?:string;
    width?:string;
    height?:string;
}

const TimedSkeletonLogoCtrl = ({className, Text}:TimedSkeletonLogoCtrlProps)=>{
    const theme = useTheme();
    return (
        <div className={`TimedSkeletonBI ${className}`}>
            <div className={`ske-in`}>
                <div className={`spinner-place`}>
                    <Oval
                        height={120}
                        width={120}
                        color={theme.Primary}
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel='Chargement'
                        secondaryColor={theme.PrimaryLight}
                        strokeWidth={2}
                        strokeWidthSecondary={2}

                    />
                </div>
                <div className={`Icon-place`}>
                    <img src={logoShort} alt={"logo"}/>
                </div>
                {Text &&
                    <div className={"text-place"}>{Text}</div>
                }
            </div>
        </div>
    )
}

const TimedSkeletonLogo = styled(TimedSkeletonLogoCtrl)`
    width: ${props => props.width ? props.width : "300px"};
    height: ${props => props.height ? props.height : "300px"};
    position: relative;
    .ske-in{
      position: absolute;
      left: 50%;
      top: 50%;
      width: 120px;
      height: 120px;
      transform: translate(-50%,-50%);
      .Icon-place{
        position: absolute;
        left: 50%;
        top: 56%;
        transform: translate(-50%,-50%);
        font-size: 32px;
        img{
          width: 120px;
          height: auto;
        }
      }
    }
`

export default TimedSkeletonLogo;