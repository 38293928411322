import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {useGetCompteurTempsDateDate} from "../../../../../features/compteurTempsDateDate/compteurTempsDateDate.hooks";
import {useCentreConnectedData} from "../../../../../services/hooks";

interface TimedMetrixHebdoCtrlProps{
    className?:string;
    dateFrom:string;
    dateTo:string;
    user:User|UserSimple;
    supervisor?:boolean
    NoMarginLeft?:boolean
}

const TimedMetrixHebdoCtrl = ({className, dateFrom, dateTo, user, supervisor}:TimedMetrixHebdoCtrlProps)=>{
    const centre = useCentreConnectedData();
    const CtpsQuery = useGetCompteurTempsDateDate(dateFrom, dateTo, [user.id], supervisor ? centre : null);
    const [totalToDo, setTotalToDo] = useState<number>(0)
    const [totalDid, setTotalDid] = useState<number>(0)
    const [totalDidRelevees, setTotalDidRelevees] = useState<number>(0)
    const [totalDidPlanning, setTotalDidPlanning] = useState<number>(0)
    const [totalBrouillons, setTotalBrouillons] = useState<number>(0)
    const [totalRegules, setTotalRegules] = useState<number>(0)
    const [bars, setBars] = useState<OneBarTime[]>([]);
    const [weekCurrent, setWeekCurrent] = useState(false)
    const [widthTotal, setWidthTotal] = useState(0);
    const refLine = useRef<HTMLDivElement>(null);
    useEffect(()=>{
        const Today = new Date();
        if(new Date(dateFrom) <=Today && new Date(dateTo)>=Today) {
            setWeekCurrent(true);
        } else {
            setWeekCurrent(false);
        }
    }, [dateFrom, dateTo])
    useEffect(()=>{
        if(refLine.current){
            const elt = refLine.current.getBoundingClientRect();
            setWidthTotal(elt.width);
        } else {
            setWidthTotal(0);
        }
    }, [dateFrom, dateTo, refLine,bars])
    useEffect(()=>{
        const MethodCalc = ()=>{
            if(refLine.current){
                const elt = refLine.current.getBoundingClientRect();
                setWidthTotal(elt.width);
            } else {
                setWidthTotal(0);
            }
        }
        window.addEventListener('resize', MethodCalc);
        return ()=>{
            window.removeEventListener('resize', MethodCalc);
        }
    }, [refLine, dateFrom, dateTo])
    useEffect(()=>{
        if(CtpsQuery.data){
            const myTotalToDo = CtpsQuery.data.reduce((acc:number, item:CompteurTempsDateDate)=>acc+item.heuresAFaire, 0);
            const myTotalDid = CtpsQuery.data.reduce((acc:number, item:CompteurTempsDateDate)=>acc+(item.heuresFaites+item.heuresNoW), 0);
            setTotalDidRelevees( Math.round(CtpsQuery.data.reduce((acc:number, item:CompteurTempsDateDate)=>acc+(item.heuresFaitesRelevees+item.heuresNoWRelevees), 0)*100)/100);
            setTotalDidPlanning( Math.round(CtpsQuery.data.reduce((acc:number, item:CompteurTempsDateDate)=>acc+(item.heuresFaitesPlanning+item.heuresNoWPlanning), 0)*100)/100);
            setTotalBrouillons( Math.round(CtpsQuery.data.reduce((acc:number, item:CompteurTempsDateDate)=>acc+(item.heuresBrouillons), 0)*100)/100);
            setTotalRegules( Math.round(CtpsQuery.data.reduce((acc:number, item:CompteurTempsDateDate)=>acc+(item.regules), 0)*100)/100);
            setTotalToDo(Math.round(myTotalToDo*100)/100);
            setTotalDid(Math.round(myTotalDid*100)/100);
            let listBars:OneBarTime[] = [];
            CtpsQuery.data.map(cpt=>{
                const start = new Date(cpt.start);
                const end = new Date(cpt.end);
                const nbDays = (end.getTime() - start.getTime())/86400000 + 1;
                listBars.push({
                    type:cpt.periode.type,
                    width:(nbDays/7*100)+'%',
                    widthNB:nbDays/7,
                    solde:Math.round(cpt.Solde*100)/100,
                    soldeIn:Math.round(cpt.soldeIn*100)/100,
                    soldeOut:Math.round(cpt.soldeOut*100)/100,
                    heuresAFaire:Math.round(cpt.heuresAFaire*100)/100,
                    heuresFaites:Math.round((cpt.heuresFaites+cpt.heuresNoW)*100)/100,
                    PausePlanning:Math.round((cpt.PausePlanning)*100)/100,
                    PauseRelevee:Math.round((cpt.PauseRelevee)*100)/100,
                    heuresFaitesNet:Math.round((cpt.heuresFaites+cpt.heuresNoW - cpt.PausePlanning - cpt.PauseRelevee)*100)/100,

                    heuresPlanning:Math.round((cpt.heuresFaitesPlanning+cpt.heuresNoWPlanning - cpt.PausePlanning)*100)/100,
                    heuresPlanningNet:Math.round((cpt.heuresFaitesPlanning+cpt.heuresNoWPlanning)*100)/100,
                    heuresReleveesNet:Math.round((cpt.heuresFaitesRelevees+cpt.heuresNoWRelevees - cpt.PauseRelevee)*100)/100,
                    heuresRelevees:Math.round((cpt.heuresFaitesRelevees+cpt.heuresNoWRelevees)*100)/100,

                    start:start,
                    end:end,
                })
                return cpt
            })
            setBars(listBars);
        }
    }, [CtpsQuery.data])
    return (
        <div className={`TimedMetrixHebdo ${className}`}>
            {CtpsQuery.isLoading ? '...':
                <>

                    <div className={`line-periodes`} ref={refLine}>
                        {bars.map((item:OneBarTime, idx:number)=>(
                            <div className={`one-bar-periode`} key={`barperiod${idx}`} style={{width:item.width}}>
                                <div className={`wrapper-ct ${item.widthNB*widthTotal < 450 ? 'is_out' : 'is_in'}`}>
                                    <div className={`wrappy-ct`} style={{width:`${item.heuresFaitesNet>item.heuresAFaire ? '100%' : (item.heuresFaitesNet/item.heuresAFaire*100)+'%'}`}}/>
                                    <div className={`solde nb-ct ${item.widthNB*widthTotal < 450 && widthTotal!==0 ? 'is_out' : 'is_in'}`}>
                                        <span>Contrat {item.heuresAFaire}h </span><span>  Relevées : {item.heuresReleveesNet}h </span><span> Planning : {item.heuresPlanningNet}h </span><span> Projeté : {item.heuresFaitesNet}h</span>
                                    </div>
                                </div>
                                {item.soldeIn!==0 &&
                                    <div className={`solde-in`}>{item.soldeIn}h</div>
                                }

                                <div className={`solde-out`}>{item.soldeOut}h</div>
                                <div className={`solde_ecart`}>
                                    Ecart : <span>{item.heuresFaitesNet-item.heuresAFaire>0 ? "+" : ""}{Math.round((item.heuresFaitesNet-item.heuresAFaire)*100)/100}h</span>
                                    {totalRegules !== 0 &&
                                        <span> // avec Régules ({totalRegules>0 ? "+" : ""}{Math.round(totalRegules*100)/100}) : {(item.heuresFaitesNet + totalRegules)-item.heuresAFaire>0 ? "+" : ""}{Math.round(((item.heuresFaitesNet + totalRegules)-item.heuresAFaire)*100)/100}h</span>
                                    }
                                </div>
                            </div>
                        ))}
                    </div>
                    {bars.length>1 &&
                    <div className={`line-rapport-hebdo`}>
                        <div className={`wrapper-ct`}>
                            <div className={`wrappy-ct`} style={{width:`${totalDid>totalToDo ? '100%' : (totalDid/totalToDo*100)+'%'}`}}/>
                            {/*<div className={`nb-ct`}>{totalDid}h / {totalToDo}h</div>*/}
                            <div className={`nb-ct`}>Contrat {totalToDo}h -  Relevées : {totalDidRelevees}h - Planning : {totalDidPlanning}h</div>
                        </div>
                    </div>
                    }
                    {/*{totalBrouillons>0 &&*/}
                    {/*    <div className={`line-rapport-hebdo`}>*/}
                    {/*        <div className={`wrapper-ct`}>*/}
                    {/*            <div className={`wrappy-ct`} style={{width:`${totalBrouillons>totalToDo ? '100%' : (totalBrouillons/totalToDo*100)+'%'}`}}/>*/}
                    {/*            /!*<div className={`nb-ct`}>{totalDid}h / {totalToDo}h</div>*!/*/}
                    {/*            <div className={`nb-ct`}>Contrat {totalToDo}h -  Brouillons : {totalBrouillons}h - Planning-Brouillons : {totalDidPlanning+totalBrouillons}h</div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*}*/}
                </>
            }
        </div>
    )
}

export const TimedMetrixHebdo = styled(TimedMetrixHebdoCtrl)`
  width: 100%;
  margin-top: 10px;
  margin-bottom: 5px;
  .wrapper-ct{
    width: 100%;
    background: ${props => props.theme.NeutreMegaLight};
    border: solid 1px ${props => props.theme.BodyBackground};
    height: 22px;
    border-radius: 4px;
    overflow: hidden;
    position: relative;
    box-shadow: 1px 1px 13px 2px rgba(0,0,0,.05) inset, -1px -1px 13px  2px rgba(0,0,0,.05) inset;
    .nb-ct.is_out{
      display: none;
    }
    &.is_out:hover{
      overflow: visible;
      .nb-ct.is_out{
        display: block;
      }
    }
  }
  .wrappy-ct{
    position: absolute;
    box-shadow: 1px 1px 3px rgba(0,0,0,.2);
    background: ${props=>props.theme.PrimaryExtraLight};
    left: 0;
    top: 0;
    height: 100%;
    z-index: 1;
    border-radius: 4px;
    transition: width 0.3s;
  }
    .line-rapport-hebdo{
      width: 100%;
      //padding-left: 32px;
      margin: 5px auto;
      
    }
  .line-periodes{
    padding-top: 25px;
    width: calc(100% - 32px);
    margin-left: ${props=>props.NoMarginLeft ? "0" : "32px"};
    display: flex;
    justify-content: flex-start;
  }
  .one-bar-periode{
    height: 22px;
    border-radius: 4px;
    border: solid 1px ${props => props.theme.BodyBackground};
    position: relative;
    display: flex;
    justify-content: center;
    box-shadow: 1px 1px 13px 2px rgba(0,0,0,.05) inset, -1px -1px 13px  2px rgba(0,0,0,.05) inset;
  }
  .nb-ct{
    position: absolute;
    left: 50%;
    top: 50%;
    font-size: 13px;
    transform: translate(-50%,-50%);
    z-index: 2;
    font-weight: bold;
    width: 100%;
    text-align: center;
    span{
      display: inline-block;
      margin-right: 6px;
      &:not(:last-child):after{
        content:' - ';
      }
    }
    &.is_out{
      span{
        display: block;
        margin-right: 0px;
        &:after{
          content:'';
        }
      }
      left: 3%;
      transform: none;
      top: 105%;
      width: 100px;
      padding: 0.25rem 0.5rem;
      background: white;
      box-shadow: 1px 1px 3px rgba(0,0,0,.2);
      border-radius: 4px;
      z-index: 9;
    }
  }
  .solde-in{
    position: absolute;
    left: 0;
    font-size: 13px;
    top:-14px;
    transform: translateY(-100%);
    font-weight: bold;
    &:after{
      content: '';
      position: absolute;
      left: 0;
      top: 100%;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 12px 6px 0 6px;
      border-color: ${props=>props.theme.Secondary} transparent transparent transparent;
    }
  }
  .solde_ecart{
    position: absolute;
    left: 50%;
    font-size: 13px;
    top:-14px;
    transform: translate(-50%, -100%);
    font-weight: bold;
    text-align: center;
    &:after{
      content: '';
      position: absolute;
      left: 0;
      top: 100%;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 12px 6px 0 6px;
      border-color: white transparent transparent transparent;
    }
  }
  .solde-out{
    position: absolute;
    right: 0;
    font-size: 13px;
    font-weight: bold;
    top:-14px;
    transform: translateY(-100%);
    &:after{
      content: '';
      position: absolute;
      right: 0;
      top: 100%;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 12px 6px 0 6px;
      border-color: ${props=>props.theme.Secondary} transparent transparent transparent;
    }
  }
`