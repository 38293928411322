import styled from "styled-components";
import {ReactNode} from "react";

interface RightCardCtrlProps{
    className?:string;
    children:ReactNode;
    isCurrent?:boolean;
    ClickOn?:()=>void;
}

const RightCardCtrl = (props:RightCardCtrlProps)=>{
    const ClickOnIt = ()=>{
        if(props.ClickOn){
            props.ClickOn();
        }
    }
    return (
        <div className={`rightCard ${props.className} ${props.isCurrent ? "current" : ""}`} onClick={ClickOnIt}>
            <div className="in_right">
                {props.children}
            </div>
        </div>
    )
}

const RightCard = styled(RightCardCtrl)`
    flex-grow: 1;
    border:solid ${props=>props.theme.Primary} 1px;
    border-radius: 4px;
    .in_right{
        padding: 0.25rem 0.5rem;
        border-radius: 4px;
        display: flex;
        justify-content: flex-start;
        gap: 10px;
        align-items: center;
        font-weight: normal;
        
    }
    &.current, &:hover{
        cursor: pointer;
        background: ${props=>props.theme.PrimaryMegaLight};
        .in_right{
            border:solid 2px ${props=>props.theme.Primary};
            font-weight: bold;
        }
        
    }
    .in_right{
        width: 100%;
        border:solid 2px transparent;
    }
    
`

export default RightCard