import React, {useEffect, useState} from "react";
import {useGetCreneauxFuture} from "../../../features/creneau/creneau.hooks";
import {WidgetJauge} from "../Components/WidgetJauge";
import {useCentreConnectedData} from "../../../services/hooks";

interface WidgetRationPrisCtrlProps{
    supervisor?:boolean;
}

export const WidgetRationPris = ({supervisor}:WidgetRationPrisCtrlProps)=>{
    const centre = useCentreConnectedData();
    const CreneauxQuery = useGetCreneauxFuture(supervisor ? centre : null);
    const [ratio, setRatio] = useState(0)
    useEffect(()=>{
        if(CreneauxQuery.data){
            const CrenPris = CreneauxQuery.data.filter(c=>c.UserAffected!==null);
            setRatio(CrenPris.length/CreneauxQuery.data.length);
        }
    }, [CreneauxQuery.data])

    return (
        <WidgetJauge isPending={CreneauxQuery.isLoading || CreneauxQuery.isFetching} title={"Ratio créneaux pris"} percent={ratio}/>
    )
}