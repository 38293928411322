import {useMutation, useQuery, useQueryClient, UseQueryResult} from "react-query";
import {getAllLieuHorairesByCentre, getAllLieuHorairesByLieu, putLieuHoraires} from "./lieuHoraires.services";

export const useGetAllLieuHorairesByLieu = (id:number):UseQueryResult<LieuHoraires[], Error>=>{
    return useQuery(["lieu_horaires", id], ()=>getAllLieuHorairesByLieu(id), {
        enabled:id!==0
    })
}

export const useGetAllLieuHorairesByCentre = (id:number):UseQueryResult<LieuHoraires[], Error>=>{
    return useQuery(["lieu_horaires_centre", id], ()=>getAllLieuHorairesByCentre(id), {
        enabled:id!==0
    })
}

export const usePutLieuHoraires = ()=>{
    const queryClient = useQueryClient();
    return useMutation(
        (datas:LieuHoraireFD)=>{
            return putLieuHoraires(datas)
        }, {
            onSuccess:()=>{
                queryClient.invalidateQueries(["lieu_horaires"])
                queryClient.invalidateQueries(["lieu_horaires_centre"])
            }
        }
    )
}
