import styled from "styled-components";
import {OneLineReso} from "../TimedApplication/TempsActivites/GestionHeuresSupp/lineReso";
import {useEffect, useMemo, useRef, useState} from "react";
import TimedButton from "../../components/atoms/TimedButton/TimedButton";
import {IoWarningOutline} from "react-icons/io5";
import {useGetMotifsReguleTpsByCentre} from "../../features/motifReguleTemps/motifReguleTemps.hooks";
import {useAddReguleTemps} from "../../features/pointeuseTreat/pointeuseTreat.hooks";
import {useGetCptAbsHeureUser} from "../../features/compteurAbs/compteurAbs.hooks";
import {useAddReguleAcqui} from "../../features/acquisitionAbs/acquisitionAbs.hooks";
import {TabMois} from "../../services/constantes/constantes";
import TimedChips from "../../components/atoms/TimedChips/TimedChips";
import {useGetCentreActuel} from "../../features/centre/centre.hooks";

interface FormResolutionHeuresSuppCtrlProps{
    className?:string;
    From:Date,
    To:Date,
    Line:OneLineReso|null;
    closeModal:()=>void;
}

interface OneChoixPaie{
    id:number;
    libelle:string;
    dateTo:Date;
    locale:string;
}
const DayLimitForPaie = 17;

const FormResolutionHeuresSuppCtrl = (props:FormResolutionHeuresSuppCtrlProps)=>{
    const mutation = useAddReguleTemps();
    const mutationACQ = useAddReguleAcqui();
    const CentreQuery = useGetCentreActuel();
    const [idCentre, setIdCentre] = useState(0)
    const MotifTpsQuery = useGetMotifsReguleTpsByCentre(idCentre);
    const CptrsAbsQuery = useGetCptAbsHeureUser(props.Line ? props.Line.id : 0, props.From.toISOString().slice(0,10))
    const [heuresAbandon, setHeuresAbandon] = useState<number>(0);
    const [heuresSupp, setHeuresSupp] = useState<number>(0);
    const [heuresReport, setHeuresReport] = useState<number>(0);
    const [heuresCptHeures, setheuresCptHeures] = useState<number>(0);
    const [soldecurrent, setSoldeCurrent] = useState<number>(props.Line ? props.Line.ecart_nb : 0)
    const refAbandon = useRef<HTMLInputElement>(null)
    const refPaid = useRef<HTMLInputElement>(null)
    const refReport = useRef<HTMLInputElement>(null)
    const refHeureCpt = useRef<HTMLInputElement>(null)
    const [PeriodeAbsSelected, setPeriodeAbsSelected] = useState<PeriodeAbs|null>(null)
    const refSelectCptHeures = useRef<HTMLSelectElement>(null);
    const [choixPaies, setChoixPaies] = useState<OneChoixPaie[]>([]);
    const [choixPaie, setChoixPaie] = useState<OneChoixPaie|null>(null);
    useEffect(() => {
        if(CentreQuery.data) setIdCentre(CentreQuery.data.id)
    }, [CentreQuery.data]);
    useEffect(()=>{
        if(props.Line){
            const myChoixPaies:OneChoixPaie[] = [];
            const Today = new Date(new Date().toISOString().slice(0,10)+" 12:00:00");
            const jour = Today.getDate();
            const year = Today.getFullYear();
            const month = Today.getMonth();
            const dateFirst = new Date(year, month, 1);
            const MoisStrFirst = TabMois.find(t=>t.numEn === month);
            myChoixPaies.push({
                id:0,
                libelle:`${MoisStrFirst ? MoisStrFirst.name : ''} ${year}`,
                dateTo:dateFirst,
                locale:dateFirst.toLocaleDateString()
            })
            if(jour>=DayLimitForPaie && month!==4){
                const yearTo = month === 11 ? year+1 : year;
                const monthTo = month === 11 ? 0 : month+1;
                const datePlus = new Date(yearTo, monthTo, 1);
                datePlus.setHours(12,0,0);
                const MoisStrplus = TabMois.find(t=>t.numEn === monthTo);
                myChoixPaies.push({
                    id:1,
                    libelle:`${MoisStrplus ? MoisStrplus.name : ''} ${year}`,
                    dateTo:datePlus,
                    locale:datePlus.toLocaleDateString()
                })
            }
            setChoixPaies(myChoixPaies);
            if(props.Line.ecart_nb>0){
                setChoixPaie(myChoixPaies[0]);
            }
        }
    }, [props.Line])
    const PeriodesAbsList = useMemo(()=>{
        const list:PeriodeAbs[] = [];
        if(CptrsAbsQuery.data){
            CptrsAbsQuery.data.forEach(cpt=>{
                const Periodes = cpt.Periodes;
                Periodes.forEach(p=>list.push(p));
            })
        }
        return list;
    }, [CptrsAbsQuery.data])
    useEffect(()=>{
        if(props.Line && props.Line.ecart_nb <0){
            setSoldeCurrent(Math.round((Math.abs(props.Line.ecart_nb) - heuresAbandon - heuresReport - heuresCptHeures)*100)/100)
        } else if(props.Line && props.Line.ecart_nb > 0){
            setSoldeCurrent(Math.round((Math.abs(props.Line.ecart_nb) - heuresSupp - heuresReport - heuresCptHeures)*100)/100)
        }
    }, [heuresSupp, heuresAbandon, heuresReport, heuresCptHeures, props.Line])
    const handleChangeAbandon = ()=>{
        if(refAbandon.current){
            setHeuresAbandon(parseFloat(refAbandon.current.value));
        }
    }
    const handleChangePaid = ()=>{
        if(refPaid.current){
            setHeuresSupp(parseFloat(refPaid.current.value));
        }
    }
    const handleChangeReport = ()=>{
        if(refReport.current){
            setHeuresReport(parseFloat(refReport.current.value));
        }
    }
    const handleChangeHeureCpt = ()=>{
        if(refHeureCpt.current){
            setheuresCptHeures(parseFloat(refHeureCpt.current.value));
        }
    }
    const SolderAbandon = ()=>{
        if(props.Line){
            setHeuresAbandon(Math.abs(props.Line.ecart_nb) - heuresReport - heuresCptHeures)
        }

    }
    const SolderPaid = ()=>{
        if(props.Line){
            setHeuresSupp(Math.abs(props.Line.ecart_nb) - heuresReport - heuresCptHeures)
        }

    }
    const SolderReport = ()=>{
        if(props.Line && props.Line.ecart_nb <0){
            if(refAbandon.current && props.Line){
                setHeuresReport(Math.abs(props.Line.ecart_nb) - parseFloat(refAbandon.current.value) - heuresCptHeures)
            }
        } else if(props.Line) {
            if(refPaid.current && props.Line){
                setHeuresReport(Math.abs(props.Line.ecart_nb) - parseFloat(refPaid.current.value) - heuresCptHeures)
            }
        }

    }
    const SolderCompteur = ()=>{
        if(props.Line){
            setheuresCptHeures(Math.abs(props.Line.ecart_nb) - heuresReport - heuresAbandon - heuresSupp)
        }
    }
    const Sender = ()=>{
        if(props.Line){
            const MotifNoReport = props.Line.ecart_nb <0 ? MotifTpsQuery.data?.find(m=>m.id === 4) : MotifTpsQuery.data?.find(m=>m.id === 3)
            const MotifReportNeg = MotifTpsQuery.data?.find(m=>m.id === 6)
            const MotifReportPos = MotifTpsQuery.data?.find(m=>m.id === 5)
            const TabRegules:ReguleTpsFD[] = [];
            if(heuresCptHeures>0){
                const myDatas:PostAcquisitionAbs={
                    User:`/api/users/${props.Line.id}`,
                    acquisitionAt:props.To.toISOString().slice(0,10),
                    TypeAcquisitionAbsence:`/api/type_acquisition_absences/3`,
                    qty:heuresCptHeures,
                    MotifRegulation:'Résolution des heures',
                    TypePeriode:`/api/type_periodes/${PeriodeAbsSelected?.Periode.Type.id}`
                }
                TabRegules.push({
                    User:`/api/users/${props.Line.id}`,
                    dateAT:props.To.toISOString().slice(0,10),
                    MotifReguleTemps:`/api/motif_regule_temps/${MotifReportNeg ? MotifReportNeg.id : 0}`,
                    timeFinal:heuresCptHeures,
                    timeSystem:heuresCptHeures,
                    comentFinalHour:"Contre acquisition dans cpt - résolution"
                })
                mutationACQ.mutate(myDatas, {
                    onSuccess:()=>{
                        props.closeModal();
                    }
                })
            }
            if(heuresAbandon>0){
                TabRegules.push({
                    User:`/api/users/${props.Line.id}`,
                    dateAT:props.To.toISOString().slice(0,10),
                    MotifReguleTemps:`/api/motif_regule_temps/${MotifNoReport ? MotifNoReport.id : 0}`,
                    timeFinal:heuresAbandon,
                    timeSystem:heuresAbandon,
                    comentFinalHour:"Abandon heures résolution"
                })
            }
            if(heuresSupp >0 ){
                let AddSupp:ReguleTpsFD = {
                    User:`/api/users/${props.Line.id}`,
                    dateAT:props.To.toISOString().slice(0,10),
                    MotifReguleTemps:`/api/motif_regule_temps/${MotifNoReport ? MotifNoReport.id : 0}`,
                    timeFinal:heuresSupp,
                    timeSystem:heuresSupp,
                    comentFinalHour:"Paiement heures S résolution"
                }
                if(choixPaie){
                    AddSupp.dateTo = choixPaie.dateTo.toISOString().slice(0,10);
                }
                TabRegules.push(AddSupp)
            }
            if(heuresReport>0){
                const newDate = new Date(props.To.getTime() + 86_400_000*7)
                const MotifA = props.Line.ecart_nb <0 ? (MotifReportPos ? MotifReportPos.id :0) : (MotifReportNeg ? MotifReportNeg.id : 0)
                const MotifB = props.Line.ecart_nb >0 ? (MotifReportPos ? MotifReportPos.id :0) : (MotifReportNeg ? MotifReportNeg.id : 0)
                TabRegules.push({
                    User:`/api/users/${props.Line.id}`,
                    dateAT:props.To.toISOString().slice(0,10),
                    MotifReguleTemps:`/api/motif_regule_temps/${MotifA}`,
                    timeFinal:heuresReport,
                    timeSystem:heuresReport,
                    comentFinalHour:"Report heure résolution"
                })
                TabRegules.push({
                    User:`/api/users/${props.Line.id}`,
                    dateAT:newDate.toISOString().slice(0,10),
                    MotifReguleTemps:`/api/motif_regule_temps/${MotifB}`,
                    timeFinal:heuresReport,
                    timeSystem:heuresReport,
                    comentFinalHour:"Report heure résolution"
                })
            }
            TabRegules.forEach(dataR=>{
                mutation.mutate(dataR, {
                    onSuccess:()=>{
                        props.closeModal();
                    }
                })
            })

        }
    }
    const setFxChoixPaie = (choix:Choice)=>{
        const myChoixPaie = choixPaies.find(c=> c.id === choix.id);
        if(myChoixPaie){
            setChoixPaie(myChoixPaie);
        }
    }
    const ChangeCptAbs = ()=>{
        if(refSelectCptHeures.current){
            const idRefSel = refSelectCptHeures.current ? parseInt(refSelectCptHeures.current.value) : 0
            const PeriodeAbsFind = PeriodesAbsList.find(p=>p.Periode.id === idRefSel)
            if(PeriodeAbsFind){
                setPeriodeAbsSelected(PeriodeAbsFind);
            } else {
                setPeriodeAbsSelected(null);
            }
        }
    }
    if(!props.Line){
        return null;
    } else {
        return (
            <div className={`reso_hs ${props.className}`}>
                {props.Line.ecart_nb<0 ?
                    <p>L'écart de <span className={`infos_heures ${props.Line.ecart_nb<0 ? 'warning' : 'success'}`}>{props.Line.ecart_nb}</span> étant négatif vous pouvez abandonner les heures ou les reporter sur la prochaine période</p>
                    :
                    <p>L'écart de {props.Line.ecart_nb} étant positif vous pouvez payer les heures supplémentaires ou les reporter sur la prochaine période</p>
                }
                <div className={`solde_current`}><strong>Solde à répartir</strong>: <span>{Math.round(soldecurrent*100)/100}</span></div>
                {soldecurrent<0 &&
                    <div className={`error_solde`}>
                        <IoWarningOutline/>
                        <span>Le solde final ne peut pas être inférieur à 0</span>
                        <IoWarningOutline/>
                    </div>
                }
                <div className={`load_balancing`}>
                    <div className={"column_balancing"}>
                        {props.Line.ecart_nb<0 ?
                            <>
                                <div className={`titre_col`}>Heures abandonnées</div>
                                <div className={`wrap_input_hours`}>
                                    <input ref={refAbandon} step={0.01} type={"number"} value={heuresAbandon} onChange={handleChangeAbandon} disabled={mutation.isLoading}/>
                                </div>
                                {soldecurrent>0 && (Math.abs(props.Line.ecart_nb) - heuresAbandon - heuresReport - heuresCptHeures)!==0 && !mutation.isLoading &&
                                    <TimedButton themeColor={"Primary"} size={"xs"} text={heuresReport > 0 ? "Equilibrer" : "Tout abandonner"} onClick={SolderAbandon} isFull/>
                                }
                            </>
                        :
                            <>
                                <div className={`titre_col`}>Heures payées</div>
                                <div className={`wrap_input_hours`}>
                                    <input ref={refPaid} type={"number"} step={0.01} value={heuresSupp} onChange={handleChangePaid} disabled={mutation.isLoading}/>
                                </div>
                                {soldecurrent>0 && (Math.abs(props.Line.ecart_nb) - heuresSupp - heuresReport - heuresCptHeures)!==0 && !mutation.isLoading &&
                                    <TimedButton themeColor={"Primary"} size={"xs"} text={(heuresReport + heuresReport) > 0 ? "Equilibrer" : "Tout payer"} onClick={SolderPaid} isFull/>
                                }
                            </>
                        }
                    </div>
                    <div className={`column_balancing`}>
                        <div className={`titre_col`}>Heures reportées</div>
                        <div className={`wrap_input_hours`}>
                            <input ref={refReport} type={"number"} step={0.01} value={heuresReport} onChange={handleChangeReport} disabled={mutation.isLoading}/>
                        </div>
                        {(((props.Line.ecart_nb<0 && Math.abs(props.Line.ecart_nb) - heuresAbandon - heuresReport - heuresCptHeures !==0) || (props.Line.ecart_nb>0 && Math.abs(props.Line.ecart_nb) - heuresSupp - heuresReport - heuresCptHeures!==0))) && !mutation.isLoading &&
                            <TimedButton themeColor={"Primary"} size={"xs"} text={props.Line.ecart_nb<0 ? ((heuresAbandon + heuresReport + heuresCptHeures)>0 ? 'Equilibrer' : 'Tout reporter') : (heuresSupp+heuresCptHeures>0 ? 'Equilibrer' : 'Tout reporter')} onClick={SolderReport} isFull/>
                        }
                    </div>
                    {CptrsAbsQuery.data && PeriodesAbsList.length >0 && props.Line.ecart_nb>0 &&
                        <div className={`column_balancing`}>
                            <div className={`titre_col`}>
                                <div className={`wrap_select_cpt_heures`}>
                                    <select id={"cptr_heure"} ref={refSelectCptHeures} value={PeriodeAbsSelected ? PeriodeAbsSelected.Periode.id : 0} onChange={ChangeCptAbs}>
                                        <option value={0}>choix Compteur</option>
                                        {PeriodesAbsList.map((item)=>(
                                            <option key={`one_option_p${item.Periode.id}`} value={item.Periode.id}>{item.Periode.libelle}</option>
                                        ))}
                                    </select>
                                    {PeriodeAbsSelected &&
                                        <div className={"solde_cpt"}>Dispo : {PeriodeAbsSelected.Solde}</div>
                                    }
                                </div>
                            </div>
                            <div className={`wrap_input_hours`}>
                                <input ref={refHeureCpt} type={"number"} step={0.01} value={heuresCptHeures} onChange={handleChangeHeureCpt} disabled={mutation.isLoading || !PeriodeAbsSelected}/>
                            </div>
                            {(((props.Line.ecart_nb>0 && Math.abs(props.Line.ecart_nb) - heuresSupp - heuresReport - heuresCptHeures!==0))) && !mutation.isLoading && PeriodeAbsSelected &&
                                <TimedButton themeColor={"Primary"} size={"xs"} text={props.Line.ecart_nb<0 ? ((heuresAbandon + heuresReport)>0 ? 'Equilibrer' : 'Tout prendre sur le compteur') : ((heuresSupp + heuresReport)>0 ? 'Equilibrer' : 'Tout épargner')} onClick={SolderCompteur} isFull/>
                            }
                        </div>
                    }
                </div>
                {props.Line && props.Line.ecart_nb >0 && heuresSupp>0 &&
                    <div className={`wrapper_choix_to`}>
                        <div className={"title_choix"}>Merci de choisir le mois de paiement</div>
                        <div className={`wrapper_actif_choix_to`}>
                            {choixPaies.map(item=>(
                                <TimedChips
                                    key={`OcT${item.id}`}
                                    themeColor={"Primary"}
                                    setChoice={setFxChoixPaie}
                                    MyChoice={{id:item.id, libelle:item.libelle}}
                                    choices={choixPaie ? [{id:choixPaie.id, libelle:choixPaie.libelle}] : []}
                                    //withOutChoche={true}
                                    // BackColorPerso?:string;
                                    // BorderColorPerso?:string;
                                    // ColorPerso?:string;
                                    // dbClickChoice?:Function;
                                />
                                //<div className={`one_choix_to ${choixPaie && choixPaie.id === item.id ? 'selected' : ''}`} key={`OcT${item.id}`} onClick={()=>setChoixPaie(item)}>{item.libelle}</div>
                            ))}
                        </div>
                    </div>
                }
                <div className="wrap_sender">
                    {soldecurrent===0 &&
                        <TimedButton text={"Enregistrer"} size={"md"} onClick={Sender} themeColor={"Primary"} isPending={mutation.isLoading}/>
                    }
                </div>
            </div>
        )
    }
}

export const FormResolutionHeuresSupp = styled(FormResolutionHeuresSuppCtrl)`
  .wrapper_choix_to{
    margin: 10px 0;
    .title_choix{
      font-weight: bold;
      margin-bottom: 6px;
    }
  }
  .wrapper_actif_choix_to{
    display: flex;
    justify-content: flex-start;
    gap:8px;
  }
  .wrap_select_cpt_heures{
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    align-items: center;
    select{
      height: 32px;
      border: solid ${props => props.theme.PrimaryLight} 1px;
      border-radius: 4px;
      flex-grow: 1;
    }
  }
  .wrap_sender{
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }
  .error_solde{
    color: ${props => props.theme.Complementary};
    font-weight: bold;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    margin-top: 8px;
  }
    .infos_heures{
      font-weight: bold;
      &.warning{
        color: ${props => props.theme.Complementary};
      }
      &.success{
        color: ${props => props.theme.Secondary};
      }
    }
  .wrap_input_hours{
    padding: 1rem 0;
    input{
      height: 32px;
      width: 100%;
      border:solid ${props => props.theme.PrimaryExtraLight} 1px;
      border-radius: 6px;
      text-align: center;
      margin-bottom: 5px;
    }
  }
  .load_balancing{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin: 15px auto;
    .column_balancing{
      flex-grow: 1;
      flex-shrink: 0;
      .titre_col{
        font-weight: bold;
        padding: 0.5rem;
        height: calc(32px + 0.5rem);
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        border-bottom: solid 1px ${props=>props.theme.Primary};
      }
      &:not(:last-child){
        padding-right: 20px;
        border-right: solid 1px #ccc;
      }
      &:nth-child(3), &:nth-child(2){
        padding-left: 20px;
      }
    }
  }
`