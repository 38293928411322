import {useQuery, UseQueryResult} from "react-query";
import {getAllModule, getAllModuleTop, getAllSection} from "./module.services";


export const useGetModules = ():UseQueryResult<Module[], Error>=>{
    return useQuery(["modules"], getAllModule, {
        staleTime:60000,
        refetchOnWindowFocus:false
    })
}

export const useGetTopModules = ():UseQueryResult<Module[], Error>=>{
    return useQuery(["modules_stats"], getAllModuleTop, {
        staleTime:1000,
        refetchOnWindowFocus:false
    })
}

export const useGetSections = ():UseQueryResult<Section[], Error>=>{
    return useQuery(["sections"], getAllSection, {
        staleTime:60000,
        refetchOnWindowFocus:false
    })
}