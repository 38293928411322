import styled from "styled-components";
import React, {useEffect, useState} from "react";
import ReactDOM from "react-dom";
import {GrClose} from "react-icons/gr";
import {BsArrowsAngleExpand} from "react-icons/bs";
import {useNavigate} from "react-router-dom";

export interface TimedModalPrePageCtrlProps{
    className?:string;
    open:boolean
    children?:React.ReactNode
    closeMe:()=>void;
    titre:string;
    Orientation:"top"|"left"|"right"|"bottom";
    linkExpand:LinkExpand;
    Wd?:number;
    Hei?:number;
    UniteSize:"px"|"%"
}
const VISIBLE = 1;
const HIDDEN = 2;
const ENTERING = 3;
const LEAVING = 4;

const TimedModalPrePageCtrl = (props:TimedModalPrePageCtrlProps)=>{
    const navigate = useNavigate();
    const [state, setState] = useState(props.open ? VISIBLE : HIDDEN);
    const ClickExpand = ()=>{
        navigate(props.linkExpand.link);
        props.closeMe()
    }
    useEffect(()=>{
        if(!props.open){
            setState(LEAVING)
        } else{
            setState((s)=>s===HIDDEN ? ENTERING : LEAVING)
        }
    }, [props.open])
    useEffect(()=>{
        if(state === LEAVING){
            const timer = setTimeout(()=>{
                setState(HIDDEN)
            }, 300)
            return ()=>{
                clearTimeout(timer);
            }
        } else if (state === ENTERING){
            const xx = document.body.offsetHeight
            setState(VISIBLE);
        }
    }, [state])
    const style = {transitionDuration: `300ms`, transitionProperty:"opacity", opacity:1}
    const styleModalRL = {transitionDuration: `300ms`, transitionProperty:"width", width:props.Wd ? `${props.Wd}${props.UniteSize}` : "350px"}
    const styleModalTB= {transitionDuration: `300ms`, transitionProperty:"height", height:props.Hei ? `${props.Hei}${props.UniteSize}` : "500px"}
    if(state!==VISIBLE){
        style.opacity = 0;
        styleModalRL.width = "0";
        styleModalTB.height = "0";
    }

    if(state === HIDDEN){
        return null;
    } else {
        return ReactDOM.createPortal(
            <div className={`timed_modal ${props.className}`}>
                <div style={style} className={`back-drop`} onClick={()=>props.closeMe()}/>
                <div className={`modal_content`} style={['right', 'left'].indexOf(props.Orientation) === -1 ? styleModalTB :  styleModalRL}>
                    <div className={`header_modal`}>
                        <div className={`expand ${props.Orientation}`}>
                            <button className={"expand_me"} onClick={ClickExpand}>
                                <BsArrowsAngleExpand/>
                                <span className={"tooltip"}>{props.linkExpand.libelle}</span>
                            </button>
                        </div>
                        <div className={`titre_modal`}>{props.titre}</div>
                        <div className="close_modal" onClick={()=>props.closeMe()}>
                            <GrClose/>
                        </div>
                    </div>
                    <div className={`body_modal`}>
                        {props.children}
                    </div>
                </div>
            </div>, document.body
        )
    }
}



export const TimedModalPrePage = styled(TimedModalPrePageCtrl)`
  .expand{
    padding: 0 0.5rem;
    button.expand_me{
      background: transparent;
      border: none;
      border-radius: 50%;
      padding: 0.3rem;
      position: relative;
      .tooltip{
        top:50%;
        visibility: hidden;
        position: absolute;
        left: calc(100% + 3px);
        background: black;
        padding: 0.2rem 0.5rem;
        border-radius: 3px;
        width: max-content;
        color: white;
        font-size: 14px;
        transform: translateY(-50%);
      }
      &:hover{
        cursor: pointer;
        background: ${props => props.theme.PrimaryExtraLight};
        .tooltip{
          visibility: visible;
        }
      }
    }
  }
  .back-drop{
    position:fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.7);
    z-index: 10;
  }
  .modal_content{
    height: ${props=>['top', 'bottom'].indexOf(props.Orientation) !==-1 ? "100%" : props.Hei ? `${props.Hei}${props.UniteSize}` : "500px"};
    background: white;
    width: ${props=>['top', 'bottom'].indexOf(props.Orientation) !==-1 ? "100%" : props.Wd ? `${props.Wd}${props.UniteSize}` : "350px"};
    border-top-left-radius: ${props=>['top', 'left'].indexOf(props.Orientation) !==-1 ? 0 : "12px"};
    border-top-right-radius: ${props=>['top', 'right'].indexOf(props.Orientation) !==-1 ? 0 : "12px"};
    border-bottom-left-radius: ${props=>['bottom', 'left'].indexOf(props.Orientation) !==-1 ? 0 : "12px"};
    border-bottom-right-radius: ${props=>['top', 'right'].indexOf(props.Orientation) !==-1 ? 0 : "12px"};
    position: fixed;
    right: ${props => props.Orientation === 'right' ? 0 : "auto"};
    left: ${props => props.Orientation === 'left' ? 0 : "auto"};
    bottom: ${props => ['left', 'bottom', 'right'].indexOf(props.Orientation) !==-1 ? 0 : "auto"};
    top: ${props => ['left', 'top', 'right'].indexOf(props.Orientation) !==-1 ? 0 : "auto"};
    z-index: 10;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    .body_modal{
      overflow: hidden;
      flex-grow: 1;
      height: 0;
      overflow-y: auto;
    }
    .header_modal{
      width: 100%;
      display: flex;
      justify-content: flex-start;
      padding: 0.5rem;
      align-content: center;
      .titre_modal{
        flex-grow: 1;
        font-weight: bold;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      .close_modal{
        &:hover{
          cursor: pointer;
        }
      }
    }
  }
`