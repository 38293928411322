import styled from "styled-components";
import {OneMonthChoice, OneSemaineChoice} from "./GestionHeuresSuppFull";
import {useGetParamsTimed} from "../../../../hooks/useGetParamsTimed";
import {useMemo} from "react";
import {getMonthsByTwoDate, getNumWeek, getWeekByTwoDate} from "../../../../components/functions/TimesFunctions";
import {TabMois} from "../../../../services/constantes/constantes";
import {OneChoiceMonthCard} from "./components/OneChoiceMonthCard";

interface ListChoiceMonthsCtrlProps{
    className?:string;
    MonthCurrent:OneMonthChoice|null;
    setMonthCurrent:(m:OneMonthChoice)=>void;
    Periode:Periode|null;
    wR:boolean // with result (est-ce qu'on met les résultats par mois)
}

const ListChoiceMonthsCtrl = (props:ListChoiceMonthsCtrlProps)=>{
    const {To, From} = useGetParamsTimed();
    const listMonthsFiltered = useMemo(()=>{
        const list:OneMonthChoice[] = [];
        if(props.Periode) {
            const StartPeriode = new Date(props.Periode.debutAt);
            const EndPeriode = new Date(props.Periode.finAt);
            StartPeriode.setHours(12, 0, 0);
            EndPeriode.setHours(12, 0, 0);
            const FinalFrom = From && From >= StartPeriode ? From : StartPeriode;
            const FinalTo = To && To <= EndPeriode ? To : EndPeriode;
            const months = getMonthsByTwoDate(FinalFrom, FinalTo)
            months.forEach(w => {
                const libMonth = TabMois.find(m=>m.numEn === w.start.getMonth())
                list.push({
                    libelle: `${libMonth ? libMonth.shortName : 'nc'} ${(w.start.getFullYear()).toString().slice(-2)}`,
                    From: w.start,
                    To: w.end,
                    nbResult: 0,
                    id:w.start.getMonth()+"_"+w.start.getFullYear()
                })
            })
        }
        return list;
    }, [props.Periode, To,From])
    return (
        <div className={`list_month_choice ${props.className}`}>
            {listMonthsFiltered.map(item=>(
                <OneChoiceMonthCard wR={props.wR} MoisCurrent={props.MonthCurrent} Mois={item} setMois={props.setMonthCurrent}  key={`onCM${item.From.toISOString().slice(0,10)}`}/>
            ))}
        </div>
    )
}

export const ListChoiceMonths = styled(ListChoiceMonthsCtrl)`
  
`