import styled from "styled-components";
import React from "react";

interface BlockInfosShortCtrlProps{
    className?:string;
    title:string;
    children:React.ReactNode;
    noFlex?:boolean
}

const BlockInfosShortCtrl = (props:BlockInfosShortCtrlProps)=>{
    return (
        <div className={`block_infos_short ${props.className}`}>
            <div className={`title_block`}>{props.title}</div>
            <div className={`in_block`}>
                {props.children}
            </div>
        </div>
    )
}

export const BlockInfosShort = styled(BlockInfosShortCtrl)`
  margin-bottom: 25px;
  .title_block{
    font-weight: bold;
    color: ${props => props.theme.PrimaryDark};
    margin-bottom: 10px;
    border-bottom: solid 1px ${props=>props.theme.Primary};
  }
  .in_block{
    display: ${props=>props.noFlex ? 'block' : 'flex'};
    justify-content: flex-start;
    flex-wrap: wrap;
  }
`