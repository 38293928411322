import React from "react";
import styled from "styled-components";
import waity from "../../../assets/images/wait-data.png";

interface TimedAlertChoiceCtrlProps{
    className?:string;
    children:React.ReactNode;
    title?:string;
    width?:string;
}

const TimedAlertChoiceCtrl = ({className, children, title}:TimedAlertChoiceCtrlProps)=>{
    return (
        <div className={`noChoiceAlert ${className}`}>
            <div className={`image-place`}>
                <img src={waity} alt={"en attente elts"}/>
            </div>
            <div className={"legend"}>{title ? title : 'En attente des éléments'}</div>
            <div className={"describe"}>
                {children}
            </div>
        </div>
    )
}

const TimedAlertChoiceStyled = styled(TimedAlertChoiceCtrl)`
  padding: 4rem 1rem;
  background: white;
  //margin: auto;
  width: ${props => props.width ? props.width : "100%"};
  .hightlight{
    font-weight: bold;
    color: black;
  }
  .describe{
    width: 80%;
    color: ${props=>props.theme.Primary};
    text-align: center;
    margin: auto;
    padding: 1rem;
  }
  .legend{
    padding: 0.5rem;
    text-align: center;
    font-size: 18px;
    color: ${props=>props.theme.Primary};
    font-weight: bold;
  }
  .image-place{
    width: 150px;
    margin: auto;
    img{
      width: 100%;
      height: auto;
    }
  }
`

const TimedAlertChoice = React.memo(TimedAlertChoiceStyled);

export default TimedAlertChoice;