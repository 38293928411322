import {useQuery, UseQueryResult} from "react-query";
import {getUserHeureDD} from "./userHeureDD.services";
import {DateToYMD} from "../../components/functions/TimesFunctions";


export const useGetUserHeureDD = (idUser:number, dateStart:Date, dateEnd:Date, idCentre:number|null=null):UseQueryResult<UserHeureDD, Error>=>{
    const startFormatted = DateToYMD(dateStart);
    const endFormatted = DateToYMD(dateEnd);
    return useQuery(["user_heure_dd", idUser, dateStart, dateEnd, idCentre], ()=>getUserHeureDD(idUser, startFormatted, endFormatted, idCentre), {
        staleTime:6000,
        enabled: idUser !== 0
    })
}