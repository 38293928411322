import React, {useEffect, useState} from "react";
import styled, {keyframes} from "styled-components";
import ReactDOM from "react-dom";
import {GrClose} from "react-icons/gr";

export interface TimedModalECtrlProps{
    className?:string;
    footer?:React.ReactNode;
    children?:React.ReactNode;
    titre:string;
    CloseMe:()=>void;
    open:boolean;
    type:"center" | "right"
}
const times = 300;

const TimedModalECtrl = ({className, footer, children, titre, CloseMe, open}:TimedModalECtrlProps)=>{
    const [classy, setClassy] = useState(false);
    const [visible, setVisible] = useState(false);
    useEffect(()=>{
        if(open){
            setClassy(true);
            setVisible(true);
        } else {
            if(visible){
                closeModal();
            }
        }
    }, [open])
    const closeModal = ()=>{
        setClassy(false);
        setTimeout(()=>{
            setVisible(false);
        }, times)
    }

    return ReactDOM.createPortal(
        <div className={`TimedModalE ${className} ${classy ? 'opened' : 'closed'} ${visible ? 'visible' : 'invisible'}`}>
            <div className={`ModalContent`}>
                <div className={`header-modal`}>
                    <div className={`titre`}>{titre}</div>
                    <div className={`closeModal`}>
                        <div className={`wrap-close`} onClick={CloseMe}><GrClose/></div>
                    </div>
                </div>
                <div className={`body-modal`}>{children}</div>
                {footer &&
                    <div className={`footer-modal`}>{footer}</div>
                }
            </div>
        </div>, document.body
    )
}

const AnimationRA = keyframes`
  from {
    width: 0;
    right: 0;
    top: 0;
    opacity: 0;
    overflow: hidden;

  }
  to {
    width: 400px;
    right: 0;
    top: 0;
    opacity: 1;
  }
`
const AnimationRD = keyframes`
  from {
    width: 400px;
    right: 0;
    top: 0;
    opacity: 1;
  }
  to {
    width: 0;
    right: 0;
    top: 0;
    opacity: 0;
    overflow: hidden;

  }
`

const AnimationAppear = keyframes`
    from{
      width: 0;
      right: 0;
      top: 50%;
      opacity: 0;
      overflow: hidden;
      
    }
  to{
    width: 50%;
    right: 50%;
    top: 50%;
    opacity: 1;
  }
`
const AnimationDisAppear = keyframes`
  from{
    width: 50%;
    right: 50%;
    top: 50%;
    opacity: 1;
  }
    to{
      width: 0;
      right: 0;
      top: 50%;
      opacity: 0;
      overflow: hidden;
      
    }
  
`

export const TimedModalE = styled(TimedModalECtrl)`
  position: fixed;
  z-index: 999;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.6);
  &.invisible{
    display: none;  
  }
  &.visible{
    display: block;
  }
  .body-modal{
    flex-grow: 1;
    height: 0;
    overflow-y: auto;
  }
  .footer-modal{
    padding: 0.5rem;
    border-top: solid 1px ${props => props.theme.PrimaryExtraLight};
  }
  .header-modal{
    display: flex;
    justify-content: flex-start;
    padding: 0.5rem 0.5rem;
    align-items: center;
    .titre{
      flex-grow: 1;
      font-weight: bold;
      color:${props=>props.theme.PrimaryDark};
    }
    .wrap-close{
      &:hover{
        cursor: pointer;
        font-weight: bold;
      }
    }
  }
  &.opened{
    .ModalContent{
      animation: ${props=>props.type === 'center' ? AnimationAppear : AnimationRA} ${times/1000}s cubic-bezier(0, 0, 0, 0.94) forwards;
    }
  }
  &.closed{
    .ModalContent{
      animation: ${props=>props.type === 'center' ? AnimationDisAppear : AnimationRD} ${times/1000}s cubic-bezier(0, 0, 0.61, 0.14) forwards;
    }
  }
  .ModalContent{
    position: absolute;
    background: white;
    transform: ${props=> props.type === 'center' ? `translate(50%,-50%)` : 'none'};
    border-radius: 4px;
    height: ${props=>props.type === 'center' ? 'auto' : '100%'};
    max-height: ${props=>props.type === 'center' ? '60vh' : '100%'};
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  
`

