import React from "react";
import styled from "styled-components";
import {Shine} from "../../../assets/styles/elements";

interface TimedSkeletonListChipsCtrlProps{
    className?:string;
    marginTop?:string;
    marginBottom?:string;
}

const TimedSkeletonListChipsCtrl = (props:TimedSkeletonListChipsCtrlProps)=>{
    return (
        <div className={`TimedSkeListChips ${props.className}`}>
            <div className="chips-wait"/>
            <div className="chips-wait"/>
            <div className="chips-wait"/>
            <div className="chips-wait"/>
            <div className="chips-wait"/>
            <div className="chips-wait"/>
        </div>
    )
}

const TimedSkeletonListChips = styled(TimedSkeletonListChipsCtrl)`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  gap: 10px;
  margin-top: ${props => props.marginTop ? props.marginTop : "0px"};
  margin-bottom: ${props => props.marginBottom ? props.marginBottom : "0px"};
  .chips-wait{
    width: 80px;
    height: 28px;
    border-radius: 15px;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    background-size: 200% 100%;
    animation: 1.5s ${Shine} linear infinite;
  }
`

export default TimedSkeletonListChips;