import styled from "styled-components";
import {useEffect, useState} from "react";
import {useGetUser} from "../../../../features/user/user.hooks";
import {TimedLoaderWrapper} from "../../../../components/atoms/TimedWaitUser/TimedLoaderWrapper";
import GestionDocument from "./GestionDocument";
import {NoRightToAccess} from "../../../NotAccess/NoRightToAccess";



const GestionDocumentRooter = () => {
    const UserConnectedQuery = useGetUser();
    const [accesDoc, setAccesDoc] = useState(false);
    useEffect(()=>{
        if(UserConnectedQuery.data){
            const PP = UserConnectedQuery.data.particularParams;
            let myAccessDoc:boolean = true;
            if(PP && PP.hasOwnProperty('isRestreint') && PP["isRestreint"] === 1){
                myAccessDoc = false;
            }
            setAccesDoc(myAccessDoc);
        }
    }, [UserConnectedQuery.data])
    return (
        <>
            {UserConnectedQuery.isLoading ?
                <TimedLoaderWrapper width={"80px"}/>:
                !accesDoc ?
                    <NoRightToAccess/>:
                    <GestionDocument/>
            }
        </>
    )
}

export default GestionDocumentRooter;