import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import {isAuth} from "../../services/security/isAuth";


interface PublicRouteCtrlProps {}

function PublicRoute(props: PublicRouteCtrlProps) {
    return !isAuth() ? <Outlet /> : <Navigate to="/timed_application" />;
}

export default PublicRoute;
