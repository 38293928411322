import {dataFetcher} from "../../services/dataGuetter/dataFetcher";


export async function getAllLBByCentre(id:number):Promise<LieuBesoin[]>{
    const res:any = await dataFetcher(`lieu_besoins?Centre=${id}`)
    return res["hydra:member"]
}

export function PutLieuBesoin(id:number, datas:PutLB):Promise<LieuBesoin>{
    return dataFetcher(`lieu_besoins/${id}`, {
        method:'put',
        body:datas
    })
}

export function PostLieuBesoin(datas:PostLieuBesoin):Promise<LieuBesoin>{
    return dataFetcher(`lieu_besoins`, {
        method:'post',
        body:datas
    })
}