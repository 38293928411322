import styled from "styled-components";
import {useChangeOrdreLieu, useGetAllLieu} from "../../../../features/lieu/lieu.hooks";
import {useCallback, useEffect, useMemo, useState} from "react";
import {HTML5Backend} from "react-dnd-html5-backend";
import {DndProvider} from "react-dnd";
import OneCardLieu from "./components/OneCardLieu";
import update from "immutability-helper";
import OneCardLieuInactif from "./components/OneCardLieuInactif";

interface WrapListLieuxCtrlProps{
    className?:string;
    setLieuxActif:(l:Lieu)=>void;
    LieuActif:Lieu|null;
    isActifView:boolean;
}

const WrapListLieuxCtrl = (props:WrapListLieuxCtrlProps)=>{
    const LieuxQuery = useGetAllLieu();
    const mutation = useChangeOrdreLieu();
    const [myLieux, setMyLieux] = useState<Lieu[]>([])
    useEffect(()=>{
        if(LieuxQuery.data){
            setMyLieux(LieuxQuery.data.filter(l=>l.isProduction === props.isActifView).sort((a,b)=>a.ordreEdit > b.ordreEdit ? 1 : -1));
        }
    }, [LieuxQuery.data, props.isActifView])
    const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
        setMyLieux((prevCards: Lieu[]) =>
            update(prevCards, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, prevCards[dragIndex] as Lieu],
                ],
            }),
        )
        //SaveMove();
    }, [])
    const SaveMove = ()=>{
        const ids = myLieux.map(l=>l.id).join('_');
        const datas:LieuChangeOrdre = {lieux:ids};
        mutation.mutate((datas))
    }
    return (
        <div className={`wrapListLieux wrap_list ${props.className}`}>
            {props.isActifView ?
            <DndProvider backend={HTML5Backend}>
                {myLieux.map((item, idx)=>(
                    <OneCardLieu
                        ClickOnEdit={()=>props.setLieuxActif(item)}
                        Lieu={item}
                        key={`cL${item.id}`}
                        isCurrent={props.LieuActif?.id === item.id}
                        index={idx}
                        Move={moveCard}
                        SaveMove={SaveMove}
                    />
                ))}
            </DndProvider>
                :
                <>
                    {myLieux.map(item=>(
                        <OneCardLieuInactif
                            key={`cL${item.id}`}
                            Lieu={item}
                            isCurrent={props.LieuActif?.id === item.id}
                            ClickOnEdit={()=>props.setLieuxActif(item)}
                        />
                    ))}
                </>
            }
        </div>
    )
}

const WrapListLieux = styled(WrapListLieuxCtrl)``

export default WrapListLieux