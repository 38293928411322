import styled from "styled-components";
import {ReactNode} from "react";

interface PageDoubleSettingCtrlProps{
    className?:string;
    children:ReactNode;
}

const PageDoubleSettingCtrl = (props:PageDoubleSettingCtrlProps)=>{
    return (
        <div className={`pageDoubleSetting ${props.className}`}>
            {props.children}
        </div>
    )
}

const PageDoubleSetting = styled(PageDoubleSettingCtrl)`
    display: flex;
    justify-content: stretch;
    align-items: stretch;
    background: white;
    padding: 1rem;
`

export default PageDoubleSetting