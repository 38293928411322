import React, {useEffect, useState} from "react";
import {useGetCreneauxNonPris} from "../../../features/creneau/creneau.hooks";
import WidgetMetrix from "../Components/WidgetMetrix";
import {useCentreConnectedData} from "../../../services/hooks";

interface WidgetCreneauxNonPrisJ7CtrlProps{
    supervisor?:boolean;
}

export const WidgetCreneauxNonPrisJ7 = ({supervisor}:WidgetCreneauxNonPrisJ7CtrlProps)=>{
    const centre = useCentreConnectedData();
    const CreneauxNonPrisQuery = useGetCreneauxNonPris(supervisor ? centre : null);
    const [total, setTotal] = useState(0)
    useEffect(()=>{
        if(CreneauxNonPrisQuery.data){
            let TodayMid = new Date();
            TodayMid.setHours(0,0, 0);
            const J7 = new Date(new Date(TodayMid).getTime() + 7*86400000);
            const CreneauxConcerned = CreneauxNonPrisQuery.data.filter(c=>new Date(c.creneauAt) < J7 && new Date(c.creneauAt) > new Date(TodayMid));
            setTotal(CreneauxConcerned.length);
        }
    }, [CreneauxNonPrisQuery.data])
    return (
        <WidgetMetrix
            title={"Créneaux non pris J+7"}
            Metrix={total}
            width={"100%"}
            isPending={CreneauxNonPrisQuery.isLoading}
            isNoAlert={!CreneauxNonPrisQuery.isLoading && total === 0}
            isAlert={!CreneauxNonPrisQuery.isLoading && total > 0}
        />
    )
}

