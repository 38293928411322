import {useMutation, useQuery, useQueryClient, UseQueryResult} from "react-query";
import {
    addSemaineType, getProjections,
    getSemaineTypeCentre,
    getSemaineTypeUser,
    modifySemaineType,
    trashSemaineType
} from "./semaineType.services";
import {toast} from "react-toastify";


export const useGetSemaineTypesUser=(id:number):UseQueryResult<SemaineType[], Error>=>{
    return useQuery<SemaineType[], Error>(["semaineTypes", id], ()=>getSemaineTypeUser(id), {
        staleTime:60000,
        refetchOnWindowFocus:false,
        enabled:id!==undefined && id!==0
    })
}

export const useGetSemaineTypesCentre=(id:number):UseQueryResult<SemaineType[], Error>=>{
    return useQuery<SemaineType[], Error>(["semaineTypes_centre", id], ()=>getSemaineTypeCentre(id), {
        staleTime:60000,
        refetchOnWindowFocus:false,
        enabled:id!==undefined && id!==0
    })
}

export const useModifySemaineType=()=>{
    const queryClient = useQueryClient();
    return useMutation(
        (datas:ModifySemaineTypeFormData)=>{
            return modifySemaineType(datas);
        }, {
            onSuccess:()=>{
                queryClient.invalidateQueries(["semainesTypes"]);
                queryClient.invalidateQueries(["semaineTypes"]);
                queryClient.invalidateQueries(["centreSemaineType"]);
                queryClient.invalidateQueries(["projections_st"]);
                queryClient.invalidateQueries(["time_cst"]);
                toast.success('Plage modifiée');
            },
            onError:(e:any)=>{
                toast.error(e.message);
            }
        }
    )
}

export const useTrashSemaineType=()=>{
    const queryClient = useQueryClient();
    return useMutation(
        (id:number)=>{
            return trashSemaineType(id);
        },{
            onSuccess:()=>{
                queryClient.invalidateQueries(["semainesTypes"]);
                queryClient.invalidateQueries(["centreSemaineType"]);
                queryClient.invalidateQueries(["time_cst"]);
                toast.success('Plage supprimée');
            },
            onError:(e:any)=>{
                toast.error(e.message);
            }
        }

    )
}

export const useAddSemaineType=()=>{
    const queryClient = useQueryClient();
    return useMutation(
        (datas:AddSemaineTypeFormData)=>{
            return addSemaineType(datas);
        },{
            onSuccess:()=>{
                queryClient.invalidateQueries(["semainesTypes"]);
                queryClient.invalidateQueries(["centreSemaineType"]);
                queryClient.invalidateQueries(["time_cst"]);
                //toast.success('Plage ajoutée');
            },
            onError:(e:any)=>{
                toast.error(e.message);
            }
        }
    )
}

export const useGetProjectionsST = ():UseQueryResult<OneProjection[], Error>=>{
    return useQuery(["projections_st"], getProjections)
}