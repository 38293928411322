import React from "react";
import styled from "styled-components";
import {useNavigate} from "react-router-dom";
import logoShort from "../../../assets/images/Logo-Small-Timed-Light.png";
import {MdDashboard} from "react-icons/md";
import TimedSideLink from "../../atoms/TimedSideLink/TimedSideLink";
import {myBoxShadow} from "../../../assets/styles/elements";
import {BsFillUnlockFill} from "react-icons/bs";
import {removeToken} from "../../../services/dataGuetter/dataFetcher";
import {FaMask} from "react-icons/fa";
import {RiSwitchFill} from "react-icons/ri";
import { GrConnect } from "react-icons/gr";
import {AiOutlineFork} from "react-icons/ai";
import {FaBug} from "react-icons/fa";
import {useCancelWMSub, useGetReal} from "../../../features/user/user.hooks";
import TimedSideLinkAction from "../../atoms/TimedSideLink/TimedSideLinkAction";

interface TimedWMSideBarCtrlProps{
    className?:string;
}

const TimedWMSideBarCtrl = ({className}:TimedWMSideBarCtrlProps)=>{
    const userConnectedRealQuery = useGetReal();
    const navigate = useNavigate();
    const mutationCancel = useCancelWMSub();
    const Disconnect = ()=>{
        removeToken();
        navigate('/login');
    }
    const goToSupervisor=()=>{
        let x:void;
        mutationCancel.mutate(x, {
            onSuccess:()=>{
                if(userConnectedRealQuery.data){
                    localStorage.setItem('__centre_connected__', userConnectedRealQuery.data.Centre.id.toString())
                }
                navigate("/supervisor_application/dashboard")
            }
        })
    }
    return (
        <div className={`TimeWBSideBar ${className}`}>
            <div className="content-side-bar">
                <div className={`logo-place`}>
                    <img src={logoShort} alt={"Timed application"}/>
                </div>
                <div className={"items-place"}>
                    <TimedSideLink libelle={"Tableau de bord"} link={"dashboard"} sectionName={""} thisSection={"Main"} icon={<MdDashboard/>} isOpen={false}/>
                    <TimedSideLink libelle={"Supports"} link={"supports"} sectionName={""} thisSection={"Main"} icon={<FaBug/>} isOpen={false}/>
                    <TimedSideLink libelle={"App Timed"} link={"/timed_application/dashboard"} sectionName={""} thisSection={"Main"} icon={<RiSwitchFill/>} isOpen={false}/>
                    <TimedSideLinkAction libelle={"App Supervisor"} action={goToSupervisor} sectionName={""} thisSection={"Main"} icon={<RiSwitchFill/>} isOpen={false}/>
                    <TimedSideLink libelle={"Connexion shadow"} link={"connect_shadow"} sectionName={""} thisSection={"Main"} icon={<FaMask/>} isOpen={false}/>
                    <TimedSideLink libelle={"Onboarding"} link={"onboarding"} sectionName={""} thisSection={"Main"} icon={<GrConnect/>} isOpen={false}/>
                    <TimedSideLink libelle={"Fiche"} link={"fiche"} sectionName={""} thisSection={"Main"} icon={<AiOutlineFork/>} isOpen={false}/>

                    <div className={`disconnect`} onClick={Disconnect}>
                        <div className={`wrap-close`}><BsFillUnlockFill/></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export const TimedWMSideBar = styled(TimedWMSideBarCtrl)`
  transition: width 0.3s;
  z-index: 9;
  width: 80px;
  position: relative;
  background: ${props => props.theme.TertiaryDark};
  flex-shrink: 0;
  .logo-place{
    margin: 25px auto;
    padding: 0 0.5rem;
    text-align: center;
    img{
      width: 70%;
      height: auto;
    }
  }
  height: 100vh;
  .disconnect{
    text-decoration: none;
    width: 100%;
    padding: 0.75rem 0.85rem;
    display: block;
    font-size: 16px;
    position: relative;
    color:${props=>props.theme.colorLight}!important;
    &.closed{
      &:after{
        content: attr(data-info);
        position: absolute;
        left: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        top: 0;
        ${myBoxShadow};
        height: 100%;
        padding: 0;
        background: ${props=>props.theme.Primary};
        color: white;
        text-wrap: none;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 0;
        transition: width 0.4s;
        border-bottom-right-radius: 0.4rem;
        border-top-right-radius: 0.4rem;
      }
      &:hover:after{
        width: 180px;
        white-space: nowrap;
        text-wrap: none;

        transition: width 0.4s;
      }
    }
    .wrap-close{
      position: relative;
      display: flex;
      justify-content: center;
      font-size: 22px;

    }
    .wrap-opened{
      display: flex;
      justify-content: start;
      .lib-place{
        margin-left: 10px;
      }
    }
    &:hover, &.isActive{
      cursor: pointer;
      background: ${props=>props.theme.Primary};
    }
  }
`