import React, {useEffect, useMemo, useRef, useState} from "react";
import styled from "styled-components";
import {BiTimeFive} from "react-icons/bi";

interface TimedTimeChoiceCtrlProps{
    /**
     * class css du composant
     */
    className?:string;
    /**
     * Intitule du champ
     */
    label:string;
    /**
     * Champ obligatoire ?
     */
    isRequired:boolean;
    /**
     * le champ est-il en erreur ?
     */
    isError:boolean;
    /**
     * Text d'aide si erreur ou complex
     */
    helperText:string|undefined;

    getValue:any;
    /**
     * Fonction de sélection du champ
     */
    setFieldValue:Function;
    /**
     * identifiant du champ
     */
    id:string;
    /**
     * taille de l'input
     */
    size?:"small" | "normal";
    /**
     * input full
     */
    isFull?:boolean;
    isFullH?:boolean;
    posLeft:string;
    posTop:string;
    hourStart:number;
    hourStop:number;
    value:string;
    isBig?:boolean;
    forceStatut?:boolean;
    setForceStatut?:(val:boolean)=>void;
    jun?:boolean,
    isGereJun?:boolean

}

const TimedTimeChoiceCtrl = ({hourStart, isGereJun, hourStop, className, label, id, setFieldValue,isRequired, isError, helperText, value, isBig, forceStatut, setForceStatut, jun  }:TimedTimeChoiceCtrlProps)=>{
    const [open, setOpen] = useState(false);

    const [hourSelect, setHourSelect] = useState(value !== undefined ? value.split(":")[0] : '--')
    const [minSelect, setMinSelect] = useState(value !== undefined ? value.split(":")[1] : '--')
    const refObj = useRef<HTMLDivElement>(null);
    const refGoodHour = useRef<HTMLLIElement>(null);
    const refGoodMinute = useRef<HTMLLIElement>(null);
    const [hourKey, setHourKey]=useState('');
    const [hightL, setHightL] = useState(-1);
    const refInputKey = useRef<HTMLInputElement>(null);
    useEffect(()=>{
        setHourSelect(value !== undefined ? value.split(":")[0] : '--')
        setMinSelect(value !== undefined ? value.split(":")[1] : '--')
    }, [value])
    useEffect(()=>{
        if(forceStatut){
            setOpen(forceStatut);
            if(refInputKey.current) {
                refInputKey.current.focus();
            }
        }
    }, [forceStatut])
    useEffect(()=>{
        if(hourKey.length<2){
            setHightL(0)
        } else {
            setHightL(1);
        }
        if(hourKey.length === 1){
            if(parseInt(hourKey)>2){
                setHourKey(`0${hourKey}`);
            }
        }
        if(hourKey.length === 2){
            const minFinal = minSelect === '--' ? '00' : minSelect;
            if(isGereJun) {
                setFieldValue(id, `${hourKey}:${minFinal}`, false)
            } else {
                setFieldValue(id, `${hourKey}:${minFinal}`, {shouldDirty:true})
            }
        }
        if(hourKey.length === 4){
            const valueInt = parseInt(hourKey.slice(-2));
            const RoundValue = Math.floor(valueInt/5)*5;
            const StrValue = RoundValue<10 ? `0${RoundValue}` : RoundValue;
            if(isGereJun) {
                setFieldValue(id, `${hourSelect}:${StrValue}`, false)
            } else {
                setFieldValue(id, `${hourSelect}:${StrValue}`, {shouldDirty:true})
            }
            setHourKey('');
        }
        // if(hourKey.length>4){
        //     setHourKey('');
        // }
    }, [hourKey, id, isGereJun])
    const listHeure = useMemo(()=>{
        let myList:string[] = [];
        let dep = hourStart;
        const hourStopReal = hourStop === 24 ? 23 : hourStop;
        while(dep<=hourStopReal){
            myList.push(`0${dep}`.slice(-2));
            dep++;
        }
        if(hourStop === 24){
            myList.push("(+1)00")
            myList.push("(+1)01")
            myList.push("(+1)02")
        }
        return myList
    }, [hourStart, hourStop])

    const listMinutes = useMemo(()=>{
        let myList:string[] = [];
        let dep = 0;
        while(dep<60){
            myList.push(`0${dep}`.slice(-2));
            dep = dep+5;
        }
        return myList
    }, [])
   const handleClickHour = (choice:string)=>{
        const minFinal = minSelect === '--' ? '00' : minSelect;
        let myFinalChoice =choice;
        let jUn = false;
        if(choice === "(+1)00"){
            myFinalChoice = "00";
            jUn=true
        } else {
            jUn=false;
        }
       if(choice === "(+1)01"){
           myFinalChoice = "01";
           jUn=true
       } else {
           jUn=false;
       }
       if(choice === "(+1)02"){
           myFinalChoice = "02";
           jUn=true
       } else {
           jUn=false;
       }
       if(!isGereJun){
           setFieldValue(id, `${myFinalChoice}:${minFinal}`, {shouldDirty:true})
       } else {
           setFieldValue(id, `${myFinalChoice}:${minFinal}`, jUn)
       }
       if(refInputKey.current) {
           refInputKey.current.value = `${choice}`
           setHourKey(`${choice}`)
           refInputKey.current.focus();
       }
   }
   const handleClickMin = (choice:string)=>{
        if(!isGereJun){
            setFieldValue(id, `${hourSelect}:${choice}`, {shouldDirty:true})
        } else {
            setFieldValue(id, `${hourSelect}:${choice}`, false)
        }
       if(refInputKey.current) {
           refInputKey.current.value = `${hourSelect}${choice}`
           setHourKey(hourSelect + `${choice}`)
           refInputKey.current.focus();
       }
   }

   useEffect(()=>{
       if(value){

           if(refGoodMinute.current){
               refGoodMinute.current.scrollIntoView({ behavior: 'auto', block: 'start' });
           }

       }
   }, [value, open, refGoodMinute])
    useEffect(()=>{
        if(value){
            if(refGoodHour.current){
                refGoodHour.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }
    }, [value, refGoodHour,open])
    useEffect(() => {
        function handleClickOutside(event:any) {
            if (refObj.current && !refObj.current.contains(event.target)) {
                setOpen(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [refObj]);
    const handleClickOnIt =()=>{
        if(!open){
            setHourKey('');
            setOpen(true);
            if(setForceStatut){
                setForceStatut(false);
            }
            if(refInputKey.current) {
                refInputKey.current.focus();
            }
        }
    }
    const OnChag = ()=>{
        if(refInputKey.current){
            setHourKey(refInputKey.current.value);
        }
    }
    const handleKeyDown = (event:any) => {
        if (event.key === 'Enter') {
            // 👇 Get input value
            setOpen(false);
            if(setForceStatut) setForceStatut(true);
        }
    };
    return (
        <div className={`TimedTimedChoice ${className}`} ref={refObj}>
            <div className={`wrap-input ${isError ? 'onError' : ''}`} onClick={handleClickOnIt}>
                <label>{label} {isRequired ? '*' : ''}</label>
                <div style={{width:0, height:0, overflow:'hidden', position:'relative'}}>
                    <div className={`wrap_input2`} style={{opacity:0, position:"absolute", top:"-100px"}}>
                        <input ref={refInputKey} type={"text"} onChange={OnChag} value={hourKey} onKeyDown={handleKeyDown}/>
                    </div>
                </div>
                <div className={`front-input`}>
                    <div className={`timeChoice`}>
                        <span className={`hour_sel ${(hightL === 0 && open) ? 'in' : ''}`}>{hourSelect}</span>:<span className={`hour_sel ${(hightL === 1 && open) ? 'in' : ''}`}>{minSelect}</span>
                        {jun && <span>(+1)</span>}
                    </div>
                    <div className={`iconPlace`}><BiTimeFive/></div>
                </div>
                {open &&
                    <div className={`wrap-choicer ${isBig ? 'is-big' : ''}`}>
                        <div className={`wrapper-list`}>
                            <div className={`wrappy-list`}>

                                <ul>
                                    {listHeure.map((h:string, idx:number)=>{
                                        if (hourSelect === h){
                                            return (
                                                <li ref={refGoodHour} key={`oneH${idx}`} className={hourSelect === h ? 'onSelect' : ''} onClick={()=>handleClickHour(h)}>{h}</li>
                                            )
                                        } else {
                                            return (
                                                <li key={`oneH${idx}`} className={hourSelect === h ? 'onSelect' : ''} onClick={()=>handleClickHour(h)}>{h}</li>
                                            )
                                        }
                                    })}
                                    {/*<li ref={refGoodHour}></li>*/}
                                </ul>
                            </div>
                        </div>
                        <div className={`wrapper-list`}>
                            <div className={`wrappy-list`}>
                                <ul>
                                    {listMinutes.map((m:string, idx:number)=>{
                                        if(minSelect === m){
                                            return (
                                                <li ref={refGoodMinute} key={`oneM${idx}`} className={minSelect === m ? 'onSelect' : ''} onClick={()=>handleClickMin(m)}>{m}</li>
                                            )
                                        } else {
                                            return (
                                                <li key={`oneM${idx}`} className={minSelect === m ? 'onSelect' : ''} onClick={()=>handleClickMin(m)}>{m}</li>
                                            )
                                        }
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                }
            </div>
            {helperText &&
                <div className={`helper-text`}>{helperText}</div>
            }
        </div>
    )
}

const TimedTimeChoice =styled(TimedTimeChoiceCtrl)`
  width: ${props=>props.isFull ? '100%' : '150px'};
  background: white;
  padding: 0.5rem;
  .hour_sel{
    &.in{
      background: ${props=>props.theme.NeutreMegaLight};
    }
  }
  .helper-text{
    font-size: 12px;
    color: red;
    font-style: italic;
  }
  &:hover{
    cursor: pointer;
  }
  ul{
    margin: 0;
    padding: 0;
    li{
      display: block;
      padding:${props=>props.isBig ? "0.5rem 0.5rem" :  "0.25rem"};
      &:hover, &.onSelect{
        cursor: pointer;
        background: ${props=>props.theme.PrimaryExtraLight};
      }
    }
  }
  .wrap-choicer{
    position: absolute;
    left:${props=>props.posLeft};
    top:${props=>props.posTop};
    background: white;
    z-index: 99;
    box-shadow: 1px 1px 3px rgba(0,0,0,.2);
    width: ${props=>props.hourStop===24 ? "115px" : "max-content"};
    display: flex;
    justify-content: start;
    padding: 0.25rem 0;
    &.is-big{
      .wrapper-list {
        max-height: ${props=>props.isFullH ? "412px" : "180px"};
      }
    }
    .wrapper-list{
      width: 50%;
      max-height: 100px;
      overflow: hidden;
      
      .wrappy-list{
        width: calc(100% + 10px);
        height: 100%;
        overflow-y: auto;
      }
    }
  }
    .wrap-input{
      border: solid #ccc 1px;
      position: relative;
      border-radius: 4px;
      &.onError{
        border-color: red;
      }
      label{
        font-size: 11px;
        position: absolute;
        padding: 0 0.25rem;
        left: 5px;
        top: 0;
        transform: translateY(-50%);
        background: white;
        color:rgba(0,0,0,0.6);
      }
      .front-input{
        width: 100%;
        display: flex;
        justify-content: start;
        align-items: center;
        .timeChoice{
          flex-grow: 1;
          padding: 0.65rem 0.5rem;
        }
        .iconPlace{
          padding: 0.25rem;
        }
      }
      
    }
`

export default TimedTimeChoice;

