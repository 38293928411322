import {dataFetcher} from "../../services/dataGuetter/dataFetcher";

export async function getSemaineTypeUser(id:number):Promise<SemaineType[]>{
    const res:any = await dataFetcher(`semaine_types?CentreSemaineType.User=${id}`)
    return res["hydra:member"];
}

export async function getSemaineTypeCentre(id:number):Promise<SemaineType[]>{
    const res:any = await dataFetcher(`semaine_types?User.Centre=${id}`)
    return res["hydra:member"];
}

export function modifySemaineType(datas:ModifySemaineTypeFormData):Promise<SemaineType>{
    return dataFetcher(`semaine_types/${datas.id}`, {
        method:'put',
        body:datas
    })
}

export function trashSemaineType(id:number):Promise<any>{
    return dataFetcher(`semaine_types/${id}`,{
        method:'DELETE',
    })
}

export function addSemaineType(datas:AddSemaineTypeFormData):Promise<SemaineType>{
    return dataFetcher(`semaine_types`, {
        method:'post',
        body:datas
    })
}

export async function getProjections():Promise<OneProjection[]>{
    return await dataFetcher(`get_projection_st`)
}