import React from "react";
import styled from "styled-components";
import TimedSkeletonList from "./TimedSkeletonList";

interface TimedSkeletonTableCtrlProps{
    className?:string;
    nbLines?:number;
    nbColumns?:number;
}

const TimedSkeletonTableCtrl = ({className, nbLines, nbColumns}:TimedSkeletonTableCtrlProps)=>{
    return (
        <div className={`TimedSkeTab ${className}`}>
            <div className="head"/>
            <div className="body">
                <TimedSkeletonList nbBar={nbColumns} qty={nbLines}/>
            </div>
        </div>
    )
}

const TimedSkeletonTable = styled(TimedSkeletonTableCtrl)`
  background: white;
  width: 100%;
  border-radius: 8px;
  .head{
    width: 100%;
    height: 38px;
    border-bottom: ${props=>props.theme.Neutre};
  }
  .body{
    padding: 0.5rem;
  }
`

export default TimedSkeletonTable;