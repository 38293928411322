import styled from "styled-components";
import {useSetLitts} from "../../../../features/centre/centre.hooks";
import {useEffect, useState} from "react";
import InputSelectSetting from "./InputSelectSetting";
import {useDebounce} from "../../../../services/hooks";

interface SettingYesNoLittsCtrlProps{
    className?:string;
    keyLitts:string;
    currentValue:Choice|null;
    choices:Choice[];
    litts:any;
    idCentre:number;
    InfosProp:InfosProps;
    label:string;
}

const SettingYesNoLittsCtrl = (props:SettingYesNoLittsCtrlProps)=>{
    const mutation = useSetLitts();
    const [myValueX, setMyValueX] = useState<Choice|null>(props.currentValue)
    const myValueSaved = useDebounce(myValueX, 300)
    useEffect(() => {
        console.log(props.keyLitts)
        console.log(myValueX)
    }, [myValueX, props.keyLitts]);
    const setMyValue = (value:string|null|Choice|number, id:string)=>{
        setMyValueX(value as Choice)

    }
    useEffect(()=>{
        if(myValueSaved && myValueSaved.id !== props.currentValue?.id) {
            const actual = {...props.litts}
            const newLitts = {...actual, [props.keyLitts]: (myValueX?.id || 0)}
            mutation.mutate({idCentre: props.idCentre, litt: newLitts})
        }
    }, [myValueSaved])
    return (
        <div className={`settingYesNoLitts ${props.className}`}>
            <InputSelectSetting wdLib={"35%"} setValue={setMyValue} Error={null} label={props.label} id={props.keyLitts} choices={props.choices} Current={myValueX} ItemInfos={props.InfosProp}/>
        </div>
    )
}

const SettingYesNoLitts = styled(SettingYesNoLittsCtrl)`
    
`

export default SettingYesNoLitts