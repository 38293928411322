import styled from "styled-components";
import {Dna} from "react-loader-spinner";

interface WaitingVerifCtrlProps{
    className?:string;
}

const WaitingVerifCtrl = (props:WaitingVerifCtrlProps)=>{
    return (
        <div className={`waiting_verif ${props.className}`}>
            <div className={`wrap_chargement`}>
                <div className={"spinner_wrap"}>
                    <Dna
                        visible={true}
                        height="80"
                        width="80"
                        ariaLabel="dna-loading"
                        wrapperStyle={{}}
                        wrapperClass="dna-wrapper"
                    />
                </div>
                <p>Chargement en cours...</p>
            </div>
        </div>
    )
}

export const WaitingVerif = styled(WaitingVerifCtrl)`
    width: 100%;
    height: 100%;
  position: relative;
  .wrap_chargement{
    padding: 1rem;
    position: absolute;
    left: 50%;
    top:30%;
    transform: translate(-50%,-50%);
    p{
      font-size: 20px;
    }
  }
  .spinner_wrap{
    display: flex;
    justify-content: center;
  }
`