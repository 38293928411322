import React, {useMemo, useState} from "react";
import styled from "styled-components";
import TimedModal from "../../components/molecules/TimedModal/TimedModal";
import {useGetUsersByFonctionFAdm} from "../../features/user/user.hooks";
import {useSendEmailRplt} from "../../features/creneauEmailSender/creneauEmailSender.hooks";
import TimedButton from "../../components/atoms/TimedButton/TimedButton";
import {useCentreConnectedData} from "../../services/hooks";

interface ModalSendEmailRemplCtrlProps{
    className?:string;
    Close:Function;
    supervisor?:boolean;
}

const ModalSendEmailRemplCtrl = ({className,  ...props}:ModalSendEmailRemplCtrlProps)=>{
    const centre = useCentreConnectedData();
    const usersQuery = useGetUsersByFonctionFAdm(1,3, props.supervisor ? centre : null, true);
    const mutationAdd = useSendEmailRplt();
    const [nivoSelect, setNivoSelect] = useState<number|null>(null);
    const listLevels = useMemo(()=>{
        let myList:(number|null)[] = [];
        if(usersQuery.data){
            const TabLevels = usersQuery.data.filter(u=>u.levelRplt).map(u=>u.levelRplt);
            myList = Array.from(new Set(TabLevels));
        }
        return myList;
    }, [usersQuery.data])
    const Submit = ()=>{
        if(nivoSelect){
            const datas:CreneauEmailSenderFD={
                level:nivoSelect
            }
            mutationAdd.mutate((datas), {
                onSuccess:()=>{
                    props.Close();
                },onError:()=>{
                    props.Close();
                }
            })
        }
    }
    const handleClickClose = ()=>{
        props.Close();
    }
    return (
        <TimedModal {...props}
                    title={"Envoi des emails"}
                    size={"regular"}
                    Close={props.Close}
                    position={"center"}
                    topPosition={"Middle"}
        >
            <div className={`ModalSendEmailRplt ${className}`}>
                {usersQuery.isLoading ?
                    <p>Chargement des niveaux disponibles</p>:
                    <div className={`wrapper-choice-niveaux`}>
                        <p>Merci de sélectionner le niveau des médecins remplaçants qui recevront un email :</p>
                        <div className={`wrap-choice-niveaux`}>
                            {listLevels.filter(i=>!!i).map((item:number|null, idx:number)=>(
                                <div key={`oneNiveau${idx}`} className={`oneNiveau ${nivoSelect === item ? 'selected' : ''}`} onClick={()=>setNivoSelect(item)}>Niveau {item ? item : 0}</div>
                            ))}
                        </div>
                        <div className={`actions-sender`}>
                            {!mutationAdd.isLoading &&
                                <TimedButton
                                    text={"Annuler"}
                                    themeColor={"Warning"}
                                    onClick={handleClickClose}
                                />
                            }
                            {nivoSelect &&
                                <TimedButton
                                    text={"Envoyer"}
                                    themeColor={"Primary"}
                                    onClick={Submit}
                                    isPending={mutationAdd.isLoading}
                                />
                            }
                        </div>
                    </div>
                }
            </div>
        </TimedModal>
    )
}

const ModalSendEmailRempl = styled(ModalSendEmailRemplCtrl)`
    padding: 1rem;
  .actions-sender{
    margin: 20px auto 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 20px;
  }
  .wrapper-choice-niveaux{
    p{
      text-align: center;
      font-size: 14px;
    }
    .oneNiveau{
      padding: 0.5rem 0.75rem;
      font-size: 14px;
      border:${props => props.theme.Primary} solid 1px;
      border-radius: 16px;
      color: ${props => props.theme.Primary};
      &:hover, &.selected{
        cursor: pointer;
        border:${props => props.theme.SuccessExtraLight} solid 1px;
        background: ${props=>props.theme.SuccessExtraLight};
        color: ${props=>props.theme.SecondaryDark};
      }
    }
    .wrap-choice-niveaux{
      margin: 20px auto;
      display: flex;
      justify-content: flex-start;
      gap: 10px;
    }
  }
`

export default ModalSendEmailRempl;