import styled from "styled-components";
import {LineUser, OnePlageChrono} from "../LivePointage";
import {OnePlageLivePointage} from "./OnePlageLivePointage";
import TimedRoundAvatar from "../../../../components/atoms/TimedRoundAvatar/TimedRoundAvatar";
import React, {useEffect, useMemo, useState} from "react";

interface OneLineLivePointageCtrlProps{
    className?:string;
    LineUser:LineUser
    HourStart:number;
    HourEnd:number;
    widthHour:number;
    widthUser:number;
    isPast?:boolean;
}

interface OnePresence{
    start:number;
    end:number;
}

const OneLineLivePointageCtrl = (props:OneLineLivePointageCtrlProps)=>{
    const [isPrez, setIsPrez] = useState(false);
    const [isPb, setIsPb] = useState(false);
    const [hourActual, setHourActual] = useState<number>(0)
    useEffect(()=>{
        const timer = setInterval(()=>{
            const timeLocal = new Date().toLocaleTimeString();
            const tabTime = timeLocal.split(":");
            const Actual = parseInt(tabTime[0]) + parseInt(tabTime[1])/60 + parseInt(tabTime[2])/3600
            setHourActual(Actual)
        }, 1000);

        return ()=>{
            clearInterval(timer)
        }

    }, [])
    let hourStart = props.HourStart;
    const TabHour:React.ReactNode[] = [];
    let idx = 0;
    while(hourStart<=props.HourEnd){
        TabHour.push(<div className={`one_hour_i`} style={{width:(100/((props.HourEnd+1) - props.HourStart))+"%", left:((idx*100)/((props.HourEnd+1) - props.HourStart))+"%"}} key={`oH2${hourStart}`}/> )
        hourStart++;
        idx++;
    }
    useEffect(()=>{
        if(props.isPast){
            setIsPrez(true)
        } else if(props.LineUser.Pointeuses.length > 0){
            const Pointages = props.LineUser.Pointeuses.sort((a:Pointeuse, b:Pointeuse)=>new Date(a.pointAt) > new Date(b.pointAt) ? 1 : -1);
            const Last = Pointages[Pointages.length-1];
            setIsPrez(Last.type === 'E');
        }
    }, [props.LineUser])
    const ConvertTimeStr=(p:Pointeuse)=>{
        const timeS = p.pointAt;
        const TabSA = timeS.split('T');
        const TabSB = TabSA[1].split('+')
        const tabTimeS = TabSB[0].split(":");
        const timeSnb = parseInt(tabTimeS[0]) + parseInt(tabTimeS[1])/60 + parseInt(tabTimeS[2])/3600;
        return timeSnb;
    }
    const listPrezence = useMemo(()=>{
        const list:OnePresence[] = [];
        const Pointages = props.LineUser.Pointeuses.sort((a:Pointeuse, b:Pointeuse)=>new Date(a.pointAt) > new Date(b.pointAt) ? 1 : -1);
        for(let i=0; i<Pointages.length; i++){
            if(Pointages[i].type === 'E'){
                const timeS = Pointages[i].pointAt;
                const TabSA = timeS.split('T');
                const TabSB = TabSA[1].split('+')
                const tabTimeS = TabSB[0].split(":");
                const timeSnb = parseInt(tabTimeS[0]) + parseInt(tabTimeS[1])/60 + parseInt(tabTimeS[2])/3600;
                let timeEnb = hourActual;
                if(i+1 <= Pointages.length-1){
                  const timeE =   Pointages[i+1].pointAt;
                    const TabEA = timeE.split('T');
                    const TabEB = TabEA[1].split('+')
                   const tabTimeE = TabEB[0].split(":");
                    timeEnb = parseInt(tabTimeE[0]) + parseInt(tabTimeE[1])/60 + parseInt(tabTimeE[2])/3600;
                }
                list.push({
                    start: timeSnb,
                    end:timeEnb
                })
            }
        }

        return list;
    }, [props.LineUser, hourActual])
    useEffect(()=>{
        if(isPrez){
            setIsPb(false);
        } else {
            const Pointages = props.LineUser.Pointeuses.sort((a:Pointeuse, b:Pointeuse)=>new Date(a.pointAt) > new Date(b.pointAt) ? 1 : -1);
            const Plages = props.LineUser.Plages.sort((a:Plage, b:Plage)=>a.heureDebut>b.heureDebut ? 1 : - 1);
            const PlageConc = Plages.find(p=>p.heureDebut/12 <= hourActual && p.heureFin/12 >=hourActual);
            if(!PlageConc){
                setIsPb(false);
            } else {
                if(Pointages.length === 0 || (Pointages[Pointages.length-1].type === 'S' && (ConvertTimeStr(Pointages[Pointages.length-1])<PlageConc.heureFin/12)  )){
                    setIsPb(true);
                }
            }
        }

    }, [props.LineUser, hourActual, isPrez, listPrezence])
    return (
        <div className={`one_line_live_pointage ${props.className}`}>
            <div className={`cell_user`} style={{width:props.widthUser+"%"}}>
                <div className={"content_user"}>
                    <div className={`avatar_place`}>
                        {isPrez && !isPb &&
                            <TimedRoundAvatar src={props.LineUser.User.avatarUrl!==undefined ? props.LineUser.User.avatarUrl : '' } size={"other"} sizeP={"30px"} isSquare={true}/>
                        }
                    </div>
                    <div className={`name_fx ${(isPrez && !isPb) ? 'success' : isPb ? 'warning' : ''}`}>
                        <div>{props.LineUser.User.prenom} {props.LineUser.User.nom}</div>
                        <div className={`fx`}>{props.LineUser.User.Fonction.libelle}</div>
                    </div>
                </div>
            </div>
            <div className={`wrap_times`} style={{width:(100-props.widthUser)+"%"}}>
                {props.LineUser.Plages.map(item=>(
                    <OnePlageLivePointage key={`OP${item.id}`} HourStart={props.HourStart} HourEnd={props.HourEnd} plage={item}/>
                ))}
                <div className={"wrapper_hour_i"}>{TabHour}</div>
                {listPrezence.map((item, idx:number)=>(
                    <div className={"surr"} key={`prez${idx}`} style={{left:(item.start - props.HourStart)*100/((props.HourEnd+1) - props.HourStart)+"%", width:(item.end - item.start)*100/((props.HourEnd+1) - props.HourStart)+"%"}} />
                ))}
            </div>
        </div>
    )
}

export const OneLineLivePointage = styled(OneLineLivePointageCtrl)`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  border-bottom: solid 1px #ccc;
  align-content: stretch;
  position: relative;
  .cell_user{
    padding: 0.25rem;
  }
  .name_fx{
    color:${props => props.theme.Neutre};
    &.success{
      color:${props => props.theme.SecondaryDark};
      font-weight: bold;
    }
    &.warning{
      color:${props => props.theme.ComplementaryDark};
      font-weight: bold;
    }
  }
  .content_user{
    display: flex;
    justify-content: flex-start;
    gap: 12px;
    align-content: center;
    .name_fx{
      display: flex;
      justify-content: center;
      flex-direction: column;
      font-size: 14px;
      .fx{
        font-size: 13px;
        font-style: italic;
      }
    }
  }
  .wrap_times{
      position: relative;
    
      display: flex;
  }
  .surr{
    background: rgba(0,125,0,.2);
    height: 100%;
    position: absolute;
    z-index: 2;
  }
  .wrapper_hour_i{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    height: 12px;
  }
  .one_hour_i{
    height: 8px;
    border-left: solid #ccc 1px;
    position: absolute;
    bottom: 0;
    width: 100%;
  }
  .avatar_place{
    width: 30px;
    height: 30px;
  }
`