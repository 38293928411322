import React from "react";
import styled from "styled-components";

interface TimedHelperTextCtrlProps{
    className?:string;
    text:string;
}

const TimedHelperTextCtrl = ({className, text}:TimedHelperTextCtrlProps)=>{
    return (
        <div className={`helper-text ${className}`}>{text}</div>
    )
}

export const TimedHelperText = styled(TimedHelperTextCtrl)`
  color: rgba(0, 0, 0, 0.6);
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin: 3px 14px 0;
  color: red;
`