import React from "react"
import styled, {keyframes} from "styled-components";
import {BodyDash, ContentDash, Shine, TitleDash, Widy} from "../../../assets/styles/elements";
import {MdLaunch} from "react-icons/md";
import {useLocation, useNavigate} from "react-router-dom";

interface WidgetMetrixCtrlProps{
    className?:string;
    title:string;
    Metrix:number|string;
    width:string;
    subTitle?:string;
    isPending?:boolean;
    redirect?:string;
    isAlert?:boolean;
    isNoAlert?:boolean;
    speFrom?:string;
}

const WidgetMetrixCtrl = ({className, title, Metrix, subTitle, isPending, redirect, isNoAlert, isAlert, speFrom}:WidgetMetrixCtrlProps)=>{
    const location = useLocation();
    const { search } = useLocation();
    const navigate = useNavigate()
    const GoRedirect =()=>{
        if(redirect){
            const TabNav = location.pathname.split('/');
            navigate(`${redirect}${search}`, {state:{from:speFrom ? speFrom : TabNav[2]}})
        }
    }
    return (
        <Widy className={`widget_metrix ${className} ${isAlert ? "is-alerte" : ""} ${isNoAlert ? "is-no-alert" : ""}`}>
            <ContentDash>
                <TitleDash>
                    <div className={`main-title`}>
                        {!!redirect ?
                            <div className={`wrap-titre-redirect`}>
                                <div className={`lib-title`}>{title}</div>
                                <div className={`wrap-redirect`} onClick={GoRedirect}><MdLaunch/></div>
                            </div>:title
                        }
                    </div>
                    {subTitle &&
                        <div className={`sub-title`}>{subTitle}</div>
                    }
                </TitleDash>
                <BodyDash className={`${subTitle ? 'suby' : ''}`}>
                    <div className={"NumberMetrix"}>
                        {isPending ?
                            <div className={`Pending-Number`}>00</div>:

                                <span>{Metrix}</span>

                        }
                    </div>
                </BodyDash>
            </ContentDash>
        </Widy>
    )
}

const Stripes = keyframes`
  100% {
    background-position: 0 -50px;
  }
`

const WidgetMetrix = styled(WidgetMetrixCtrl)`
  
  width: ${props=>props.width};
  height: 100%;
  .Pending-Number {
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    background-size: 200% 100%;
    animation: 1.5s ${Shine} linear infinite;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-animation: 1.5s ${Shine} linear infinite;
    font-size: calc(26px + 0.75vw);
  }
  .NumberMetrix{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: calc(26px + 0.75vw);
    text-align: center;
    font-weight: bold;
    color:${props=>props.theme.Primary};
  }
  
`

export default WidgetMetrix;