
import React, {useEffect, useState} from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import {GrClose} from "react-icons/gr";

export interface TimedModal2CtrlProps{
    className?:string;
    open:boolean
    children:React.ReactNode
    closeMe:()=>void;
    titre:string;
    Wd?:string;
}

export interface TimedModal2rootCtrlProps{
    className?:string;
    open:boolean
    closeMe:()=>void;
    titre:string;
    Wd?:string;
}

const VISIBLE = 1;
const HIDDEN = 2;
const ENTERING = 3;
const LEAVING = 4;

const TimedModal2Ctrl = ({className, open, children, closeMe, titre, Wd}:TimedModal2CtrlProps)=>{
    // const childrenRef = useRef<ReactNode>(children)
    const [state, setState] = useState(open ? VISIBLE : HIDDEN);
    // if(open){
    //     childrenRef.current = children
    // }
    useEffect(()=>{
        if(!open){
            setState(LEAVING)
        } else{
            setState((s)=>s===HIDDEN ? ENTERING : LEAVING)
        }
    }, [open])
    useEffect(()=>{
        if(state === LEAVING){
            const timer = setTimeout(()=>{
                setState(HIDDEN)
            }, 300)
            return ()=>{
                clearTimeout(timer);
            }
        } else if (state === ENTERING){
            const xx = document.body.offsetHeight
            setState(VISIBLE);
        }
    }, [state])

    const style = {transitionDuration: `300ms`, transitionProperty:"opacity", opacity:1}
    const styleModal = {transitionDuration: `300ms`, transitionProperty:"width", width:Wd ? Wd : "350px"}
    if(state!==VISIBLE){
        style.opacity = 0;
        styleModal.width = "0";
    }


    if(state === HIDDEN){
        return null;
    } else {
        return ReactDOM.createPortal(
            <div className={`timed_modal ${className}`}>
                <div style={style} className={`back-drop`} onClick={()=>closeMe()}/>
                <div className={`modal_content`} style={styleModal}>
                    <div className={`header_modal`}>
                        <div className={`titre_modal`}>{titre}</div>
                        <div className="close_modal" onClick={()=>closeMe()}>
                            <GrClose/>
                        </div>
                    </div>
                    <div className={`body_modal`}>
                        {children}
                    </div>
                </div>
            </div>, document.body
        )
    }

}

const TimedModal2 = styled(TimedModal2Ctrl)`
    .back-drop{
      position:fixed;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(0,0,0,.7);
      z-index: 10;
    }
  .modal_content{
    height: 100%;
    background: white;
    width: ${props=>props.Wd ? props.Wd : "350px"};
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 10;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    .body_modal{
      overflow: hidden;
      flex-grow: 1;
      height: 0;
      overflow-y: auto;
    }
    .header_modal{
      width: 100%;
      display: flex;
      justify-content: flex-start;
      padding: 0.5rem;
      align-content: center;
      .titre_modal{
        flex-grow: 1;
        font-weight: bold;
      }
      .close_modal{
        &:hover{
          cursor: pointer;
        }
      }
    }
  }
`

export default TimedModal2;