import React, {useMemo, useState} from "react";
import styled from "styled-components";
import { TitrePage } from "../../../../assets/styles/elements";
import TimedIconButton from "../../../../components/atoms/TimedIconButton/TimedIconButton";
import { AiOutlinePlus } from "react-icons/ai";
import {useGetNewsByParams} from "../../../../features/news/news.hooks";
import TimedChips from "../../../../components/atoms/TimedChips/TimedChips";
import {CardNews} from "./CardNews";
import {useModal} from "../../../../hooks/useModal";
import {ModalNewNews} from "../../../Modaux/ModalNewNews";
import TimedSkeleton from "../../../../components/atoms/TimedSkeleton/TimedSkeleton";
import NoRes from "../../../../assets/images/No-Results.png";
import {useCentreConnectedData} from "../../../../services/hooks";
import {BsGear} from "react-icons/bs";
import {TimedModalCentral} from "../../../../components/molecules/TimedModal/TimedModalCentral";
import {ModalGestionPosition} from "./ModalGestionPosition";

interface NewsCtrlProps{
    className?:string;
    supervisor?:boolean;
}

export interface OneItemChips{
    id:number;
    libelle:string;
    themeColor:string;
}

const TabChipsNews:OneItemChips[] = [
    {id:0, libelle:"Active", themeColor:"Secondary"},
    {id:1, libelle:"Inactive", themeColor:"Neutre"},
]

const TabChipsChrono:OneItemChips[] = [
    {id:2, libelle:"En cours", themeColor:"Secondary"},
    {id:3, libelle:"Programmées", themeColor:"Tertiary"},
    {id:4, libelle:"Terminées", themeColor:"Complementary"}
]

const NewsCtrl=({className, supervisor}:NewsCtrlProps)=>{
    const centre = useCentreConnectedData();

    const NewsQuery = useGetNewsByParams(null, supervisor ? centre : null);
    const {open, toggle} = useModal();
    const {open:openGestion, toggle:toggleGestion} = useModal();

    const [status, setStatus] = useState<OneItemChips[]>(TabChipsNews);
    const [statusC, setStatusC] = useState<OneItemChips[]>(TabChipsChrono);
    const [newsCurr, setNewsCurr] = useState<News|null>(null);

    const SwitchStatut = (item:OneItemChips, db:boolean=false)=>{
        const Exist = [...status].find(i=>i.id===item.id);
        if(db){
            setStatus([item]);
        } else {
            setStatus(Exist ? [...status].filter(i=>i.id!==item.id) : [...status, item]);
        }

    }
    const SwitchChrono = (item:OneItemChips, db:boolean=false)=>{
        const Exist = [...statusC].find(i=>i.id===item.id);
        if(db){
            setStatusC([item]);
        } else {
            setStatusC(Exist ? [...statusC].filter(i=>i.id!==item.id) : [...statusC, item]);
        }

    }
    const listNews = useMemo(()=>{
        if(!NewsQuery.data){
            return [];
        } else {
            return NewsQuery.data.filter(n=>{
                const idsStatut = status.map(s=>s.id);
                const idsChrono = statusC.map(c=>c.id);
                const isProgramme = new Date(n.startAt) > new Date()
                const isTermine = n.endAt!==null && new Date(n.endAt) < new Date();
                const isEnCours = !isProgramme && !isTermine
                const isActive = n.isActive;
                if(idsStatut.indexOf(0) === -1 && isActive){
                    return false;
                }
                if(idsStatut.indexOf(1) === -1 && !isActive){
                    return false;
                }

                if(idsChrono.indexOf(4) === -1 && isTermine){
                    return false;
                }

                if(idsChrono.indexOf(2) === -1 && isEnCours){
                    return false;
                }
                if(idsChrono.indexOf(3) === -1 && isProgramme){
                    return false;
                }
                return true;

            }).sort((a:News, b:News)=>new Date(a.startAt) > new Date(b.startAt) ? -1 : 1)
        }
    }, [NewsQuery.data, status, statusC])
    const handleClickCreate = ()=>{
        setNewsCurr(null);
        toggle();
    }
    const setNewsEdit = (news:News)=>{
        setNewsCurr(news);
        toggle();
    }
    const HandleClickGestion = ()=>{
        toggleGestion()
    }
    return (
        <div className={`FP ${className}`}>
            <TitrePage>Les News</TitrePage>
            <div className={`wrap-line-action`}>
                <div className={`wrap-chips`}>
                    <div className={`wrap-statut wrappy-chips`}>
                        {TabChipsNews.map((item: OneItemChips, idx: number) => (
                            <TimedChips
                                key={`oneFilterStatut${idx}`}
                                themeColor={item.themeColor}
                                choices={status}
                                MyChoice={item}
                                setChoice={SwitchStatut}
                                dbClickChoice={SwitchStatut}
                            />
                        ))}
                    </div>
                    <div className={`wrap-statut wrappy-chips`}>
                        {TabChipsChrono.map((item: OneItemChips, idx: number) => (
                            <TimedChips
                                key={`oneFilterChrono${idx}`}
                                themeColor={item.themeColor}
                                choices={statusC}
                                MyChoice={item}
                                setChoice={SwitchChrono}
                                dbClickChoice={SwitchChrono}
                            />
                        ))}
                    </div>
                </div>
                <div className={`wrap-action`}>
                    <TimedIconButton
                        size={"medium"}
                        themeColor={"Primary"}
                        Icon={<BsGear/>}
                        toolTip={"Gestion des positions"}
                        onClick={HandleClickGestion}
                        BackAlw={true}
                        isActive={false}
                    />
                    <TimedIconButton
                        size={"medium"}
                        themeColor={"Primary"}
                        Icon={<AiOutlinePlus/>}
                        toolTip={"Créer une news"}
                        onClick={handleClickCreate}
                        BackAlw={true}
                        isActive={false}
                    />
                </div>
            </div>
            <div className={`wrap-news-content`}>
                {NewsQuery.isLoading ?
                    <TimedSkeleton type={"perso"} nbOccurence={5} width={"100%"} heightPerso={"110px"}/>:
                    listNews.length === 0 ?
                        <div className={`block-no-res`}>
                            <div className={"img-place"}>
                                <img src={NoRes} alt={"aucun-resultat"}/>
                            </div>
                            <div className={`legende`}>Aucun résultat</div>
                        </div>
                        :
                        listNews.map((item:News, idx:number)=>(
                            <CardNews key={`News${idx}`} News={item} setNewsEdit={setNewsEdit}/>
                        ))
                }
            </div>
            {open &&
                <ModalNewNews
                    title={newsCurr ? "Edition" : "Nouvelle news"}
                    size={"regular"}
                    position={"right"}
                    topPosition={"Top"}
                    Close={toggle}
                    News={newsCurr ? newsCurr : undefined}
                    supervisor={supervisor}
                />
            }
            <TimedModalCentral closeMe={toggleGestion} open={openGestion} Pending={false} titre={"Gestion de l'ordre"} Wd={500}>
                <ModalGestionPosition/>
            </TimedModalCentral>
        </div>
    )
}

const News = styled(NewsCtrl)`
    padding: 1rem;
  .block-no-res{
    width: 100%;
    .img-place, .legende{
      text-align: center;
    }
    .legende{
      padding: 0.5rem;
      text-align: center;
      font-size: 14px;
      font-weight: bold;
    }
  }
  .wrap-news-content{
    margin-top: 50px;
  }
  .wrap-chips{
    display: flex;
    gap: 30px;
    .wrappy-chips{
      display: flex;
    }
  }
  .wrap-line-action{
    display: flex;
    justify-content: space-between;
    margin: 15px 0 5px 0;
  }
  .wrap-action{
    padding: 0.25rem 2rem;
    gap: 10px;
    display: flex;
    justify-content: end;
  }
`

export default News;