import React from "react";
import styled from "styled-components";
import {RegisterOptions} from "react-hook-form";

interface TimedCheckBoxHCtrlProps{
    className?:string;
    themeColor?:"primary"|"secondary"|"success"|"warning";
    size:"regular"|"large"|"xl";
    label:string;
    id:string;
    register: any;
    optionRegister?:RegisterOptions;
}

const TimedCheckBoxHCtrl = ({className, id, label, register, optionRegister}:TimedCheckBoxHCtrlProps)=>{

    return (
        <div className={`TimedCheckBox ${className}`}>
            <input
                {...register(id, optionRegister)}
                type="checkbox"

            />
            <label>{label}</label>
        </div>
    );
}

const TimedCheckBoxH = styled(TimedCheckBoxHCtrl)`
    label{
      margin-left: 10px;
    }
`

export default TimedCheckBoxH;