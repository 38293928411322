import React from "react";
import styled from "styled-components";
import {Outlet} from "react-router-dom";
import TimedHeader from "../../molecules/TimedHeader/TimedHeader";
import TimedFullSideBar from "../../molecules/TimedFullSideBar/TimedFullSideBar";
import {useModal} from "../../../hooks/useModal";
import TimedNotificationCurtain from "../../molecules/TimedNotificationsCenter/TimedNotificationCurtain";
import {UseQueryResult} from "react-query";
import {useGetUser} from "../../../features/user/user.hooks";
import {TimedHeaderSV} from "../../molecules/TimedHeader/TimedHeaderSV";

interface MainLayoutSVCtrlProps{
    className?:string;
}

const MainLayoutSVCtrl = ({className}:MainLayoutSVCtrlProps)=>{
    const UserQuery = useGetUser();
    const {open, toggle} = useModal();
    return (
        <div className={`MainLayoutSV ${className}`}>
            <TimedFullSideBar UserQuery={UserQuery} supervisor={true}/>
            <div className={`MainLay`}>
                <TimedHeaderSV/>
                <div className={`MainContent`}>
                    <Outlet/>
                </div>
                <TimedNotificationCurtain Open={open} SwitchOpen={toggle}/>
            </div>
        </div>
    )
}

const MainLayoutSV = styled(MainLayoutSVCtrl)`
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: stretch;
  
  .MainLay{
    overflow: hidden;
    position: relative;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    .MainContent{
      overflow-y: auto;
      height: calc(100% - 90px);
    }
  }
`

export default MainLayoutSV;