import React from "react";
import styled from "styled-components";

interface TimedSquareInfosCtrlProps{
    className?:string;
    title:string;
    children:React.ReactNode;
}

const TimedSquareInfosCtrl = ({className, title, children}:TimedSquareInfosCtrlProps)=>{
    return (
        <div className={`TimedSquareInfos ${className}`}>
            <div className={"title"}>{title}</div>
            <div className={"content"}>{children}</div>
        </div>
    )
}

const TimedSquareInfos = styled(TimedSquareInfosCtrl)`
    width: 100%;
    padding: 0.25rem;
  display: flex;
  flex-direction: column;
  justify-content: start;
  .title{
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 15px;
  }
  .content{
    flex-grow: 1;
  }
`

export default TimedSquareInfos;