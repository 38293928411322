import {dataFetcher} from "../../services/dataGuetter/dataFetcher";

/**
 * @summary permet de récupérer les acquisitions d'un user en particulier
 * @param id idUser concerné
 */
export async function getAcquiUser(id:number):Promise<AcquisitionAbs[]>{
    const res:any = await dataFetcher(`acquisition_droits_absences?User=${id}`, {method:'get'})
    return res["hydra:member"];
}

export function AddReguleAcquiUser(datas:PostAcquisitionAbs):Promise<AcquisitionAbs>{
    return dataFetcher(`acquisition_droits_absences`, {
        method:'post',
        body:datas
    })
}

export function ModifySoldeDepart(datas:ModifSoldeDepart):Promise<AcquisitionAbs>{
    return dataFetcher(`acquisition_droits_absences/${datas.id}`, {
        method:'put',
        body: {qty:datas.qty}
    })
}

export function CancelAcquisition(id:number):Promise<AcquisitionAbs>{
    return dataFetcher(`acquisition_droits_absences/${id}/cancel_acquisition`, {
        method:'post',
        body:{}
    })
}

