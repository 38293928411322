import {dataFetcher} from "../../services/dataGuetter/dataFetcher";


export async function getAllLieuHorairesByLieu(id:number):Promise<LieuHoraires[]>{
    const res:any = await dataFetcher(`lieu_horaires?Lieu=${id}`, {method:'get'})
    return res["hydra:member"];
}

export async function getAllLieuHorairesByCentre(id:number):Promise<LieuHoraires[]>{
    const res:any = await dataFetcher(`lieu_horaires?Lieu.Centre=${id}`, {method:'get'})
    return res["hydra:member"];
}

export function putLieuHoraires(datas:LieuHoraireFD):Promise<LieuHoraires>{
    return dataFetcher(`lieu_horaires/${datas.id}`, {
        method:"put",
        body:datas
    })
}