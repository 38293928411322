import styled from "styled-components";
import {useCallback, useEffect, useState} from "react";
import update from "immutability-helper";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import OneCardLieu from "../04_LieuxVues/components/OneCardLieu";
import OneCardLieuInactif from "../04_LieuxVues/components/OneCardLieuInactif";
import {useChangeOrdreActe} from "../../../../features/acte/acte.hooks";
import OneCardActe from "./components/OneCardActe";
import OneCardActeInactif from "./components/OneCardActeInactif";

interface WrapListActeCtrlProps{
    className?:string;
    Actes:Acte[];
    ActeActif:Acte|null;
    isActifView:boolean;
    setActeActif:(a:Acte)=>void;
}

const WrapListActeCtrl = (props:WrapListActeCtrlProps)=>{
    const mutation = useChangeOrdreActe();
    const [myActes, setMyActes] = useState<Acte[]>([])
    useEffect(()=>{
        console.log("log 1")
        if(props.Actes.length>0){
            setMyActes(props.Actes.filter(l=>l.isProduction === props.isActifView).sort((a,b)=>(a.ordreEdit||0) > (b.ordreEdit||0) ? 1 : -1));
        }
    }, [props.Actes, props.isActifView])
    const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
        setMyActes((prevCards: Acte[]) =>
            update(prevCards, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, prevCards[dragIndex] as Acte],
                ],
            }),
        )
        //SaveMove();
    }, [])
    const SaveMove = ()=>{
        const ids = myActes.map(l=>l.id).join('_');
        const datas:ActesChangeOrdre = {actes:ids};
        mutation.mutate((datas))
    }
    return (
        <div className={`wrapListActe wrap_list ${props.className}`}>
            {props.isActifView ?
                <DndProvider backend={HTML5Backend}>
                    {myActes.map((item, idx)=>(
                        <OneCardActe
                            ClickOnEdit={()=>props.setActeActif(item)}
                            Acte={item}
                            key={`cL${item.id}`}
                            isCurrent={props.ActeActif?.id === item.id}
                            index={idx}
                            Move={moveCard}
                            SaveMove={SaveMove}
                        />
                    ))}
                </DndProvider>
                :
                <>
                    {myActes.map(item=>(
                        <OneCardActeInactif
                            key={`cL${item.id}`}
                            Acte={item}
                            isCurrent={props.ActeActif?.id === item.id}
                            ClickOnEdit={()=>props.setActeActif(item)}
                        />
                    ))}
                </>
            }
        </div>
    )
}

const WrapListActe = styled(WrapListActeCtrl)``

export default WrapListActe