import {dataFetcher} from "../../services/dataGuetter/dataFetcher";

export async function getCentreSemaineType(id:number, idCentre:number|null):Promise<CentreSemaineType[]>{
    let parms = `User=${id}`
    if(idCentre){
        parms+=`&Centre=${idCentre}`
    }
    const res:any = await dataFetcher(`centre_semaine_types?${parms}`);
    return res['hydra:member'];
}


export async function getAllCentreSemaineType(userConnected:UserSimple|undefined, idCentre:number|null):Promise<CentreSemaineType[]>{
    let url = userConnected===undefined ? `centre_semaine_types` : `centre_semaine_types?Centre=${userConnected.Centre.id}`;
    if(idCentre){
        url = `centre_semaine_types?Centre=${idCentre}`
    }
    const res:any = await dataFetcher(url);
    return res["hydra:member"]
}

export  function addCentreSemaineType(id:number):Promise<CentreSemaineType>{
    return dataFetcher(`centre_semaine_types`, {
        method:'post',
        body:{User:`/api/users/${id}`}
    })
}

export function convertPlToST(datas:ConvertPlToSTFormData):Promise<CentreSemaineType>{
    return dataFetcher(`users/${datas.idUser}/build_semType?dateStart=${datas.dateStart}`, {
        method:'post'
    })
}

export function trashCentreSemaineType(id:number):Promise<any>{
    return dataFetcher(`centre_semaine_types/${id}`, {
        method:'delete'
    })
}

export function duplicateCentreSemaineType(id:number):Promise<CentreSemaineType>{
    return dataFetcher(`centre_semaine_types/${id}/duplicate`, {
        method:'post',
        body:{}
    })
}

export function ChangeNamePerso(id:number, namePerso:string):Promise<CentreSemaineType>{
    return dataFetcher(`centre_semaine_types/${id}`, {
        method:'put',
        body:{namePerso:namePerso}
    })
}

export function getTimeCST(id:number):Promise<TimeCentreSemaineType>{
    return dataFetcher(`centre_semaine_types_get_time/${id}`)
}