import React from "react";
import styled from "styled-components";

interface ListChoiceCtrlProps{
    className?:string;
    children:React.ReactNode;
    posLeft?:string;
    posTop?:string;
    MaxList?:string;
}

const ListChoiceCtrl = ({className, children}:ListChoiceCtrlProps)=>{
    return (
        <div className={`list-choice ${className}`}>
            {children}
        </div>
    )
}

export const ListChoice = styled(ListChoiceCtrl)`
  position: absolute;
  padding: 0.25rem 0;
  z-index: 99;
  top:${props=>props.posTop ? props.posTop : "90%"};
  left: ${props=>props.posLeft ? props.posLeft : "0"};
  background: white;
  min-width: 150px;
  width: max-content;
  font-size: 16px;
  
  .in-list-user{
    max-height: ${props=>props.MaxList ? props.MaxList : "250px"};
    overflow-y: auto;
  }
  box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
`