import {dataFetcher} from "../../services/dataGuetter/dataFetcher";

export async function getAllLieux(idCentre:number|null, FilterVue:boolean):Promise<Lieu[]>{
    let url = `lieus`
    const TabParams:string[] = []
    if(idCentre){
        TabParams.push(`Centre=${idCentre}`);
    }
    if(FilterVue){
        TabParams.push(`Fvues=1`);
    }
    if(TabParams.length>0){
        url += '?'+TabParams.join('&')
    }
    const res:any = await dataFetcher(url, {method:'get'});
    return res["hydra:member"];
}

export async function getAllLieuxByCentre(idCentre:number):Promise<Lieu[]>{
    const res:any = await dataFetcher(`lieus?Centre=${idCentre}`, {method:'get'});
    return res["hydra:member"];
}

export function modifyLieu(id:number, datas:LieuFD):Promise<Lieu>{
    return dataFetcher( `lieus/${id}`, {
        method:'put',
        body:datas
    })
}

export function addLieu(datas:LieuFD):Promise<Lieu>{
    return dataFetcher(`lieus`, {
        method:'post',
        body:datas
    })
}

export function changeOrdreLieu(datas:LieuChangeOrdre):Promise<Lieu[]>{
    return dataFetcher(`lieus/updateOrdre`, {
        method:'post',
        body:datas
    })
}

export function TrashLieu(id:number):Promise<any>{
    return dataFetcher(`lieus/${id}`, {
        method:'delete',
    })
}