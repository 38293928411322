import styled from "styled-components";
import {TimedLoadingVLight} from "./TimedLoadingVLight";


interface TimedLoaderWrapperCtrlProps {
    className?:string;
    width?:string;
}

const TimedLoaderWrapperCtrl = ({width, className}: TimedLoaderWrapperCtrlProps) => {
    return (
        <div className={"wrap_load " +className }>
            <div className="in_loader">
                <TimedLoadingVLight width={width}/>
            </div>
        </div>
    )
}

export const TimedLoaderWrapper = styled(TimedLoaderWrapperCtrl)`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .in_loader{
        display: flex;
        justify-content: center;
    }
`