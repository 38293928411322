import {useQuery, UseQueryResult} from "react-query";
import {
    getAllCptAbs,
    getAllCptAbsDate, getCptAbsByParams,
    getCptAbsByUser,
    getCptAbsHeuresByUserDate,
    getCptsAbsUser,
    getCptsAbsUsers
} from "./compteurAbs.services";

export const useGetCptAbsByUser = (id:number, idCentre:number|null=null):UseQueryResult<CompteurAbs, Error>=>{
    return useQuery(["compteur_abs", id, idCentre], ()=>getCptAbsByUser(id, idCentre), {
        staleTime:60000,
        enabled:id!==0
    })
}

export const useGetCptAbsHeureUser = (id:number, date:string):UseQueryResult<CompteurAbs[], Error>=>{
    return useQuery(["compteur_abs_user_date", date, id], ()=>getCptAbsHeuresByUserDate(id, date), {
        staleTime:60000,
        enabled:id!==0 && date!==''
    })
}

export const useGetCptAbsByParams = (params:ParamsGetCompteurAbs|null):UseQueryResult<CompteurAbs[], Error>=>{
    return useQuery(["compteur_abs_params", params], ()=>getCptAbsByParams(params), {
        staleTime:60000,
        enabled:!!params
    })
}

export const useGetAllCptAbs = (idCentre:number|null=null):UseQueryResult<CompteurAbs[], Error>=>{
    return useQuery(["compteur_abs", idCentre], ()=>getAllCptAbs(idCentre), {
        staleTime:60000,
    })
}

export const useGetAllCptAbsDate = (date:string, idCentre:number|null=null):UseQueryResult<CompteurAbs[], Error>=>{
    return useQuery(["compteur_abs_date", date, idCentre], ()=>getAllCptAbsDate(date, idCentre), {
        staleTime:60000,
        refetchOnWindowFocus:false,
        enabled:date!==''
    })
}

export const useGetCptsAbsTabUser = (Users:User[], idCentre:number|null=null):UseQueryResult<CompteurAbs[], Error>=>{
    const ids = Users.map(u=>u.id).join("_")
    return useQuery(["compteur_abs", ids], ()=>getAllCptAbs(idCentre), {
        refetchOnWindowFocus:false,
        staleTime:60000,
        enabled:Users.length >0
    })
}

export const useGetCptsAbsOneUser = (id:number):UseQueryResult<CompteurAbs[], Error>=>{
    return useQuery(["compteur_abs_on", id], ()=>getCptsAbsUser(id), {
        refetchOnWindowFocus:false,
        enabled:id!==0
    })
}