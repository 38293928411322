import React, {useEffect, useState} from "react";
import styled, {useTheme} from "styled-components";
import {MdOutlineClose} from "react-icons/md";
import {useGetOnePointeuseTreat} from "../../features/pointeuseTreat/pointeuseTreat.hooks";
import TimedSkeletonList from "../../components/atoms/TimedSkeleton/TimedSkeletonList";
import TimedSkeletonTable from "../../components/atoms/TimedSkeleton/TimedSkeletonTable";
import {ResumeDayPointage} from "../TimedApplication/Pointages/components/ResumeDayPointage";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import {EditionPointage} from "../TimedApplication/Pointages/components/EditionPointage";
import {BasketToDrop} from "../TimedApplication/Pointages/components/BasketToDrop";

import TimedIconButton from "../../components/atoms/TimedIconButton/TimedIconButton";
import {BsPencil} from "react-icons/bs";
import TimedButton from "../../components/atoms/TimedButton/TimedButton";
import CustomDragLayerOHD from "../../components/atoms/TimedCustomLayers/CustomLayerOneHoraireDrag/CustomDragLayerOHD";

interface ModalGereDayPointageCtrlProps{
    className?:string;
    id:number;
    CloseMe:Function;
    supervisor?:boolean;
    preOpen?:boolean;
}

export const ItemTypes = {
    Horaire: 'Horaire'
}

const ModalGereDayPointageCtrl = ({className, id, CloseMe, supervisor, preOpen}:ModalGereDayPointageCtrlProps)=>{
    const theme = useTheme();
    const PointeuseTreatQuery = useGetOnePointeuseTreat(id);
    const [edit, setEdit] = useState(preOpen ? preOpen : false);
    const [editable, setEditable] = useState<boolean>(false);
    useEffect(()=>{
        if(PointeuseTreatQuery.data){
            setEditable(PointeuseTreatQuery.data.MethodTimes.id === 1)
        }
    }, [PointeuseTreatQuery.data])
    const CloseMeReset = ()=>{
        CloseMe();
    }
    return (
        <div className={`modal-two-part ${className}`}>
            <div className={"back-shadow"}/>
            <DndProvider backend={HTML5Backend}>
                <div className={`modal-in`}>
                    {edit &&
                        <div className={"part-left"} style={{background:theme.BodyBackground}}>
                            <div className={"title-modal"}>Edition</div>
                            {PointeuseTreatQuery.data &&
                                <EditionPointage PointeuseTreat={PointeuseTreatQuery.data} supervisor={supervisor}/>
                            }
                        </div>
                    }

                    <div className={"part-right"} style={{background:theme.BodyBackground}}>

                            <div className={"head-right"}>
                                <div className={"title-modal"}>Gestion temps journée {PointeuseTreatQuery.data ? new Date(PointeuseTreatQuery.data.dateAT.slice(0,10)).toLocaleDateString() : ''}</div>
                                <div className={"close-modal"}>
                                    <div className={"content-close"} onClick={CloseMeReset}><MdOutlineClose/></div>
                                </div>
                            </div>
                            <div className={`body-right`}>
                                <div className={"content-right"}>
                                <div className={"wrap-edit-btn"}>
                                    <TimedIconButton
                                        size={"sm"}
                                        themeColor={"Primary"}
                                        Icon={<BsPencil/>}
                                        onClick={()=>setEdit(_o=>!_o)}
                                        toolTip={"Editer la période"}
                                        BackAlw={false}
                                        isActive={edit}
                                    />
                                </div>
                                {PointeuseTreatQuery.isLoading ?
                                    <TimedSkeletonTable nbColumns={1} nbLines={3}/>:
                                    PointeuseTreatQuery.data &&
                                    <>
                                        {edit && editable &&
                                            <BasketToDrop PointeuseTreat={PointeuseTreatQuery.data} supervisor={supervisor}/>
                                        }
                                        <ResumeDayPointage PointeuseTreat={PointeuseTreatQuery.data} supervisor={supervisor}/>
                                    </>
                                }
                                </div>
                            </div>
                            <div className={"footer-right"}>
                                <TimedButton
                                    size={"sm"}
                                    themeColor={"Primary"}
                                    onClick={CloseMeReset}
                                    text={"Enregistrer"}
                                />
                            </div>
                        </div>
                </div>
                <CustomDragLayerOHD/>
            </DndProvider>
        </div>
    )
}

export const ModalGereDayPointage = styled(ModalGereDayPointageCtrl)`
  .footer-right{
    padding: 0.5rem;
    display: flex;
    justify-content: flex-end;
  }
  .title-modal{
    flex-grow: 1;
    padding-left: 0.5rem;
    font-size: 18px;
    color:${props => props.theme.Primary};
    font-weight: bold;
  }
  .wrap-edit-btn{
    display: flex;
    justify-content: flex-end;
    margin: 10px auto;
  }
  .content-right{
    height: 100%;
    overflow-y: auto;
  }
`