import React from "react";
import styled from "styled-components";
import {BodyDash, ContentDash, TitleDash, Widy, WrapBodyDash} from "../../../assets/styles/elements";
import TimedSkeletonList from "../../../components/atoms/TimedSkeleton/TimedSkeletonList";

interface WidgetListCtrlProps{
    className?:string;
    children:React.ReactNode;
    width?:string;
    height?:string;
    title?:string;
    subTitle?:string;
    isPending?:boolean;
    isFullH?:boolean;
    isAlert?:boolean;
    isNoAlert?:boolean;
}

const WidgetListCtrl = ({className, children, title, subTitle, isPending, isFullH, isAlert, isNoAlert}:WidgetListCtrlProps)=>{
    return (
        <Widy className={`widget_list ${className} ${isAlert ? "is-alerte" : ""} ${isNoAlert ? "is-no-alert" : ""}`}>
            <ContentDash>
                <TitleDash>
                    <div className={`main-title`}>{title}</div>
                    {subTitle &&
                        <div className={`sub-title`}>{subTitle}</div>
                    }
                </TitleDash>
                <BodyDash className={`${subTitle ? 'suby' : ''} body-dash`}>
                    <WrapBodyDash className={`wrap-body-dash`} style={{height:isFullH ? '100%' : 'auto'}}>
                        {isPending ?
                            <TimedSkeletonList

                            />:children
                        }

                    </WrapBodyDash>
                </BodyDash>
            </ContentDash>
        </Widy>
    )
}

const WidgetList = styled(WidgetListCtrl)`
  width: ${props => props.width ? props.width : "25%"};
  height: ${props=>props.height ? props.height : "150px"};
`

export default WidgetList;