import styled from "styled-components";
import React, {useEffect, useRef, useState} from "react";
import {GrClose} from "react-icons/gr";

interface TimedMenuHeadCtrlProps{
    className?:string;
    children:React.ReactNode;
    open:boolean;
    closeMe:()=>void;
    titre?:string;
    Wd?:string;
    Ht?:string;
    isFixed?:boolean;
    LeftPos:string;
    TopPos:string;
    isRightEffect?:boolean;
    TriggerNoClose?:string;
}

const VISIBLE =1;
const HIDDEN =2;
const ENTERING =3;
const LEAVING =4;

const TimedMenuHeadCtrl = ({className, open, children, closeMe, titre, LeftPos, TopPos, TriggerNoClose}:TimedMenuHeadCtrlProps)=>{
    const [state, setState] = useState(open ? VISIBLE : HIDDEN);
    const [posX, setPosX] = useState(LeftPos)
    const [posY, setPosY] = useState(TopPos)
    const [posXb, setPosXb] = useState(LeftPos)
    const [posYb, setPosYb] = useState(TopPos)
    const [movable, setMovable] = useState(false);
    const refObj = useRef<HTMLDivElement>(null);
    useEffect(()=>{
        if(!open){
            setState(LEAVING)
        } else{
            setState((s)=>s===HIDDEN ? ENTERING : LEAVING)
        }
    }, [open])
    useEffect(()=>{
        if(state === LEAVING){
            const timer = setTimeout(()=>{
                setState(HIDDEN)
                setPosXb(LeftPos)
                setPosYb(TopPos)
            }, 300)
            return ()=>{
                clearTimeout(timer);
            }
        } else if (state === ENTERING){
            const xx = document.body.offsetHeight
            setState(VISIBLE);
        }
    }, [state])
    useEffect(() => {
        function handleClickOutside(event:any) {
            const eltParent = event.target.parentNode;

            if (refObj.current && !refObj.current.contains(event.target) && eltParent.className !== TriggerNoClose) {
                closeMe();
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [refObj, closeMe, TriggerNoClose]);

    const style = {transitionDuration: `300ms`, transitionProperty:"transform", transform:"scale(1)"}
    if(state!==VISIBLE){
        style.transform = "scale(0)";
    }
    useEffect(()=>{
        const Move = (e:any)=>{
            if(e.target.id!=='closer') {
                setPosX((e.pageX - 10) + 'px');
                setPosY((e.pageY - 10) + 'px')
            }
        }
        window.addEventListener('mousemove', (e) => Move(e))
        return ()=>{
            window.removeEventListener('mousemove', (e)=>Move(e))
        }

    }, [])
    const HandleMovable = () =>{
        setMovable(true);
    }
    const HandleNMovable = ()=>{
        setMovable(false)
    }
    useEffect(()=>{
        if(movable){
            setPosXb(posX)
            setPosYb(posY)
        }
    }, [movable, posX, posY])
    if(state === HIDDEN){
        return null;
    } else {
        return (
            <div className={`TimedMenuHead ${className}`} style={{...style, left:posXb, top:posYb}} ref={refObj}>
                <div className={`body_MenuHead`}>
                    {children}
                </div>
            </div>
        )
    }
}

const TimedMenuHead = styled(TimedMenuHeadCtrl)`
  transform-origin: ${props => props.isRightEffect ? "100% 0" : "0 0"};
  position: ${props=>props.isFixed ? "fixed" : "absolute"};
  width: ${props => props.Wd ? props.Wd : "250px"};
  background: white;
  border-radius: 6px;
  box-shadow: 1px 1px 3px rgba(0,0,0,.2);
  z-index: 5;
  .body_MenuHead{
    height: ${props=>props.Ht ? props.Ht : "450px"};
  }
`

export default TimedMenuHead;