import React, {useEffect, useRef, useState} from "react";
import {TimedModalE, TimedModalECtrlProps} from "../../components/molecules/TimedModal/TimedModalE";
import {useChangeFerie} from "../../features/pointeuseTreat/pointeuseTreat.hooks";
import {FormProvider, RegisterOptions, useForm} from "react-hook-form";
import TimedInputTextH from "../../components/atoms/TimedInputText/TimedInputTextH";
import TimedCheckBoxH from "../../components/atoms/TimedCheckBox/TimedCheckBoxH";
import TimedButton from "../../components/atoms/TimedButton/TimedButton";
import styled from "styled-components";
import { WrapSender } from "../../assets/styles/elements";
import {TimedErrorFormG} from "../../components/atoms/TimedErrorFormG/TimedErrorFormG";

interface ModalTreatJourFerieCtrlProps extends TimedModalECtrlProps{
    className?:string;
    PointeuseTreat:PointeuseTreat;
}

const ModalTreatJourFerieCtrl = ({className, PointeuseTreat, ...rest}:ModalTreatJourFerieCtrlProps)=>{
    const mutation = useChangeFerie();
    const [errors, setErrors] = useState<string|null>(null)
    const defaultValues = {
        timeFinal:0,
        droitCP:false,
    }
    const methods = useForm({
        mode:"onChange",
        defaultValues:defaultValues
    })
    useEffect(()=>{
        methods.reset({
            timeFinal:PointeuseTreat.timeFinal ? Math.round(PointeuseTreat.timeFinal*100)/100 : 0,
            droitCP:PointeuseTreat.AcquisitionDroitsAbsence !== null
        })
    }, [PointeuseTreat, methods.reset])
    useEffect(()=>{
        if(methods.formState.isDirty){
            setErrors(null);
        }
    }, [methods.formState.isDirty])
    const onSubmit = (data:any)=>{
        const myDatas:ChangeFerieFD={
            timeFinal:parseFloat(data.timeFinal),
            droitCP:data.droitCP
        }
        setErrors('');
        mutation.mutate(({fd:myDatas, id:PointeuseTreat.id}), {
            onSuccess:()=>{
                rest.CloseMe();
            }, onError:()=>{
                setErrors('Une erreur est survenue, merci de retenter plus tard');
            }
        })
    }
    return (
        <TimedModalE  {...rest}
                      footer={<WrapSender>
                          <TimedButton
                              size={"sm"}
                              themeColor={"Primary"}
                              text={"Enregistrer"}
                              disabled={mutation.isLoading}
                              isPending={mutation.isLoading}
                              type={"button"}
                              onClick={methods.handleSubmit(onSubmit)}
                              isFull={false}
                          />
                      </WrapSender>}
        >
            <div className={`Content-gere-ferie ${className}`}>
                <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(onSubmit)}>
                        <div className={"line-form"}>
                            <TimedInputTextH
                                label={"Temps accordé"}
                                type={"number"}
                                isRequired={true}
                                register={methods.register}
                                optionRegister={{required: {value:true, message:'temps obligatoire'}, min:0}}
                                getValue={methods.getValues}
                                id={"timeFinal"}
                                size={"small"}
                                isError={!!methods.formState.errors.timeFinal}
                                helperText={methods.formState.errors.timeFinal  ? methods.formState.errors.timeFinal.message : ""}
                                isFull={true}
                                step={0.01}
                            />
                        </div>
                        <div className={`line-form`} style={{padding:"0 0.5rem"}}>
                            <TimedCheckBoxH
                                themeColor={"primary"}
                                size={"regular"}
                                label={"Droit à un CP supplémentaire"}
                                id={"droitCP"}
                                register={methods.register}
                            />
                        </div>
                        {errors &&
                            <TimedErrorFormG Err={errors}/>
                        }
                    </form>
                </FormProvider>
            </div>
        </TimedModalE>
    )
}

export const ModalTreatJourFerie = styled(ModalTreatJourFerieCtrl)`
    padding: 1rem 0.5rem;
  .line-form{
    
  }
`