import styled from "styled-components";
import AvatAno from "../../../assets/images/Unisex-Person-with-Glasses.png";
import {memo, useEffect, useState} from "react";

interface TimedUserAvatarStdCtrlProps{
    className?:string;
    User:User|UserSimple;
    fonction?:boolean;
    onClick?:(u:User|UserSimple)=>void;
    typeName?:"prenom_nom"|"nom_prenom"|"inits_prenom_nom"|"prenom_nom_inits"|"inits"
}

const TimedUserAvatarStdCtrl = ({className, User, ...props}:TimedUserAvatarStdCtrlProps)=>{
    const [libName, setLibName] = useState(`${User.prenom} ${User.nom}`)

    useEffect(() => {
        switch (props.typeName){
            case "prenom_nom":
                setLibName(`${User.prenom} ${User.nom}`);
                break;
            case "nom_prenom":
                setLibName(`${User.nom} ${User.prenom}`);
                break;
            case "inits_prenom_nom":
                setLibName(`(${User.initials}) ${User.nom} ${User.prenom}`);
                break;
            case "prenom_nom_inits":
                setLibName(`(${User.initials}) ${User.nom} ${User.prenom} (${User.initials})`);
                break;
            case "inits":
                setLibName(`${User.initials}`);
                break;
            default:
                setLibName(`${User.prenom} ${User.nom}`);
        }
    }, [props.typeName]);
    const handleError = (e:any)=>{
        e.target.src = AvatAno;
    }
    const handleClicOn = ()=>{
        if(props.onClick){
            props.onClick(User);
        }
    }
    return (
        <div className={`timed_user_avatar_std ${className} ${props.onClick ? 'clickable' : ''}`} onClick={handleClicOn}>
            <div className="avatar">
                <div className={"in_avatar"}>
                    <img src={`${process.env.REACT_APP_API_URL_ROOT}${User.avatarUrl}`} alt={`pic ${User.nom}`} onError={handleError}/>
                </div>
            </div>
            <div className="text">
                <div className={"in_text"}>
                    <div className="name">{libName}</div>
                    {props.fonction &&
                        <div className="fonction">{User.Fonction.libelle}</div>
                    }
                </div>
            </div>
        </div>
    )
}

const TimedUserAvatarStdStyled = styled(TimedUserAvatarStdCtrl)`
    display: flex;
    //border:solid 1px ${props=>props.theme.PrimaryMegaLight};
    border-radius: 4px;
    justify-content: flex-start;
    align-items: stretch;
    width: 100%;
  &.clickable{
    cursor: pointer;
    transition: all 0.3s;
    &:before{
      content:'';
      width: 3px;
      background: ${props=>props.theme.Primary};
      height: 100%;
    }
    &:hover{
      padding-left: 4px;
      transition: padding-left 0.3s;
    }
  }
  .name{
    font-weight: bold;
  }
  .fonction{
    font-style: italic;
    color:${props => props.theme.NeutreExtraDark};
  }
    .text{
      min-height: 30px;
      padding: 0 0.5rem;
      flex-grow: 1;
      font-size: 14px;
    }
    .avatar{
      display: flex;
      flex-direction: column;
      justify-content: center;
      .in_avatar{
        width: 30px;
        height: 30px;
        overflow: hidden;
        border-radius: 4px;
        img{
          width: 100%;
          height: auto;
        }
      }
      
    }
`

export const TimedUserAvatarStd = memo(TimedUserAvatarStdStyled)