import styled from "styled-components";
import React from "react";

interface WrapperInputCtrlProps{
    className?:string;
    children:React.ReactNode;
    onError:boolean;
    ClickOnIt:()=>void;
    backG?:string;
    FontColor?:string;
    noFocus?:boolean;
    He?:string;
    BorderBtm?:string;
}

const WrapperInputCtrl = (props:WrapperInputCtrlProps)=>{
    return (
        <div className={`wrapper_input ${props.className} ${props.onError && 'error'}`} onClick={()=>props.ClickOnIt()}>
            {props.children}
        </div>
    )
}

export const WrapperInput = styled(WrapperInputCtrl)`
    width: 100%;
    border-bottom: solid 2px ${props=>props.BorderBtm ? props.BorderBtm : props.theme.PrimaryExtraLight};
    &.error{
      border-bottom: solid 2px ${props=>props.theme.Warning};
    }
    &>input:focus{
      border-bottom: solid 2px ${props=>props.theme.Primary};
      background: ${props=>props.noFocus ? 'transparent' :  props.theme.PrimaryMegaLight};
    }
  input:disabled{
    background: ${props=>props.backG ? props.backG : "white"};
    color:${props=>props.theme.NeutreLight};
    height: ${props=>props.He ? props.He : "32px"};
    width: 100%;
    border:none;
    padding: 0 0.5rem;
    font-weight: bold;
  }
  input:not(:disabled){
    height: ${props=>props.He ? props.He : "32px"};
    width: 100%;
    border:none;
    padding: 0 0.5rem;
    font-weight: bold;
    background: ${props=>props.backG ? props.backG : "white"};
    color:${props=>props.FontColor ? props.FontColor : "black"}
    
  }
`