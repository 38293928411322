import React, {Fragment, useEffect, useMemo, useState} from "react";
import styled from "styled-components";
import {useDrop} from "react-dnd";
import {ItemTypes} from "../../../Modaux/ModalGereDayPointage";
import {UseGetPointeuseTreatByParams} from "../../../../features/pointeuseTreat/pointeuseTreat.hooks";
import {OnePlageTreatEdit} from "./OnePlageTreatEdit";
import TimedRoundAvatar from "../../../../components/atoms/TimedRoundAvatar/TimedRoundAvatar";
import {useCentreConnectedData} from "../../../../services/hooks";
import {GereComentPTreat} from "./GereComentPTreat";

interface EditionPointageCtrlProps{
    className?:string;
    PointeuseTreat:PointeuseTreat
    supervisor?:boolean;
}

const EditionPointageCtrl = ({className, PointeuseTreat, supervisor}:EditionPointageCtrlProps)=>{
    const centre = useCentreConnectedData();
    const [time, setTime] = useState<string>('')
    const [params, setParams] = useState<any|undefined>(undefined);
    const PointeusesTreatQuery = UseGetPointeuseTreatByParams(params)
    const ListPT = useMemo(()=>{
        let listPT:PointeuseTreat[] = [];
        if(PointeusesTreatQuery.data){
            listPT = PointeusesTreatQuery.data.filter(pt=>!pt.MotifReguleTemps)
        }
        return listPT
    }, [PointeusesTreatQuery])
    useEffect(()=>{
        const dateAt = new Date(PointeuseTreat.dateAT.slice(0,10));
        const dateStr = dateAt.toISOString().slice(0,10);
        const user = PointeuseTreat.User;
        let paramsX:any = {"dateAT":dateStr, User:user.id};
        if(supervisor){
            paramsX.Centre = centre;
        }
        setParams(paramsX);
    }, [PointeuseTreat, centre, supervisor])
    const [{ isOver }, drop] = useDrop(
        () => ({
            accept: ItemTypes.Horaire,
            drop: (item) => moveHoraire(item),
            collect: (monitor) => ({
                isOver: !!monitor.isOver()
            })
        }),
        []
    )
    const moveHoraire = (item:any)=>{
        setTime(item.horaire);
    }
    return (
        <div className={`EditionPointage ${className}`}>
            <div className={`User-place`}>
                <div className={`name-place`}>
                    <div className={`avatar-place`}>
                        <TimedRoundAvatar BoxShadow={"1px 1px 3px rgba(0,0,0,.7)"} Border={true} src={PointeuseTreat.User.avatarUrl!==undefined ? PointeuseTreat.User.avatarUrl : '' } size={"other"} sizeP={"50px"}/>
                    </div>
                    <div className={`infos-place`}>
                        <div className={'name-infos'}>{PointeuseTreat.User.prenom} {PointeuseTreat.User.nom} ({PointeuseTreat.User.initials})</div>
                        <div className={'fx-infos'}>{PointeuseTreat.User.Fonction.libelle}</div>
                    </div>
                </div>
                <div className={`titre-place`}>
                    Gestion de la durée de travail<br/> {PointeuseTreat.MethodTimes && PointeuseTreat.MethodTimes.id ===2 ? '(Planning)' : PointeuseTreat.MethodTimes && PointeuseTreat.MethodTimes.id === 1 ? '(Badgeuse)' : ''}
                </div>
            </div>
            {PointeusesTreatQuery.isLoading ? 'chargement' :
                ListPT.map((pt:PointeuseTreat, idx:number)=>(
                    <div className={`wrap_one`} key={`Periode${idx}`}>
                        <OnePlageTreatEdit PointeuseTreat={pt} key={`Periode${idx}`} index={idx+1} nbPTS={ListPT.length} isLoading={PointeusesTreatQuery.isFetching} coment={pt.comentFinalHour}/>
                    </div>
                ))
            }
        </div>
    )
}

export const EditionPointage = styled(EditionPointageCtrl)`
  width: 100%;
  padding: 2rem 1rem;
  height: 100%;
  overflow-y: auto;
  .User-place{
    display: flex;
    justify-content: space-between;
    background: white;
    padding: 1rem;
    margin-bottom: 15px;
    .titre-place{
      color:${props => props.theme.PrimaryDark};
      font-size: 20px;
      font-weight: bold;
      text-align: right;
    }
    .name-place{
      display: flex;
      justify-content: flex-start;
      gap: 10px;
      align-items: stretch;
      .name-infos{
        font-weight: bold;
      }
      .fx-infos{
        color:${props => props.theme.PrimaryDark};
      }
    }
  }
  
`