import React, {useMemo} from "react";
import styled from "styled-components";
import WidgetList from "../Components/WidgetList";
import TimedChipsUser from "../../../components/atoms/TimedChips/TimedChipsUser";
import {useGetAllUser} from "../../../features/user/user.hooks";
import {useNavigate} from "react-router-dom";
import {TimedEmptyFlex} from "../../../components/molecules/TimedWidgetEmpty/TimedEmptyFlex";
import IllCtOk from "../../../assets/images/ContratsOK.png";
import {TextStd} from "../../../components/molecules/TimedWidgetEmpty/TextStd";
import {useCentreConnectedData} from "../../../services/hooks";
import {TimedUserAvatar} from "../../../components/molecules/_TimedUserAvatar/TimedUserAvatar";
import {TimedUserAvatarStd} from "../../../components/molecules/_TimedUserAvatar/TimedUserAvatarStd";


interface WidgetNoContractCtrlProps{
    className?:string;
    supervisor?:boolean;
}


const WidgetNoContractCtrl = ({className, supervisor}:WidgetNoContractCtrlProps)=>{
    const centre = useCentreConnectedData();
    const UserQuery = useGetAllUser(supervisor ? centre : null);
    const navigate = useNavigate();
    const GoFiche = (User:User)=>{
        navigate(`/timed_application/fiche_personnel?idCollab=${User.id}`)
    }
    const listUser = useMemo(()=>{
        let list:User[] = [];
        if(UserQuery.data){
            list = UserQuery.data.filter(u=>u.Fonction.id !==1 && u.contrats.length === 0);
        }
        return list;
    }, [UserQuery.data])
    return (
        <WidgetList
            title={`Collaborateurs sans contrats`}
            isPending={UserQuery.isLoading}
            width={"100%"}
            height={"100%"}
            isFullH={true}
            isAlert={listUser.length>0}
            isNoAlert={listUser.length===0}
        >
            <div className={`NoContract ${className}`}>
                {!UserQuery.isLoading && listUser.length === 0 ?
                    <TimedEmptyFlex
                        Ill={IllCtOk}
                        HeiImg={"115px"}
                    >
                        <TextStd
                            firstLine={"Un contrat est renseigné"}
                            sdLine={"Pour tous les collaborateurs"}
                        />
                    </TimedEmptyFlex>:
                    <div className={`widget_no_contrat`}>
                        {listUser.map((item:User, idx:number)=>(
                            <TimedUserAvatarStd User={item} fonction={true} key={`oneLC${idx}`} onClick={()=>GoFiche(item)}/>
                        ))}
                    </div>
                }
            </div>
        </WidgetList>
    )
}

const WidgetNoContract = styled(WidgetNoContractCtrl)`
  width: 100%;
  height: 100%;
  .widget_no_contrat{
    display: flex;
    flex-direction: column;
    gap: 4px;
    justify-content: flex-start;
  }
  .one_user_no_contract{
    width: 100%;
  }
`

export default  WidgetNoContract
