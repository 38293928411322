import React from "react";
import styled from "styled-components";

interface TimedTitreH2CtrlProps{
    className?:string;
    text:string;
    marginTop?:string;
}

const TimedTitreH2Ctrl = ({className, text}:TimedTitreH2CtrlProps)=>{
    return (
        <h2 className={`${className}`}>{text}</h2>
    )
}

export const TimedTitreH2 = styled(TimedTitreH2Ctrl)`
    font-size: 16px;
    color: ${props => props.theme.Dark};
    margin: ${props=>props.marginTop ? props.marginTop : "24px"} auto 12px auto;
`