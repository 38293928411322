import React from "react";
import styled from "styled-components";

interface TimedWrapperDayCtrlProps{
    className?:string;
    heureDep:number; // en heure deci
    heureFin:number; // en heure deci
    PlagesIn:PlageInWrapper[];
    ActionOnClick?:Function;
}

const TimedWrapperDayCtrl = ({className, heureDep, heureFin, PlagesIn, ActionOnClick}:TimedWrapperDayCtrlProps)=>{
    let hStart = 0
    const TabObj:React.ReactNode[] = []
    while(hStart < (heureFin - heureDep)){
        TabObj.push(<div className={`trait-heures`} key={`TH${hStart}`}>
            <div className={`trait`}/>
            <div className={`numHeure`}>{hStart+6}</div>
        </div> )
        hStart++
    }
    const handleClickOnIt = (p:PlageInWrapper)=>{
        if(ActionOnClick){
            ActionOnClick(p.id);
        }
    }
    return (
        <div className={`TimedWrapperDay ${className}`}>
            <div className={`wrap-heures`}>
                {TabObj.map(it=>it)}
            </div>
            <div className={`wrapper-day`}>
                {PlagesIn.map((item:PlageInWrapper, idx:number)=>(
                    <div onClick={()=>handleClickOnIt(item)} className={`onePlageWrap`} key={`onePlageWrap${idx}`} style={{left:(((item.debPlage/60)-6)*6.25)+"%", width:((((item.finPlage-item.debPlage)/60))*(100/(heureFin-heureDep)))+"%", background:item.backGround}}>
                        <div className={`infos-in`}>
                            <div className={`triangle`}/>
                            {item.infos}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export const TimedWrapperDay = styled(TimedWrapperDayCtrl)`
  width: 100%;
  height: 32px;
  position: relative;
  margin-bottom: 20px;
  .trait-heures{
    width: 6.25%;
    position: relative;
    height: 36px;
    .trait{
      position: absolute;
      left: 0;
      top: 0;
      width: 1px;
      background: #ccc;
      height: 36px;
      margin: auto;
    }
    .numHeure{
      position: absolute;
      left: 0;
      padding-top: 4px;
      transform: translateX(-50%);
      top: 100%;
      font-size: 12px;
      color: ${props => props.theme.NeutreDark};
    }

  }
  .wrap-heures{
    width: 100%;
    left: 0;
    top: 0;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    position: absolute;
    z-index: 0;
  }
  .wrapper-day {
    width: 100%;
    height: 100%;
    border: solid ${props => props.theme.NeutreExtraLight} 1px;
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;

    .onePlageWrap {
      position: absolute;
      height: 22px;
      top: 50%;
      transform: translateY(-50%);

      &.is-ano {
        background: ${props => props.theme.Error};
      }

      .triangle {
        position: absolute;
        display: none;
        left: 50%;
        top: 100%;
        transform: translateX(-50%);
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 12px 6px 0 6px;
        border-color: #ffffff transparent transparent transparent;
        z-index: 2;
      }

      &:hover {
        cursor: pointer;

        .infos-in {
          display: flex;
          flex-direction: column;
          gap: 10px;
        }

        .triangle {
          display: block;
        }
      }

      .infos-in {
        display: none;
        position: absolute;
        left: 50%;
        top: -10px;
        transform: translate(-50%, -103%);
        background: white;
        width: max-content;
        padding: 0.5rem;
        border-radius: 6px;
        box-shadow: 1px 1px 3px rgba(0, 0, 0, .2);
        font-size: 13px;
      }
    }
  }
`