import styled from "styled-components";
import OneCardVue from "./OneCardVue";

interface WrapVuePlCtrlProps{
    className?:string;
    VueActive:VuePlanning;
    setVue:(vue:VuePlanning)=>void;
    Vues:VuePlanning[];
}

const WrapVuePlCtrl = (props:WrapVuePlCtrlProps)=>{
    return (
        <div className={`wrapVuePl wrap_list ${props.className}`}>
            {props.Vues.map(item=>(
                <OneCardVue isCurrent={props.VueActive.id === item.id} ClickOnEdit={()=>props.setVue(item)} VuePlanning={item} key={`oneVue${item.id}`}/>
            ))}
        </div>
    )
}

const WrapVuePl = styled(WrapVuePlCtrl)``

export default WrapVuePl