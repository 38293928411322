import styled from "styled-components";
import {TimedSwitchCentre} from "../TimedSwitchCentre/TimedSwitchCentre";
import {TimedSelectUrl} from "../../organisms/TimedSmartNav/TimedSelectUrl";
import {TimedSelectPersonne} from "../../organisms/TimedSmartNav/TimedSelectPersonne";
import {TimedSelectDates} from "../../organisms/TimedSmartNav/TimedSelectDates";
import {TimedSelectLieux} from "../../organisms/TimedSmartNav/TimedSelectLieux";

interface TimedHeaderSVCtrlProps{
    className?:string;
}

const TimedHeaderSVCtrl = ({className}:TimedHeaderSVCtrlProps)=>{
    return (
        <div className={`header_sv ${className}`}>
            <TimedSwitchCentre/>
            <div className={`wrap_smart`}>
                <TimedSelectUrl/>
                <TimedSelectPersonne/>
                <TimedSelectDates supervisor={true}/>
                <TimedSelectLieux/>
            </div>
        </div>
    )
}

export const TimedHeaderSV = styled(TimedHeaderSVCtrl)`
  padding: 1rem;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  .wrap_smart{
    display: flex;
    justify-content: flex-start;
    width: 80%;
    box-shadow: 1px 1px 3px rgba(0,0,0,.2);
  }
`

