import React, {useMemo} from "react";
import styled from "styled-components";
import { TitrePage } from "../../../../assets/styles/elements";
import {useGetCreneauxFullParams} from "../../../../features/creneau/creneau.hooks";
import {useGetUsersByFonctionFAdm} from "../../../../features/user/user.hooks";
import TimedRoundAvatar from "../../../../components/atoms/TimedRoundAvatar/TimedRoundAvatar";
import TimedSkeletonTable from "../../../../components/atoms/TimedSkeleton/TimedSkeletonTable";
import TimedTable from "../../../../components/molecules/TimedTable/TimedTable";
import {useNavigate} from "react-router-dom";
import {useCentreConnectedData} from "../../../../services/hooks";

interface ListRemplacantCtrlProps{
    className?:string;
    supervisor?:boolean;
}

const TitlesTab:TitleTable[]=[
    {libelle:'Actif', field:'ActifObj', fieldSort:'ActifValue', date:false, textAlign:'', width:"80px", StyleHead:{textAlign:"center"}},
    {libelle:'Pic', field:'avatar', fieldSort:'nom', date:false, textAlign:'text-center', width:"80px", StyleHead:{textAlign:"center"}},
    {libelle:'Initiales', field:'inits', fieldSort:'inits', date:false, textAlign:'text-center', StyleHead:{textAlign:"center"}},
    {libelle:'Prénom et nom', field:'fullName', fieldSort:'nom', date:false, textAlign:''},
    {libelle:'Niveau', field:'level', fieldSort:'level', date:false, textAlign:'text-center', width:"80px", StyleHead:{textAlign:"center"}},
    {libelle:'N° Ordre', field:'numOrdre', fieldSort:'numOrdre', date:false, textAlign:'text-center', StyleHead:{textAlign:"center"}},
    {libelle:'N° Urssaf', field:'numUrssaf', fieldSort:'numUrssaf', date:false, textAlign:'text-center', StyleHead:{textAlign:"center"}},
    {libelle: 'Nombre de remplacement', field:'nbReplace', fieldSort:'nbReplace', date:false, textAlign:'text-right', StyleHead:{textAlign:"right"}},
    {libelle: 'Premier remplacement le', field:'firstAt', fieldSort:'firstAt', date:true, textAlign:'text-right', StyleHead:{textAlign:"right"}},
    {libelle: 'Dernier remplacement le', field:'lastAt', fieldSort:'lastAt', date:true, textAlign:'text-right', StyleHead:{textAlign:"right"}},
]

const ListRemplacantCtrl = ({className, supervisor}:ListRemplacantCtrlProps)=>{
    const centre = useCentreConnectedData();
    const navigate = useNavigate();
    const paramsX = supervisor ? {isAffect:1, Centre:centre} : {isAffect:1}
    const CreneauxQuery = useGetCreneauxFullParams(paramsX)
    const UsersQuery = useGetUsersByFonctionFAdm(1,3, supervisor ? centre : null);
    const myList = useMemo(()=>{
        let myList:any[] = [];
        if(CreneauxQuery.data && UsersQuery.data){
            UsersQuery.data.map(u=>{
                const CreneauxConc = CreneauxQuery.data.filter(c=>c.UserAffected && c.UserAffected.id === u.id);
                let first:string = '1970-01-01';
                let last:string = '1970-01-01';
                if(CreneauxConc.length>0){
                    const CrenAsc = [...CreneauxConc]
                    CrenAsc.sort((a:Creneau, b:Creneau)=>{
                        return new Date(a.creneauAt) > new Date(b.creneauAt) ? 1 : -1;
                    })
                    const CrenDesc = [...CreneauxConc]
                    CrenDesc.sort((a:Creneau, b:Creneau)=>{
                        return new Date(a.creneauAt) > new Date(b.creneauAt) ? -1 : 1;
                    })

                    first = new Date(CrenAsc[0].creneauAt.slice(0,10)).toISOString().slice(0,10);
                    last = new Date(CrenDesc[0].creneauAt.slice(0,10)).toISOString().slice(0,10);
                }
                myList.push({
                    id:u.id,
                    avatar: <TimedRoundAvatar src={u.avatarUrl!==undefined ? u.avatarUrl : '' } size={"sm"} isSquare={true}/>  ,
                    prenom:u.prenom,
                    nom:u.nom,
                    numOrdre:u.numOrdre ? u.numOrdre : '--',
                    level:u.levelRplt,
                    numUrssaf:u.numUrssaf ? u.numUrssaf : '--',
                    inits:u.initials,
                    fullName:u.prenom+' '+u.nom.toUpperCase(),
                    ActifObj:<div className={`actif-round ${u.isActif ? 'actif' : 'inactif'}`}/>,
                    ActifValue:u.isActif ? 1 : 0,
                    nbReplace:CreneauxConc.length,
                    firstAt:first,
                    lastAt:last
                })
                return u;
            })
        }
        return myList;
    }, [CreneauxQuery.data, UsersQuery.data])
    const handleClickLine = (data:any)=>{
        navigate(`/timed_application/fiche_personnel?idCollab=${data["id"]}`);
    }
    return (
        <div className={`ListRepl ${className}`}>
            <TitrePage>Liste des remplaçants</TitrePage>
            {CreneauxQuery.isLoading || UsersQuery.isLoading || CreneauxQuery.isFetching || UsersQuery.isFetching ?
                <TimedSkeletonTable nbColumns={10} nbLines={10}/>:
                <>
                    <TimedTable
                        Titles={TitlesTab}
                        sortable={true}
                        themeColor={"Primary"}
                        Outline={true}
                        Lines={myList ? myList : []}
                        scrollable={true}
                        loading={CreneauxQuery.isLoading || UsersQuery.isLoading}
                        maxHeight={"90%"}
                        onClickLine={handleClickLine}
                    />
                </>
            }
        </div>
    )
}

const Wround = "20px";

const ListRemplacant = styled(ListRemplacantCtrl)`
    padding: 1rem 2rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  
  .actif-round{
    width: ${Wround};
    height: ${Wround};
    border-radius: 50%;
    margin: auto;
    &.actif{
      background: ${props=>props.theme.Success};
    }
    &.inactif{
      background: ${props=>props.theme.Neutre};
    }
  }
`
export default ListRemplacant;