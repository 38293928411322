import styled from "styled-components";
import React, {createContext, ReactNode, useMemo} from "react";
import {useGetUser} from "../../../features/user/user.hooks";
import {useGetRoleNiveaux} from "../../../features/roleNiveau/roleNiveau.hooks";
import {useGetModules} from "../../../features/module/module.hooks";
import {forceReRender} from "@storybook/react";
import {FaAddressCard, FaUmbrellaBeach, FaWhmcs} from "react-icons/fa";
import {BsCalendarDate, BsClockFill, BsFillCalendar3WeekFill, BsMegaphoneFill, BsPersonLinesFill} from "react-icons/bs";
import { MdBadge, MdDashboard } from "react-icons/md";
import { HiSwitchHorizontal } from "react-icons/hi";
import {IoChatbubbleEllipses} from "react-icons/io5";
import {DirectLink} from "./DirectLink";
import {SectionAccordion} from "./SectionAccordion";
import CSS from 'csstype';
import {useGetCentreActuel} from "../../../features/centre/centre.hooks";

interface OneSectionCtrlProps{
    className?:string;
    Section:Section;
}

export interface OneIconObject{
    lib:string;
    comp:ReactNode;
}



const styleTitle:CSS.Properties = {
    display: "flex",
    justifyContent: "flex-start",
    gap: "8px",
    padding: "0.5rem",
    alignItems: "center",
}

const TabIcon:OneIconObject[]=[
    {lib:"BsPersonLinesFill", comp:<BsPersonLinesFill/>},
    {lib:"BsFillCalendar3WeekFill", comp:<BsFillCalendar3WeekFill/>},
    {lib:"BsClockFill", comp:<BsClockFill/>},
    {lib:"MdBadge", comp:<MdBadge/>},
    {lib:"HiSwitchHorizontal", comp:<HiSwitchHorizontal/>},
    {lib:"FaUmbrellaBeach", comp:<FaUmbrellaBeach/>},
    {lib:"IoChatbubbleEllipses", comp:<IoChatbubbleEllipses/>},
    {lib:"BsMegaphoneFill", comp:<BsMegaphoneFill/>},
    {lib:"MdDashboard", comp:<MdDashboard/>},
    {lib:"FaWhmcs", comp:<FaWhmcs/>},
]

export interface ContextSection{
    Section?:Section;
    TabIcon?:OneIconObject[];
    styleTitle?:CSS.Properties;
}

export const SectionContext = createContext<ContextSection>({});

const OneSectionCtrl = (props:OneSectionCtrlProps)=>{
    const UserQuery = useGetUser();
    const CentreQuery = useGetCentreActuel();
    const RoleNiveauQuery = useGetRoleNiveaux();
    const ModulesQuery = useGetModules();
    const myModules = useMemo(()=>{
        const myList:Module[] = [];
        if(UserQuery.data && RoleNiveauQuery.data && ModulesQuery.data && CentreQuery.data){
            const rolesUser = UserQuery?.data?.roles?.filter(r=>r!=='ROLE_ADMIN');
            let niveauUser = 0;
            if(RoleNiveauQuery.data) {
                RoleNiveauQuery.data.map(item => {
                    if (rolesUser?.indexOf(item.libelle) !== -1 && item.niveau > niveauUser) {
                        niveauUser = item.niveau;
                    }
                    return item;
                })
            }

            const MyModules = ModulesQuery.data.filter(m=>m.sectionId === props.Section.id && m.niveau<=niveauUser)
            MyModules.forEach(m=> {
                const Param = m.Parameter;
                if (!Param) {
                    myList.push(m);
                } else {
                    const ParamsCentre = CentreQuery.data.parameters;
                    const GoodParams = ParamsCentre.find(x => x.Parameter.id === Param.id);
                    if (!GoodParams || GoodParams.statut) {
                        myList.push(m);
                    }
                }
            });
        }
        return myList;
    }, [props.Section, UserQuery.data, RoleNiveauQuery.data, ModulesQuery.data, CentreQuery.data])
    const getIcon = (lib:string)=>{
        const myIconTab = TabIcon.find(i=>i.lib===lib);
        if(myIconTab!==undefined){
            return myIconTab.comp;
        } else {
            return <BsCalendarDate/>
        }
    }
    return (
        <section className={`section_timed ${props.className}`}>
            <SectionContext.Provider value={{Section:props.Section, TabIcon:TabIcon, styleTitle:styleTitle}}>
                {myModules.length === 1 ?
                    <DirectLink
                        icon={getIcon(props.Section.icon)}
                        Module={myModules[0]}
                    />:
                    <SectionAccordion Modules={myModules}/>
                }
            </SectionContext.Provider>
        </section>
    )
}

export const OneSection = styled(OneSectionCtrl)`
    margin-bottom: 8px;
`