import {useMutation, useQuery, useQueryClient, UseQueryResult} from "react-query";
import {AddReguleAcquiUser, CancelAcquisition, getAcquiUser, ModifySoldeDepart} from "./acquisitionAbs.services";
import {toast} from "react-toastify";


export const useGetAcquiByUser = (id:number):UseQueryResult<AcquisitionAbs[], Error>=>{
    return useQuery<AcquisitionAbs[], Error>(["acqui_abs", id], ()=>getAcquiUser(id), {
        staleTime:60000,
        refetchOnWindowFocus:false,
        enabled:id!==0
    })
}

export const useAddReguleAcqui = ()=>{
    const queryClient = useQueryClient();
    return useMutation(
        (datas:PostAcquisitionAbs)=>{
            return AddReguleAcquiUser(datas);
        },{
            onSuccess:()=>{
                queryClient.invalidateQueries(["acqui_abs"])
                queryClient.invalidateQueries(["compteur_abs"])
                queryClient.invalidateQueries(["compteur_abs_date"])
                queryClient.invalidateQueries(["compteur_temps_travail_week"])
                queryClient.invalidateQueries(["compteur_abs_params"])
                toast.success('Ajoutée')
            },
            onError:()=>{
                toast.error('Une erreur est survenue merci de retenter plus tard');
            }
        }
    )
}
//ModifSoldeDepart
export const useModifySoldeDepart = ()=>{
    const queryClient = useQueryClient();
    return useMutation(
        (datas:ModifSoldeDepart)=>{
            return ModifySoldeDepart(datas);
        },{
            onSuccess:()=>{
                queryClient.invalidateQueries(["acqui_abs"])
                queryClient.invalidateQueries(["compteur_abs"])
                toast.success('Solde modifié')
            },
            onError:()=>{
                toast.error('Une erreur est survenue merci de retenter plus tard');
            }
        }
    )
}

export const useCancelAcquisition = ()=>{
    const queryClient = useQueryClient();
    return useMutation(
        (id:number)=>{
            return CancelAcquisition(id);
        },{
            onSuccess:()=>{
                queryClient.invalidateQueries(["acqui_abs"])
                queryClient.invalidateQueries(["compteur_abs"])
                toast.success('acquisition annulée')
            },
            onError:()=>{
                toast.error('Une erreur est survenue merci de retenter plus tard');
            }
        }
    )
}