import styled from "styled-components";
import InputTextSetting from "./InputTextSetting";
import {useSetLitts} from "../../../../features/centre/centre.hooks";
import {useEffect, useState} from "react";
import InputTextAreaSetting from "./InputTextAreaSetting";
import {useDebounce} from "../../../../services/hooks";

interface SettingsTextAtreaLittsCtrlProps{
    className?:string;
    keyLitts:string;
    currentValue:string;
    litts:any;
    idCentre:number;
    InfosProp:InfosProps;
    label:string;

}

const SettingsTextAtreaLittsCtrl = (props:SettingsTextAtreaLittsCtrlProps)=>{
    const mutation = useSetLitts();
    const [myValueX, setMyValueX] = useState<string>(props.currentValue)
    const myValueSaved = useDebounce(myValueX, 3500)
    const setMyValue = (value:string|null|Choice|number, id:string)=>{
        console.log(value);
        setMyValueX(value as string)
    }
    const SaveMe = ()=>{
        const actual = {...props.litts}
        const newLitts = {...actual, [props.keyLitts]:myValueSaved}
        mutation.mutate({idCentre:props.idCentre, litt:newLitts})
    }
    useEffect(() => {
        SaveMe();
    }, [myValueSaved]);
    return (
        <div className={`SettingsTextAtreaLitts ${props.className}`}>
            <InputTextAreaSetting
                wdLib={"35%"}
                current={myValueX}
                setValue={setMyValue}
                Error={null}
                id={props.keyLitts}
                label={props.label}
                subLabel={props.InfosProp.description}
            />
        </div>
    )
}

const SettingsTextAtreaLitts = styled(SettingsTextAtreaLittsCtrl)``

export default SettingsTextAtreaLitts