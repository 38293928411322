import styled from "styled-components";
import Yogi from "../../../assets/images/biebe_nibe_medical_HR_cartoon_woman_character_yoga_position_fun_de09082a-7bc6-4c18-a711-a16c8eebdcb7.png"
import TimedButton from "../../atoms/TimedButton/TimedButton";
import {useAddSupport} from "../../../features/support/support.hooks";
import Support from "../../../pages/TimedApplication/VieCentre/Support/Support";
import {useState} from "react";

interface NoSuscribe1CtrlProps {
    className?:string;
    libModule:string;
}

const NoSuscribe1Ctrl = ({className, libModule}: NoSuscribe1CtrlProps) => {
    const mutation = useAddSupport();
    const [successTransmission, setSuccessTransmission] = useState(false)
    const AskInfos = ()=>{
        const myData:SupportFD={
            sujet:'Demande informations sur le module '+libModule,
            content:"Suite à l'ouverture d'une page non accessible l'utilisateur à fait une demande d'informations"
        }
        mutation.mutate((myData), {
            onSuccess:(support:Support)=>{
                setSuccessTransmission(true)
                setTimeout(()=>{
                    setSuccessTransmission(false)
                }, 2500)
            }
        });
    }
    return (
        <div className={`TimedNoSuscribe ${className}`}>
            <div className="content_no_sub">
                <div className="wrap_texts">
                    <p style={{marginBottom:"20px"}}>Vous n'avez pas encore le <strong>module {libModule}</strong></p>
                    <p>N'hésitez pas à nous contacter pour découvrir toutes les fonctionnalités de ce module !!</p>
                </div>
                <div className="wrap_image">
                    <img src={Yogi} alt={"yogi timed"}/>
                </div>
                <div className="wrap_sender">
                    {successTransmission ?
                        <div className="success_trans">Votre demande à bien été enregistrée<br/>Vous serez contactez dans les meilleurs délais</div>:
                        <TimedButton themeColor={"Secondary"} size={"xl"} text={mutation.isLoading ? "Transmission en cours" : "Demander des informations"} onClick={AskInfos} isPending={mutation.isLoading} disabled={mutation.isLoading}/>
                    }
                </div>
            </div>
        </div>
    )
}

export const NoSuscribe1 = styled(NoSuscribe1Ctrl)`
    padding: 2%;
    .content_no_sub{
        padding: 5rem;
        background: white;
    }
    .wrap_sender{
        padding: 1rem;
        display: flex;
        justify-content: center;
    }
    .success_trans{
        text-align: center;
        font-size: 18px;
        padding: 0.5rem;
        background: ${props => props.theme.SecondaryLight};
        color: white;
        border-radius: 14px;
    }
    .wrap_texts{
        width: 70%;
        margin: auto;
    }
    p{
        font-size: 28px;
        text-align: center;
    }
    .wrap_image{
        width: 300px;
        margin: 20px auto;
        img{
            width: 100%;
        }
    }
`