import styled from "styled-components";
import {ReactNode} from "react";

interface OneBlockLeftPartCtrlProps{
    className?:string;
    titre?:string;
    children:ReactNode;
}

const OneBlockLeftPartCtrl = (props:OneBlockLeftPartCtrlProps)=>{
    return (
        <div className={`oneBlockLeftPart ${props.className}`}>
            {props.titre &&
                <h5>{props.titre}</h5>
            }
            <div className="content_blocklp">
                {props.children}
            </div>
        </div>
    )
}

const OneBlockLeftPart = styled(OneBlockLeftPartCtrl)`
    h5{
        margin-bottom: 10px;
        font-size: 16px;
    }
`

export default OneBlockLeftPart