import styled from "styled-components";
import PoigneeDrag from "../../components/PoigneeDrag";
import RightCard from "../../components/RightCard";
import SquareIndic from "../../components/SquareIndic";
import LibCard from "../../components/LibCard";

interface OneCardCompteurCtrlProps{
    className?:string;
    Periode:Periode;
    ClickOnEdit:()=>void;
    isCurrent?:boolean;
}

const OneCardCompteurCtrl = (props:OneCardCompteurCtrlProps)=>{
    return (
        <div className={`oneCardCompteur card_draggable ${props.className}`}>
            <RightCard isCurrent={props.isCurrent} ClickOn={()=>props.ClickOnEdit()}>
                <LibCard text={props.Periode.libelle}/>
            </RightCard>
        </div>
    )
}

const OneCardCompteur = styled(OneCardCompteurCtrl)``

export default OneCardCompteur