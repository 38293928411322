import {useMutation, useQuery, useQueryClient, UseQueryResult} from "react-query";
import {
    addUserRubrique, getUserRubriqueByCentre, getUserRubriqueByRubrique,
    getUserRubriqueByUser,
    modifyUserRubrique,
    trashUserRubrique
} from "./userRubriqueSpe.services";
import {toast} from "react-toastify";


export const useGetUserRubriqueByUser = (id:number):UseQueryResult<UserRubriqueSpe[], Error>=>{
    return useQuery<UserRubriqueSpe[], Error>(["user_rubrique", id], ()=>getUserRubriqueByUser(id), {
        staleTime:60000
    })
}
export const useGetUserRubriqueByCentre = (idCentre:number):UseQueryResult<UserRubriqueSpe[], Error>=>{
    return useQuery<UserRubriqueSpe[], Error>(["user_rubrique_centre", idCentre], ()=>getUserRubriqueByCentre(idCentre), {
        staleTime:60000,
        enabled:idCentre!==0
    })
}
export const useGetUserRubriqueByRubrique = (id:number):UseQueryResult<UserRubriqueSpe[], Error>=>{
    return useQuery<UserRubriqueSpe[], Error>(["user_rubrique_rub", id], ()=>getUserRubriqueByRubrique(id), {
        staleTime:60000,
        enabled:id!==0
    })
}

export const useAddUserRubrique = ()=>{
    const queryClient = useQueryClient();
    return useMutation(
        (datas:UserRubriqueSpeNewFD)=>{
            return addUserRubrique(datas);
        }, {
            onSuccess:()=>{
                queryClient.invalidateQueries(["user_rubrique"]);
                toast.success('Rubrique ajoutée pour le collaborateur');
            }, onError:()=>{
                toast.error("Impossible d'ajouter la rubrique")
            }
        }
    )
}

export const useTrashUserRubrique = ()=>{
    const queryClient = useQueryClient();
    return useMutation(
        (id:number)=>{
            return trashUserRubrique(id);
        }, {
            onSuccess:()=>{
                queryClient.invalidateQueries(["user_rubrique"]);
                toast.success('Rubrique supprimée pour le collaborateur');
            }, onError:()=>{
                toast.error("Impossible de supprimer la rubrique")
            }
        }
    )
}

export const useModifyUserRubrique = ()=>{
    const queryClient = useQueryClient();
    return useMutation(
        (datas:{id:number, data:UserRubriqueSpeModifyFD})=>{
            return modifyUserRubrique(datas.id, datas.data);
        }, {
            onSuccess:()=>{
                queryClient.invalidateQueries(["user_rubrique"]);
                toast.success('Rubrique modifiée pour le collaborateur');
            }, onError:()=>{
                toast.error("Impossible de modifier la rubrique")
            }
        }
    )
}