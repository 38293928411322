import React from "react";
import styled from "styled-components";

interface ConfidentialCtrlProps{
    className?:string;
}

const ConfidentialCtrl = ({className}:ConfidentialCtrlProps)=>{
    return (
        <div className={`${className}`}>
            <h1>Confidentialité des données</h1>
            <p>Everything is ok</p>
        </div>
    )
}

const Confidential = styled(ConfidentialCtrl)`
    
`

export default Confidential