import styled from "styled-components";
import {useAddRubrique} from "../../../../features/rubriqueSpe/rubriqueSpe.hooks";
import {useState} from "react";
import {useGetCentreActuel} from "../../../../features/centre/centre.hooks";
import InputTextSetting from "../components/InputTextSetting";
import TimedButton from "../../../../components/atoms/TimedButton/TimedButton";

interface FormNewRubriqueCtrlProps{
    className?:string;
    Rubriques:RubriqueSpe[];
    closeMe:(r:RubriqueSpe)=>void;
}

const FormNewRubriqueCtrl = (props:FormNewRubriqueCtrlProps)=>{
    const CentreQuery = useGetCentreActuel();
    const mutation = useAddRubrique();
    const [libNewRubrique, setLibNewRubrique] = useState<string>("")
    const [errorLib, setErrorLib] = useState<string|null>(null)
    const setMyValue = (value:string|null|Choice|number, id:string)=> {
        if(id === "libelle") {
            const myVal: string = value as string;
            if (props.Rubriques.map(a => a.libelle.toLowerCase()).indexOf(myVal)!==-1){
                setErrorLib("Cette Rubrique existe déjà");
            } else if (myVal === "") {
                setErrorLib("Le nom est obligatoire");
            } else {
                setErrorLib(null)
            }
            setLibNewRubrique(myVal);
        }
    }
    const Submit = ()=>{
        const datas:RubriqueSpeNewFD={
            libelle:libNewRubrique,
            type:"string",
            isJournalPaie:false,
            Centre:`/api/centres/${CentreQuery.data?.id||1}`
        }
        mutation.mutate(datas,{
            onSuccess:(newRub:RubriqueSpe)=>{
                props.closeMe(newRub)
            }
        })
    }
    return (
        <div className={`formNewRubrique ${props.className}`}>
            <div className="wrap_lib">
                <InputTextSetting current={libNewRubrique} setValue={setMyValue} Error={null} id={"libelle"}
                                  label={"Nom de la rubrique"} wdLib={"25%"}/>
            </div>
            {!!errorLib &&
                <div className="wrap_error">{errorLib}</div>
            }
            <div className="wrap_send">
                <TimedButton size={"sm"} text={"Enregistrer la rubrique"} themeColor={"Secondary"}
                             disabled={!!errorLib || mutation.isLoading} onClick={Submit}
                             isPending={mutation.isLoading}/>
            </div>
        </div>
    )
}

const FormNewRubrique = styled(FormNewRubriqueCtrl)`
    .wrap_error{
        color: red;
        padding: 1rem;
        text-align: center;
    }
    .wrap_lib{
        margin: 30px 0;
    }
    .wrap_send{
        display: flex;
        justify-content: center;
        padding: 1rem;
    }
`

export default FormNewRubrique