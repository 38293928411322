import React from "react";
import styled from "styled-components";

interface TimedSquareIconInfosCtrlProps{
    className?:string;
    Icon:React.ReactNode;
    infos:string;
    subTitle?:string;
    themeColor:"Primary" | "Success" | "Warning";
}

const TimedSquareIconInfosCtrl = ({className, Icon, infos, subTitle}:TimedSquareIconInfosCtrlProps)=>{
    return(
        <div className={`infosBigIcon ${className}`}>
            <div className={"icon-place"}>{Icon}</div>
            <div className={"infosPlace"}>
                <div className={"Big-infos"}>{infos}</div>
                {subTitle &&
                    <div className={"small-infos"}>{subTitle}</div>
                }
            </div>
        </div>
    )
}

const TimedSquareIconInfos = styled(TimedSquareIconInfosCtrl)`
  display: flex;
  justify-content: start;
  align-items: stretch;
  color : ${props=>props.themeColor === 'Primary' ? props.theme.Primary : props.themeColor === 'Success' ? props.theme.Success : props.theme.Warning};
  .icon-place{
    padding: 0.25rem 0.25rem 0.25rem 0;
    svg{
      font-size: 26px;
    }
  }
  .infosPlace{
    display: flex;
    flex-direction: column;
    .Big-infos{
      font-size: 1.5vw;
      font-weight: bold;
    }
    .small-infos{
      font-size: 10px;
      font-weight: bold;
    }
  }
`

export default TimedSquareIconInfos;