import {useQuery, UseQueryResult} from "react-query";
import {getAllMotifReguleTps, getAllMotifReguleTpsByCentre} from "./motifReguleTemps.services";


export const useGetAllMotifsReguleTps = ():UseQueryResult<MotifReguleTemps[], Error>=>{
    return useQuery<MotifReguleTemps[], Error>(["motif_regule_tps"], getAllMotifReguleTps, {
        staleTime:60000,
        refetchOnWindowFocus:false,
    })
}

export const useGetMotifsReguleTpsByCentre = (idCentre:number):UseQueryResult<MotifReguleTemps[], Error>=>{
    return useQuery<MotifReguleTemps[], Error>(["motif_regule_tps"], ()=>getAllMotifReguleTpsByCentre(idCentre), {
        enabled:idCentre!==0,
        staleTime:60000,
        refetchOnWindowFocus:false,
    })
}