import styled from "styled-components";
import {useGetAllRubrique} from "../../../../features/rubriqueSpe/rubriqueSpe.hooks";
import {useEffect, useState} from "react";
import TimedButton from "../../../../components/atoms/TimedButton/TimedButton";
import {TiPlus} from "react-icons/ti";
import TitrePageSetting from "../components/TitrePageSetting";
import {useModal} from "../../../../hooks/useModal";
import LeftPartDoublePage from "../components/LeftPartDoublePage";
import PageDoubleSetting from "../components/PageDoubleSetting";
import RightPartDoublePage from "../components/RightPartDoublePage";
import WrapListRubrique from "./WrapListRubrique";
import FormEditRubrique from "./FormEditRubrique";
import {TimedModalCentral2} from "../../../../components/molecules/TimedModal/TimedModalCentral2";
import FormNewRubrique from "./FormNewRubrique";

interface ParamsRubriqueCtrlProps{
    className?:string;

}

const ParamsRubriqueCtrl = (props:ParamsRubriqueCtrlProps)=>{
    const RubriquesQuery = useGetAllRubrique();
    const {open, toggle} = useModal()
    const [rubSet, setRubSet] = useState<RubriqueSpe|null>(null)
    const [myRubriques, setMyRubriques] = useState<RubriqueSpe[]>([])
    useEffect(() => {
        if(RubriquesQuery.data){
            setMyRubriques(RubriquesQuery.data.sort((a,b)=>a.libelle > b.libelle ? 1: -1))
        }
    }, [RubriquesQuery.data]);
    useEffect(() => {
        if(myRubriques.length > 0 && !rubSet){
            setRubSet(myRubriques[0])
        }
    }, [myRubriques, rubSet]);
    const EndNewRub = (r:RubriqueSpe)=>{
        setRubSet(r);
        toggle();
    }
    return (
        <div className={`paramsRubrique page_params ${props.className}`}>
            <TitrePageSetting titre={"Gestion des Rubriques spécifiques"}>
                <TimedButton onClick={toggle} themeColor={"Primary"} size={"sm"}>
                    <div className={`wrap_in_btn`}><TiPlus /><span>Créer une rubrique</span></div>
                </TimedButton>
            </TitrePageSetting>
            <PageDoubleSetting>
                <LeftPartDoublePage titre={"Vos Rubriques"}>
                    <WrapListRubrique Rubriques={myRubriques} RubriqueActive={rubSet} setRubrique={setRubSet}/>
                </LeftPartDoublePage>
                <RightPartDoublePage titre={rubSet ? `Configuration de la rubrique ${rubSet.libelle}` : "Configuration de la rubrique"} subtitle={"Modifier les paramètres de la rubrique en cours"}>
                    {rubSet &&
                        <FormEditRubrique Rubrique={rubSet} setRubActif={setRubSet}/>
                    }
                </RightPartDoublePage>
            </PageDoubleSetting>
            <TimedModalCentral2 closeMe={toggle} Wd={"650px"} open={open} Pending={false} titre={"Nouvelle rubrique"}>
                <FormNewRubrique Rubriques={myRubriques} closeMe={EndNewRub}/>
            </TimedModalCentral2>
        </div>
    )
}

const ParamsRubrique = styled(ParamsRubriqueCtrl)``

export default ParamsRubrique