import React from "react";
import styled from "styled-components";

interface TimedSwitchCtrlProps{
    /**
     * Class du switch
     */
    className?:string;
    /**
     * Theme couleur du switch
     */
    themeColor:"Primary"|"Secondary"|"Tertiary"|"Complementary"|undefined;
    /**
     * Statut du switch
     */
    statut:boolean;
    /**
     * Fonction permettant le switch
     */
    setStatut: Function;
    /**
     * Taille du switch
     */
    size?:"sm" | "medium" | "large" | "xl";
    disabled?:boolean;
    toolTip?:string;
}

const TimedSwitchCtrl = ({className, statut, setStatut, ...rest}:TimedSwitchCtrlProps)=>{
    const HandlerStatut = ()=>{
        if(!rest.disabled) {
            setStatut();
        }
    }
    return (
        <div className={`T_Switch ${className} ${statut ? 'On' : 'Off'} ${rest.toolTip ? 'isTooly' : ''}`} data-infos={rest.toolTip ? rest.toolTip : ''} onClick={HandlerStatut}>
            <div className={`barSw`}/>
            <div className={`wrap-roundSw`}/>
            <div className="roundSw"/>
        </div>
    )
}

const TimedSwitch = styled(TimedSwitchCtrl)`
    width: ${props=>props.size==="sm" ? "25px" : props.size==="medium" ? "32px" : props.size==="large" ? "36px" : props.size==="xl" ? "40px" : "25px"};
  height: ${props=>props.size==="sm" ? "16px" : props.size==="medium" ? "18px" : props.size==="large" ? "20px" : props.size==="xl" ? "22px" : "16px"};
  position: relative;
  &.isTooly{
    &:hover:after{
      content: attr(data-infos);
      position: absolute;
      width: max-content;
      padding: 0.25rem;
      border-radius: 6px;
      background: ${props=>props.theme.PrimaryDark};
      color: white;
      right: 0;
      top: calc(100% + 6px);
      box-shadow: 1px 1px 3px rgba(0,0,0,.2);
      font-size: 13px;
    }
  }
  .barSw{
    box-shadow: 1px 1px 3px rgba(0,0,0,.1) inset;
    z-index: 1;
    width: 100%;
    position: absolute;
    left: 0;
    top:50%;
    transform: translateY(-50%);
    height: 62%;
    border-radius: 30px;
    background: ${props =>props.disabled ? props.theme.NeutreMegaLight : props.themeColor === "Primary" ? props.theme.PrimaryExtraLight :
            props.themeColor === "Secondary" ? props.theme.SecondaryExtraLight :
                    props.themeColor === "Tertiary" ? props.theme.TertiaryExtraLight :
                            props.themeColor === "Complementary" ? props.theme.ComplementaryExtraLight :
                                    props.theme.PrimaryExtraLight
    };
  }
  &:hover{
    cursor: ${props=>props.disabled ? 'no-drop': 'pointer'};
    //cursor: no-drop;
    .wrap-roundSw {
      opacity: 0.5;
    }
  }
  &.On{
    .wrap-roundSw {
      transition: left 0.3s;
      left: calc(50% - 2px);
      
    }
    .roundSw{
      box-shadow: 1px 1px 3px rgba(0,0,0,0.2);
      left: 50%;
      border:solid 2px ${props =>props.disabled ? props.theme.SecondaryExtraLight :  props.themeColor === "Primary" ? props.theme.Primary :
              props.themeColor === "Secondary" ? props.theme.Secondary :
                      props.themeColor === "Tertiary" ? props.theme.Tertiary :
                              props.themeColor === "Complementary" ? props.theme.Complementary :
                                      props.theme.Primary};
      background: ${props => props.disabled ? props.theme.SecondaryExtraLight : props.themeColor === "Primary" ? props.theme.Primary :
              props.themeColor === "Secondary" ? props.theme.Secondary :
                      props.themeColor === "Tertiary" ? props.theme.Tertiary :
                              props.themeColor === "Complementary" ? props.theme.Complementary :
                                      props.theme.Primary
      };
    }
  }
  .wrap-roundSw {
    transition: left 0.3s;
    height: ${props=>props.size==="sm" ? "20px" : props.size==="medium" ? "22px" : props.size==="large" ? "24px" : props.size==="xl" ? "26px" : "20px"};
    width: ${props=>props.size==="sm" ? "20px" : props.size==="medium" ? "22px" : props.size==="large" ? "24px" : props.size==="xl" ? "26px" : "20px"};
    z-index: 2;
    position: absolute;
    left: ${props=>props.size==="sm" ? "-4px" : props.size==="medium" ? "-2px" : props.size==="large" ? "-2px" : props.size==="xl" ? "-2px" : "-4px"};
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 50%;
    opacity: 0;
    background: ${props =>props.disabled ? props.theme.NeutreDark :  props.themeColor === "Primary" ? props.theme.PrimaryLight :
            props.themeColor === "Secondary" ? props.theme.SecondaryLight :
                    props.themeColor === "Tertiary" ? props.theme.TertiaryLight :
                            props.themeColor === "Complementary" ? props.theme.ComplementaryLight :
                                    props.theme.PrimaryLight
    };
  }
  .roundSw{
    
    z-index: 3;
    transition: left 0.3s;
    position: absolute;
    height: ${props=>props.size==="sm" ? "16px" : props.size==="medium" ? "18px" : props.size==="large" ? "20px" : props.size==="xl" ? "22px" : "16px"};
    width: ${props=>props.size==="sm" ? "16px" : props.size==="medium" ? "18px" : props.size==="large" ? "20px" : props.size==="xl" ? "22px" : "16px"};
    left: ${props=>props.size==="sm" ? "-2px" : props.size==="medium" ? "0px" : props.size==="large" ? "0px" : props.size==="xl" ? "0px" : "-2px"};
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 50%;
    border:solid 2px ${props =>props.disabled ? props.theme.NeutreExtraLight :  props.themeColor === "Primary" ? props.theme.Primary :
            props.themeColor === "Secondary" ? props.theme.Secondary :
                    props.themeColor === "Tertiary" ? props.theme.Tertiary :
                            props.themeColor === "Complementary" ? props.theme.Complementary :
                                    props.theme.Primary
    };
    background: white;
  }
`

TimedSwitch.defaultProps = {
    themeColor:"Primary",
    size:"medium",
    statut:false,

}


export default TimedSwitch;