import React from "react";
import styled from "styled-components";

interface TimedErrorFormGCtrlProps{
    className?:string;
    Err:string;
}

const TimedErrorFormGCtrl = ({className, Err}:TimedErrorFormGCtrlProps)=>{
    if(Err === ''){
        return null;
    }
    return (
        <div className={`TimedErrorForm ${className}`}>
            {Err}
        </div>
    )
}

export const TimedErrorFormG = styled(TimedErrorFormGCtrl)`
    width: 100%;
  margin: 20px auto;
  background: ${props => props.theme.WarningLight};
  color: white;
  padding: 0.5rem;
  text-align: center;
    border-radius: 4px;
`