import styled from "styled-components";
import React, {useEffect, useRef, useState} from "react";
import {useModifySemaineType, useTrashSemaineType} from "../../features/semaineType/semaineType.hooks";
import {useGetAllLieu} from "../../features/lieu/lieu.hooks";
import {useGetAllActes} from "../../features/acte/acte.hooks";
import {useGetAllUserD} from "../../features/user/user.hooks";
import {useGetAllHoraires} from "../../features/horaires/horaires.hooks";
import {useGetCentreActuel} from "../../features/centre/centre.hooks";
import {useGetAllLieuHorairesByLieu} from "../../features/lieuHoraires/lieuHoraires.hooks";
import {VerifErrorString} from "../../components/atoms/TimedFormInteractif/Verifs";
import {getIsHorseBase5SameDay} from "../../components/functions/PlageFunctions";
import {TimedSelect324} from "../../components/atoms/TimedForm324/TimedSelect324";
import {SetHoraires324} from "../../components/atoms/TimedForm324/SetHoraires324";
import TimedSwitch from "../../components/atoms/TimedSwitch/TimedSwitch";
import {IoWarning} from "react-icons/io5";
import TimedButton from "../../components/atoms/TimedButton/TimedButton";
import TimedIconButton from "../../components/atoms/TimedIconButton/TimedIconButton";
import {BsTrash} from "react-icons/bs";
import {TimedUserAvatar} from "../../components/molecules/_TimedUserAvatar/TimedUserAvatar";
import {toast} from "react-toastify";

interface FormEditST2CtrlProps {
    className?:string;
    SemaineType:SemaineType;
    setSemainesTypes:(sts:SemaineType[])=>void;
    closeMe:()=>void;
    allSemType:SemaineType[];
    User:User;
    CentreSemType?:CentreSemaineType;
    nbCST?:number;

}

interface StereoST{
    Lieu: Choice|null,
    Acte:Choice|null,
    numJour:number,
    heureDebut:number,
    heureFin:number,
    UserLink:Choice|null,
    isSpecial:boolean,
}


interface ErrorsProps{
    Lieu: string|null,
    Acte: string|null,
    numJour: string|null,
    heureDebut: string|null,
    heureFin: string|null,
    UserLink: string|null,
    isSpecial: string|null,
}

const DefaultErrors:ErrorsProps = {
    Lieu: null,
    Acte: null,
    numJour: null,
    heureDebut: null,
    heureFin: null,
    UserLink: null,
    isSpecial: null,
}
const TabJ = ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche"];
const FormEditST2Ctrl = (props: FormEditST2CtrlProps) => {
    const mutationModify = useModifySemaineType();
    const mutatationSuppr=useTrashSemaineType();
    const [myST, setMyST] = useState<StereoST>({
        Lieu: {id:props.SemaineType.Lieu.id, libelle:props.SemaineType.Lieu.libelle, ftColor:props.SemaineType.Lieu.fontColor, bkgColor:props.SemaineType.Lieu.backColor},
        Acte:{id:props.SemaineType.Acte.id, libelle:props.SemaineType.Acte.libelle, ftColor:props.SemaineType.Acte.fontColor, bkgColor:props.SemaineType.Acte.backColor},
        numJour:props.SemaineType.numJour,
        heureDebut:props.SemaineType.HeureDebut,
        heureFin:props.SemaineType.HeureFin,
        UserLink:props.SemaineType.UserLink ? {id:props.SemaineType.UserLink.id, libelle:`${props.SemaineType.UserLink.prenom} ${props.SemaineType.UserLink.nom}`} : null,
        isSpecial:props.SemaineType.isSpecial||false,
    })
    const refContext = useRef<HTMLDivElement>(null)
    const LieuxQuery = useGetAllLieu(null);
    const ActesQuery = useGetAllActes(null);
    const UsersQuery = useGetAllUserD(null);
    const HorairesQuery = useGetAllHoraires(null);
    const CentreQuery = useGetCentreActuel();
    const [MyErrors, setMyErrors] = useState<ErrorsProps>(DefaultErrors)
    const [myLieu, setMyLieu] = useState<Lieu|null>(null)
    const [myActe, setMyActe] = useState<Acte|null>(null)
    const LieuxHorairesQuery = useGetAllLieuHorairesByLieu(myLieu ? myLieu.id : 0)
    const [listHoraires, setListHoraires] = useState<OneHoraireQuick2[]>([]);
    const [libUrgence, setLibUrgence] = useState('');
    const TabChoice = ["Lieu", "Acte", "UserLink"];
    const [stsConflicts, setSTSConflicts] = useState<SemaineType[]>([]);
    const TabNeeds:MyConstraint[]=[
        {id:"Lieu", required:true},
        {id:"Acte", required:true},
        {id:"heureDebut", required:true, regex: new RegExp('^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$')},
        {id:"heureFin", required:true, regex: new RegExp('^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$')},
    ]


    useEffect(() => {
        if(ActesQuery.data){
            const myActex = ActesQuery.data.find(a=>a.id === myST.Acte?.id)
            if(myActex){
                setMyActe(myActex)
            }
        }
        if(LieuxQuery.data){
            const myLieux = LieuxQuery.data.find(l=>l.id === myST.Lieu?.id)
            if(myLieux){
                setMyLieu(myLieux);
            }
        }
    }, [myST.Lieu, myST.Acte, LieuxQuery, ActesQuery]);
    useEffect(() => {
        let listHQ:OneHoraireQuick2[] = [];
        if(HorairesQuery.data){
            const HorairesSorted = HorairesQuery.data.sort((a:Horaires, b:Horaires)=>{
                return a.start < b.start ? -1 : 1;
            })
            const HoraireStart = HorairesSorted[0];
            const HoraireEnd = HorairesSorted[HorairesSorted.length - 1];
            listHQ = HorairesSorted.map(h=>{
                return {
                    lib:h.libelle,
                    HD:h.start,
                    HF:h.end,
                }
            })
            listHQ.push({
                lib:'Journée',
                HD:HoraireStart.start,
                HF:HoraireEnd.end,
            })
        }
        setListHoraires(listHQ);
    }, [HorairesQuery.data]);
    useEffect(() => {

        if(LieuxHorairesQuery.data && LieuxHorairesQuery.data.length>0){
            let listHQ:OneHoraireQuick2[];
            const HorairesSorted = LieuxHorairesQuery.data.sort((a:LieuHoraires, b:LieuHoraires)=>{
                return a.start < b.start ? -1 : 1;
            })
            const HoraireStart = HorairesSorted[0];
            const HoraireEnd = HorairesSorted[HorairesSorted.length - 1];
            listHQ = HorairesSorted.map(h=>{
                return {
                    lib:h.libelle,
                    HD:h.start,
                    HF:h.end,
                }
            })
            listHQ.push({
                lib:'Journée',
                HD:HoraireStart.start,
                HF:HoraireEnd.end,
            })

            setListHoraires(listHQ);
        }
    }, [LieuxHorairesQuery.data, myLieu]);
    useEffect(()=>{
        if(CentreQuery.data){
            const CentreParameters = CentreQuery.data.parameters;
            const litts:any = CentreQuery.data.literalParameters;
            const ParamsUrg = CentreParameters.find(p=>p.Parameter.id === 19);
            if(ParamsUrg && ParamsUrg.statut){
                setLibUrgence(litts.hasOwnProperty('libUrgence') ? litts.libUrgence : 'Urgence')
            }
        }
    }, [CentreQuery.data])

    const setSelect = (elt:Choice|string|number|null, id:string)=>{
        if(TabChoice.indexOf(id)!==-1){
            const myVal = !elt ? null : elt as Choice;
            const ErrorRetour = VerifErrorString(id, (myVal?.libelle||""), TabNeeds)
            setMyErrors(e=>{
                return {...e, [id]:ErrorRetour==='' ? null : ErrorRetour}
            })

            setMyST(p=> {
                return {...p, [id]: !elt ? null : elt as Choice}
            });
        } else if(["heureDebut", "heureFin"].indexOf(id)!==-1){
            setMyST(p=> {
                return {...p, [id]: elt as number}
            });
        }else {
            const myVal = elt as string;
            const ErrorRetour = VerifErrorString(id, myVal, TabNeeds)
            setMyErrors(e=>{
                return {...e, [id]:ErrorRetour==='' ? null : ErrorRetour}
            })
            setMyST(p=> {
                return {...p, [id]: elt as string}
            });
        }
    }
    const handleQuickHor = (h:OneHoraireQuick2)=>{
        setMyST(p=>{
            return {...p, heureDebut:h.HD, heureFin:h.HF}
        })
    }
    useEffect(() => {
        function handleClickOutside(event:any) {
            if (refContext.current && !refContext.current.contains(event.target) && ["on_choice_list", "span_choice"].indexOf(event.target.className)===-1) {
                props.closeMe();
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [refContext, props.closeMe]);
    const TrashST = ()=>{
        mutatationSuppr.mutate(props.SemaineType.id, {
            onSuccess:()=>{
                props.setSemainesTypes([...props.allSemType.filter(one=>one.id !== props.SemaineType.id)])
                props.closeMe();
            }, onError:()=>{
                toast.error("Impossible de supprimer la semaine type")
            }
        })
    }
    const onSub = ()=>{
        const LieuPresume = LieuxQuery.data?.find(l=>l.id === myST.Lieu?.id)
        const ActePresume = ActesQuery.data?.find(a=>a.id === myST.Acte?.id)
        if(!LieuPresume){
            setMyErrors(e=>{
                return {...e, Lieu:"Lieu introuvable"}
            })
            return;
        }
        if(!ActePresume){
            setMyErrors(e=>{
                return {...e, Acte:"Acte introuvable"}
            })
            return;
        }
        if(myST.heureFin <= myST.heureDebut){
            setMyErrors(e=>{
                return {...e, heureDebut:"date invalide", heureFin:"date invalide"}
            })
            return;
        }
        if(LieuPresume && ActePresume) {
            const datas: ModifySemaineTypeFormData = {
                HeureDebut: myST.heureDebut,
                HeureFin: myST.heureFin,
                Lieu: `/api/lieus/${LieuPresume.id}`,
                Acte: `/api/actes/${ActePresume.id}`,
                numJour: myST.numJour,
                id:props.SemaineType.id,
                isSpecial:myST.isSpecial
            }
            if(myST.UserLink){
                const UserPresumeLink = UsersQuery.data?.find(u=>u.id === myST.UserLink?.id)
                if(UserPresumeLink){
                    datas.UserLink = `/api/users/${UserPresumeLink.id}`

                }
            } else {
                datas.UserLink = null
            }

            mutationModify.mutate((datas), {
                onSuccess: (newData) => {
                    props.setSemainesTypes([...props.allSemType.filter(one=>one.id !== props.SemaineType.id), newData])
                    props.closeMe();
                },
                onError: () => {
                    toast.error("Impossible de modifier la semaine type")
                }
            })
        }
    }
    useEffect(() => {
        if(props.allSemType.length === 0){
            setSTSConflicts([]);
        } else{
            const myHD = myST.heureDebut;
            const myHF = myST.heureFin;
            const TabConf:SemaineType[] = [];
            const SemTypeDay = props.allSemType.filter(st=>st.numJour === props.SemaineType.numJour && st.id !== props.SemaineType.id)
            SemTypeDay.forEach(st=>{
                if(getIsHorseBase5SameDay(st.HeureDebut, st.HeureFin, myHD, myHF)){
                    TabConf.push(st);
                }
            })
            setSTSConflicts(TabConf)
        }

    }, [props.allSemType, myST.heureDebut, myST.heureFin]);
    return (
        <div className={`modal_edit_st ${props.className}`} ref={refContext}>
            {props.CentreSemType &&
                <div className="details">
                    <div className="user_place">
                        <TimedUserAvatar
                            User={props.User}
                            RightAddInit
                            isAddFonction
                        />
                    </div>
                    <div className="infos_sem_type">
                        {TabJ[props.SemaineType.numJour]} sur la Semaine {props.CentreSemType.namePerso ? `${props.CentreSemType.namePerso} (${props.CentreSemType.numRotation}/${props.nbCST})` : `Rotation ${props.CentreSemType.numRotation}/${props.nbCST}`}
                    </div>
                </div>
            }
            <TimedSelect324
                id={"Lieu"}
                Choices={LieuxQuery.data ? LieuxQuery.data.map(i => {
                    return {id: i.id, libelle: i.libelle, bkgColor: i.backColor, ftColor: i.fontColor}
                }) : []}
                Current={myST.Lieu}
                setValue={setSelect}
                Error={MyErrors.Lieu}
                label={"Lieu"}
                colorBorder={myLieu?.backColor || '#ccc'}
            />
            <TimedSelect324
                id={"Acte"}
                Choices={ActesQuery.data ? ActesQuery.data.filter(a => !a.isDemAbs && a.isProduction).map(i => {
                    return {id: i.id, libelle: i.libelle, bkgColor: i.backColor, ftColor: i.fontColor}
                }) : []}
                Current={myST.Acte}
                setValue={setSelect}
                Error={MyErrors.Acte}
                label={"Acte"}
                colorBorder={myActe?.backColor || '#ccc'}
            />
            <div className="wrap_choice_hor">
                <div className="quick">
                    {listHoraires.map((h: OneHoraireQuick2, idx: number) => (
                        <button className={"btn_choice_g"} key={"Ch" + idx} onClick={() => handleQuickHor(h)}>
                            {h.lib}
                        </button>
                    ))}
                </div>
                <div className="wrap_sethor">
                    <SetHoraires324
                        CurrentStart={myST.heureDebut}
                        CurrentEnd={myST.heureFin}
                        idStart={"heureDebut"}
                        idEnd={"heureFin"}
                        setHor={setSelect}
                        ErrorStart={MyErrors.heureDebut}
                        ErrorEnd={MyErrors.heureFin}
                    />
                </div>
            </div>
            {libUrgence !== '' &&
                <div className={"wrap_switch"} style={{
                    flexGrow: 1,
                    display: "flex",
                    justifyContent: "flex-start",
                    gap: "8px",
                    alignItems: "center"
                }}>
                    <TimedSwitch
                        themeColor={"Primary"}
                        size={"sm"}
                        statut={myST.isSpecial}
                        setStatut={() => {
                            setMyST(p => {
                                return {...p, isSpecial: !p.isSpecial}
                            })
                        }}
                    />
                    <label className={`lab_switch ${myST.isSpecial ? "actif" : "inactif"}`}>{libUrgence}</label>
                </div>
            }
            <div className="wrap_choix_priv">
                <div className="wrap_input_choix_priv">
                    <TimedSelect324
                        id={"UserLink"}
                        Choices={UsersQuery.data ? UsersQuery.data.filter(u => u.Fonction.id === 1 && u.FonctionAdm.id !== 3).map(u => {
                            return {id: u.id, libelle: `${u.prenom} ${u.nom}`}
                        }) : []}
                        Current={myST.UserLink}
                        setValue={setSelect}
                        Error={MyErrors.Lieu}
                        label={"Choix " + (process.env['REACT_APP_NAME'] === 'Timed' ? "Médecin" : "Personne") + " à privilégier"}
                    />
                </div>
                <div className="wrap_trash_choix">
                    {myST.UserLink &&
                        <TimedIconButton onClick={()=>setMyST(st=>{
                            return {...st, UserLink:null}
                        })} size={"sm"} Icon={<BsTrash/>} isSquare={true}/>
                    }
                </div>
            </div>
            <div className="wrap_send">
                {stsConflicts.length>0 ?
                    <div className={`alert_conflicts`}>
                        <IoWarning/>
                        <p>Plage en conflit avec une autre plage</p>
                    </div>:
                    <TimedButton
                        size={"sm"}
                        isFull={true}
                        themeColor={"Primary"}
                        onClick={onSub}
                        text={"Enregistrer"}
                        disabled={mutationModify.isLoading ||mutatationSuppr.isLoading}
                        isPending={mutationModify.isLoading ||mutatationSuppr.isLoading}
                    />
                }
            </div>
            {props.CentreSemType &&
                <div className="wrap_send">
                    <TimedButton
                        size={"sm"}
                        isFull={true}
                        themeColor={"Complementary"}
                        onClick={TrashST}
                        text={"Supprimer"}
                        disabled={mutationModify.isLoading ||mutatationSuppr.isLoading}
                        isPending={mutationModify.isLoading ||mutatationSuppr.isLoading}
                    />
                </div>
            }
        </div>
    )
}

export const FormEditST2 = styled(FormEditST2Ctrl)`
    padding: 1rem;
    display: flex;
    justify-content: flex-start;
    gap: 15px;
    flex-direction: column;
    .infos_sem_type{
        margin: 10px auto;
        font-size: 14px;
    }
    .wrap_choix_priv{
        display: flex;
        justify-content: flex-start;
        align-items: end;
        gap: 10px;
        .wrap_trash_choix{
            display: flex;
            justify-content: center;
            flex-direction: column;
            width: 40px;
            padding-bottom: 5px;
        }
        .wrap_input_choix_priv{
            flex-grow: 1;
        }
    }
    .wrap_choice_hor {
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        gap: 6px;
    }
    .quick{
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 3px;
        width: 40%;
        padding-right: 1rem;
        border-right: solid ${props => props.theme.NeutreExtraLight} 1px;
        .btn_choice_g{
            display: block;
            border:solid 1px ${props=>props.theme.NeutreLight};
            background: transparent;
            border-radius: 4px;
            padding: 0.25rem;
            color:${props=>props.theme.NeutreDark};
            &:hover{
                cursor: pointer;
                font-weight: bold;
                color:${props=>props.theme.NeutreExtraDark};
                background: ${props=>props.theme.NeutreMegaLight};
            }
        }
    }
`