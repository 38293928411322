import styled from "styled-components";
import {ReactNode} from "react";

interface RightPartTriplePageCtrlProps{
    className?:string;
    titre:string;
    subtitle?:string;
    children:ReactNode;
}

const RightPartTriplePageCtrl = (props:RightPartTriplePageCtrlProps)=>{
    return (
        <div className={`rightPartTriplePage ${props.className}`}>
            <div className="titre">
                <div className="lib_titre">{props.titre}</div>
                {props.subtitle &&
                    <div className="sub_title">{props.subtitle}</div>
                }
            </div>
            <div className="content_rpdp">
                {props.children}
            </div>
        </div>
    )
}

const RightPartTriplePage = styled(RightPartTriplePageCtrl)`
    flex-grow: 1;
    padding: 0 3rem;
    display: flex;
    flex-direction: column;
    gap: 30px;
    max-height: 100%;
    overflow-y: auto;
    .lib_titre{
        font-weight: bold;
        font-size: 18px;
        margin-bottom: 6px;
    }
    .content_rpdp{
        flex-grow: 1;
        overflow-y: auto;
    }
`

export default RightPartTriplePage