import {useQuery, useQueryClient, UseQueryResult} from "react-query";
import {GetAllUserCentre} from "./userCentre.services";


export const useGetAllUserCentre=():UseQueryResult<UserCentre[], Error>=>{
    const queryClient = useQueryClient();
    const UserConnected:UserSimple|undefined = queryClient.getQueryData(["user_connected"])
    const id = UserConnected ? UserConnected.id : 0;
    return useQuery(["user_centres", id], ()=>GetAllUserCentre(id), {
        enabled:id!==0,
        refetchOnWindowFocus:false
    })
}