import styled from "styled-components";
import {FaCheck} from "react-icons/fa";

interface OneChoiceHelpCtrlProps{
    className?:string;
    Choice:Choice;
    setValue:(Choice:Choice|string|number|null, id:string)=>void;
    isCurrent:boolean;
    id:string;
}

const OneChoiceHelpCtrl = (props:OneChoiceHelpCtrlProps)=>{
    return (
        <div className={`oneChoiceHelp ${props.className} ${props.isCurrent ? "current" : ""}`} onClick={()=>props.setValue(props.Choice, props.id)}>
            <div className="in_one_choice">
                <div className="dis_choice">
                    <div className="libelle">
                        <div className="main_lib">{props.Choice.libelle}</div>
                        {props.Choice.description &&
                            <div className="description_lib">{props.Choice.description}</div>
                        }
                    </div>
                    <div className="wrap_checkmark">
                        <FaCheck />
                    </div>
                </div>
            </div>
        </div>
    )
}

const OneChoiceHelp = styled(OneChoiceHelpCtrl)`
    width: 100%;
    border:solid ${props => props.theme.Primary} 1px;
    border-radius: 4px;
    &:hover{
        cursor: pointer;
        .in_one_choice{
            background: ${props=>props.theme.PrimaryMegaLight};
            border:solid 2px ${props=>props.theme.Primary};
        }
    }
    &.current{
        .in_one_choice{
            background: ${props=>props.theme.PrimaryMegaLight};
            border:solid 2px ${props=>props.theme.Primary};
        }
        .dis_choice .wrap_checkmark svg{
            transform: scale(1);
        }
    }
    .in_one_choice{
        width: 100%;
        border:solid 2px transparent;
        padding: 1rem;
        border-radius: 4px;
    }
    .dis_choice{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .libelle{
            flex-grow: 1;
            .main_lib{
                font-weight: bold;
            }
        }
        .wrap_checkmark{
            width: 36px;
            font-size: 28px;
            color: ${props=>props.theme.Primary};
            display: flex;
            justify-content: center;
            flex-direction: column;
            svg{
                transform: scale(0);
                margin: auto;
            }
        }
    }
`

export default OneChoiceHelp