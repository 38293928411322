import styled from "styled-components";
import PoigneeDrag from "../../components/PoigneeDrag";
import RightCard from "../../components/RightCard";
import SquareIndic from "../../components/SquareIndic";
import LibCard from "../../components/LibCard";

interface OneCardRubriqueCtrlProps{
    className?:string;
    Rubrique:RubriqueSpe;
    isCurrent:boolean;
    ClickOnEdit:()=>void;
}

const OneCardRubriqueCtrl = (props:OneCardRubriqueCtrlProps)=>{
    return (
        <div className={`oneCardRubrique ${props.className}`}>
            <PoigneeDrag isNoDrag/>
            <RightCard isCurrent={props.isCurrent} ClickOn={()=>props.ClickOnEdit()}>
                <LibCard text={props.Rubrique.libelle}/>
            </RightCard>
        </div>
    )
}

const OneCardRubrique = styled(OneCardRubriqueCtrl)``

export default OneCardRubrique