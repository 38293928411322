import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {getWeeksByDate, getWeeksForHeuresSupp} from "../../../../../../components/functions/TimesFunctions";
import WeekTime from "./WeekTime";

interface WeeklyHeuresCtrlProps{
    className?:string;
    idUser:number;
    dateUse:string;
    supervisor?:boolean;
}

export interface OneitemDateW{
    Monday:Date,
    Sunday:Date
}

const WeeklyHeuresCtrl = ({className, idUser, dateUse, supervisor}:WeeklyHeuresCtrlProps)=>{
    const [tabWeeks, setTabWeeks] = useState<OneitemDateW[]>([])
    useEffect(()=>{
        setTabWeeks(getWeeksForHeuresSupp(new Date(dateUse)))
    }, [dateUse])

    return (
        <div className={`WeeklyHeures ${className}`}>
            <div className={`wrap-weeks`}>
                {tabWeeks.map((item:OneitemDateW, idx:number)=>(
                    <WeekTime key={`WT${idx}`} idUser={idUser} OneitemDateW={item} supervisor={supervisor} dateStart={dateUse}/>
                ))}
            </div>
        </div>
    )
}

const WeeklyHeures = styled(WeeklyHeuresCtrl)`
    width: 100%;
    overflow: auto;
    .wrap-weeks{
      overflow-x: auto;
      display: flex;
      justify-content: start;
      gap: 20px;
    }
`

export default WeeklyHeures;