import React from "react";
import styled from "styled-components";
import {ConvertBase5} from "../../functions/TimesFunctions";

interface TimedChipsPlageSideBarCtrlProps{
    className?:string;
    Plage:Plage;
}

const TimedChipsPlageSideBarCtrl = ({className, Plage}:TimedChipsPlageSideBarCtrlProps)=>{
    return (
        <div className={`one-plage ${className}`}>
            <div className={`left-bar`} style={{background:Plage.Lieu.backColor}}/>
            <div className={`horaires`}>
                <div className={`one-horaire`}><span>{ConvertBase5(Plage.heureDebut)}</span></div>
                <div className={`one-horaire`}><span>{ConvertBase5(Plage.heureFin)}</span></div>
            </div>
            <div className={`lieu-acte`}>
                <div className={`in-l-a`}><strong>{Plage.Lieu.libelle}</strong> <br/> {Plage.Acte.libelle}</div>
            </div>
        </div>
    )
}

export const TimedChipsPlageSideBar = styled(TimedChipsPlageSideBarCtrl)`
  display: flex;
  gap: 8px;
  justify-content: flex-start;
  align-items: stretch;
  .left-bar{

    width: 6px;
    border-radius: 3px;
  }
  .horaires{
    .one-horaire{
      height: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
  .lieu-acte{
    flex-grow: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
`