import React, {useState} from "react";
import styled from "styled-components";
import {BsChevronDown} from "react-icons/bs";

interface TimedAccordionCtrlProps{
    className?:string;
    title:string;
    children:React.ReactNode;
    fontSize?:string;
}

const TimedAccordionCtrl = ({className, title, children}:TimedAccordionCtrlProps)=>{
    const [isOpen, setIsOpen] = useState(false);
    return (
        <div className={`myAccordion ${className}`}>
            <div className={`title`} onClick={()=>setIsOpen(_o=>!_o)}>
                <div className={`lib-title`}>{title}</div>
                <div className={`switch-icon ${isOpen ? 'isOpen' : 'isClose'}`}>
                    <BsChevronDown/>
                </div>
            </div>
            <div className={`content-accordion`} aria-expanded={!isOpen}>
                {children}
            </div>
        </div>
    )
}

const TimedAccordion = styled(TimedAccordionCtrl)`
    width:100%;
    .switch-icon{
      display: flex;
      flex-direction: column;
      justify-content: center;
      transform: rotate(0deg);
      transition: transform 0.3s;
      &.isOpen{
        transform: rotate(180deg);
        transition: transform 0.3s;
      }
    }
    .title{
      display: flex;
      justify-content: start;
      gap: 10px;
      align-items: center;
      margin-bottom: 20px;
      font-weight: bold;
      font-size: ${props=>props.fontSize ? props.fontSize : '16px'};
      &:hover{
        cursor: pointer;
      }
    }
    .content-accordion{
      width: 100%;
      overflow: hidden;
      max-height: 1000px;
      transition: max-height 1s ease-in-out;
    }
  .content-accordion[aria-expanded="true"] {
    max-height: 0px;
    transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
  }
`

export default TimedAccordion;