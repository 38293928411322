import styled from "styled-components";
import {BsDot} from "react-icons/bs";
import {FaCheck} from "react-icons/fa";

interface TimedChoiceMultiCtrlProps{
    className?:string;
    TabChoice:Choice[];
    ChoicesCurrent:Choice[];
    setChoice:(choices:Choice[])=>void;
    libelle:string;
    isSideWay?:boolean;
}

const TimedChoiceMultiCtrl = (props:TimedChoiceMultiCtrlProps)=>{
    const handleClickAll = ()=>{
        props.setChoice(props.TabChoice);
    }
    const handleClickNone = ()=>{
        props.setChoice([]);
    }
    const handleClickOne = (item:Choice)=>{
        const Exist = props.ChoicesCurrent.map(i=>i.id).indexOf(item.id)!==-1
        if(Exist){
            props.setChoice([...props.ChoicesCurrent.filter(i=>i.id !== item.id)])
        } else {
            props.setChoice([...props.ChoicesCurrent, item])
        }
    }
    return (
        <div className={`choice_multi ${props.className}`}>
            <div className="head_choice_multi">{props.libelle}</div>
            <div className="all_un">
                <div className="one_select_unselect" onClick={handleClickAll}>Tous</div>
                <BsDot/>
                <div className="one_select_unselect" onClick={handleClickNone}>Aucun</div>
            </div>
            <div className="wrap_choices">
                {props.TabChoice.map((item:Choice)=>(
                    <div onClick={()=>handleClickOne(item)} key={`on_C_M${item.id}`} className={`one_choice_multi ${props.ChoicesCurrent.map(i=>i.id).indexOf(item.id)!==-1 ? 'selected' : ''}`} >
                        <div className="checkmark">
                            <FaCheck />
                        </div>
                        <span>{item.libelle}</span>
                    </div>
                ))}
            </div>
        </div>
    )
}

const WCheck = 20;

export const TimedChoiceMulti = styled(TimedChoiceMultiCtrl)`
    .head_choice_multi{
      padding: 0.25rem;
      text-align: ${props=>props.isSideWay ? "left" :  "center"};
      font-weight: bold;
        font-size: 18px;
    }
   
    .all_un{
      display: flex;
      justify-content: flex-start;
      gap: 3px;
      color: ${props => props.theme.Primary};
      padding: 0.25rem;
        margin-bottom: 8px;
      .one_select_unselect{
          font-size: 12px;
        &:hover{
          cursor: pointer;
          color: ${props => props.theme.PrimaryDark};
        }
      }
    }
  .wrap_choices{
    .one_choice_multi{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 8px;
        gap: 6px;
        color: ${props=>props.theme.Neutre};
        span{
            font-size: 14px;
        }
        .checkmark{
            color: white;
            display: flex;
            flex-direction: column;
            justify-content: center;
            border-radius: 4px;
            font-size: 12px;
            svg{
                margin: auto;
                display: none;
            }
            width: ${WCheck+"px"};
            height: ${WCheck+"px"};
            background: white;
            border:solid ${props=>props.theme.Neutre} 1px;
        }
      &.selected{
            font-weight: bold;
          color: ${props=>props.theme.Dark};
          .checkmark{
              background: ${props=>props.theme.Primary};
              border:solid ${props=>props.theme.Primary} 1px;
              svg{
                  display: block;
              }
          }
          &:hover{
              .checkmark{
                  filter: brightness(95%);
              }
          }
      }
      &:hover{
        cursor: pointer;
        font-weight: bold;
          color: ${props=>props.theme.Dark};
          
      }
      &:not(.selected):hover{
          .checkmark {
              background: ${props => props.theme.PrimaryMegaLight};
          }
      }
    }
  }
`