import React, {useEffect, useMemo, useState} from "react";
import styled from "styled-components";
import {
    useCancelRegule,
    UseGetPointeuseTreatByParams
} from "../../../../../../features/pointeuseTreat/pointeuseTreat.hooks";
import TimedSkeletonList from "../../../../../../components/atoms/TimedSkeleton/TimedSkeletonList";
import TimedIconButton from "../../../../../../components/atoms/TimedIconButton/TimedIconButton";
import {AiOutlinePlus} from "react-icons/ai";
import {useModal} from "../../../../../../hooks/useModal";
import {ModalNewRegule} from "../../../../../Modaux/ModalNewRegule";
import {useGetOneUser} from "../../../../../../features/user/user.hooks";
import {useCentreConnectedData} from "../../../../../../services/hooks";
import {IoFlashSharp} from "react-icons/io5";
import {BsTrash} from "react-icons/bs";
import {TimedModalConfirm} from "../../../../../../components/molecules/TimedModal/TimedModalConfirm";

interface DetailsDailyRegulesCtrlProps{
    className?:string;
    dateUseEnd:string;
    idUser:number;
    supervisor?:boolean;
}

const DetailsDailyRegulesCtrl = ({className, idUser, dateUseEnd, supervisor}:DetailsDailyRegulesCtrlProps)=>{
    const centre = useCentreConnectedData();
    const [params, setParams] = useState<any|undefined>(undefined)
    const UserQuery = useGetOneUser(idUser);
    const PTQuery = UseGetPointeuseTreatByParams(params);
    const {open, toggle} = useModal();
    const [openC, setOpenC] = useState<boolean>(false)
    const mutationTrash = useCancelRegule();
    const [idTrash, setIdTrash] = useState<number|null>(null)
    const toggleC = ()=>{
        setOpenC(o=>!o);
    }
    useEffect(()=>{
        const now = new Date();
        let dateS = new Date(new Date(dateUseEnd).getFullYear(), new Date(dateUseEnd).getMonth(), 1);
        dateS = new Date(dateS.getTime() - dateS.getTimezoneOffset()*60000);
        const dateE = new Date(dateUseEnd).getMonth() === now.getMonth() ? new Date(now.getTime() - 86400000) : new Date(dateUseEnd);
        let paramsX:any = {"dateAT[after]":dateS.toISOString().slice(0,10), "dateAT[before]":dateE.toISOString().slice(0,10), User:idUser}
        if(supervisor){
            paramsX.Centre = centre;
        }
        setParams(paramsX)
    }, [dateUseEnd, idUser, supervisor, centre])
    const ListRegule = useMemo(()=>{
        let list:PointeuseTreat[] = [];
        if(PTQuery.data){
            return PTQuery.data.filter(pt=>!!pt.MotifReguleTemps && pt.MotifReguleTemps.id !==7 && pt.timeFinal!==0).sort((a:PointeuseTreat, b:PointeuseTreat)=>{
                return new Date(a.dateAT) > new Date(b.dateAT) ? 1 : -1;
            })
        }
        return list;
    }, [PTQuery.data])
    const handleClickAddRegule = ()=>{
        toggle();
    }
    const TrashRegule = (pt:PointeuseTreat)=>{
        setIdTrash(pt.id)
        toggleC()

    }
    const ConfirmTrash = ()=>{
        if(idTrash){
            mutationTrash.mutate(idTrash, {
                onSuccess:()=>{
                    toggleC()
                    setIdTrash(null)
                }
            });
        }

    }
    return (
        <div className={`dailyRegules ${className}`}>
            <div className={`wrap-action`}>
                {UserQuery.data &&
                    <TimedIconButton
                        size={"medium"}
                        themeColor={"Success"}
                        Icon={<AiOutlinePlus/>}
                        toolTip={"Ajouter une Régulation"}
                        onClick={handleClickAddRegule}
                        BackAlw={true}
                        isActive={false}
                    />
                }
            </div>
            {PTQuery.isLoading ?
                <TimedSkeletonList nbBar={3}/>:
                <>
                    {ListRegule.length === 0 ?
                        <p className={`no-result`}>Aucune régulation</p>:

                        <table className={`table_regules`}>
                            <thead>
                            <tr>
                                <th>Date</th>
                                <th>Motif</th>
                                <th>Temps</th>
                                <th>Commentaires</th>
                                <th>Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            {ListRegule.map((item:PointeuseTreat, idx:number)=>(
                                <tr key={`one-regule${idx}`}>
                                    <td className={`date-place`}>{new Date(item.dateAT).toLocaleDateString()}</td>
                                    <td className={`motif`}>{item.MotifReguleTemps?.libelle}</td>
                                    <td className={`temps ${item.MotifReguleTemps?.coef === -1 ? 'warn' : 'good'}`}>{item.MotifReguleTemps?.coef === -1 ? '-' : '+'}{Math.round((item.timeFinal||0)*100)/100} heures</td>
                                    <td>
                                        {item.comentFinalHour !== '' && item.comentFinalHour!==null &&
                                            item.comentFinalHour
                                        }
                                    </td>
                                    <td>
                                        <TimedIconButton
                                            size={"xs"}
                                            themeColor={"Warning"}
                                            Icon={<BsTrash/>}
                                            toolTip={"Supprimer"}
                                            onClick={()=>TrashRegule(item)}
                                            BackAlw={true}
                                            isActive={true}
                                            marginTop={"-0.1rem"}
                                            isPending={mutationTrash.isLoading}
                                        />
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    }
                </>
            }
            {open && UserQuery.data &&
                <ModalNewRegule
                    title={"Nouvelle régulation"}
                    Close={toggle}
                    size={"regular"}
                    position={"right"}
                    topPosition={"Top"}
                    user={UserQuery.data}
                    month={new Date(dateUseEnd).getMonth()+1}
                    year={new Date(dateUseEnd).getFullYear()}
                />
            }
            <TimedModalConfirm
                texts={{yes: "OUI", no:"NON", answer:"Êtes-vous sûr de vouloir supprimer cette régulation ? "}}
                callBackCancel={toggleC}
                open={openC}
                Pending={mutationTrash.isLoading}
                callBackConfirm={ConfirmTrash}
            />
        </div>
    )
}

const DetailsDailyRegules = styled(DetailsDailyRegulesCtrl)`
  .wrap-action{
    padding: 1rem 0.5rem 0.5rem 0.5rem;
    display: flex;
    justify-content: flex-end;
  }
  .table_regules{
    width: 100%;
    border-collapse: collapse;
    thead{
      th{
        font-size: 14px;
        padding: 0.25rem;
        text-align: center;
        border-bottom: ${props => props.theme.NeutreExtraLight} solid 1px;
      }
    }
    tbody{
      tr{
          td{
            border-bottom: ${props => props.theme.NeutreExtraLight} solid 1px;
            font-size: 14px;
            padding: 0.2rem;
            height: 35px;
            text-align: center;
            .TimedIconBtn{
              margin: auto;
              display: none;
            }
          }
        &:hover{
          background: ${props => props.theme.PrimaryMegaLight};
          td .TimedIconBtn{
            display: flex;
          }
        }
      }
    }
  }
  .date-place{
    font-weight: bold;
  }
  .temps{
    font-weight: bold;
    &.good{
      color:${props => props.theme.Success};
    }
    &.warn{
      color:${props => props.theme.Complementary};
    }
  }
  ul{
    padding: 0 .5rem;
  }
  .wrap-infos-regule{
    font-size: 14px;
    display: flex;
    justify-content: flex-start;
    gap:10px
  }
`

export default DetailsDailyRegules;