import React from "react";
import styled, {keyframes} from "styled-components";
import {BodyDash, ContentDash, Shine, TitleDash, Widy} from "../../../assets/styles/elements";
import {MdLaunch} from "react-icons/md";
import {ReactComponent as TextSVG} from "../../../assets/svgs/cpteur.svg";
import {useLocation, useNavigate} from "react-router-dom";

interface WidgetJaugeCtrlProps{
    className?:string;
    tabColors?:string[];
    title:string;
    subTitle?:string;
    percent:number;
    redirect?:string;
    isPending:boolean;
}

const WidgetJaugeCtrl = ({className, title, percent, redirect, subTitle, isPending}:WidgetJaugeCtrlProps)=>{
    const { search } = useLocation();
    const navigate = useNavigate()
    const GoRedirect =()=>{
        if(redirect){
            navigate(`${redirect}${search}`)
        }
    }
    return (
        <Widy className={`widget_metrix ${className}`}>
            <ContentDash>
                <TitleDash>
                    <div className={`main-title`}>
                        {!!redirect ?
                            <div className={`wrap-titre-redirect`}>
                                <div className={`lib-title`}>{title}</div>
                                <div className={`wrap-redirect`} onClick={GoRedirect}><MdLaunch/></div>
                            </div>:title
                        }
                    </div>
                    {subTitle &&
                        <div className={`sub-title`}>{subTitle}</div>
                    }
                </TitleDash>
                <BodyDash className={`${subTitle ? 'suby' : ''}`}>
                   <div className={`wrap-jauge`}>
                       <MonCompteur deg={isPending ? 0 : (Math.round(percent*100)/100)*180} isPending={isPending}/>
                   </div>
                    <div className={`wrap-percent`}>
                        {isPending ?
                            <div className={`Pending-Number`}>00%</div>:
                            <span>{Math.round(percent*10000)/100}%</span>
                        }
                    </div>
                </BodyDash>
            </ContentDash>
        </Widy>
    )
}

interface MonCompteurCtrlProps{
    className?:string;
    deg:number;
    isPending:boolean;
}

const MonCompteurCtrl = ({className, isPending, deg}:MonCompteurCtrlProps)=>{
    return (
        <div className={`cpteur ${className} ${isPending ? 'pending' : ''}`}>
            <TextSVG/>
        </div>
    )
}

const Rotation = (deg:number)=> keyframes`
    from{
      transform:rotate(0deg)
    }
    to{
      transform:rotate(${deg}deg)
    }
`

const AnimeColor = keyframes`
  0% {
    fill: #f3e2e2;
  }
  50% {
    fill: #d5b7b7;
  }
  100% {
    fill: #f3e2e2;
  }
`

const MonCompteur = styled(MonCompteurCtrl)`
  width: 159px; //#F7AE4A
  margin: auto;
  text-align: center;
  display: flex;
  justify-content: center;
  svg{
    margin: auto;
  }
  #zeroVingt{
    path{
      fill:${props => props.theme.ErrorDark}
    }
  }
  #Vingt40{
    path{
      fill:${props => props.theme.Error}
    }
  }
  #_0Soixante{
    path{
      fill:#F7AE4A
    }
  }
  #_04Vingt{
    path{
      fill:${props => props.theme.Secondary}
    }
  }
  #_0Cent{
    path{
      fill:${props => props.theme.SecondaryDark}
    }
  }
  &.pending{
    #zeroVingt, #Vingt40, #_0Soixante, #_04Vingt, #_0Cent{
      path{
        animation: 2s ${AnimeColor} linear infinite;
      }
    }
    #aiguille{
      animation: 2s ${AnimeColor} linear infinite;
    }
  }
  #aiguille{
    transform-origin: center 129px;
    animation: ${props=>Rotation(props.deg)} linear 2s forwards;
    box-shadow: 1px 1px rgba(0,0,0,.2);
  }
`




export const WidgetJauge = styled(WidgetJaugeCtrl)`
  width: 100%;
  height: 100%;
  .wrap-jauge{
    padding-top: 0.5rem;
    text-align: center;
    svg{
      margin: auto;
    }
  }
  .wrap-percent{
    text-align: center;
    font-weight: bold;
    color:${props => props.theme.Primary};
    span{
      font-size: 28px;
      padding: 1rem;
    }
  }
  .Pending-Number {
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    background-size: 200% 100%;
    animation: 1.5s ${Shine} linear infinite;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-animation: 1.5s ${Shine} linear infinite;
    font-size: calc(26px + 0.75vw);
  }
`

