import styled from "styled-components";
import {OneDayProj} from "../ProjectionST";
import {useState} from "react";
import {ProjItem} from "./ProjItem";

interface ProjDayCtrlProps {
    className?:string;
    ProjD:OneDayProj;
}

const ProjDayCtrl = ({className, ProjD}: ProjDayCtrlProps) => {
    const [classBesoin, setClassBesoin] = useState<string>('')
    const HoveringBesoin = ()=>{
        setTimeout(()=>{
            setClassBesoin('hover')
        }, 500)
    }
    const UnHoverBesoin = ()=>{
        setClassBesoin('')
    }
    const getCouleurBesoin = ()=>{
        if(!ProjD.isPbBesoin || ProjD.BesoinTips.length=== 0){
            return "#97B77B"
        } else if(ProjD.isPbBesoin){
            const nbPb = ProjD.BesoinTips.filter(x=>x.need!==x.have).length;
            if(nbPb === 0){
                return "#97B77B"
            }
            let nbSSEffectif = 0;
            let nbSurEffectif = 0;
            ProjD.BesoinTips.forEach(x=>{
                if(x.have > x.need){
                    nbSurEffectif++;
                } else if(x.have < x.need){
                    nbSSEffectif++;
                }
            })
            if(nbSSEffectif === nbPb){
                return "#A85131"
            } else if(nbSurEffectif === nbPb){
                return "#43356E"
            } else {



                return "orange"
            }
        } else {
            return "#97B77B"
        }
    }
    return (
        <div className={`one_day_proj ${className}`}>
            <div className="wrapper_in_date">
                <div className={`mark_Besoin ${classBesoin}`} onMouseOver={HoveringBesoin}
                     onMouseLeave={UnHoverBesoin} style={{background: getCouleurBesoin()}}
                     >
                    <div className="detailsBesoin">
                        {ProjD.items.length === 0 &&
                            <div className="one_pb">
                                <span>Aucun besoin</span>
                            </div>
                        }
                        {ProjD.BesoinTips?.map((item, idx) => (
                            <div className="one_pb" key={`onePbB${idx}`}>
                                <span>{item.fx} ({item.have} présent{item.have > 1 ? "s" : ""} /{item.need} besoin{item.need > 1 ? "s" : ""})</span>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="in_day">
                    {ProjD.items.map((item, idx)=>(
                        <ProjItem ProjItem={item} key={`projItem${item.id}${idx}`}/>
                    ))}
                </div>
            </div>
        </div>
    )
}

export const ProjDay = styled(ProjDayCtrl)`
    width: 14.2857143%;
    height: 100%;
    background: white;
    &:not(:last-child) {
        border-right: solid 1px ${props => props.theme.TertiaryExtraLight};
    }

    .detailsBesoin {
        display: none;
        position: absolute;
        left: calc(100% + 20px);
        background: rgba(255, 255, 255, .9);
        padding: 0.5rem;
        border-radius: 4px;
        z-index: 9;
        border: solid ${props => props.theme.Primary} 1px;
        width: max-content;
    }

    .mark_Besoin {
        width: 10px;
        border-radius: 2px;
        transition: width 0.3s;
        margin-right: 4px;
        position: relative;

        &:hover {
            .detailsBesoin {
                display: block;
            }
        }

        &:hover {
            cursor: pointer;
            width: 14px;
            transition: width 0.3s;
        }
    }
    .wrapper_in_date{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: stretch;
        gap: 2px;
        .mark_Besoin{
            width: 10px;
            border-radius: 2px;
            transition: width 0.3s;
            margin-right: 4px;
            position: relative;

            &:hover{
                .detailsBesoin{
                    display: block;
                }
            }

            &:hover{
                cursor: pointer;
                width: 14px;
                transition: width 0.3s;
            }
        }
        .standard{
            flex-grow: 1;
        }
    }
    .in_day{
        flex-grow: 1;
    }
`