import React from "react";
import styled from "styled-components";
import {NavLink, useLocation} from "react-router-dom";
import {myBoxShadow} from "../../../assets/styles/elements";
import {useAddNavigation} from "../../../features/navigation/navigation.hooks";

interface TimedLinkSubCtrlProps{
    className?:string;
    libelle:string;
    icon:React.ReactNode;
    link:string;
    Open:boolean;
    module:Module;
}

const TimedLinkSubCtrl = ({className, libelle, icon, link, Open, module}:TimedLinkSubCtrlProps)=>{
    const location = useLocation();
    const mutation = useAddNavigation();
    const isActive = ()=>{
        const TabLocation = location.pathname.split('/');
        const TabLink = link.split('?');
        return TabLocation[2] === TabLink[0];
    }
    const handleClick = ()=>{
        const datas:AddNavigation={SkeletonModule:`/api/skeleton_modules/${module.id}`}
        mutation.mutate((datas));
    }
    return (
        <NavLink onClick={handleClick} to={link} className={`TimedItemMenu ${className} ${isActive() ? 'isActive' : ''} ${Open ? 'opened' : 'closed'}`} data-info={libelle}>
            {Open ?
                <div className={`wrap-infos`}>
                    <div className={`icon-place`}>{icon}</div>
                    <div className={`lib-place`}>{libelle}</div>
                </div>:
                <div className={`wrap-closed`}>{icon}</div>
            }
        </NavLink>
    )
}

const TimedLinkSub = styled(TimedLinkSubCtrl)`
  text-decoration: none;
  width: 100%;
  padding: 0.75rem 0.85rem;
  display: block;
  font-size: 16px;
  position: relative;
  color:${props=>props.theme.PrimaryDark}!important;
  &.closed{
    &:after{
      content: attr(data-info);
      position: absolute;
      left: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      top: 0;
      ${myBoxShadow};
      height: 100%;
      padding: 0;
      background: white;
      color: ${props=>props.theme.TextMenuColor};
      text-wrap: none;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 0;
      transition: width 0.4s;
      border-bottom-right-radius: 0.4rem;
      border-top-right-radius: 0.4rem;
    }
    &:hover:after{
      width: 280px;
      white-space: nowrap;
      text-wrap: none;
      transition: width 0.4s;
    }
  }
  .wrap-infos{
    display: flex;
    justify-content: start;
    .lib-place{
      margin-left: 10px;
    }
  }
  &:hover, &.isActive{
    background: white;
    font-weight: 500;
    color:${props=>props.theme.colorDark}!important;
  }
  .wrap-closed{
    position: relative;
    display: flex;
    justify-content: center;
    font-size: 22px;
  }
`

export default TimedLinkSub