import styled from "styled-components";
import IllUpdate from "../../assets/images/Update.png";
import TimedButton from "../atoms/TimedButton/TimedButton";


interface SomethingWrongCtrlProps{
    className?:string;
}

const SomethingWrongCtrl = (props:SomethingWrongCtrlProps)=>{
    return (
        <div className={`something_wrong ${props.className}`}>
            <div className={`in_refresh`}>
                <img src={IllUpdate} alt={"refresh"}/>
                <div className={`legende`}>
                    <p className={`leg1`}>L'application a été mise à jour<br/> merci de rafraichir la page</p>
                    <p className={`leg2`}>Si toutefois cette page persiste, merci de contacter le support technique</p>
                </div>
                <TimedButton themeColor={"Secondary"} onClick={()=>window.location.reload()}>Rafraichir</TimedButton>
            </div>
        </div>
    )
}

export const SomethingWrong = styled(SomethingWrongCtrl)`
  position: fixed;
  left: 0;
  z-index: 99;
  width: 100%;
  height: 100%;
  background: white;
  .in_refresh{
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translate(-50%,-50%);
    text-align: center;
    .legende{
      margin: 30px auto;
      .leg1{
        color: ${props => props.theme.Primary};
        font-weight: bold;
        font-size: 22px;
      }
      .leg2{
        margin-top: 15px;
        color: ${props=>props.theme.ComplementaryDark};
        font-style: italic;
      }
    }
  }
`