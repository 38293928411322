import React from "react";
import styled from "styled-components";

interface TimedChipUserAvatarNameCtrlProps{
    className?:string;
    Round:React.ReactNode;
    libelle:string;
    isFx?:boolean;
    libFx?:string;
    isBold?:boolean;
}

const TimedChipUserAvatarNameCtrl = ({className, Round, libelle, isFx, libFx}:TimedChipUserAvatarNameCtrlProps)=>{
    return (
        <div className={`TimedChipUAN ${className}`}>
            <div className={`avatar-place`}>{Round}</div>
            <div className={`name-place`}>
                {libelle}
                {isFx && <span className={`fx_lib`}>{libFx||'nc'}</span> }
            </div>
        </div>
    )
}

const TimedChipUserAvatarName = styled(TimedChipUserAvatarNameCtrl)`
    display: flex;
    justify-content: start;
  .fx_lib{
    font-size: 11px;
    color: ${props=>props.theme.SecondaryDark};
  }
    .name-place{
      padding: 0 0.5rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-weight: bold;
      font-weight: ${props=>props.isBold ? "bold" : "normal"};
    }
`

export default TimedChipUserAvatarName;