import {dataFetcher} from "../../services/dataGuetter/dataFetcher";


export async function getCompteurTempsDateDate(dateFrom:string, dateTo:string, users:number[], signal:any, idCentre:number|null):Promise<CompteurTempsDateDate[]>{
    const idsUser = users.join('_');
    const config =  {method:'get'}
    let parms = `dateFrom=${dateFrom}&dateTo=${dateTo}&Users=${idsUser}`
    if(idCentre){
        parms +=`&Centre=${idCentre}`
    }
    const res:any = await dataFetcher(`compteur_temps_date_dates?${parms}`, config)
    return res["hydra:member"];
}