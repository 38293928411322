import styled from "styled-components";

interface ErrorInputSettingCtrlProps{
    className?:string;
    Error:string|null;
}

const ErrorInputSettingCtrl = (props:ErrorInputSettingCtrlProps)=>{
    if(!props.Error){
        return null;
    } else {
        return (
            <div className={`errorInputSetting ${props.className}`}>{props.Error}</div>
        )
    }
}

const ErrorInputSetting = styled(ErrorInputSettingCtrl)`
    font-size: 14px;
    color: red;
    width: 100%;
    margin-left: 27%;
`

export default ErrorInputSetting