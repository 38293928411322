import React, {useMemo} from "react";
import styled, {useTheme} from "styled-components";
import logo from '../../../assets/images/logo-timed-horizontal-black-8.png'
import {Dna, TailSpin, ThreeDots} from "react-loader-spinner";
import {BsCheck} from "react-icons/bs";

interface TimedWaitUserCtrlProps{
    className?:string;
    UserLoading:boolean;
    FonctionsQuery:boolean;
    LieuxLoading:boolean;
    VuesLoading:boolean;
}

interface OneLoad{
    isLoad:boolean,
    libLoad:string;
    libLoaded:string;
}

const TimedWaitUserCtrl = ({className, UserLoading, VuesLoading, LieuxLoading, FonctionsQuery}:TimedWaitUserCtrlProps)=>{
    const theme = useTheme();
    const myListLoading = useMemo(()=>{
        let myList:OneLoad[] = [
            {
                isLoad:UserLoading,
                libLoad:"Chargement des collaborateurs",
                libLoaded:"Collaborateurs chargés"
            },
            {
                isLoad:FonctionsQuery,
                libLoad:"Chargement des fonctions",
                libLoaded:"Fonctions chargées"
            },
            {
                isLoad:LieuxLoading,
                libLoad:"Chargement des lieux",
                libLoaded:"Lieux chargés"
            },
            {
                isLoad:VuesLoading,
                libLoad:"Chargement des vues plannings",
                libLoaded:"Vues plannings chargées"
            },

        ];
        return myList;
    }, [UserLoading, VuesLoading, LieuxLoading, FonctionsQuery])
    return (
        <div className={`TimedWaitUser ${className}`}>
            <div className={"message-wait"}>
                <div className={`logoWait`}>
                    <div className={`logo-place`}><img className={'my-logo'} src={logo} alt={"Timed"}/></div>
                    <div className={"spinner"}>
                        <ThreeDots
                            height="30"
                            width="40"
                            radius="9"
                            color={theme.Primary}
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}

                            visible={true}
                        />
                    </div>
                </div>
                <div className={"list-loading"}>
                    {myListLoading.map((item:OneLoad, idx:number)=>(
                        <div className={`item-loader`} key={`OneLoader${idx}`}>
                            <div className={`load ${item.isLoad ? 'loading' : 'ok'}`}>
                                {item.isLoad ?
                                    <TailSpin
                                        height="22"
                                        width="22"
                                        color="#4fa94d"
                                        ariaLabel="tail-spin-loading"
                                        radius="1"
                                        wrapperStyle={{}}
                                        wrapperClass=""
                                        visible={true}
                                    />:
                                    <div className={`isCheck`}><BsCheck/></div>
                                }
                            </div>
                            <div className={"lib-loading"}>
                                {item.isLoad ? item.libLoad : item.libLoaded}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

const TimedWaitUserStyled = styled(TimedWaitUserCtrl)`
   position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100%;
  background: white;
  .isCheck{
    width: 22px;
    height: 22px;
    border-radius: 50%;
    border:solid ${props => props.theme.Success} 2px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    svg{
      margin: auto;
      color: ${props => props.theme.Success};
      fill : ${props => props.theme.Success};
    }
  }
  .list-loading{
    margin-top: 40px;
    .item-loader{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 10px;
      .lib-loading{
        font-size: 18px;
      }
      .load{
        width: 22px;
        height: 22px;
        margin-right: 10px;
      }
    }
  }
  .message-wait{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    .logoWait{
      display: flex;
      justify-content: flex-start;
      align-items: baseline;
      margin-bottom: 30px;
      .logo-place{
        height: 45px;
        img{height: 100%}
        margin-right: 20px;
      }
    }
    
  }
`
const TimedWaitUser = React.memo(TimedWaitUserStyled);

export default TimedWaitUser;