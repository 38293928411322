import styled from "styled-components";
import {TimedSpinner} from "../../components/atoms/TimedSkeleton/TimedSpinner";
import {TimedUserAvatar} from "../../components/molecules/_TimedUserAvatar/TimedUserAvatar";
import {ConvertBase5} from "../../components/functions/TimesFunctions";
import React from "react";

interface GestionConflitsCreneauCtrlProps{
    className?:string;
    creneaux:Creneau[];
    isLoading:boolean;
    libelle?:string;
}

const GestionConflitsCreneauCtrl = (props:GestionConflitsCreneauCtrlProps)=>{
    return (
        <div className={`gestion_conflits ${props.className}`}>
            {props.isLoading ? <div className={"wrapper_spinner"}><TimedSpinner/></div> :
                props.creneaux.length>0 ?
                    <div className={`results_conflits`}>
                        <h4 style={{color:"red", fontSize:"14px", marginBottom:"6px"}}>{props.libelle ? props.libelle : "Créneaux en conflits"}</h4>
                        <div className={`wrapper_creneaux_conf`}>
                            {props.creneaux.map(item=>(
                                <div key={`crenConf${item.id}`} className={`cren_conf`}>
                                    <div className="MedToReplace">
                                        <TimedUserAvatar User={item.UserReplaced} LeftAddInit isInactif={false} isInverseName/>
                                    </div>
                                    <div className={"Lieu"}>{item.Lieu.libelle}</div>
                                    <div className="date">{new Date(item.creneauAt).toLocaleString().slice(0,10)}</div>
                                    <div className="segmentation" style={{width:!item.UserAffected ? "10%" : "5%", paddingRight:"7px"}}>{item.heureFin - item.heureDebut > 60 ? 'Journée' : item.heureDebut < 144 ? 'Matin' : 'Après-midi'}</div>
                                    {item.UserAffected &&
                                        <div className="MedReplace" style={{width:"17%"}}>
                                            <TimedUserAvatar User={item.UserAffected} LeftAddInit isInactif={false} isInverseName/>
                                        </div>
                                    }
                                    <div className="horaires">{ConvertBase5(item.heureDebut)} - {ConvertBase5(item.heureFin)}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                    :''
            }
        </div>
    )
}

export const GestionConflitsCreneau = styled(GestionConflitsCreneauCtrl)`
    .wrapper_spinner{
      margin: 10px 0;
      display: flex;
      justify-content: center;
    }
  .results_conflits{
    margin: 10px 0;
  }
    .wrapper_creneaux_conf{
    
      display: flex;
      flex-direction: column;
    }
    .cren_conf{
      width: 100%;
      padding: 0.5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border:solid 1px ${props=>props.theme.PrimaryMegaLight};
      border-radius: 4px;
      font-size: 13px;
    }
`