import jwt_decode from "jwt-decode";

export function isAuth(): string | null {
    const Token = localStorage.getItem("__timed_user_token__");
    if(Token){
        const TokenDecode:any = jwt_decode(Token);
        const Roles:string[] = TokenDecode["roles"];
        if(Roles.indexOf("ROLE_WEBMASTER")!==-1 || Roles.indexOf("ROLE_ADMIN")!==-1){
            return Token;
        } else {
            return null;
        }
    } else {
        return null;
    }
}

export function isAuthWM():boolean{
    const Token = localStorage.getItem("__timed_user_token__");
    if(Token){
        const TokenDecode:any = jwt_decode(Token);
        const Roles:string[] = TokenDecode["roles"];
        if(Roles.indexOf("ROLE_WEBMASTER")!==-1){
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
}

export function isAuthSV():boolean{
    const Token = localStorage.getItem("__timed_user_token__");
    if(Token){
        const TokenDecode:any = jwt_decode(Token);
        const Roles:string[] = TokenDecode["roles"];
        if(Roles.indexOf("ROLE_WEBMASTER")!==-1 || Roles.indexOf("ROLE_SUPERVISOR")!==-1){
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
}