import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import {isAuth, isAuthWM} from "../../services/security/isAuth";



interface WebmasterRouteCtrlProps {
    from: string;
    children?:React.ReactNode;
}

function WebmasterRoute(props: WebmasterRouteCtrlProps) {
    const location = useLocation();
    return isAuthWM() ? <Outlet /> : <Navigate to="/login" state={{ from: location.pathname }} />;
}

export default WebmasterRoute;