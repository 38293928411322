import styled from "styled-components";
import OneCardCompteur from "./components/OneCardCompteur";

interface WrapListCompteursCtrlProps{
    className?:string;
    Periodes:Periode[];
    PeriodeActive:Periode|null;
    setPeriode:(p:Periode)=>void;
}

const WrapListCompteursCtrl = (props:WrapListCompteursCtrlProps)=>{
    return (
        <div className={`wrapListCompteurs wrap_list ${props.className}`}>
            {props.Periodes.map(item=>(
                <OneCardCompteur ClickOnEdit={()=>props.setPeriode(item)} isCurrent={props.PeriodeActive?.id === item.id} Periode={item} key={`P${item.id}`}/>
            ))}
        </div>
    )
}

const WrapListCompteurs = styled(WrapListCompteursCtrl)``

export default WrapListCompteurs