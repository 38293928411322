import styled from "styled-components";
import {MdOutlineDragIndicator} from "react-icons/md";

interface PoigneeDragCtrlProps{
    className?:string;
    isNoDrag?:boolean;
}

const PoigneeDragCtrl = (props:PoigneeDragCtrlProps)=>{
    return (
        <div className={`poigneeDrag  ${props.className} ${!props.isNoDrag ? "draggable" : ""}`}>
            {!props.isNoDrag &&
                <MdOutlineDragIndicator />
            }
        </div>
    )
}

const PoigneeDrag = styled(PoigneeDragCtrl)`
    font-size: 24px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0 0.5rem;
    min-width: 40px;
    flex-shrink: 0;
    &.draggable:hover{
        cursor: grab;
    }
`

export default PoigneeDrag