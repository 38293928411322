import TimedSuspense from "../../components/atoms/TimedWaitUser/TimedSuspense";
import React, {lazy, Suspense} from "react";
import {ErrorBoundary} from "../../services/ErrorBoundary";
import {Routes, Route, Navigate} from "react-router-dom";
import MainLayoutSV from "../../components/organisms/layouts/MainLayoutSV";
import styled from "styled-components";
import NotFound from "../NotFound/NotFound";
import TempsMedecin from "../TimedApplication/TempsActivites/TempsMedecin/TempsMedecin";
import TempsTravaille from "../TimedApplication/TempsActivites/TempsTravaille/TempsTravaille";
import EquivalentTempsPlein from "../TimedApplication/TempsActivites/EquivalentTempsPlein/EquivalentTempsPlein";
import TableauCroiseDemiJours from "../TimedApplication/TempsActivites/TableauCroiseDemiJours/TableauCroiseDemiJours";
import Exports from "../TimedApplication/TempsActivites/Exports/Exports";
import DashboardCreneaux from "../TimedApplication/Remplacements/DashCreneaux/DashboardCreneaux";
import CreneauxEnCours from "../TimedApplication/Remplacements/CreneauxEnCours/CreneauxEnCours";
import ListRemplacant from "../TimedApplication/Remplacements/ListeRemplacant/ListRemplacant";
import News from "../TimedApplication/VieCentre/News/News";
import GestionHeuresSuppFull from "../TimedApplication/TempsActivites/GestionHeuresSupp/GestionHeuresSuppFull";




const DashboardSV = lazy(()=>import("./Dashboard/DashboardSV"));
const DashboardRH = lazy(()=>import("../TimedApplication/AdministrationRH/DashboardRH/DashboardRH"));
const FichePersonnel = lazy(()=>import("../TimedApplication/AdministrationRH/FichePersonnel"));
const TicketRestaurant = lazy(()=>import( "../TimedApplication/AdministrationRH/TicketRestaurant"));
const EtatPreparatoirePaie = lazy(()=>import( "../TimedApplication/AdministrationRH/EtatPreparatoirePaie/EtatPreparatoirePaie"));
const Trombinoscope = lazy(()=>import("../TimedApplication/AdministrationRH/Trombinoscore/Trombinoscope")) ;
const GenerationPlanning = lazy(()=>import("../TimedApplication/Plannings/GenerationPlanning/GenerationPlanning")) ;
const RechercheEvenements = lazy(()=>import("../TimedApplication/TempsActivites/RechercheEvenements/RechercheEvenements")) ;
const PlanningDashboard = lazy(()=>import("../TimedApplication/Plannings/PlanningDashboard/PlanningDashboard")) ;
const PlanningIndiHebdo = lazy(()=>import("../TimedApplication/Plannings/PlanningIndiHebdo/PlanningIndiHebdo")) ;
const PlanningIndiMensuel = lazy(()=>import("../TimedApplication/Plannings/PlanningIndiMensuel/PlanningIndiMensuel")) ;
const SemaineType = lazy(()=>import("../TimedApplication/Plannings/SemaineType/SemaineType")) ;
const PlanningGlobalLieu3 = lazy(()=>import("../TimedApplication/Plannings/PlanningGlobalLieu/PlanningGlobalLieu3")) ;
const PlanningGlobalPersonne = lazy(()=>import("../TimedApplication/Plannings/PlanningGlobalPersonne/PlanningGlobalPersonne")) ;
const EventGroup = lazy(()=>import("../TimedApplication/Plannings/EventGroup/EventGroup")) ;
const SuppressionPlanning = lazy(()=>import("../TimedApplication/Plannings/SuppressionPlanning/SuppressionPlanning")) ;
const TauxAffectation = lazy(()=>import("../TimedApplication/Plannings/TauxAffectation/TauxAffectation")) ;
const ModificationPlanning = lazy(()=>import("../TimedApplication/Plannings/ModificationPlanning/ModificationPlanning")) ;
const CoherencePlanningCurve = lazy(()=>import("../TimedApplication/Plannings/CoherencePlanning/CoherencePlanningCurve")) ;
const EtatVisuelAbsence = lazy(()=>import("../TimedApplication/Plannings/EtatVisuelAbsence/EtatVisuelAbsence")) ;
const DashboardAbs = lazy(()=>import("../TimedApplication/CongesAbsences/DashboardAbs/DashboardAbs")) ;
const DemandesAbs = lazy(()=>import("../TimedApplication/CongesAbsences/DemandesAbs/DemandesAbs")) ;
const CompteursEtDroitsAbs = lazy(()=>import("../TimedApplication/CongesAbsences/CompteursEtDroitsAbs/CompteursEtDroitsAbs")) ;
const StatsAbsences = lazy(()=>import("../TimedApplication/TempsActivites/StatsAbsences/StatsAbsences")) ;
const DashboardPointage = lazy(()=>import("../TimedApplication/Pointages/Dashboard/DashboardPointage")) ;
const HistoriquePointage =lazy(()=>import("../TimedApplication/Pointages/Historique/HistoriquePointage"));
const GestionPointages = lazy(()=>import( "../TimedApplication/Pointages/GestionPointages/GestionPointages"));
const DashboardTps =lazy(()=>import( "../TimedApplication/TempsActivites/DashboardTps/DashboardTps"));

interface SupervisorControllerCtrlProps{
    className?:string;

}

export const SupervisorControllerCtrl = ({className}:SupervisorControllerCtrlProps)=>{
    return (
        <div className={`superverisor ${className}`}>
            <Suspense fallback={<TimedSuspense/>}>
                <ErrorBoundary>
                    <Routes>
                        <Route element={<MainLayoutSV/>}>
                            <Route path="" element={<Navigate to="dashboard" />} />
                            <Route path="/dashboard" element={<DashboardSV/>}/>
                            <Route path={`/rh_dashboard`} element={<DashboardRH supervisor={true}/>}/>
                            <Route path="/fiche_personnel" element={<FichePersonnel supervisor={true}/>}/>
                            <Route path="/tickets_restaurant" element={<TicketRestaurant supervisor={true}/>}/>
                            <Route path="/etat_preparatoire_paie" element={<EtatPreparatoirePaie supervisor={true}/>}/>
                            <Route path="/trombinoscope" element={<Trombinoscope supervisor={true}/>}/>
                            <Route path="/recherche_evenements" element={<RechercheEvenements  supervisor={true}/>}/>
                            <Route path="/planning_dashboard" element={<PlanningDashboard supervisor={true}/>}/>
                            <Route path="/planning_indi_hebdo" element={<PlanningIndiHebdo supervisor={true}/>}/>
                            <Route path="/planning_indi_mensuel" element={<PlanningIndiMensuel supervisor={true}/>}/>
                            <Route path="/semaine_type" element={<SemaineType supervisor={true}/>}/>
                            <Route path="/planning_global_lieu" element={<PlanningGlobalLieu3 supervisor={true}/>}/>
                            <Route path="/planning_global_personne" element={<PlanningGlobalPersonne supervisor={true}/>}/>
                            <Route path="/generation_planning" element={<GenerationPlanning supervisor={true}/>}/>
                            <Route path="/group_event" element={<EventGroup supervisor={true}/>}/>
                            <Route path="/suppression_planning" element={<SuppressionPlanning supervisor={true}/>}/>
                            <Route path="/modification_planning" element={<ModificationPlanning supervisor={true}/>}/>
                            <Route path="/taux_affectation" element={<TauxAffectation supervisor={true}/>}/>
                            <Route path="/coherence_planning" element={<CoherencePlanningCurve supervisor={true}/>}/>
                            <Route path="/etat_visuel_absence" element={<EtatVisuelAbsence supervisor={true}/>}/>
                            <Route path="/abs_dashboard" element={<DashboardAbs supervisor={true}/>}/>
                            <Route path="/demandes_abs" element={<DemandesAbs supervisor={true}/>}/>
                            <Route path="/compteurs_droits_abs" element={<CompteursEtDroitsAbs supervisor={true}/>}/>
                            <Route path="/etat_visuel_absence2" element={<EtatVisuelAbsence supervisor={true}/>}/>
                            <Route path="/taux_affectation2" element={<TauxAffectation supervisor={true}/>}/>
                            <Route path="/stats_absence" element={<StatsAbsences supervisor={true}/>}/>
                            <Route path="/stats_abs" element={<StatsAbsences supervisor={true}/>}/>
                            <Route path="/dashboard_pointages" element={<DashboardPointage supervisor={true}/>}/>
                            <Route path="/historique_pointages" element={<HistoriquePointage supervisor={true}/>}/>
                            <Route path="/gestion_pointages" element={<GestionPointages supervisor={true}/>}/>
                            <Route path="/tps_dashboard" element={<DashboardTps supervisor={true}/>}/>
                            <Route path="/temps_medecin" element={<TempsMedecin supervisor={true}/>}/>
                            <Route path="/temps_travaille" element={<TempsTravaille supervisor={true}/>}/>
                            <Route path="/heures_supp" element={<GestionHeuresSuppFull supervisor={true}/>}/>
                            <Route path="/equivalent_temps_plein" element={<EquivalentTempsPlein supervisor={true}/>}/>
                            <Route path="/stats_activites" element={<TableauCroiseDemiJours supervisor={true}/>}/>
                            <Route path="/exports" element={<Exports supervisor={true}/>}/>
                            <Route path="/creneaux_dashboard" element={<DashboardCreneaux supervisor={true}/>}/>
                            <Route path="/creneaux" element={<CreneauxEnCours supervisor={true}/>}/>
                            <Route path="/list_remplacants" element={<ListRemplacant supervisor={true}/>}/>
                            <Route path="/news" element={<News supervisor={true}/>}/>
                            <Route path="*" element={<NotFound />} />
                        </Route>
                    </Routes>
                </ErrorBoundary>
            </Suspense>
        </div>
    )
}

const SupervisorController = styled(SupervisorControllerCtrl)``

export default SupervisorController;