import styled from "styled-components";
import {useGetCentreActuel} from "../../../../features/centre/centre.hooks";
import {useEffect, useState} from "react";
import TitrePageSetting from "../components/TitrePageSetting";
import PageSimpleSetting from "../components/PageSimpleSetting";
import LoadDatas from "../components/LoadDatas";
import FormSetParamsNotif from "./FormSetParamsNotif";
import {useGetFonctions} from "../../../../features/fonction/fonction.hooks";

interface ParamsNotificationsCtrlProps{
    className?:string;
}

const ParamsNotificationsCtrl = (props:ParamsNotificationsCtrlProps)=>{
    const CentreQuery = useGetCentreActuel();
    const FonctionsQuery = useGetFonctions();
    const [litterals, setLitterals] = useState<any|null>(null)
    useEffect(() => {
        if(CentreQuery.data) {
            setLitterals(CentreQuery.data.literalParameters)
        }
    }, [CentreQuery.data]);
    return (
        <div className={`paramsNotifications page_params ${props.className}`}>
            <TitrePageSetting titre={"Configuration des notifications"}/>
            <PageSimpleSetting>
                <p className={"intro_simple"}>Définissez les paramètres de notification pour les collaborateurs et administrateurs, y compris les alerte de planning et les canaux de diffusions</p>
                {CentreQuery.isLoading || FonctionsQuery.isLoading ?
                    <LoadDatas/>:
                    (litterals && CentreQuery.data && FonctionsQuery.data) ?
                        <FormSetParamsNotif Centre={CentreQuery.data} litterals={litterals} Fonctions={FonctionsQuery.data}/> : null
                }
            </PageSimpleSetting>
        </div>
    )
}

const ParamsNotifications = styled(ParamsNotificationsCtrl)``

export default ParamsNotifications