import React, {useEffect, useMemo, useState} from "react";
import styled from "styled-components";
import {SubTitrePAge, TitrePage} from "../../../../assets/styles/elements";
import {useGetParamsTimed} from "../../../../hooks/useGetParamsTimed";
import {useGetAllUser} from "../../../../features/user/user.hooks";
import {useGetAllTypeContrat} from "../../../../features/typeContrat/typeContrat.hooks";
import TimedSkeleton from "../../../../components/atoms/TimedSkeleton/TimedSkeleton";
import TimedTable from "../../../../components/molecules/TimedTable/TimedTable";
import {getMonthByDate} from "../../../../components/functions/TimesFunctions";
import {TimedTitreH2} from "../../../../components/atoms/TimedTitres/TimedTitreH2";
import WidgetNoContract from "../../../Widgets/AdminRH/WidgetNoContract";
import {useCentreConnectedData} from "../../../../services/hooks";

interface EquivalentTempsPleinCtrlProps{
    className?:string;
    supervisor?:boolean;
}
const Year = new Date().getFullYear();

export const CountEqTpsPleinMonthly = (users:User[], dateStart:Date, dateEnd:Date, TypeContrat:TypeContrat)=>{
    const nbJ = (dateEnd.getTime() - dateStart.getTime())/86400000 +1;
    const refTempsPlein = 151.67;
    let nbEQTP = 0;
    users.map(u=>{
        const contrats = u.contrats.filter(c=> {
            const dateEndC = !c.endAt ? new Date("2099-01-01") : new Date(c.endAt);
            const dateStartC = new Date(c.startAt);
            return !(dateEndC<dateStart || dateStartC > dateEnd || c.TypeContrat.id !== TypeContrat.id)
        });
        let TotalContrats = 0;
        contrats.map(c=>{
            const dateStartC = new Date(c.startAt) < dateStart ? dateStart : new Date(c.startAt);
            const dateEndC = c.endAt && new Date(c.endAt)<dateEnd ? new Date(c.endAt) : dateEnd;
            const nbJC = (dateEndC.getTime() - dateStartC.getTime())/86400000 +1;
            const qte = c.quantite;
            const unite = c.uniteTps;
            const nbH = unite === 'j' ? qte*7 : unite === 'dj' ? qte*3.5 : unite === 'h' ? qte : 0;
            const ByM = c.RefTempsTravail.id === 1 ? nbH*4.33 : c.RefTempsTravail.id === 2 ? nbH : c.RefTempsTravail.id === 3 ? nbH/12 : 0;
            const refM = ByM/refTempsPlein;
            const PropJ = nbJC/nbJ;
            TotalContrats+= PropJ*refM;
            return c
        })
        nbEQTP+=TotalContrats;
        return u
    })
    return Math.round(nbEQTP*100)/100;
}

export const CountEqTpsPlein = (users:User[], Year:number, TypeContrat:TypeContrat)=>{
    const dateStart = new Date(`${Year}-01-01`);
    const dateEnd = new Date(`${Year}-12-31`);
    const nbJ = (dateEnd.getTime() - dateStart.getTime())/86400000;
    let nbEQTP = 0;
    const refTempsPlein = 151.67;
    users.map(u=>{
        const contrats = u.contrats.filter(c=>c.TypeContrat.id === TypeContrat.id && (c.endAt === null || (c.endAt && new Date(c.endAt)>=dateStart && new Date(c.endAt)<=dateEnd)));
        let TotalContrats = 0;
        contrats.map(c=>{
            const dateStartC = new Date(c.startAt) < dateStart ? dateStart : new Date(c.startAt);
            const dateEndC = c.endAt ? new Date(c.endAt) : dateEnd;
            const nbJC = (dateEndC.getTime() - dateStartC.getTime())/86400000;

            const qte = c.quantite;
            const unite = c.uniteTps;
            const nbH = unite === 'j' ? qte*7 : unite === 'dj' ? qte*3.5 : unite === 'h' ? qte : 0;

            const ByM = c.RefTempsTravail.id === 1 ? nbH*4.33 : c.RefTempsTravail.id === 2 ? nbH : c.RefTempsTravail.id === 3 ? nbH/12 : 0;

            const refM = ByM/refTempsPlein;

            const PropJ = nbJC/nbJ;

            TotalContrats+= PropJ*refM;
            return c
        })
        nbEQTP+=TotalContrats;
        return u
    })
    return Math.round(nbEQTP*100)/100;
}

const EquivalentTempsPleinCtrl=({className, supervisor}:EquivalentTempsPleinCtrlProps)=>{
    const centre = useCentreConnectedData();
    const {From, TabVues, TabFx, TabCollab} = useGetParamsTimed();
    const [YearTo, setYearTo] = useState(Year);
    const {FirstMonth, LastMonth} = getMonthByDate(new Date());
    const [dateStart, setDateStart] = useState<Date>(FirstMonth)
    const [dateEnd, setDateEnd] = useState<Date>(LastMonth)
    const UsersQuery = useGetAllUser(supervisor ? centre : null);
    const TypeContratsQuery = useGetAllTypeContrat();
    const [usersConcerned, setUsersConcerned] = useState<User[]>([]);
    const [titlesTable, setTitlesTab] = useState<TitleTable[]>([]);

    useEffect(()=>{
        if(From!==undefined){
            const {FirstMonth, LastMonth} = getMonthByDate(From);
        } else {
            const {FirstMonth, LastMonth} = getMonthByDate(new Date());
        }
        setDateStart(FirstMonth);
        setDateEnd(LastMonth);
    }, [From])
    useEffect(()=>{
        if(UsersQuery.data) {
            let usersConc:User[] = UsersQuery.data.filter(u=>u.isActif && u.Fonction.id !== 1);
            if (TabVues !== undefined) {
                usersConc = usersConc.filter(u=>u.VuePlanning === null || (u.VuePlanning && TabVues.indexOf(u.VuePlanning.id.toString())!==-1))
            }
            if(TabFx !== undefined){
                usersConc = usersConc.filter(u=>TabFx.indexOf(u.Fonction.id.toString())!==-1);
            } else if(TabCollab!==undefined){
                usersConc = usersConc.filter(u=>TabCollab.indexOf(u.id.toString())!==-1);
            }
            setUsersConcerned(usersConc);
        }
    }, [TabCollab, TabVues, TabFx, UsersQuery.data])
    const lineFiltered = useMemo(()=>{
        const myList:any = [];

        if(TypeContratsQuery.data){
            const FxsDD = usersConcerned.map(u=>u.Fonction.libelle);
            let FxsSDD = Array.from(new Set(FxsDD));
            const TypesContrat = TypeContratsQuery.data;
            let ResThisTotal:any = {};
            ResThisTotal.Fonction = "Total";
            ResThisTotal.isFoot = true;
            let TotalTotal = 0;
            let TabTitlesOK:string[] = [];
            TypesContrat.map(tc=>{
                const nbEqTps = CountEqTpsPleinMonthly(usersConcerned, dateStart, dateEnd, tc);
                TotalTotal+=nbEqTps;
                if(nbEqTps>0) {
                    TabTitlesOK.push(tc.libelle);
                    ResThisTotal[tc.libelle] = nbEqTps;
                }
                return tc;
            })
            ResThisTotal.Total = <div className={`chips-total`}>{Math.round(TotalTotal * 100) / 100}</div>;
            myList.push(ResThisTotal);
            FxsSDD.map(fx=>{
                const users = usersConcerned.filter(u=>u.Fonction.libelle === fx);
                let ResThisFx:any = {};
                ResThisFx['Fonction'] = fx;
                let TotalThisFx = 0;
                TypesContrat.filter(tc=>TabTitlesOK.indexOf(tc.libelle)!==-1).map(tc=>{
                    const nbEqTps = CountEqTpsPleinMonthly(users, dateStart, dateEnd, tc);
                    TotalThisFx+=nbEqTps;
                    ResThisFx[tc.libelle] = nbEqTps;
                    return tc;
                })
                ResThisFx["Total"] = <div className={`chips-total`}>{Math.round(TotalThisFx * 100) / 100}</div>
                ResThisFx.isFoot = false;
                myList.push(ResThisFx);
                return fx;
            })
        }
        return myList;
    }, [usersConcerned, dateStart, dateEnd, TypeContratsQuery.data])
    useEffect(()=>{
        if(TypeContratsQuery.data && lineFiltered){
            const lineTot = lineFiltered.filter((l:any)=>l.isFoot === true);
            const titlesOk:string[] = [];
            for( const [k] of Object.entries(lineTot[0])){
                titlesOk.push(k);
            }
            let titles:TitleTable[] = [];
            titles.push({libelle:'Fonction', field:'Fonction', fieldSort:'Fonction', date:false, textAlign:'', StyleHead:{textAlign: "left"}})
            TypeContratsQuery.data.filter(tc=>titlesOk.indexOf(tc.libelle)!==-1).map(tc=>{
                titles.push({libelle:tc.libelle, field:tc.libelle, fieldSort:tc.libelle, date:false, textAlign:'text-center'})
                return tc;
            })
            titles.push({libelle:'Total', field:'Total', fieldSort:'Total', date:false, textAlign:'text-center'})
            setTitlesTab(titles);
        }
    }, [TypeContratsQuery.data, lineFiltered])
    return (
        <div className={`EqTpsPlein ${className}`}>
            <TitrePage>Equivalent temps pleins</TitrePage>
            <TimedTitreH2 text={`du ${dateStart.toLocaleDateString()} au ${dateEnd.toLocaleDateString()}`}/>
            {(UsersQuery.isLoading || TypeContratsQuery.isLoading) ?
                <div className={"pad-ske"}><TimedSkeleton width={"100%"} type={"rect"} nbOccurence={4}/></div> :
                <>

                    <TimedTable
                        Titles={titlesTable}
                        sortable={true}
                        themeColor={"Primary"}
                        Outline={true}
                        Lines={lineFiltered ? lineFiltered.filter((l:any)=>l.isFoot === false) : []}
                        lineFoot={lineFiltered ? lineFiltered.filter((l:any)=>l.isFoot === true) : []}
                        scrollable={true}
                        loading={false}
                        maxHeight={"100%"}
                        isTable={true}
                        onClickLine={()=>{}}
                    />
                    <div style={{margin:"30px auto"}}>
                        <WidgetNoContract supervisor={supervisor}/>
                    </div>
                </>
            }
        </div>
    )
}

const EquivalentTempsPlein = styled(EquivalentTempsPleinCtrl)`
  padding: 1rem 2rem;
  .Annee{
    padding: 0.5rem;
    font-weight: bold;
  }
  .chips-total{
    padding: 0.25rem 0.5rem;
    background: ${props=>props.theme.PrimaryDark};
    border-radius: 12px;
    color: white;
    width: max-content;
    margin: auto;
    font-weight: bold;
  }
`

export default EquivalentTempsPlein;