import styled from "styled-components";
import LibelleSetting from "./LibelleSetting";
import {BsTrash} from "react-icons/bs";
import {useRef, useState} from "react";
import {TiPlus} from "react-icons/ti";
import {FaCheck} from "react-icons/fa6";
import {GrClose} from "react-icons/gr";

interface InputListChoixCtrlProps{
    className?:string;
    libelle:string;
    myList:string[];
    AddItem:(s:string)=>void;
    RemoveChoice:(s:string)=>void;
    id:string;
}

const InputListChoixCtrl = (props:InputListChoixCtrlProps)=>{
    const [openAdd, setOpenAdd] = useState(false)
    const [textAdd, setTextAdd] = useState<string>("")
    const refI = useRef<HTMLInputElement>(null)
    const handleChangeText = ()=>{
        if(refI.current) setTextAdd(refI.current.value);
    }
    return (
        <div className={`inputListChoix wrap_input_setting ${props.className}`}>
            <div className="in_wrap_input_setting">
                <LibelleSetting libelle={props.libelle}/>
                <div className="wrap_setter">
                    <div className="wrap_list">
                        {props.myList.map((item, idx)=>(
                            <div className="one_choice_list" key={`IL${props.id}${idx}`}>
                                <button className="delete_item" onClick={()=>props.RemoveChoice(item)}><BsTrash/></button>
                                <span className="lib_choice">{item}</span>
                            </div>
                        ))}
                    </div>
                    {!openAdd ?
                        <div className="wrap_add_choice">
                            <button className="add_choice" onClick={()=>setOpenAdd(o=>!o)}>
                                <TiPlus /><span>Ajouter un élément</span>
                            </button>
                        </div>:
                        <div className="wrap_input_add_item">
                            <input placeholder={"Nom de l'élément"} type="text" className="add_choice_input" ref={refI} onChange={handleChangeText} value={textAdd}/>
                            <div className="valide_add">
                                <button className="remove_choice_val" onClick={()=>setOpenAdd(o=>!o)}>
                                    <GrClose/>
                                </button>
                            </div>
                            <div className="valide_add">
                                {textAdd.length > 0 &&
                                    <button className="add_choice_val" onClick={()=> {
                                        props.AddItem(textAdd)
                                        setTextAdd("")
                                    }}>
                                        <FaCheck />
                                    </button>
                                }
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

const InputListChoix = styled(InputListChoixCtrl)`
    width: 100%;
    .wrap_setter{
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        max-height: 350px;
        padding: 10px;
        border:solid ${props => props.theme.Primary} 1px;
        border-radius: 4px;
        width: 350px;
    }
    .wrap_input_add_item{
        display: flex;
        justify-content: flex-start;
        gap: 6px;
        margin-top: 15px;
        input{
            padding-left: 5px;
            height: 32px;
            background: ${props=>props.theme.colorBackInput};
            border-radius: 4px;
            border: none;
            flex-grow: 1;
        }
    }
    .valide_add{
        width: 32px;
        height: 32px;
        
    }
    .remove_choice_val, .add_choice_val{
        width: 32px;
        height: 32px;
        border: none;
        display: flex;
        border-radius: 4px;
        flex-direction: column;
        justify-content: center;
        svg{
            margin: auto;
        }
        &:hover{
            cursor: pointer;
        }
    }
    .remove_choice_val{
        background: red;
        color: white;
    }
    .add_choice_val{
        background: ${props=>props.theme.SecondaryExtraLight};
        color: ${props=>props.theme.SecondaryDark};
    }
    .wrap_add_choice{
        margin-top: 15px;
    }
    .add_choice{
        width: 100%;
        padding: 0.25rem 0.5rem;
        text-align: center;
        background: ${props=>props.theme.SecondaryExtraLight};
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 4px;
        border: none;
        border-radius: 4px;
        &:hover{
            cursor: pointer;
        }
    }
    .wrap_list{
        flex-grow: 1;
        overflow-y: auto;
    }
    .one_choice_list{
        padding: 0.25rem 0.5rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;
        &:not(:last-child) {
            border-bottom: solid 1px ${props => props.theme.Primary};
        }
        .delete_item{
            width: 24px;
            height: 24px;
            border-radius: 4px;
            border: none;
            background: red;
            color:white;
            display: flex;
            flex-direction: column;
            justify-content: center;
            svg{
                margin: auto;
            }
            &:hover{
                cursor: pointer;
            }
        }
    }
`

export default InputListChoix