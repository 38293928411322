
import React, {useEffect, useState} from "react";
import {useGetParamsTimed} from "../../../../../../hooks/useGetParamsTimed";
import {useGetOneUser} from "../../../../../../features/user/user.hooks";
import {WaitingVerif} from "./WaitingVerif";
import {NoContractPeriode} from "./NoContractPeriode";
import TempsTravailleSingle from "../TempsTravailleSingle";

interface PreVerifSingleCtrlProps{
    idUser:number,
    supervisor?:boolean;
}

export const PreVerifSingle = (props:PreVerifSingleCtrlProps)=>{
    const {From} = useGetParamsTimed()
    const UserQuery = useGetOneUser(props.idUser);
    const [hasContrat, setHasContrat] = useState(false);
    const [dateStart, setDateStart] = useState<string>('')
    const [dateEnd, setDateEnd] = useState<string>('')
    useEffect(()=>{
        let DateRef = new Date(new Date().setHours(18,0));
        if(From){
            DateRef = new Date(From.setHours(18,0));
        }
        const First = new Date(DateRef.getFullYear(), DateRef.getMonth(), 1);
        First.setHours(18,0);
        const NextMonth = new Date(First.getTime()+32*86400000);
        const FirstNext = new Date(NextMonth.getFullYear(),NextMonth.getMonth(), 1);
        const Last = new Date(FirstNext.getTime() - 86400000);
        Last.setHours(18,0,0);
        const Today = new Date();
        Today.setHours(18,0);
        let Hier = new Date();
        Hier = new Date(Hier.getTime() - 86400000)
        let myEnd = Last>Today ? Hier : Last;
        setDateStart(First.toISOString().slice(0,10));
        setDateEnd(myEnd.toISOString().slice(0,10));
        if(UserQuery.data){
            const Contrats = UserQuery.data.contrats;
            const ContratsConc = Contrats.filter(c=>new Date(c.startAt)<=new Date(myEnd) && (!c.endAt || new Date(c.endAt.slice(0,10))>=new Date(First)))
            setHasContrat(ContratsConc.length>0)
        }

    }, [UserQuery.data, From])

    if(UserQuery.isLoading){
        return <WaitingVerif/>
    } else if(!hasContrat){
        return <NoContractPeriode dateEnd={dateEnd} dateStart={dateStart} User={UserQuery.data}/>
    } else {
        return <TempsTravailleSingle idUser={props.idUser.toString()} supervisor={props.supervisor}/>
    }
}