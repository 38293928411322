import styled from "styled-components";
import {TabMois} from "../../../../../../services/constantes/constantes";
import {useSearchParams} from "react-router-dom";
import ImgNoCtr from '../../../../../../assets/images/No-Contrat.png'
import {FiChevronLeft, FiChevronRight} from "react-icons/fi";
import React from "react";

interface NoContractPeriodeCtrlProps{
    className?:string;
    dateStart:string;
    dateEnd:string;
    User?:User;
}

const NoContractPeriodeCtrl = (props:NoContractPeriodeCtrlProps)=>{
    const [searchParams, setSearchParams] = useSearchParams();
    const getLibDate = ()=>{
        if(props.dateStart === ''){
            return ''
        } else {
            const dateUseDate = new Date(props.dateStart);
            const month = dateUseDate.getMonth()+1;
            const year = dateUseDate.getFullYear();
            const LibMonth = TabMois.find(m=>m.num === month);
            if(!LibMonth){
                return 'error'
            } else {
                return LibMonth.name+' '+year;
            }
        }
    }
    const MoveMonth = (coef:number)=>{
        const params:any = {};
        searchParams.forEach((key, value) => {
            params[value] = key;
        });
        const DateUseDate = new Date(props.dateStart);
        const month = DateUseDate.getMonth();
        let year = DateUseDate.getFullYear();
        let newMonth = month+coef;
        if(newMonth < 0){
            newMonth = 11;
            year--;
        }
        if(newMonth > 11 ){
            newMonth = 0;
            year++;
        }
        const myDate = `01/${newMonth+1}/${year}`
        setSearchParams({...params, from:myDate, to:myDate})
    }
    return (
        <div className={`no_contract ${props.className}`}>
            <div className={`wrap_info`}>
                <div className={"wrap_img"}>
                    <img src={ImgNoCtr} alt={"no contract"}/>
                </div>
                <p>{props.User ? `${props.User.prenom} ${props.User.nom}` : 'Ce collaborateur'} n'a pas de contrat sur cette période.</p>
                <div className={`wrap-choice-date`}>
                    <div className={`moveDate isRwd`} onClick={()=>MoveMonth(-1)}>
                        <FiChevronLeft/>
                    </div>
                    <div className={`libDate`}>
                        {getLibDate()}
                    </div>
                    <div className={`moveDate isFwd`} onClick={()=>MoveMonth(1)}><FiChevronRight/></div>
                </div>
            </div>
        </div>
    )
}

export const NoContractPeriode = styled(NoContractPeriodeCtrl)`
    width: 100%;
  height: 100%;
  position: relative;
  .wrap_img{
    text-align: center;
    margin-bottom: 15px;
    img{
      
    }
  }
  .wrap_info{
    padding: 1rem;
    position: absolute;
    left: 50%;
    top:30%;
    transform: translate(-50%,-50%);
    p{
      font-size: 18px;
      text-align: center;
      font-weight: bold;
      color: ${props => props.theme.Complementary};
    }
  }
  .wrap-choice-date{
    margin-top: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    .libDate{
      padding: 0 1rem;
    }
    .moveDate{
      text-align: center;
      border-radius: 50%;
      width: 25px;
      height: 25px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      svg{
        margin: auto;
      }
      &:hover{
        cursor: pointer;
        background: ${props=>props.theme.NeutreExtraLight};
      }
    }

  }
`