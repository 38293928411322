import styled from "styled-components";
import {OneItemDoc, UserDocument} from "../../../../features/userDocument/userDocument";
import {useTrashDocument} from "../../../../features/userDocument/userDocument.hooks";
import TimedRoundAvatar from "../../../../components/atoms/TimedRoundAvatar/TimedRoundAvatar";
import TimedChipUserAvatarName from "../../../../components/molecules/TimedChipsUser/TimedChipUserAvatarName";
import React from "react";
import {OneInfosShort} from "../../../../components/organisms/TimedProfilShort/OneInfosShort";
import TimedButton from "../../../../components/atoms/TimedButton/TimedButton";

interface InfosModalDocCtrlProps{
    className?:string;
    Doc?:UserDocument|null;
    closeMe:()=>void;
}

const InfosModalDocCtrl = (props:InfosModalDocCtrlProps)=>{
    const mutationTrash = useTrashDocument();
    const downLoad = ()=>{
        if(props.Doc) {
            window.open(`${process.env.REACT_APP_API_URL_ROOT}${props.Doc.documentUrl}`);
        }
    }
    const Trash = ()=>{
        if(props.Doc) {
            mutationTrash.mutate(props.Doc.id, {
                onSuccess: () => {
                    props.closeMe();
                }
            })
        }
    }
    return (
        <div className={`infos_modal_doc ${props.className}`}>
            {props.Doc &&
                <>
                    <div className={`body-infos`}>
                        <TimedChipUserAvatarName Round={<TimedRoundAvatar size={"other"} sizeP={"28px"} src={props.Doc.User ? props.Doc.User.avatarUrl : ''}/>} libelle={`${props.Doc.User?.prenom} ${props.Doc.User?.nom}`} />
                        <OneInfosShort title={"Nom"} info={props.Doc.namePerso} width={"100%"}/>
                        <OneInfosShort title={"Type"} info={props.Doc.Type.libelle} width={"100%"}/>
                        <OneInfosShort title={"Créé le"} info={new Date(props.Doc.creationAt).toLocaleDateString()} width={"100%"}/>
                    </div>
                    <div className={`footer-infos`}>
                        <TimedButton
                            size={"sm"}
                            themeColor={"Complementary"}
                            text={"Supprimer"}
                            onClick={Trash}
                        />
                        <TimedButton
                            size={"sm"}
                            themeColor={"Secondary"}
                            text={"Télécharger"}
                            onClick={downLoad}
                            isPending={mutationTrash.isLoading}
                        />
                    </div>
                </>
            }
        </div>
    )
}

export const InfosModalDoc = styled(InfosModalDocCtrl)`
    padding: 0.5rem;
    .footer-infos{
      margin-top: 10px;
      display: flex;
      justify-content: center;
      gap: 10px;
    }
`