import styled from "styled-components";
import {BlockInfosShort} from "./BlockInfosShort";
import {useGetOneUser} from "../../../features/user/user.hooks";
import React, {useEffect, useMemo, useState} from "react";
import {getFirstLastDay, getWeekByDate} from "../../functions/TimesFunctions";
import {
    TimedMetrixHebdo
} from "../../../pages/TimedApplication/Plannings/PlanningIndiHebdo/components/TimedMetrixHebdo";
import {OneInfosShort} from "./OneInfosShort";
import {useGetCompteurTempsDateDate} from "../../../features/compteurTempsDateDate/compteurTempsDateDate.hooks";

interface TimedCTShortCtrlProps{
    className?:string;
    idUser:number;
    DateCalc:Date;
    Tempo:"Mensuel"|"Hebdomadaire";
}

const TimedCTShortCtrl = (props:TimedCTShortCtrlProps)=>{
    const DateToday = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 12, 0,0);
    const DateArret = new Date(DateToday.getTime() - 86_400_000)
    const UserQuery = useGetOneUser(props.idUser);
    const [DateStart, setDateStart] = useState<string>('')
    const [DateStop, setDateStop] = useState<string>('')
    const CtpsQuery = useGetCompteurTempsDateDate(DateStart, DateStop, [props.idUser]);
    const [isAnnual, setIsAnnual] = useState<boolean>(false)
    const CptData = useMemo(()=>{
        if(CtpsQuery.data){
            return CtpsQuery.data[0]
        } else{
            return null;
        }
    }, [CtpsQuery.data])
    useEffect(()=>{
        if(UserQuery.data){
            const Centre = UserQuery.data.Centre;
            const Params = Centre.parameters;
            const ParamsAnnual = Params.find(pa=>pa.Parameter.id === 6);
            if(ParamsAnnual){
                setIsAnnual(ParamsAnnual.statut);
            }
        }
    }, [UserQuery.data])
    useEffect(()=>{
        if(props.Tempo === 'Hebdomadaire'){

            const {Monday, Sunday} = getWeekByDate(props.DateCalc)
            setDateStart(new Date(Monday.toISOString().slice(0,10)+" 12:00:00").toISOString().slice(0,10))
            setDateStop(new Date(Sunday.toISOString().slice(0,10)+" 12:00:00").toISOString().slice(0,10))
        } else{
            const {first, last} = getFirstLastDay(props.DateCalc.getFullYear(), props.DateCalc.getMonth())
            setDateStart(new Date(first.toISOString().slice(0,10)+" 12:00:00").toISOString().slice(0,10))
            setDateStop(new Date(last.toISOString().slice(0,10)+" 12:00:00").toISOString().slice(0,10))
        }
    }, [props.DateCalc, props.Tempo])

    return (
        <div className={`ctp_temps_short ${props.className}`}>
            <BlockInfosShort title={`Compteur Temps`}>
                {UserQuery.data && DateStart!=='' && DateStop!=='' &&
                    <>
                        <div style={{marginBottom:"15px", width:"100%"}}>
                            <h3>Temps réalisé + planning projeté du {new Date(DateStart).toLocaleDateString()} au {new Date(DateStop).toLocaleDateString()}</h3>
                            <TimedMetrixHebdo
                                user={UserQuery.data}
                                dateFrom={DateStart}
                                dateTo={DateStop}
                                NoMarginLeft
                            />
                        </div>
                        {isAnnual && CptData &&
                            <div className={`wrap_info_cpteur`}>
                                <h3>Compteur temps (arrêté au {DateArret.toLocaleDateString()} 23h00) </h3>
                                <div className={`compteur_temps ${CptData.soldeOutRelevees > 0 ? 'success' : 'warning'}`}>{`${CptData.soldeOutRelevees > 0 ? '+' : ''}${Math.round(CptData.soldeOutRelevees*100)/100}`}</div>
                            </div>
                        }
                    </>
                }
            </BlockInfosShort>
        </div>
    )
}

export const TimedCTShort = styled(TimedCTShortCtrl)`
  padding: 1rem;
  h3{
    font-size: 15px;
    text-decoration: underline;
    margin: 15px 0 15px 0;
  }
  .compteur_temps{
    font-weight: bold;
    &.success{
      color: ${props => props.theme.Secondary};
    }
    &.warning{
      color: ${props => props.theme.Complementary};
    }
  }
`