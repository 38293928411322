import styled from "styled-components";

import React, {useEffect, useMemo, useState} from "react";
import {ConvertBase5, getWeekByDate} from "../../../../components/functions/TimesFunctions";
import {useGetOneUser} from "../../../../features/user/user.hooks";
import {useGetPlageParams2} from "../../../../features/plage/plage.hooks";
import {TabJourISO} from "../../../../services/constantes/constantes";
import TimedRoundAvatar from "../../../../components/atoms/TimedRoundAvatar/TimedRoundAvatar";
import {TimedMetrixHebdo} from "./components/TimedMetrixHebdo";
import {useGetCentreActuel} from "../../../../features/centre/centre.hooks";
import TimedSkeletonLogo from "../../../../components/atoms/TimedSkeleton/TimedSkeletonLogo";


interface TimedPlanningCtrlProps{
    className?:string;
    dateFrom?:Date;
    dateTo?:Date;
    myHeightHour?:number;
    idUser:number;
    noMetrix?:boolean;
}

const {Monday, Sunday} = getWeekByDate(new Date());
const HourStart = 6;
const HourEnd = 23;
const startHeightHour = 25;
const HeightHead = 38;

export interface OneDayPlage{
    height:string;
    top:string;
    left:string;
    Plage:Plage;
    Team: { prenom:string, nom:string, avatar?:string, fonction:string, isMed:boolean }[];
}
interface ParamsPlagePl{
    "plageAt[after]":string;
    "plageAt[before]":string;
    User:number;
}

const TimedPlanningCtrl = ({className, dateFrom, dateTo, myHeightHour, idUser, ...props}:TimedPlanningCtrlProps)=>{
    const HeightHour = myHeightHour ? myHeightHour : startHeightHour;
    const UserQuery = useGetOneUser(idUser);
    const [heureRealEnd, setHeureRealEnd] = useState(HourEnd)
    const [hours, setHours] = useState<number[]>([])
    const [myDateFrom, setMyDateFrom] = useState(dateFrom ? dateFrom : Monday);
    const [myDateTo, setMyDateTo] = useState(dateTo ? dateTo : Sunday)
    const [libdays, setLibDays] = useState<string[]>([])
    const [params, setParams] = useState<ParamsPlagePl|null>(null)
    const PlagesQuery = useGetPlageParams2(params)
    const [posInfos, setPosInfos] = useState({x:0, y:0})
    const [infosVis, setInfosVis] = useState(false);
    const [plageCurrent, setPlageCurrent] = useState<OneDayPlage|null>(null)
    const CentreQuery = useGetCentreActuel();
    const [color, setColor] = useState("Lieu")
    useEffect(() => {
        setParams({
            "plageAt[after]":myDateFrom.toISOString().slice(0,10),
            "plageAt[before]":myDateTo.toISOString().slice(0,10),
            User:idUser
        })
    }, [idUser, myDateFrom, myDateTo]);
    useEffect(()=>{
        if(CentreQuery.data){
            const params = CentreQuery.data.parameters;
            const ParamsColor = params.find(p=>p.Parameter.id === 7);
            if(ParamsColor){
                if(ParamsColor.statut) {
                    setColor("Acte");
                } else {
                    setColor("Lieu");
                }
            }
        }
    }, [CentreQuery.data])
    useEffect(()=>{
        let startH:number = HourStart;
        const myhours:number[] = [];
        while(startH <= heureRealEnd){
            myhours.push(startH);
            startH++;
        }
        setHours(myhours);
    }, [heureRealEnd])
    useEffect(()=>{
        if(PlagesQuery.data && UserQuery.data){
            const idUser = UserQuery.data.id;
            let maxHour = 0;
            const PlagesUser = PlagesQuery.data.filter(p=>p.User.id === idUser && p.Statut.id !== 5);
            PlagesUser.forEach(p=>{
                const thisHE = Math.floor(p.heureFin/12);
                if(thisHE > maxHour){
                    maxHour = thisHE;
                }
            })
            setHeureRealEnd(maxHour > 0 ? maxHour+1 : 18);
        }
    }, [PlagesQuery.data, UserQuery.data])
    const ListPlage = useMemo(()=>{
        const list:OneDayPlage[] = [];
        const nbJour = libdays.length;
        if(PlagesQuery.data && UserQuery.data && myDateFrom){
            const dayFromSem = myDateFrom.getDay() === 1 ? 0 : myDateFrom.getDay() === 0 ? 6 : myDateFrom.getDay() - 1;
            const idUser = UserQuery.data.id;
            const PlagesUser = PlagesQuery.data.filter(p=>p.User.id === idUser && new Date(p.plageAt));
            PlagesUser.map(p=>{
                const datePlage = new Date(p.plageAt);
                const dayPlageSem = datePlage.getDay() === 1 ? 0 : datePlage.getDay() === 0 ? 6 : datePlage.getDay() - 1;
                const Ecart = Math.abs(dayFromSem - dayPlageSem);
                const leftSize = `${(1/nbJour)*Ecart*100}%`
                const TopSize = (((p.heureDebut/12) - HourStart +1) * HeightHour)+"px"//
                const HeightSize = (((p.heureFin/12) - (p.heureDebut/12)) * HeightHour)+"px"
                const PlagesDay = PlagesQuery.data.filter(pq=>new Date(pq.plageAt).toLocaleDateString().slice(0,10) === new Date(p.plageAt).toLocaleDateString().slice(0,10))
                const Team:User[] = PlagesDay.filter(pl=>(pl.rang!==null && pl.rang === p.rang && pl.User.id !== p.User.id && (new Date(pl.plageAt)).getDay() === new Date(p.plageAt).getDay())).map(item=>item.User);
                list.push({
                    height:HeightSize,
                    top:TopSize,
                    left:leftSize,
                    Plage:p,
                    Team: Team.sort((a:User, b:User)=>a.Fonction.id > b.Fonction.id ? 1 : -1).map(u=>{
                        return {prenom:u.prenom, nom:u.nom, avatar:u.avatarUrl, fonction:u.Fonction.libelle, isMed:u.Fonction.id === 1}
                    })
                })
                return p;
            })

        }
        return list;
    }, [PlagesQuery.data, UserQuery.data, myDateFrom, libdays, HeightHour])
    useEffect(()=>{
        if(dateFrom) setMyDateFrom(dateFrom)
    }, [dateFrom])
    useEffect(()=>{
        if(dateTo) setMyDateTo(dateTo);
    }, [dateTo])
    useEffect(()=>{
        let dateSlidy = myDateFrom
        const TabDay:string[] = [];
        while(dateSlidy<=myDateTo){
            const thisDate = dateSlidy;
            const dayThis = (`0${thisDate.getDate()}`).slice(-2);
            const monthThis = (`0${thisDate.getMonth()+1}`).slice(-2);
            const libDay = TabJourISO[thisDate.getDay()]
            TabDay.push(`${libDay.name} ${dayThis}/${monthThis}`)
            dateSlidy = new Date(dateSlidy.getTime() + 86400000)
        }
        setLibDays(TabDay);
    }, [myDateFrom, myDateTo])
    const handleHover = (e:any, item:OneDayPlage)=>{
        const X = e.clientX;
        const Y = e.clientY;
        setPlageCurrent(item);
        setPosInfos({x:X, y:Y})
        setInfosVis(true);
    }
    const handleOut = ()=>{
        setPosInfos({x:0, y:0})
        setInfosVis(false);
        setPlageCurrent(null);
    }
    return (
        <div className={"planning_quick_hebdo " + className}>
            {UserQuery.data && !UserQuery.isLoading && dateFrom && dateTo && !props.noMetrix &&
                <TimedMetrixHebdo
                    user={UserQuery.data}
                    dateFrom={dateFrom.toISOString().slice(0,10)}
                    dateTo={dateTo.toISOString().slice(0,10)}
                />
            }
            <div className={`planning ${className}`}>
                <div className={`infos_plage ${infosVis ? 'visible' : ''}`} style={{left:`${posInfos.x}px`, top:`${posInfos.y}px`}}>
                    {plageCurrent &&
                        <>
                            <div className={`infos_l`}>
                                {plageCurrent.Plage.Lieu.libelle} - {plageCurrent.Plage.Acte.libelle} {new Date(plageCurrent.Plage.plageAt).toLocaleDateString()}<br/> {ConvertBase5(plageCurrent.Plage.heureDebut)} {ConvertBase5(plageCurrent.Plage.heureFin)}
                            </div>
                            {plageCurrent.Team.length>0 &&
                                <div className={`team_infos`}>
                                    <h5>En équipe avec :</h5>
                                    <ul>
                                        {plageCurrent.Team.map((item, idx:number)=>(
                                            <li className={`one_team`} key={`one_m_t${idx}`}>
                                                <div className="avatar_team">
                                                    <div className={`round-avatar`}>
                                                        <TimedRoundAvatar size={"sm"} src={item.avatar||""} Border={false}/>
                                                    </div>
                                                </div>
                                                <div className="name_team">{item.prenom} {item.nom}</div>
                                                <div className="fonction_team"> - {item.fonction}</div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            }
                        </>
                    }
                </div>
                <div className={`heures_wrap`}>
                    {hours.map((h: number) => (
                        <div className={`oneHour`} key={`oneHour${h}`}>
                            <div className={"hour-lib"}>{h}h</div>
                        </div>
                    ))}
                </div>
                <div className={`main_wrap`}>
                    <div className="head_days">
                        {libdays.map((item:string, idx:number)=>(
                            <div className={`one_head_day`} key={`oHd${idx}`} style={{width:`${(100/libdays.length)}%`}}>{item}</div>
                        ))}
                    </div>
                    <div className="content_days">
                        {PlagesQuery.isLoading &&
                            <TimedSkeletonLogo width={"100%"} height={"100%"}/>
                        }
                        <div className={`back-lines`}>
                            {libdays.map((item:string, idx:number)=>(
                                <div data-infos={item} className={`one_col_grid`} key={`oGd${idx}`} style={{width:`${(100/libdays.length)}%`}}>
                                    {hours.map((h: number) => (
                                        <div className={`one_hour_grid`} key={`one_hour_grid${h}`} style={{height:`${HeightHour}px`}}/>
                                    ))}
                                </div>
                            ))}
                        </div>
                        {ListPlage.map((item:OneDayPlage)=>(
                            <div onMouseOver={(e)=>handleHover(e, item)} onMouseOut={handleOut} className={`one_plage`} key={`one_plage${item.Plage.id}`} style={{left:item.left, top:item.top, height:item.height,width:`${(100/libdays.length)*0.9}%`, background:color === "Lieu" ? item.Plage.Lieu.backColor : item.Plage.Acte.backColor, color:color === "Lieu" ?  item.Plage.Lieu.fontColor : item.Plage.Acte.fontColor, borderColor:item.Plage.Statut.color}}>
                                <div className={`ContentInfos`} >
                                    <div className={"codeStatut"} style={{color:color === "Lieu" ?  item.Plage.Lieu.fontColor : item.Plage.Acte.fontColor}}>{item.Plage.Statut.code}</div>
                                    <div className={`LieuActe`}>{item.Plage.Lieu.libelle} - {item.Plage.Acte.libelle}</div>
                                    <div className={`Horaires`}>{ConvertBase5(item.Plage.heureDebut)} - {ConvertBase5(item.Plage.heureFin)}</div>
                                    {!!item.Plage.Memo &&
                                        <div>{item.Plage.Memo}</div>
                                    }
                                    {item.Plage.UserReplaced &&
                                        <div className={`user_replace`}>Remplace : {item.Plage.UserReplaced.prenom} {item.Plage.UserReplaced.nom}</div>
                                    }
                                </div>

                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

const TimedPlanning = styled(TimedPlanningCtrl)`
  width: 100%;
  .planning{
      width: 100%;
      display: flex;
      justify-content: flex-start;
      background: white;
      border-radius: 12px;
    margin-top: 15px;
  }
  .team_infos{
    margin-top: 10px;
    ul{
      margin-top: 15px;
      li{
        display: flex;
        margin-bottom: 10px;
        list-style: none;
        gap: 10px;
        align-content: center;
        justify-content: flex-start;
        .name_team{font-weight: bold}
      }
    }
  }
  .infos_plage{
    display: none;
    width: 250px;
    padding: 0.5rem;
    font-size: 14px;
    height: auto;
    background: white;
    border-radius: 6px;
    position: fixed;
    z-index: 4;
    opacity: 0;
    transition: opacity 300ms;
    box-shadow: 1px 1px 3px rgba(0,0,0,.2);
    &.visible{
      display: block;
      opacity: 1;
      transition: opacity 300ms;
    }
  }
  .back-lines{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    .one_col_grid{
      &:not(:last-child) {
        border-right: solid ${props => props.theme.PrimaryExtraLight} 1px;
      }
      .one_hour_grid:not(:last-child){
        border-bottom: dashed ${props=>props.theme.PrimaryExtraLight} 1px;
      }
    }
  }
  .one_plage{
    position: absolute;
    padding: 0.25rem;
    border-radius: 4px;
    border-width: 0 0 0 5px;
    border-style: solid;
  }
  .content_days{
    flex-grow: 1;
    position: relative;
  }
  .main_wrap{
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .heures_wrap{
    padding-top: ${`${HeightHead}px`};
    border-right: ${props=>props.theme.PrimaryExtraLight} 1px solid;
  }
  .oneHour{
    height: ${props=>props.myHeightHour ? `${props.myHeightHour}px` : `${startHeightHour}px`};
    position: relative;
    width: 30px;
    &:not(:last-child) {
      border-bottom: dashed ${props => props.theme.PrimaryExtraLight} 1px;
    }
    .hour-lib{
      font-size: 11px;
      position: absolute;
      right: 2px;
      bottom: 2px;
    }
  }
  .head_days{
    display: flex;
    justify-content: flex-start;
  }
  .one_head_day{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: ${`${HeightHead}px`};
    text-align: center;
    border-bottom: solid ${props=>props.theme.PrimaryExtraLight} 1px;
    &:not(:last-child) {
      border-right: solid ${props=>props.theme.PrimaryExtraLight} 1px;
    }
    font-size: 14px;
    font-weight: bold;
  }
  .round-avatar{
    width: 25px;
    height: 25px;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    img{
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
      width: 100%;
      height: auto;
    }
  }
  .ContentInfos{
    max-height: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    padding-right: 22px;
    .codeStatut{
      position: absolute;
      width: 18px;
      height: 18px;
      border:solid white 1px;
      border-radius: 6px;
      font-size: 12px;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      right: 3px;
    }
    .LieuActe{
      font-weight: bold;
    }
    .Horaires{
      font-size: 12px;
    }
  }
`

export default TimedPlanning;

