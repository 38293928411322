import styled from "styled-components";
import TimedButton from "../../../../components/atoms/TimedButton/TimedButton";
import {TiPlus} from "react-icons/ti";
import TitrePageSetting from "../components/TitrePageSetting";
import {useModal} from "../../../../hooks/useModal";
import PageDoubleSetting from "../components/PageDoubleSetting";
import LeftPartDoublePage from "../components/LeftPartDoublePage";
import {useEffect, useState} from "react";
import {useGetAllAdmin, useGetAllUser, useGetUser} from "../../../../features/user/user.hooks";
import RightPartDoublePage from "../components/RightPartDoublePage";
import WrapListAdmin from "./WrapListAdmin";
import FormEditDroit from "./FormEditDroit";
import {TimedModalCentral2} from "../../../../components/molecules/TimedModal/TimedModalCentral2";
import FormAddAdmin from "./FormAddAdmin";

interface ParamsDroitsCtrlProps{
    className?:string;
}

const ParamsDroitsCtrl = (props:ParamsDroitsCtrlProps)=>{
    const {open, toggle} = useModal();
    const [AdminSet, setAdminSet] = useState<User|null>(null)
    const AdminQuery = useGetAllAdmin();
    const UserQuery = useGetUser()
    const UsersQuery = useGetAllUser()
    const [MesAdmins, setMesAdmins] = useState<User[]>([])
    useEffect(() => {
        if(AdminQuery.data && UserQuery.data){
            setMesAdmins(AdminQuery.data.filter(a=>a.id !== UserQuery.data.id).sort((a,b)=>a.nom > b.nom ? 1 : -1))
        }
    }, [AdminQuery.data, UserQuery.data]);
    useEffect(() => {
        if(MesAdmins.length>0 && !AdminSet){
            setAdminSet(MesAdmins[0])
        }
    }, [MesAdmins, AdminSet]);
    const EndAddAdmin = (a:User)=>{
        setAdminSet(a);
        toggle();
    }
    return (
        <div className={`paramsDroits page_params ${props.className}`}>
            <TitrePageSetting titre={"Gestion des lieux"}>
                <TimedButton onClick={toggle} themeColor={"Primary"} size={"sm"}>
                    <div className={`wrap_in_btn`}><TiPlus /><span>Ajouter un admin</span></div>
                </TimedButton>
            </TitrePageSetting>
            <PageDoubleSetting>
                <LeftPartDoublePage titre={"Vos Administrateurs"}>
                    <WrapListAdmin AdminActif={AdminSet} Admins={MesAdmins} setAdmin={setAdminSet}/>
                </LeftPartDoublePage>
                <RightPartDoublePage titre={AdminSet ? `Configuration de l'administrateur ${AdminSet.prenom} ${AdminSet.nom.toUpperCase()}` : "Configuration de l'administrateur"} subtitle={"Modifier les paramètres de la fonction en cours"}>
                    {AdminSet &&
                        <FormEditDroit unSetAdmin={()=>setAdminSet(null)} Admin={AdminSet}/>
                    }
                </RightPartDoublePage>
            </PageDoubleSetting>
            <TimedModalCentral2 closeMe={toggle} Wd={"500px"} open={open} Pending={false} titre={"Ajouter un administrateur"}>
                {UsersQuery.data &&
                    <FormAddAdmin Users={UsersQuery.data} closeMe={EndAddAdmin}/>
                }
            </TimedModalCentral2>
        </div>
    )
}

const ParamsDroits = styled(ParamsDroitsCtrl)``

export default ParamsDroits