import {useMutation, useQuery, useQueryClient, UseQueryResult} from "react-query";
import {
    addCentreSemaineType, ChangeNamePerso,
    convertPlToST, duplicateCentreSemaineType,
    getAllCentreSemaineType,
    getCentreSemaineType, getTimeCST, trashCentreSemaineType
} from "./centreSemaineType.services";
import {toast} from "react-toastify";

export const useGetCentreSemaineType = (id:number, idCentre:number|null=null):UseQueryResult<CentreSemaineType[], Error>=>{
    return useQuery<CentreSemaineType[], Error>(["centreSemaineType", id], ()=>getCentreSemaineType(id, idCentre), {
        staleTime:60000,
        refetchOnWindowFocus:false,
        enabled:id!==0,
    })
}



export const useConvertPlToSemType = ()=>{
    const queryClient = useQueryClient();
    return useMutation(
        (datas:ConvertPlToSTFormData)=>{
            return convertPlToST(datas);
    }, {
            onSuccess:()=>{
                queryClient.invalidateQueries(["centreSemaineType"]);
                toast.success('Semaine type créée !!')
            },
            onError:()=>{
                toast.error("Une erreur est survenue, merci de retenter plus tard")
            }
        }
    )
}

export const useGetAllCentreSemaineType = (idCentre:number|null=null):UseQueryResult<CentreSemaineType[], Error>=>{
    const queryClient = useQueryClient();
    const userConnected:UserSimple|undefined = queryClient.getQueryData(["user_connected"]);
    return useQuery<CentreSemaineType[], Error>(["centreSemaineType", idCentre], () => getAllCentreSemaineType(userConnected, idCentre), {
        staleTime: 60000,
        refetchOnWindowFocus: false,
        enabled:!!userConnected
    })
}


export const useAddCentreSemaineType = ()=>{
    const queryClient = useQueryClient();
    return useMutation(
        (id:number)=>{
            return addCentreSemaineType(id);
        },{
            onSuccess:()=>{
                queryClient.invalidateQueries(["centreSemaineType"]);
                queryClient.invalidateQueries(["users"]);
                queryClient.invalidateQueries(["user"]);
                toast.success('Semaine type Ajoutée');
            },
            onError:(e:any)=>{
                toast.error(e.message);
            }
        }
    )
}
export const useModifyNameCentreST = ()=>{
    const queryClient = useQueryClient();
    return useMutation(
        (datas: { id:number, namePerso:string })=>{
            return ChangeNamePerso(datas.id, datas.namePerso);
        },{
            onSuccess:()=>{
                queryClient.invalidateQueries(["centreSemaineType"]);
                queryClient.invalidateQueries(["users"]);
                queryClient.invalidateQueries(["user"]);
                queryClient.invalidateQueries(["users_seq"]);
                toast.success('Nom Modifié');
            },
            onError:(e:any)=>{
                toast.error(e.message);
            }
        }
    )
}

export const useTrashCentreSemaineType = ()=>{
    const queryClient = useQueryClient();
    return useMutation(
        (id:number)=>{
            return trashCentreSemaineType(id);
        },{
            onSuccess:()=>{
                queryClient.invalidateQueries(["centreSemaineType"]);
                queryClient.invalidateQueries(["users"]);
                queryClient.invalidateQueries(["user"]);
                toast.success('Semaine type supprimée');
            },
            onError:(e:any)=>{
                toast.error(e.message);
            }
        }
    )
}

export const useDuplicateCentreSemaineType = ()=>{
    const queryClient = useQueryClient();
    return useMutation(
        (id:number)=>{
            return duplicateCentreSemaineType(id);
        },{
            onSuccess:()=>{
                queryClient.invalidateQueries(["centreSemaineType"]);
                queryClient.invalidateQueries(["users"]);
                queryClient.invalidateQueries(["user"]);
                toast.success('Semaine type dupliquée');
            },
            onError:(e:any)=>{
                toast.error(e.message);
            }
        }
    )
}

export const useGetTimeCentreSemaineType = (id:number):UseQueryResult<TimeCentreSemaineType, Error>=>{
    return useQuery(["time_cst", id], ()=>getTimeCST(id), {
        enabled:id!==0
    })
}