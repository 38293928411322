import React from "react";
import styled from "styled-components";
import noResImg from "../../../assets/images/NoResult.png"

interface NoResultCtrlProps{
    className?:string;
    text?:string;
    width?:string;
    pad?:string;
}

const NoResultCtrl = ({className, text}:NoResultCtrlProps)=>{
    return (
        <div className={`noResult ${className}`}>
            <div className="pic-place"><img src={noResImg} alt="aucun résultat"></img></div>
            {text &&
            <div className="noResText">{text}</div>
            }
        </div>
    )
}

const NoResult = styled(NoResultCtrl)`
    width: ${props=>props.width ? props.width : '100%'};
    padding:${props=>props.pad} | 0;
  margin: auto;
    .pic-place{
      width: 100%;
      padding: 0.5rem;
      text-align: center;
      img{
        width: 80%;
        height: auto;
      }
    }
  .noResText{
    padding: 0.5rem;
    text-align: center;
    font-size: calc(12px + 0.7vmin);
  }
`

export default NoResult;