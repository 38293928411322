import styled from "styled-components";
import {useSetLitts} from "../../../../features/centre/centre.hooks";
import SettingYesNoLitts from "../components/SettingYesNoLitts";
import SettingsTextLitts from "../components/SettingsTextLitts";
import LibelleSetting from "../components/LibelleSetting";
import {useEffect, useState} from "react";
import ErrorInputSetting from "../components/ErrorInputSetting";
import SimpleSelectSetting from "../components/SimpleSelectSetting";
import SettingsTextAtreaLitts from "../components/SettingsTextAtreaLitts";

interface FormSetParamsNotifCtrlProps{
    className?:string;
    litterals:any;
    Centre:CentreComplet;
    Fonctions:Fonction[];
}
interface DetailsProps{
    HeureBureau:InfosProps;
    DeltaDayNotif:InfosProps;
    emailPl:InfosProps;
    notificationPushPl:InfosProps;
    notificationInternePl:InfosProps;
    messageCP:InfosProps;
}
const TabInfos:DetailsProps ={
    HeureBureau:{keyProps:"HeureBureau", libelle:"Notifications - Heures bureaux", description:"En activant le module remplacement vous pourrez émettre des créneaux à des médecins remplaçants"},
    DeltaDayNotif:{keyProps:"DeltaDayNotif", libelle:"Notifications - Délai", description:"Vous pouvez choisir de notifier vos collaborateurs d'un changement de planning en fonction du délai de ce changement, légalement 7 jours maximum"},
    emailPl:{keyProps:"emailPl", libelle:"Notifications - Email", description:"Les collaborateurs recevront la notification par email"},
    notificationPushPl:{keyProps:"notificationPushPl", libelle:"Notifications - Notifications téléphones", description:"Les collaborateurs recevront la notification sur leur téléphone s'ils ont acceptés les notifications"},
    notificationInternePl:{keyProps:"notificationPushPl", libelle:"Notifications - Notifications application", description:"Les collaborateurs recevront la notification dans l'application Timed si elle est ouverte"},
    messageCP:{keyProps:"messageCP", libelle:"Notifications - Message CP", description:"Rédigez le message qui apparaitra lors d'un ajout de congés payés pour vos collaborateurs"},
}
interface OneFonctionNotif{
    Fonction:Fonction,
    isNotif:boolean,
}
const FormSetParamsNotifCtrl = (props:FormSetParamsNotifCtrlProps)=>{
    const mutation = useSetLitts();
    const [mesFonctionsNotif, setMesFonctionsNotif] = useState<OneFonctionNotif[]>([])
    const ChoicesHB:Choice[] = [{id:0, libelle:"Non", description:"Pour des raisons d'urgence, les notifications sont envoyées en direct"}, {id:1, libelle:"Oui", description:"Vous limiter l'envoi des notifications aux horaires d'ouvertures"}]
    const ChoicesNot:Choice[] = [{id:0, libelle:"Non", description:"Je ne souhaite pas ce canal"}, {id:1, libelle:"Oui", description:"J'accepter ce canal"}]
    const choicesNotifFx:Choice[] = [{id:0, libelle:"Non"}, {id:1, libelle:"Oui"}]
    const returnValue = (key:string)=>{
        if(props.litterals.hasOwnProperty(key)){
            if(key === "emailPl"){
                console.log("found it "+props.litterals[key])
            }
            return props.litterals[key];
        } else {
            if(key === "emailPl"){
                console.log("not found it ")
            }
            return 0
        }
    }
    const setMyFxNotif = (value:Choice, id:string)=>{
        const newFxNotif = [...mesFonctionsNotif].map(fx=>{
            if(fx.Fonction.id !== parseInt(id)){
                return fx;
            } else {
                return {...fx, isNotif:value.id === 1}
            }
        })
        setMesFonctionsNotif(newFxNotif);
        const actual = {...props.litterals}
        const newLitts = {...actual, notifNewAbs:newFxNotif.map(fx=>fx.Fonction.id)}
        mutation.mutate({idCentre:props.Centre.id, litt:newLitts})
    }
    useEffect(() => {
        let idsFxNotifs:number[] = [];
        if(props.litterals.hasOwnProperty("notifNewAbs")){
            idsFxNotifs = props.litterals["notifNewAbs"];
        }
        const tabFxNotif:OneFonctionNotif[] = []
        props.Fonctions.forEach(fx=>{
            tabFxNotif.push({
                Fonction:fx,
                isNotif:idsFxNotifs.indexOf(fx.id)!==-1
            })
        })
        setMesFonctionsNotif(tabFxNotif)
    }, [props.litterals, props.Fonctions]);
    return (
        <div className={`formSetParamsNotif ${props.className}`}>
            <div className="wrap_inputs_setting loud">
                <SettingYesNoLitts idCentre={props.Centre.id} litts={props.litterals} keyLitts={"HeureBureau"}
                                   label={"Limiter aux heures de bureau ?"} choices={ChoicesHB}
                                   InfosProp={TabInfos.HeureBureau} currentValue={returnValue("HeureBureau")}/>
                <h2>Planning</h2>
                <SettingsTextLitts idCentre={props.Centre.id} litts={props.litterals} keyLitts={"DeltaDayNotif"}
                                   label={"Délai d'alerte de modification de planning en jours"} type={"number"}
                                   InfosProp={TabInfos.DeltaDayNotif}
                                   currentValue={returnValue("DeltaDayNotif") === 0 ? "" : returnValue("DeltaDayNotif")}/>
                <LibelleSetting libelle={"Canaux de notification des alertes planning"} wdLib={"100%"}
                                subLabel={"De quelle façon seront notifiés les collaborateurs en cas d'alerte planning"}/>
                <div className="wrap_lib_right">
                    <SettingYesNoLitts idCentre={props.Centre.id} litts={props.litterals} keyLitts={"emailPl"}
                                       label={"Email"} choices={ChoicesNot} InfosProp={TabInfos.HeureBureau}
                                       currentValue={returnValue("emailPl") === 1 ? choicesNotifFx[1] : choicesNotifFx[0]}/>
                    <SettingYesNoLitts idCentre={props.Centre.id} litts={props.litterals}
                                       keyLitts={"notificationPushPl"} label={"Notification push"} choices={ChoicesNot}
                                       InfosProp={TabInfos.notificationPushPl}
                                       currentValue={returnValue("notificationPushPl") === 1 ? choicesNotifFx[1] : choicesNotifFx[0]}/>
                    <SettingYesNoLitts idCentre={props.Centre.id} litts={props.litterals}
                                       keyLitts={"notificationInternePl"}
                                       label={"Notification dans l'application Timed"} choices={ChoicesNot}
                                       InfosProp={TabInfos.notificationInternePl}
                                       currentValue={returnValue("notificationInternePl") === 1 ? choicesNotifFx[1] : choicesNotifFx[0]}/>
                </div>
                <h2>Demandes d'absence</h2>
                <LibelleSetting libelle={"Fonctions déclenchant des notifications aux admins"} wdLib={"100%"}
                                subLabel={"Lors d'une demande d'absence, est-ce que ces fonctions déclencheront une notification par email ?"}/>
                <div className="wrap_lib_right">
                    {mesFonctionsNotif.map(item=>(
                        <div className="wrap_input_setting" key={`FxNotif${item.Fonction.id}`}>
                            <div className="in_wrap_input_setting">
                                <LibelleSetting libelle={item.Fonction.libelle} wdLib={"35%"}/>
                                <div className="wrap_input_in">
                                    <SimpleSelectSetting setMyValue={setMyFxNotif} choices={choicesNotifFx} id={item.Fonction.id+""} Current={item.isNotif ? choicesNotifFx[1] : choicesNotifFx[0]}/>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <SettingsTextAtreaLitts
                    litts={props.litterals}
                    idCentre={props.Centre.id}
                    keyLitts={"messageCP"}
                    label={"Message lors d'un congé payé"}
                    InfosProp={TabInfos.messageCP}
                    currentValue={returnValue("messageCP") === 0 ? "" : returnValue("messageCP")}
                />
            </div>
        </div>
    )
}

const FormSetParamsNotif = styled(FormSetParamsNotifCtrl)`
    h2{
        font-size: 24px;
        margin: 25px 0 20px;
    }
    .wrap_lib_right{
        display: flex;
        flex-direction: column;
        gap: 15px;
        .libelleSetting{
            text-align: right !important;
            font-weight: normal !important;
        }
    }
`

export default FormSetParamsNotif