import styled from "styled-components";
import {useModal} from "../../../../hooks/useModal";
import BtnOpenHelp from "./BtnOpenHelp";
import {TimedModalCentral2} from "../../../../components/molecules/TimedModal/TimedModalCentral2";
import ModalHelp from "./ModalHelp";

interface WrapHelpCtrlProps{
    className?:string;
    Infos:InfosProps;
    setValue:(Choice:Choice|string|number|null, id:string)=>void;
    choices:Choice[];
    Current:Choice|null;
    placeNoChoice?:string;
}

const WrapHelpCtrl = (props:WrapHelpCtrlProps)=>{
    const {open, toggle} = useModal()
    return (
        <div className={`wrapHelp ${props.className}`}>
            <BtnOpenHelp clickOn={toggle}/>
            <TimedModalCentral2 closeMe={toggle} Wd={"40%"} open={open} Pending={false} titre={props.Infos.libelle}>
                <ModalHelp setValue={props.setValue} choices={props.choices} Infos={props.Infos} Current={props.Current} placeNoChoice={props.placeNoChoice}/>
            </TimedModalCentral2>
        </div>
    )
}

const WrapHelp = styled(WrapHelpCtrl)``

export default WrapHelp