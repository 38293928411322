import React, {useEffect, useMemo, useState} from "react";
import styled from "styled-components";
import TimedOpenCloseSideBar from "../../atoms/TimedOpenCloseSideBar/TimedOpenCloseSideBar";
import logoLong from "../../../assets/images/logo-timed-dark-mode.png";
import logoShort from "../../../assets/images/Logo-Small-Timed-Light.png";
import TimedSideLink from "../../atoms/TimedSideLink/TimedSideLink";
import {MdBadge, MdDashboard} from "react-icons/md";
import {BsClockFill,BsFillCalendar3WeekFill, BsFillUnlockFill, BsMegaphoneFill, BsPersonLinesFill
} from "react-icons/bs";
import {UseQueryResult} from "react-query";
import {GiPuppet} from "react-icons/gi";
import {FaUmbrellaBeach, FaWhmcs} from "react-icons/fa";
import {useLocation, useNavigate} from "react-router-dom";
import {myBoxShadow} from "../../../assets/styles/elements";
import {removeToken} from "../../../services/dataGuetter/dataFetcher";
import {isAuthWM} from "../../../services/security/isAuth";
import { HiSwitchHorizontal } from "react-icons/hi";
import { IoChatbubbleEllipses } from "react-icons/io5";
import {useGetModules, useGetSections} from "../../../features/module/module.hooks";
import {useGetRoleNiveaux} from "../../../features/roleNiveau/roleNiveau.hooks";
import {useCentreConnectedData} from "../../../services/hooks";
import {useGetOneCentre} from "../../../features/centre/centre.hooks";
import {version_timed} from "../../../index";

interface TimedSideBarCtrlProps{
    className?:string;
    UserQuery?:UseQueryResult<UserSimple, Error>;
    sectionName:string|null;
    Openable?:boolean;
    supervisor?:boolean;
}
interface OneMenuLink{
    libelle:string;
    item:React.ReactNode|null;
    idcond:number;
    idsort:number;
}

const TimedSideBarCtrl = ({className, UserQuery, sectionName, Openable, supervisor}:TimedSideBarCtrlProps)=>{
    const centre = useCentreConnectedData();
    const CentreQuery = useGetOneCentre(centre||-1);
    const RoleNiveauQuery = useGetRoleNiveaux();
    const {data:modules} = useGetModules();
    const { search } = useLocation();
    const [isOpen, setIsOpen] = useState(Openable===true);
    const [parameters, setParameters] = useState<any[]>([]);
    const SectionsQuery = useGetSections();
    const navigate = useNavigate();
    const TabMenu:OneMenuLink[]= useMemo(()=>{
        const myTab:OneMenuLink[] = !supervisor ?
        [
            {libelle:"Administration RH", item:<TimedSideLink key={`MenuG0`} libelle={"Administration RH"} link={`rh_dashboard${search}`} sectionName={sectionName} thisSection={"Administration RH"} icon={<BsPersonLinesFill/>} isOpen={isOpen}/>, idcond:0, idsort:1},
            {libelle:"Plannings", item:<TimedSideLink key={`MenuG1`} libelle={"Plannings"} link={`planning_dashboard${search}`} sectionName={sectionName} thisSection={"Plannings"} icon={<BsFillCalendar3WeekFill/>} isOpen={isOpen}/>, idcond:0, idsort:2},
            {libelle:"Temps et Activités", item:<TimedSideLink key={`MenuG2`} libelle={"Temps et Activités"} link={`tps_dashboard${search}`} sectionName={sectionName} thisSection={"Temps et Activités"} icon={<BsClockFill/>} isOpen={isOpen}/>, idcond:0, idsort:3},
            {libelle:"Pointages", item:<TimedSideLink key={`MenuG3`} libelle={"Pointages"} link={`dashboard_pointages${search}`} sectionName={sectionName} thisSection={"Pointages"} icon={<MdBadge/>} isOpen={isOpen}/>, idcond:2, idsort:4},
            {libelle:"Remplacements", item:<TimedSideLink key={`MenuG4`} libelle={"Remplacements"} link={`creneaux_dashboard${search}`} sectionName={sectionName} thisSection={"Remplacements"} icon={<HiSwitchHorizontal/>} isOpen={isOpen}/>, idcond:8, idsort:5},
            {libelle:"Congés et absences", item:<TimedSideLink key={`MenuG5`} libelle={"Congés et Absences"} link={`abs_dashboard${search}`} sectionName={sectionName} thisSection={"Congés et absences"} icon={<FaUmbrellaBeach/>} isOpen={isOpen}/>, idcond:0, idsort:6},
            {libelle:"Messagerie", item:<TimedSideLink key={`MenuG6`} libelle={"Messagerie"} link={`messagerie${search}`} sectionName={sectionName} thisSection={"Messagerie"} icon={<IoChatbubbleEllipses/>} isOpen={isOpen}/>, idcond:10, idsort:7},
            {libelle:"Vie du centre", item:<TimedSideLink key={`MenuG7`} libelle={"Vie du centre"} link={`news${search}`} sectionName={sectionName} thisSection={"Vie du centre"} icon={<BsMegaphoneFill/>} isOpen={isOpen}/>, idcond:0, idsort:8},
            {libelle:"Settings", item:<TimedSideLink key={`MenuG8`} libelle={"Paramétrages"} link={`profil_centre${search}`} sectionName={sectionName} thisSection={"Settings"} icon={<FaWhmcs/>} isOpen={isOpen}/>, idcond:0, idsort:8},
        ] : [
                {libelle:"Administration RH", item:<TimedSideLink key={`MenuG0`} libelle={"Administration RH"} link={`rh_dashboard${search}`} sectionName={sectionName} thisSection={"Administration RH"} icon={<BsPersonLinesFill/>} isOpen={isOpen}/>, idcond:0, idsort:1},
                {libelle:"Plannings", item:<TimedSideLink key={`MenuG1`} libelle={"Plannings"} link={`planning_dashboard${search}`} sectionName={sectionName} thisSection={"Plannings"} icon={<BsFillCalendar3WeekFill/>} isOpen={isOpen}/>, idcond:0, idsort:2},
                {libelle:"Temps et Activités", item:<TimedSideLink key={`MenuG2`} libelle={"Temps et Activités"} link={`tps_dashboard${search}`} sectionName={sectionName} thisSection={"Temps et Activités"} icon={<BsClockFill/>} isOpen={isOpen}/>, idcond:0, idsort:3},
                {libelle:"Pointages", item:<TimedSideLink key={`MenuG3`} libelle={"Pointages"} link={`dashboard_pointages${search}`} sectionName={sectionName} thisSection={"Pointages"} icon={<MdBadge/>} isOpen={isOpen}/>, idcond:2, idsort:4},
                {libelle:"Remplacements", item:<TimedSideLink key={`MenuG4`} libelle={"Remplacements"} link={`creneaux_dashboard${search}`} sectionName={sectionName} thisSection={"Remplacements"} icon={<HiSwitchHorizontal/>} isOpen={isOpen}/>, idcond:8, idsort:5},
                {libelle:"Congés et absences", item:<TimedSideLink key={`MenuG5`} libelle={"Congés et Absences"} link={`abs_dashboard${search}`} sectionName={sectionName} thisSection={"Congés et absences"} icon={<FaUmbrellaBeach/>} isOpen={isOpen}/>, idcond:0, idsort:6},
                {libelle:"Vie du centre", item:<TimedSideLink key={`MenuG7`} libelle={"Vie du centre"} link={`news${search}`} sectionName={sectionName} thisSection={"Vie du centre"} icon={<BsMegaphoneFill/>} isOpen={isOpen}/>, idcond:0, idsort:8},
                // {libelle:"Settings", item:<TimedSideLink key={`MenuG8`} libelle={"Paramétrages"} link={`profil_centre${search}`} sectionName={sectionName} thisSection={"Settings"} icon={<FaWhmcs/>} isOpen={isOpen}/>, idcond:0, idsort:8},
            ]
        const myTabFiltered:OneMenuLink[] = [];
        const rolesUser = UserQuery?.data?.roles?.filter(r=>r!=='ROLE_ADMIN');
        let niveauUser = 0;
        if(RoleNiveauQuery.data) {
            RoleNiveauQuery.data.map(item => {
                if (rolesUser?.indexOf(item.libelle) !== -1 && item.niveau > niveauUser) {
                    niveauUser = item.niveau;
                }
                return item;
            })
        }
        myTab.map(o=>{
            const myModules = modules?.filter(m=>m.sectionName === o.libelle)
            let nbVisible = 0;
            myModules?.map(m=>{
                if(m.niveau<=niveauUser){

                    nbVisible++;
                }
                return m;
            })
            if(nbVisible>0){
                myTabFiltered.push(o);
            }
            return o;
        })
        return myTabFiltered;
    }, [isOpen, sectionName, RoleNiveauQuery.data, modules, UserQuery?.data, search])
    const [Menus, setMenus] = useState<OneMenuLink[]>(TabMenu);
    const GetStatutParam = (Params:undefined|{id:number, statut:boolean})=>{
        if(!Params){
            return false;
        } else {
            return Params.statut
        }
    }
    useEffect(()=>{
        if(SectionsQuery.data && parameters){
            const TabMyMenus:OneMenuLink[] = [];
            SectionsQuery.data.map(s=>{
                const myMenuFind = TabMenu.find(m=>m.libelle === s.libelle);
                if(myMenuFind){
                    if(myMenuFind.idcond === 0 || GetStatutParam(parameters.find(p => p.id === myMenuFind.idcond))){
                        TabMyMenus.push({...myMenuFind, idsort:s.ordreEdit});
                    }
                }
                return s;
            })
            setMenus(TabMyMenus);
        }
    }, [SectionsQuery.data, parameters, isOpen, TabMenu])
    useEffect(()=>{
        let myParameters:any[] = [];
        if(UserQuery && !UserQuery.isLoading && !UserQuery.isError && !UserQuery.isFetching && !supervisor){
            const Centre = UserQuery.data?.Centre;
            const Parameters = Centre?.parameters;
            Parameters?.map(cp=>{
                myParameters.push({id:cp.Parameter.id, statut:cp.statut})
                return cp;
            })
        } else if(supervisor && CentreQuery.data){
            const Centre = CentreQuery.data;
            const Parameters = Centre?.parameters;
            Parameters?.map(cp=>{
                myParameters.push({id:cp.Parameter.id, statut:cp.statut})
                return cp;
            })
        }
        setParameters(myParameters);
    }, [UserQuery, supervisor, CentreQuery.data])
    useEffect(()=>{
        setIsOpen(Openable!==undefined ? Openable : true);
    }, [Openable])
    const Disconnect = ()=>{
        removeToken();
        navigate('/login');
    }
    const location = useLocation();
    const getLink=(link:string)=>{
        return `${link}${location.search}`
    }
    return (
        <div className={`TimedSB ${className} ${isOpen ? "opened" : ""}`}>
            <div className={"wrapper-content-side-bar"}>
                <div className={"num_version"}>{version_timed}</div>
                <div className="content-side-bar">
                    <div className={`logo-place`}>
                        {isOpen ? <img src={logoLong} alt={"Timed application"}/> : <img src={logoShort} alt={"Timed application"}/>}
                    </div>
                    <div className={"items-place"}>
                        <TimedSideLink libelle={"Tableau de bord"} link={getLink("dashboard")} sectionName={sectionName} thisSection={"Main"} icon={<MdDashboard/>} isOpen={isOpen}/>
                        {Menus.sort((a:OneMenuLink, b:OneMenuLink)=>a.idsort > b.idsort ? 1 : -1).map((item:OneMenuLink, idx:number)=>item.item)}
                        {isAuthWM() &&
                            <>
                                <TimedSideLink libelle={"WM"} link={`/webmaster_application`} sectionName={""} thisSection={"Main"} icon={<GiPuppet/>} isOpen={isOpen}/>
                                {/*<TimedSideLink libelle={"PlEq"} link={`planning_equipe`} sectionName={sectionName} thisSection={"planning_equipe"} icon={<GiTeamIdea/>} isOpen={isOpen}/>*/}
                                {/*<TimedSideLink libelle={"PlLieu2"} link={`planning_lieu2`} sectionName={sectionName} thisSection={"planning_lieu2"} icon={<BsFillCalendar3WeekFill/>} isOpen={isOpen}/>*/}
                                {/*<TimedSideLink libelle={"PlLieu3"} link={`planning_lieu3`} sectionName={sectionName} thisSection={"planning_lieu3"} icon={<BsFillCalendar3WeekFill/>} isOpen={isOpen}/>*/}
                            </>
                        }
                        <div className={`disconnect`} onClick={Disconnect}>
                            {isOpen ?
                                <div className={`wrap-opened`}>
                                    <div className={`icon-place`}><BsFillUnlockFill/></div>
                                    <div className={`lib-place`}>Deconnexion</div>
                                </div>:
                                <div className={`wrap-close`}><BsFillUnlockFill/></div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            {(Openable === true || Openable===undefined) &&
                <TimedOpenCloseSideBar className={`thisOpenClose`} isOpen={isOpen} setIsOpen={setIsOpen}/>
            }
        </div>
    )
}

const TimedSideBar = styled(TimedSideBarCtrl)`
  transition: width 0.3s;
  z-index: 9;
  width: 80px;
  position: relative;
  background: ${props => props.theme.PrimaryDark};
  height: 100svh;
  .num_version{
    position: absolute;
    font-size: 10px;
    color: white;
    left: 50%;
    top: 5px;
    transform: translateX(-50%);
  }
  .wrapper-content-side-bar{
    width: 100%;    
  }
  &.opened{
    width: 230px;
    .content-side-bar .logo-place img{
      width: 50%;
    }
  }
  .content-side-bar{
      width: 100%;
      height: 100%;
    .logo-place{
      margin: 25px auto;
      padding: 0 0.5rem;
      text-align: center;
      img{
        width: 70%;
        height: auto;
      }
    }
    
  }
  .thisOpenClose{
    position: absolute;
    bottom:35px;
    right: 0;
    transform: translateX(50%);
  }
  .disconnect{
    text-decoration: none;
    width: 100%;
    padding: 0.75rem 0.85rem;
    display: block;
    font-size: 16px;
    position: relative;
    color:${props=>props.theme.colorLight}!important;
    &.closed{
      &:after{
        content: attr(data-info);
        position: absolute;
        left: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        top: 0;
        ${myBoxShadow};
        height: 100%;
        padding: 0;
        background: ${props=>props.theme.Primary};
        color: white;
        text-wrap: none;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 0;
        transition: width 0.4s;
        border-bottom-right-radius: 0.4rem;
        border-top-right-radius: 0.4rem;
      }
      &:hover:after{
        width: 180px;
        white-space: nowrap;
        text-wrap: none;

        transition: width 0.4s;
      }
    }
    .wrap-close{
      position: relative;
      display: flex;
      justify-content: center;
      font-size: 22px;

    }
    .wrap-opened{
      display: flex;
      justify-content: start;
      .lib-place{
        margin-left: 10px;
      }
    }
    &:hover, &.isActive{
      cursor: pointer;
      background: ${props=>props.theme.Primary};
    }
  }
`

export default TimedSideBar;