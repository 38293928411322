import {useMutation, useQuery, useQueryClient, UseQueryResult} from "react-query";
import {
    addUserDocument,
    getDocumentsByUser,
    getDocumentsByUserPage, getDocumentsByUserPageDate, getOneDocument, ModifyUserDocument, ModifyUserDocument2,
    switchDiffusable,
    trashDocument
} from "./userDocument.services";
import {toast} from "react-toastify";
import {UserDocument, UserDocumentEditFD, UserDocumentNewFD, UserDocumentPage} from "./userDocument";

export const useGetDocumentByUser = (id:number):UseQueryResult<UserDocument[], Error>=>{
    return useQuery(['documents', id], ()=>getDocumentsByUser(id), {
        staleTime:60000
    })
}

export const useGetOneDocument = (id:number):UseQueryResult<UserDocument, Error>=>{
    return useQuery(['document', id], ()=>getOneDocument(id), {
        staleTime:60000,
        enabled:id!==0
    })
}

export const useGetDocumentByUserPage=(Users:number[], libType:string, namePerso:string, page:number):UseQueryResult<UserDocumentPage, Error>=>{
    let idCentre = 0;
    const queryClient = useQueryClient();
    const UserConnected:UserSimple|undefined = queryClient.getQueryData(["user_connected"])
    if(UserConnected){
        idCentre = UserConnected.Centre.id
    }
    return useQuery(['documents', {Users, libType, namePerso, page}], ()=>getDocumentsByUserPage(Users, libType, namePerso, page, idCentre), {
        staleTime:60000,
        enabled:idCentre!==0
    })
}

export const useGetDocumentByUserPageDate=(Users:number[], From:string, To:string, page:number, namePerso:string):UseQueryResult<UserDocumentPage, Error>=>{
    let idCentre = 0;
    const queryClient = useQueryClient();
    const UserConnected:UserSimple|undefined = queryClient.getQueryData(["user_connected"])
    if(UserConnected){
        idCentre = UserConnected.Centre.id
    }
    return useQuery(['documents', {Users, From, To, page, namePerso}], ()=>getDocumentsByUserPageDate(Users, From, To, page, idCentre, namePerso), {
        staleTime:60000,
        enabled:idCentre!==0 && namePerso!=='x'
    })
}

export const useTrashDocument = ()=>{
    const queryClient = useQueryClient();
    return useMutation(
        (id:number)=>trashDocument(id)
        ,{
            onSuccess:()=>{
                queryClient.invalidateQueries(["documents"]);
                queryClient.invalidateQueries(["document"]);
                queryClient.invalidateQueries(["events"]);
                toast.success("Document supprimé", {})
            },
            onError: (error: any) => {
                toast.error("Erreur : " + error.message, {});
            },
        }
    )
}

export const useSwitchDiffusasble = ()=>{
    const queryClient = useQueryClient();
    return useMutation(
        (datas:UserDocumentSwitchDiffusableFormData)=>switchDiffusable(datas.id, datas.isDiffusable)
        ,{
            onSuccess:()=>{
                queryClient.invalidateQueries(["documents"]);
                queryClient.invalidateQueries(["document"]);
                toast.success("Document modifié", {})
            },
            onError: (error: any) => {
                toast.error("Erreur : " + error.message, {});
            },
        }
    )
}

export const useAddUserDocument = ()=>{
    const queryClient = useQueryClient();
    return useMutation(
        (datas:UserDocumentNewFD)=>{
            return addUserDocument(datas);
        }, {
            onSuccess:()=>{
                queryClient.invalidateQueries(["documents"]);
                queryClient.invalidateQueries(["events"]);
                queryClient.invalidateQueries(["document"]);
            }
        }
    )
}

export const useModifyUserDocument = ()=>{
    const queryClient = useQueryClient();
    return useMutation(
        (datas:UserDocumentEditFD)=>{
            return ModifyUserDocument(datas);
        }, {
            onSuccess:()=>{
                queryClient.invalidateQueries(["documents"]);
                queryClient.invalidateQueries(["events"]);
                queryClient.invalidateQueries(["document"]);
            }
        }
    )
}
export const useModifyUserDocument2 = ()=>{
    const queryClient = useQueryClient();
    return useMutation(
        (datas:UserDocumentEditFD)=>{
            return ModifyUserDocument2(datas);
        }, {
            onSuccess:()=>{
                queryClient.invalidateQueries(["documents"]);
                queryClient.invalidateQueries(["events"]);
                queryClient.invalidateQueries(["document"]);
            }
        }
    )
}