import React from "react";
import styled from "styled-components";
import PulseLoader from "../TimedSpinners/PulseLoader";

interface TimedChipsInfosCtrlProps{
    className?:string;
    title:string;
    Pending:boolean;
    info:string|null;
    themeColor?: "primary" | "secondary" | "tertiary" | "complementary" | "success" | "warning" | "neutre";
    maxWidth?:string;
}

const TimedChipsInfosCtrl = ({className, title, Pending, info}:TimedChipsInfosCtrlProps)=>{
    return (
        <div className={`TimedChipsInfos ${className}`}>
            <div className={`infos-lib`}>{title}</div>
            <div className={`infos-content`}>
                {Pending ?
                    <PulseLoader size={"sm"} themeColor={"Neutre"}/>:
                    <span>{info ? info : 'nc'}</span>
                }
            </div>
        </div>
    )
}
const TimedChipsInfos = styled(TimedChipsInfosCtrl)`
    width: ${props=>props.maxWidth ? props.maxWidth : "max-content"};
    font-size: 13px;
    .infos-lib{
      color:${props=>props.theme.Primary};
      text-align: center;
      padding: 0.5rem;
    }
    .infos-content{
      text-align: center;
      background: ${props=>props.themeColor === "neutre" ? props.theme.NeutreMegaLight : props.themeColor === "primary" ? props.theme.PrimaryExtraLight : props.themeColor === "secondary" ? props.theme.Secondary:
      props.themeColor === "tertiary" ? props.theme.Tertiary : props.themeColor === "complementary" ? props.theme.Complementary : 
      props.themeColor === "success" ? props.theme.SuccessExtraLight : props.themeColor === "warning" ? props.theme.WarningExtraLight : props.theme.Primary};
      color: ${props=>props.themeColor === "neutre" ? props.theme.Neutre : props.themeColor === "primary" ? props.theme.PrimaryExtraDark : props.themeColor === "secondary" ? props.theme.SecondaryDark:
              props.themeColor === "tertiary" ? props.theme.TertiaryDark : props.themeColor === "complementary" ? props.theme.ComplementaryDark :
                      props.themeColor === "success" ? props.theme.SuccessDark : props.themeColor === "warning" ? props.theme.WarningDark : props.theme.PrimaryDark};
      padding: 0.25rem 0.5rem;
      border-radius: 14px;
    }
`

export default TimedChipsInfos;