import styled, {useTheme} from "styled-components";
import {Oval} from "react-loader-spinner";
import logoShort from "../../../assets/images/Logo-Small-Timed-Light.png";
import React from "react";

interface TimedSpinnerCtrlProps{
    className?:string;
    libelle?:string;
    height?:number;
}

const TimedSpinnerCtrl = (props:TimedSpinnerCtrlProps)=>{
    const theme = useTheme();
    return (
        <div className={`timed_spinner ${props.className}`}>
            <div className={`ske-in`}>
                <div className={`spinner-place`}>
                    <Oval
                        height={props.height ? props.height : 38}
                        width={props.height ? props.height : 38}
                        color={theme.Primary}
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel='Chargement'
                        secondaryColor={theme.PrimaryLight}
                        strokeWidth={2}
                        strokeWidthSecondary={2}

                    />
                </div>
                <div className={`Icon-place`}>
                    <img src={logoShort} alt={"logo"}/>
                </div>
            </div>
                {props.libelle &&
                    <div className={"text-place"}>{props.libelle}</div>
                }
        </div>
    )
}

export const TimedSpinner = styled(TimedSpinnerCtrl)`
  display: flex;
  justify-content: flex-start;
  gap: 6px;
  align-items: center;
  .text-place{
    font-weight: bold;
    font-size: 14px;
  }
  .ske-in{
    position: relative;
    width: ${props=>props.height ? props.height+"px" : "38px"};
    height: ${props=>props.height ? props.height+"px" : "38px"};
    .Icon-place{
      position: absolute;
      left: 0%;
      top: 8%;
      img{
        width: ${props=>props.height ? props.height+"px" : "38px"};
        height: auto;
      }
    }
  }
`