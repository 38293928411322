import {dataFetcher} from "../../services/dataGuetter/dataFetcher";


export async function getNewsByParams(params:any|null):Promise<News[]>{
    let TabSearch:string[] = [];
    if(params) {
        for (const [key, value] of Object.entries(params)){
            TabSearch.push(`${key}=${value}`)
        }
    }
    const search = TabSearch.length>0 ? `${TabSearch.join('&')}&pagination=false` : 'pagination=false'
    const res:any = await dataFetcher(`news?${search}`, {method:'get'})
    return res["hydra:member"];
}

export function PostNewNews(datas:NewsFD):Promise<News>{
    const formData = new FormData();
    for (const [key, value] of Object.entries(datas)){
        formData.append(`${key}`, value);
    }
    return dataFetcher<News>(
        `news/postOne`,
        {
            method: "POST",
            body: formData,
        },
        true
    );

}
export function PutNewNews(datas:NewsFD):Promise<News>{
    const formData = new FormData();
    for (const [key, value] of Object.entries(datas)){
        formData.append(`${key}`, value);
    }
    return dataFetcher<News>(
        `news/${datas.id}/putOne`,
        {
            method: "POST",
            body: formData,
        },
        true
    );
}

export function PutOrdreNews(datas:{id:number, ordreAffichage:number}):Promise<News>{
    return dataFetcher(`news/${datas.id}`, {
        method:'put',
        body:{ordreAffichage:datas.ordreAffichage}
    })
}

export function TrashNews(id:number):Promise<News>{
    return dataFetcher(`news/${id}`, {
        method:'delete'
    })
}