import styled from "styled-components";
import {useGetRoleNiveaux} from "../../../../features/roleNiveau/roleNiveau.hooks";
import {useEffect, useState} from "react";
import {DatasSetter} from "../../../../components/functions/ValideDataFunctions";
import {useModifyUserRole} from "../../../../features/user/user.hooks";
import {useGetModules} from "../../../../features/module/module.hooks";
import ModifyLoader from "../components/ModifyLoader";
import InputSelectSetting from "../components/InputSelectSetting";

interface FormEditDroitCtrlProps{
    className?:string;
    Admin:User;
    unSetAdmin:()=>void;
}
interface AdminProps{
    Restreint:Choice|null;
    Niveau:Choice|null;
}
const DefaultAdmin:AdminProps={
    Restreint:null,
    Niveau:null,
}
interface ErrorsProps{
    Niveau:string|null;
    Restreint:string|null;
}
const DefaultErrors:ErrorsProps={
    Niveau:null,
    Restreint:null,
}

interface DetailsProps{
    Niveau:InfosProps;
    Restreint:InfosProps;
}
const TabInfos:DetailsProps = {
    Niveau:{keyProps:"Niveau", libelle:"Droits - Niveau", description:"Détermine les fonctionnalités autorisées pour le collaborateur admin"},
    Restreint:{keyProps:"Restreint", libelle:"Droits - Restriction", description:"Restreint l'administrateur à certaines fonctionnalités comme la lecteur des documents"},
}

const FormEditDroitCtrl = (props:FormEditDroitCtrlProps)=>{
    const mutation = useModifyUserRole();
    const RoleNiveauQuery = useGetRoleNiveaux();
    const SkeletonQuery = useGetModules();
    const [choicesRoles, setChoicesRole] = useState<Choice[]>([])
    const choicesRestreint:Choice[] = [{id:0, libelle:"Non", description:"Le collaborateur n'est pas restreint"}, {id:1, libelle:"Oui", description:"Le collaborateur est restreint dans la lecture des documents notamment"}];
    const [myAdmin, setMyAdmin] = useState<AdminProps>(DefaultAdmin)
    const [errors, setErrors] = useState<ErrorsProps>(DefaultErrors)
    const TabChoices:string[] = ["Niveau", "Restreint"]
    useEffect(() => {
        if(choicesRoles.length>0 && RoleNiveauQuery.data) {
            const roles = props.Admin.roles;
            let maxNiveau = 0;
            roles.forEach(r=>{
                const myRoles = choicesRoles.find(c=>c.type === r);
                console.log(myRoles);
                console.log(choicesRoles);
                if(myRoles){
                    const myRN =  RoleNiveauQuery.data.find(sk=>sk.id === myRoles.id) as RoleNiveau
                    if(myRN.niveau>maxNiveau){
                        maxNiveau = myRN.niveau
                    }
                }
            })
            console.log(maxNiveau);
            const myRoleNivDef = RoleNiveauQuery.data.find(rn=>rn.niveau === maxNiveau) as RoleNiveau;
            const myChoiceNiveau = choicesRoles.find(c=>c.id === myRoleNivDef.id) as Choice;
            const pp = props.Admin.particularParams;
            let myChoiceRestreint = choicesRestreint[0];
            if(pp && pp.hasOwnProperty("isRestreint")){
                myChoiceRestreint = choicesRestreint[pp["isRestreint"]];
            }
            setMyAdmin({Niveau:myChoiceNiveau, Restreint:myChoiceRestreint})
        }
    }, [props.Admin, choicesRoles, RoleNiveauQuery.data]);
    const setMyValue = (value:string|null|Choice|number, id:string)=>{
        DatasSetter<AdminProps, ErrorsProps>(value, id as keyof AdminProps, [], [], TabChoices, [], myAdmin, setMyAdmin,errors,setErrors)
        if(id === "Niveau"){
            SaveNiveau(value as Choice)
        } else {
            SaveRestreint(value as Choice)
        }
    }
    useEffect(() => {
        if(RoleNiveauQuery.data && SkeletonQuery.data){

            setChoicesRole(RoleNiveauQuery.data.filter(i=>i.id>=4 || i.id === 1).sort((a,b)=>a.niveau > b.niveau ? 1: -1).map(rn=>{
                const SkeletonFitered = SkeletonQuery.data.filter(sk=>sk.niveau>rn.niveau)
                return {
                    id:rn.id,
                    libelle:`Niveau ${rn.niveau}`,
                    type:rn.libelle,
                    description:"Autorise à tout sauf "+SkeletonFitered.map(sk=>sk.libelle).join(", ")
                }
            }))
        }
    }, [RoleNiveauQuery.data, SkeletonQuery.data]);
    const SaveNiveau = (niveau:Choice)=>{
        SaveMe(niveau, myAdmin.Restreint as Choice)
    }
    const SaveRestreint= (is:Choice)=>{
        SaveMe(myAdmin.Niveau as Choice, is)
    }
    const SaveMe = (Niveau:Choice, isRestreint:Choice)=>{
        const datas:RolePP={
            isRestreint:isRestreint.id as number,
            RoleNiveau:Niveau.id as number,
            id:props.Admin.id
        }
        mutation.mutate(datas)
    }
    const RemoveAsAdmin = ()=>{
        const datas:RolePP={
            isRestreint:0,
            RoleNiveau:3,
            id:props.Admin.id
        }
        mutation.mutate(datas, {
            onSuccess:()=>{
                props.unSetAdmin();
            }
        })
    }

    return (
        <div className={`formEditDroit ${props.className}`}>
            {(SkeletonQuery.isLoading || RoleNiveauQuery.isLoading) &&
                <ModifyLoader/>
            }
            <div className="wrap_inputs_setting">
                <InputSelectSetting setValue={setMyValue} Error={errors.Niveau} label={"Niveau"} id={"Niveau"}
                                    choices={choicesRoles} Current={myAdmin.Niveau} ItemInfos={TabInfos.Niveau}/>
                <InputSelectSetting setValue={setMyValue} Error={errors.Restreint} label={"Restreint"} id={"Restreint"}
                                    choices={choicesRestreint} Current={myAdmin.Restreint}
                                    ItemInfos={TabInfos.Restreint}/>
                <div className="wrap_delete_poss">
                    <div className="delete_possible">
                        <span className={`link_delete`} onClick={RemoveAsAdmin}>Retirer les droits d'administrateur</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

const FormEditDroit = styled(FormEditDroitCtrl)`
    position: relative;
    .wrap_delete_poss{
        margin-top: 30px;
    }
    .no_delete_poss{
        line-height: 170%;
    }
`

export default FormEditDroit