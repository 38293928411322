import {dataFetcher} from "../../services/dataGuetter/dataFetcher";


export function ModifyCentreParam(id:number, newValue:boolean):Promise<CentreParameter>{
    return dataFetcher(`centre_parameters/${id}`, {
        method:'put',
        body:{statut:newValue}
    })
}

export function AddCentreParam(idCentre:number, idParams:number):Promise<CentreParameter>{
    return dataFetcher(`centre_parameters`, {
        method:'post',
        body:{Centre:`/api/centres/${idCentre}`, Parameter:`/api/parameters/${idParams}`, statut:true}
    })
}