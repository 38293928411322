import React, {useState} from "react";
import styled from "styled-components";
import {RegisterOptions} from "react-hook-form";
import {WrapperInput} from "../TimedAutoCompletion/WrapperInput";

interface TimedInputTextHStyle2CtrlProps{
    /**
     * class css du compossant
     */
    className?:string;
    /**
     * Intitule du champ
     */
    label:string;
    /**
     * type de données dans le input
     */
    type:"text" | "date" | "datetime" | "number" | "password" |"time";
    /**
     * Champ obligatoire ?
     */
    isRequired:boolean;
    /**
     * le champ est-il en erreur ?
     */
    isError:boolean;
    /**
     * Text d'aide si erreur ou complex
     */
    helperText:string|undefined;
    /**
     * valeur initial du champ
     */
    register: any;
    optionRegister?:RegisterOptions;
    getValue:any;
    /**
     * Fonction de sélection du champ
     */
    setFieldValue?:Function;
    /**
     * identifiant du champ
     */
    id:string;
    /**
     * taille du input
     */
    size?:"small" | "normal";
    /**
     * input full
     */
    isFull?:boolean
    step?:number;
    min?:string;
    max?:string;
    onChange?:Function;
    placeHolder?:string;
    disabled?:boolean;
    padding?:string;
}

/**
 * Composant permettant le control des input de type Text / Date / DateTime / password
 *
 */
const TimedInputTextHStyle2Ctrl = ({className, label, type, isRequired, isError, helperText, register, id, isFull, getValue,optionRegister, setFieldValue, size, placeHolder, ...props}:TimedInputTextHStyle2CtrlProps)=>{
    const [isFocus, setIsFocus] = useState(false);
    return (
        <div className={`TimedFormControl ${className} ${isError ? 'onError' : ''} ${(isFocus || getValue(id)!=="" || type==="date" || type==="datetime") ? 'onFill' : ''} ${isFocus ? 'focused' : ''} ${isFull ? 'isFull' : ''}`}>
            <WrapperInput onError={isError} ClickOnIt={()=>{}}>
                <input disabled={props.disabled ? props.disabled : false} placeholder={placeHolder ? placeHolder : label ? label : ''} type={type} {...register(id, optionRegister)} {...props} onFocus={()=>setIsFocus(true)} onBlur={()=>setIsFocus(false)}/>
            </WrapperInput>
            {helperText &&
            <div className={`helper-text ${isError ? 'onError' : ''}`}>{helperText}</div>
            }
        </div>
    )
}

const TimedInputTextHStyle2 = styled(TimedInputTextHStyle2Ctrl)`
  display: inline-flex;
  flex-direction: column;
  position: relative;
  min-width: 0;
  width: 100%;
  padding: 0;
  border: 0;
  vertical-align: top;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: bold;
  padding: ${props => props.padding ? props.padding : "0"};
  &.isFull{
    width: 100%;
    //margin: 0;
  }

  .helper-text {
    color: rgba(0, 0, 0, 0.6);
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    text-align: left;
    margin: 3px 14px 0;
    &.onError{
      color: red;
    }
  }
  
`



export default TimedInputTextHStyle2;