import {useMutation, useQueryClient} from "react-query";
import {AddCentreParam, ModifyCentreParam} from "./centreParameter.services";


export const useModifyCentreParameter = ()=>{
    const queryClient = useQueryClient();
    return useMutation(
        (data:{id:number, newValue:boolean})=>{
            return ModifyCentreParam(data.id, data.newValue);
        }, {
            onSuccess:()=>{
                queryClient.invalidateQueries(["centre"]);
                queryClient.invalidateQueries(["centres"]);
                queryClient.invalidateQueries(["user_connected"]);
            }
        }
    )
}

export const useAddCentreParameter = ()=>{
    const queryClient = useQueryClient();
    return useMutation(
        (data:{idCentre:number, idParams:number})=>{
            return AddCentreParam(data.idCentre, data.idParams);
        }, {
            onSuccess:()=>{
                queryClient.invalidateQueries(["centre"]);
                queryClient.invalidateQueries(["centres"]);
            }
        }
    )
}