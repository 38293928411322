import styled from "styled-components";
import {useGetParamsTimed} from "../../../../hooks/useGetParamsTimed";
import {OneMonthChoice, OneSemaineChoice} from "./GestionHeuresSuppFull";
import {useMemo} from "react";
import {getNumWeek, getWeekByTwoDate, getWeekByTwoDateS} from "../../../../components/functions/TimesFunctions";
import {OneChoiceWeekCard} from "./components/OneChoiceWeekCard";

interface ListChoiceWeeksCtrlProps{
    className?:string;
    SemaineCurrent:OneSemaineChoice|null;
    setSemaineCurrent:(o:OneSemaineChoice)=>void;
    Periode:OneMonthChoice|null;
}

const ListChoiceWeeksCtrl = (props:ListChoiceWeeksCtrlProps)=>{
    const {To, From} = useGetParamsTimed();
    const listSemaineFiltered = useMemo(()=>{
        const list:OneSemaineChoice[] = [];
        if(props.Periode) {
            const StartPeriode = new Date(props.Periode.From);
            const EndPeriode = new Date(props.Periode.To);
            StartPeriode.setHours(12, 0, 0);
            EndPeriode.setHours(12, 0, 0);
            const FinalFrom = From && From >= StartPeriode ? From : StartPeriode;
            const FinalTo = To && To <= EndPeriode ? To : EndPeriode;
            const weeks = getWeekByTwoDateS(FinalFrom, FinalTo)
            weeks.forEach(w => {
                list.push({
                    numSem: getNumWeek(w.Monday),
                    From: w.Monday,
                    To: w.Sunday,
                    nbResult: 0,
                    id:getNumWeek(w.Monday)+w.Monday.getFullYear()
                })
            })
        }
        return list;
    }, [props.Periode, To,From])
    return (
        <div className={`list_choice_months ${props.className}`}>
            {!props.Periode && <p>Aucune période</p>}
            {listSemaineFiltered.map(item=>(
                <OneChoiceWeekCard key={`OSw${item.numSem}`} Semaine={item} onClick={props.setSemaineCurrent} SemaineCurr={props.SemaineCurrent} />
            ))}
        </div>
    )
}

export const ListChoiceWeeks = styled(ListChoiceWeeksCtrl)`
`