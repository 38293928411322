import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {UseGetPointeuseTreatByParams} from "../../../../features/pointeuseTreat/pointeuseTreat.hooks";
import {DecToHHMM, MinutesToHHMM} from "../../../../components/functions/TimesFunctions";
import {MdLogin, MdLogout, MdOutlineWarningAmber} from "react-icons/md";
import {useCentreConnectedData} from "../../../../services/hooks";

interface ResumeDayPointageCtrlProps{
    className?:string;
    PointeuseTreat:PointeuseTreat;
    supervisor?:boolean;
}

interface OnePeriodeTr{
    lib:string;
    timeTotal:string;
    Entree:string;
    Sortie:string;
    warnEntree:boolean;
    warnSortie:boolean;
}

const ResumeDayPointageCtrl = ({className, PointeuseTreat, supervisor}:ResumeDayPointageCtrlProps)=>{
    const centre = useCentreConnectedData();
    const [params, setParams] = useState<any|undefined>(undefined);
    const PointeusesTreatQuery = UseGetPointeuseTreatByParams(params)
    const [total, setTotal] = useState<string>('--:--')
    const [warnTotal, setWarnTotal] = useState<boolean>(false);
    const [periodes, setPeriodes] = useState<OnePeriodeTr[]>([]);
    useEffect(()=>{
        const dateAt = new Date(PointeuseTreat.dateAT.slice(0,10));
        const dateStr = dateAt.toISOString().slice(0,10);
        const user = PointeuseTreat.User;
        let paramsX:any = {"dateAT":dateStr, User:user.id}
        if(supervisor){
            paramsX.Centre = centre;
        }
        setParams(paramsX);
    }, [PointeuseTreat, supervisor, centre])
    useEffect(()=>{
        if(PointeusesTreatQuery.data){
            let listPeriodes:OnePeriodeTr[] = [];
            let totalNb = 0;
            let nbPBTotal = 0;
            PointeusesTreatQuery.data.filter(pt=>!pt.MotifReguleTemps).map((pt:PointeuseTreat, idx:number)=>{
                if(!pt.timeFinal) nbPBTotal++;
                totalNb+= pt.timeFinal ? pt.timeFinal : 0;
                const lib = `Periode de travail ${idx+1}/${PointeusesTreatQuery.data.filter(pt=>!pt.MotifReguleTemps).length}`;
                const timeTotal = pt.timeFinal ? DecToHHMM(pt.timeFinal) : '--:--'
                const Entree = !!pt.pointageEntreeRetenu ? MinutesToHHMM(pt.pointageEntreeRetenu) : '--:--'
                const Sortie = !!pt.pointageSortieRetenu ? MinutesToHHMM(pt.pointageSortieRetenu) : '--:--'
                const warnEntree =  !pt.pointageEntreeRetenu;
                const warnSortie =  !pt.pointageSortieRetenu;
                listPeriodes.push({lib, timeTotal, Entree, Sortie, warnEntree, warnSortie})
                return pt
            })
            setPeriodes(listPeriodes);
            if(nbPBTotal>0){
                setWarnTotal(true);
                setTotal('--:--')
            } else {
                setWarnTotal(false);
                setTotal(DecToHHMM(totalNb))
            }
        }
    }, [PointeusesTreatQuery.data])
    return (
        <div className={`ResumePT ${className}`}>
            {PointeusesTreatQuery.isLoading ? 'loading...':
                <>
                    {periodes.map((item: OnePeriodeTr, idx: number) => (
                        <div className={`one-periode`} key={`one-periode${idx}`}>
                            <div className={"line-head-periode"}>
                                <div className={`lib-periode`}>{item.lib}</div>
                                <div className={`time-periode`}>{item.timeTotal}</div>
                            </div>
                            <div className={"body-periode"}>
                                <div className={"lineEntree is-line"}>
                                    <div className={`warn-place`}>{item.warnEntree ?
                                        <MdOutlineWarningAmber/> : ''}</div>
                                    <div className={`sign-place`}>
                                        <div className={`round-sing`}><MdLogin/></div>
                                    </div>
                                    <div className="time-place">
                                        <div className={`lib-time`}>Entrée</div>
                                        <div className={`time-time`}>{item.Entree}</div>
                                    </div>
                                </div>
                                <div className={"lineSortie is-line"}>
                                    <div className={`warn-place`}>{item.warnSortie ?
                                        <MdOutlineWarningAmber/> : ''}</div>
                                    <div className={`sign-place`}>
                                        <div className={`round-sing`}><MdLogout/></div>
                                    </div>
                                    <div className="time-place">
                                        <div className={`lib-time`}>Sortie</div>
                                        <div className={`time-time`}>{item.Sortie}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    <div className={"Total-line"}>
                        <div className={`block-total ${warnTotal ? 'is-warn' : 'is-good'}`}>
                            <div className={`label`}>Compteur du jour</div>
                            {warnTotal &&
                                <div className={"signWarn"}><MdOutlineWarningAmber/></div>
                            }
                            <div className={`big-total`}>{total}</div>
                        </div>
                        {warnTotal &&
                            <p className={`warning-text`}>Au moins un horaire manquant</p>
                        }
                    </div>
                </>
            }
        </div>
    )
}

export const ResumeDayPointage = styled(ResumeDayPointageCtrl)`
    background: white;
  border-radius: 8px;
  padding: 1.25rem;
  .signWarn{
    position: absolute;
    right: 5px;
    top: 5px;
  }
  .Total-line{
    width: 100%;
    padding: 0.5rem;
    .warning-text{
      font-size: 11px;
      color:${props => props.theme.Complementary};
    }
    .block-total{
      width: 100%;
      border-style: solid;
      border-width: 1px;
      position: relative;
      border-radius: 4px;
      .big-total{
        width: 100%;
        text-align: center;
        padding: 1rem 0;
        font-size: 24px;
      }
      .label{
        background: white;
        font-size: 12px;
        position: absolute;
        padding: 0.2rem;
        top: 0;
        left: 10px;
        transform: translateY(-53%);
      }
      &.is-warn{
        border-color: ${props => props.theme.Complementary};
        .label, .big-total{
          color:${props => props.theme.Complementary};
        }
        
      }
      &.is-good{
        border-color: ${props => props.theme.Secondary};
        .label, .big-total{
          color:${props => props.theme.Secondary};
        }
      }
    }
  }
  .lib-time{
    color: ${props => props.theme.Neutre};
  }
  .lineEntree{
    .round-sing{
      background: ${props => props.theme.SecondaryExtraLight};
      color: ${props=>props.theme.SecondaryDark};
    }  
  }
  .lineSortie{
    .round-sing{
      background: ${props => props.theme.ComplementaryExtraLight};
      color: ${props=>props.theme.ComplementaryDark};
    }
  }
  .round-sing{
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 16px;
    padding: 0.35rem;
    border-radius: 50%;
    svg{
      margin: auto;
    }
  }
  .sign-place{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0.25rem;
  }
  .warn-place{
    width: 35px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color:${props=>props.theme.Complementary};
    svg{
      margin: auto;
    }
  }
  .one-periode{
    margin-bottom: 10px;
    width: 100%;
    .body-periode{
      padding: 0.5rem;
      width: 100%;
      .is-line{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
        margin-bottom: 15px;
      }
    }
    .line-head-periode{
      color: ${props => props.theme.Primary};
      font-weight: bold;
      font-size: 14px;
      display: flex;
      justify-content: start;
      padding-bottom: 0.25rem;
      border-bottom: solid ${props => props.theme.Primary} 1px;
      margin-bottom: 10px;
      .lib-periode, .time-periode{
        width: 50%;
      }
      .time-periode{
        text-align: right;
      }
    }
  }
`