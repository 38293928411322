import {useMutation, useQuery, useQueryClient, UseQueryResult} from "react-query";
import {addExport, getAllExports, getOneExport, trashExport} from "./export.services";
import {toast} from "react-toastify";



export const useGetAllExports = (idCentre:number|null=null):UseQueryResult<Export[], Error>=>{
    return useQuery<Export[], Error>(["exports", idCentre], ()=>getAllExports(idCentre))
}

export const useGetOneExport = (id:number):UseQueryResult<Export, Error>=>{
    return useQuery<Export, Error>(["export", id], ()=>getOneExport(id))
}

export const useAddExport = ()=>{
    const queryClient = useQueryClient();
    return useMutation(
        (datas:ExportNewFD)=>{
            return addExport(datas);
        }, {
            onSuccess:()=>{
                queryClient.invalidateQueries(["exports"])
                queryClient.invalidateQueries(["export"])
                toast.success('Export ajoutée')
            }, onError:()=>{
                toast.error("Impossible d'ajouter l'export")
            }
        }
    )
}

export const useTrashExport = ()=>{
    const queryClient = useQueryClient();
    return useMutation(
        (id:number)=>{
            return trashExport(id);
        }, {
            onSuccess:()=>{
                queryClient.invalidateQueries(["exports"])
                queryClient.invalidateQueries(["export"])
                toast.success('Export supprimé')
            }, onError:()=>{
                toast.error("Impossible de supprimer l'export")
            }
        }
    )
}