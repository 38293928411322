import React, {useEffect} from "react";
import styled from "styled-components";
import {useSortableData} from "../../../hooks/useSortableData";
import {AiOutlineArrowDown, AiOutlineArrowUp} from "react-icons/ai";
import TimedLine from "./TimedLine";
import NoRes from "../../../assets/images/No-Results.png"
import TimedSkeletonTable from "../../atoms/TimedSkeleton/TimedSkeletonTable";

interface TimedTableCtrlProps{
    Titles:TitleTable[];
    sortable:boolean;
    themeColor:string;
    Outline:boolean;
    className?:string;
    Lines:any[];
    scrollable?:boolean;
    loading:boolean;
    maxHeight:string;
    onClickLine?:Function;
    lineFoot?:any;
    isTable?:boolean;
    isTooAction?:boolean;
    noResultText?:string;
}

const TimedTabCtrl = ({Titles, sortable, Outline, className, Lines, scrollable,loading, onClickLine, lineFoot, isTable, isTooAction, noResultText}:TimedTableCtrlProps)=>{
    const {lines, requestSort, sortConfig} = useSortableData(Lines);
    useEffect(()=>{
    },[lineFoot])
    const handleClickTH = (key:string)=>{
        requestSort(key);
    }
    const getStyle = (item:TitleTable)=>{
        let myStyle:any = {};
        if(item.width){
           myStyle.width = item.width
        }
        if(item.StyleHead){
            myStyle = {...myStyle, ...item.StyleHead};
        }
        return myStyle;
    }
    return (
        <div className={`wrap-table ${className} ${(scrollable!==undefined && scrollable) ? 'scrollable' : '' } ${Outline ? 'outline' : ''}`}>
            <table className={isTable ? 'isTable' : ''}>
                <thead>
                    <tr>
                        {Titles.map((item:TitleTable, idx:number)=>(
                            <th key={`head${idx}`} style={getStyle(item)}>
                                {sortable ?
                                (<div className={`switchHead`} onClick={()=>handleClickTH(item.fieldSort)}>
                                    {item.libelle}
                                    {(sortable && sortConfig && sortConfig.key === item.fieldSort) ?
                                        sortConfig.direction === 'ascending' ? <span className="ms-2"><AiOutlineArrowDown/></span> :
                                            <span className="ms-2"><AiOutlineArrowUp/></span>:
                                        ''
                                    }
                                </div>):( item.libelle)
                                }
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                {!loading && lines.map((l, i)=>(
                    <TimedLine data={l} Titles={Titles} key={`lineUsers${i}`} onClickLine={onClickLine!==undefined ? onClickLine : null} isTooAction={isTooAction}/>
                ))}
                {(lines.length === 0 && !loading) &&
                <tr>
                    <td className="text-center" colSpan={Titles.length}>
                        <div className={`block-no-res`}>
                            <div className={"img-place"}>
                                <img src={NoRes} alt={"aucun-resultat"}/>
                            </div>
                            <div className={`legende`}>{noResultText ? noResultText : "Aucun résultat"}</div>
                        </div>
                    </td>
                </tr>
                }
                {(loading) &&
                <tr>
                    <td className="text-center" colSpan={Titles.length}>
                        <TimedSkeletonTable nbLines={10} nbColumns={Titles.length}/>
                    </td>
                </tr>
                }
                </tbody>
                {!!lineFoot &&
                    <tfoot>
                    {lineFoot.map((item:any, idxI:number)=>(
                        <tr key={`lFoot${idxI}`}>
                            {Titles.map((l: TitleTable, idx: number) => (<td key={`c${idx}`} className={`${l.textAlign ? l.textAlign : ''}`}>{item[l.field]}</td>))}
                        </tr>
                    ))

                    }
                    </tfoot>
                }
            </table>
        </div>
    )
}

const TimedTable = styled(TimedTabCtrl)`
  height: 100%;
    table.isTable{
      width: 100%;
      border-spacing: 0;
      border-collapse: collapse;
      background: white;
      tfoot{
        tr{
          border-top : solid ${props => props.theme.NeutreExtraLight} 2px;
          td{
            padding: 0.5rem;
            font-size: 13px;
            font-weight: bold;
              position: relative;
            &.text-center{
              text-align: center;
            }
          }
        }
      }
      tbody{
          width: 100%;
        tr{
          td{
            padding: 0.3rem;
            font-size: 13px;
          }
        }
      }
      thead {
        font-size: 13px;
        tr{
          th{
            border-bottom: solid 1px ${props=>props.theme.NeutreExtraLight};
            padding: 0.75rem 0.5rem;
            font-size: 14px;
            .switchHead{
              font-size: 14px;
              &:hover{
                cursor: pointer;
              }
            }
          }
        }
      }
      
    }
    table:not(.isTable){
      width: 100%;
      height: 100%;
      border-spacing: 0;
      border-collapse: collapse;
      background: white;
      
      thead tr, tbody tr{
        display: ${props=>props.scrollable ? "table" : "revert"};
        width: ${props=>props.scrollable ? "100%" : "auto"};
        table-layout: ${props=>props.scrollable ? "fixed" : "auto"};
      }
      thead{
          display: ${props=>props.scrollable ? "block" : "revert"};
          // overflow: ${props=>props.scrollable ? "hidden" : "revert"};
          scrollbar-gutter: ${props=>props.scrollable ? "stable" : "revert"};
          font-size: 14px;
        tr{
          th{
            text-align: left;
            border-bottom: solid 1px ${props=>props.theme.NeutreExtraLight};
            padding: 0.75rem 0.5rem;
            font-size: 14px;
            .switchHead{
              font-size: 14px;
              &:hover{
                cursor: pointer;
              }
            }
          }
        }
      }
      tbody{
        display: ${props=>props.scrollable ? "block" : "table-row-group"};
          height: ${props=>props.maxHeight};
          overflow-y: ${props=>props.scrollable ? "auto" : "visible"};
          width: 100%;
        tr{
          td{
            padding: 0.5rem;
            font-size: 13px;
            overflow: ${props=>props.scrollable ? "hidden" : "unset"};
            text-overflow: ${props=>props.scrollable ? "ellipsis" : "unset"};
          }
        }
      }
    }
  .block-no-res{
    width: 100%;
    padding: 1rem 0;
    .img-place{
      img{
        width: 200px;
      }
    }
    .legende{
      padding: 0.5rem;
      text-align: center;
      font-size: 14px;
      font-weight: bold;
    }
  }
`

export default TimedTable;