import styled from "styled-components";
import {TimedModalPrePage, TimedModalPrePageCtrlProps} from "../../components/molecules/TimedModal/TimedModalPrePage";
import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {useGetAllUser} from "../../features/user/user.hooks";
import {useGetWeekDate} from "../../services/hooks";
import {getWeekByDate} from "../../components/functions/TimesFunctions";
import {TimedAutoCompletionMulti} from "../../components/atoms/TimedAutoCompletion/TimedAutoCompletionMulti";
import TimedInputTextHStyle2 from "../../components/atoms/TimedInputText/TimedInputTextHStyle2";
import {BiSave, BiSearchAlt} from "react-icons/bi";
import TimedButton from "../../components/atoms/TimedButton/TimedButton";
import TimedPlanning from "../TimedApplication/Plannings/PlanningIndiHebdo/TimedPlanning";

interface ModalQuickHebdoCtrlProps extends TimedModalPrePageCtrlProps{
    
}

interface BornesTime{
    Monday:Date;
    Sunday:Date;
}

const ConvertDateToDDMMYYYY = (d:Date)=>{
    const dayM = `0${d.getDate()}`.slice(-2)
    const monthM = `0${d.getMonth()+1}`.slice(-2)
    const yearM = d.getFullYear();
    return `${dayM}/${monthM}/${yearM}`
}

const ModalQuickHebdoCtrl = (props:ModalQuickHebdoCtrlProps)=>{
    const UsersQuery = useGetAllUser()
    const [Bornes, setBornes] = useState<BornesTime|null>(null)
    const [UserChoose, setUserChoose] = useState<User|null>(null)
    const [linkExpand, setLinkExpand] = useState<LinkExpand>({link:"/timed_application/planning_indi_hebdo", libelle:"Planning hebdo"})
    const methods = useForm({
        mode:'onChange',
        defaultValues:{
            date:'',
            user:''
        }
    })
    useEffect(() => {
        if(UserChoose && Bornes) {
            setLinkExpand(linkE => {
                return {...linkE, link: "/timed_application/planning_indi_hebdo?idCollab=" + UserChoose.id+"&from="+ConvertDateToDDMMYYYY(Bornes.Monday)+"&to="+ConvertDateToDDMMYYYY(Bornes.Sunday)}
            })
        }
    }, [UserChoose, Bornes]);

    const onSubmit = (datas:any)=>{
        if(datas.date !== '' && datas.user!==''){
            const UserPresume = UsersQuery.data?.find(u=>`${u.prenom} ${u.nom}` === datas.user);
            const ReturnDate = getWeekByDate(new Date(datas.date));
            if(UserPresume && ReturnDate){
                setUserChoose(UserPresume);
                setBornes(ReturnDate)
            }
        }
    }
    return (
        <TimedModalPrePage {...props} linkExpand={linkExpand}>
            <div className={`content_quick ${props.className}`}>
                <div className="search_wrap">
                    <form onSubmit={methods.handleSubmit(onSubmit)}>
                        <div className={`wrap_inputs`}>
                            <div className={`one_wrap_input`}>
                                <TimedAutoCompletionMulti
                                    onError={!!methods.formState.errors.user}
                                    setFieldValue={(name:string)=>{
                                        methods.setValue('user', name)
                                    }}
                                    Choices={UsersQuery.data ? UsersQuery.data.map(u=>{
                                        return {id: u.id, libelle: `${u.prenom} ${u.nom}`}
                                    }) : []}
                                    Current={methods.watch("user")}
                                    placeHolder={"Collaborateur"}
                                    MaxList={"250px"}
                                    contentLoading={UsersQuery.isLoading}
                                    disabled={false}
                                    isClearable={true}
                                    clearError={methods.clearErrors}
                                />
                            </div>
                            <div className={`one_wrap_input`}>
                                <TimedInputTextHStyle2
                                    label={"Date"}
                                    type={"date"}
                                    isRequired={true}
                                    register={methods.register}
                                    optionRegister={{required:true}}
                                    getValue={methods.getValues}
                                    id={"date"}
                                    size={"small"}
                                    isError={!!methods.formState.errors.date}
                                    helperText={methods.formState.errors.date && methods.formState.errors.date.type === "required" ? "Champ obligatoire" : ""}
                                    isFull={true}
                                    disabled={false}
                                />
                            </div>
                            <div className={`wrap_send`}>
                                <TimedButton
                                    size={"sm"}
                                    themeColor={"Secondary"}
                                    children={<BiSearchAlt style={{fontSize:"17px"}}/>}
                                    type={"button"}
                                    onClick={methods.handleSubmit(onSubmit)}
                                />
                            </div>
                        </div>
                    </form>
                </div>
                <div className={`content_result`} style={{padding:"1rem"}}>
                    {!UserChoose || !Bornes ?
                        <p>En Attente des éléments</p>:
                        <div className={`wrap_planning_modal`}>
                            <TimedPlanning
                                dateFrom={Bornes?.Monday}
                                dateTo={Bornes?.Sunday}
                                myHeightHour={28}
                                idUser={UserChoose.id}
                            />
                        </div>
                    }
                </div>
            </div>
        </TimedModalPrePage>
    )
}

export const ModalQuickHebdo = styled(ModalQuickHebdoCtrl)`
    .search_wrap{
      padding: 1rem;
    }
  .wrap_inputs{
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    align-items: center;
  }
`