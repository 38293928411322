import React from "react";
import styled, {keyframes} from "styled-components";
import {ImSpinner2} from "react-icons/im";

interface TimedTotalLineCtrlProps{
    className?:string;
    isLoading?:boolean;
    total:number;
    padding?:string;
}

const TimedTotalLineCtrl = ({className, isLoading, total}:TimedTotalLineCtrlProps)=>{
    return (
        <div className={`Total-line ${className}`}>
            <div className={`wrap-total`}>
                <div className={`lib-total`}>Total</div>
                <div className={`res-total`}>
                    {isLoading ?
                        <div className={`spinner-total`}><ImSpinner2/></div>:
                        <div className={`nb-total`}>{total}</div>
                    }
                </div>
            </div>
        </div>
    )
}

const MyRotation = keyframes`
    from{
      transform: rotate(0deg);
    }
  to{
    transform: rotate(360deg);
  }
`

export const TimedTotalLine = styled(TimedTotalLineCtrl)`
  padding: ${props=>props.padding ? props.padding :  0};
  font-weight: bold;
  font-size: 14px;
  .wrap-total{
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    align-items: center;
    .spinner-total{
      width: 18px;
      height: 18px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      animation: ${MyRotation} 0.3s infinite linear;
      svg{
        margin: auto;
      }
    }
  }
`