import React from "react";
import styled from "styled-components";
import {getDateFrByStringNoH} from "../../functions/TimesFunctions";


interface TimedLineCtrlProps{
    data:any,
    Titles:TitleTable[];
    className?:string;
    onClickLine:Function|null;
    isTooAction?:boolean
}

const TimedLineCtrl = ({data, Titles, className, onClickLine, isTooAction}:TimedLineCtrlProps)=>{
    const getStyle = (item:TitleTable)=>{
        let myStyle:any = {};
        if(item.width){
            myStyle.width = item.width
        }
        if(item.StyleCell){
            myStyle = item.StyleCell;
        }
        return myStyle;
    }
    const handleClickLine = (e:any)=>{
        e.stopPropagation();
        if(onClickLine){
            if(isTooAction){
                onClickLine(e, data);
            } else {
                onClickLine(data);
            }

        }
    }

    return (
        <tr className={`TimedLine ${className}`} onClick={handleClickLine}>
            {Titles.map((item:TitleTable, idx:number)=>(
                <td key={`onLine${idx}`} className={`${item.textAlign ? item.textAlign : ''} ${item.date && data[item.field] === '1970-01-01' ? 'is-novisible' : ''}`} style={getStyle(item)}>{item.date ?  getDateFrByStringNoH(data[item.field]) : data[item.field]}</td>
            ))}
        </tr>
    )
}

const TimeLine = styled(TimedLineCtrl)`
    &:hover{
      cursor: ${props=>props.onClickLine ? 'pointer' : 'normal'};
      background: ${props=>props.onClickLine ? props.theme.PrimaryMegaLight : 'transparent'};
    }
    td{
      &.is-novisible{
        opacity: 0;
      }
      &.switch{
        display: none;
      }
      &.btnRef{
        flex-grow: 3;
        .Actions{
          width: 100%;
          display: flex;
          justify-content: end;
          .oneAction{
            margin-left: 5px;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            &:hover{
              cursor: pointer;
              background: #ccc;
            }
          }
        }
      }
    }
`

export default TimeLine;