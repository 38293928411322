import styled from "styled-components";
import PoigneeDrag from "../../components/PoigneeDrag";
import RightCard from "../../components/RightCard";
import SquareIndic from "../../components/SquareIndic";
import LibCard from "../../components/LibCard";
import AvatAno from "../../../../../assets/images/Unisex-Person-with-Glasses.png";

interface OneCardAdminCtrlProps{
    className?:string;
    Admin:User;
    isCurrent?:boolean;
    ClickOnEdit:()=>void;
}

const OneCardAdminCtrl = (props:OneCardAdminCtrlProps)=>{
    const handleError = (e:any)=>{
        e.target.src = AvatAno;
    }
    return (
        <div className={`oneCardAdmin card_draggable ${props.className}`}>
            <PoigneeDrag isNoDrag/>
            <RightCard isCurrent={props.isCurrent} ClickOn={()=>props.ClickOnEdit()}>
                <SquareIndic backColor={"white"} fontColor={"black"}>
                    <img src={`${process.env.REACT_APP_API_URL_ROOT}${props.Admin.avatarUrl}`}
                         alt={`pic ${props.Admin.nom}`} onError={handleError}/>
                </SquareIndic>
                <LibCard text={`${props.Admin.prenom} ${props.Admin.nom.toUpperCase()}`}/>
            </RightCard>
        </div>
    )
}

const OneCardAdmin = styled(OneCardAdminCtrl)``

export default OneCardAdmin